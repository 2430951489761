import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Popover, Row, Image, Tooltip, Modal} from "antd";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import PrestadorConfigContext from "../../../context/PrestadorConfigContext";
import { Rest, TableIcons } from "../../../utils/utils";
import Medicine from "../entities/medicine";
import MedicineVmpModal from "./MedicineVmpModal";
import IconVidal from '../../../images/iconVidal3.png';
import MedicamentoDetailsVidalContainer from './MedicamentoDetailsVidalContainer';

const MedicineInfoPopOver = (props: {medicine?: Medicine,
    idVademecum?: number,
    codeNacional?: string, 
    codeDcpf?: number,
    flagDetallePorBaja?: boolean,
    visible: boolean,
    setMedicine?: (medicine: any) => Medicine})  => {
    const { t } = useTranslation(['medicineConfig']);

    const onClickDoc = (doc: string) => { //Para abrir documento en pestaña de navegador
        window.open(doc, '_blank');
    }

    const onClickDetail = (enModal?:boolean) => { //Para cuando son datos al vuelo y todavía no se ha seleccionado la medicina (tanto Vidal como no Vidal)
        if (props.medicine && props.medicine.idVademecum === props.idVademecum) {
            return;
        }

        if(!vidalEnabled || (props.medicine != null && props.medicine.flagDetallePorBaja == true)
            || (props.flagDetallePorBaja != null && props.flagDetallePorBaja == true) ) {
            Rest<{ type: string; idVademecum: number }, any>().operation({
                type: 'SelectMedicineById',
                idVademecum: props.idVademecum != null ? props.idVademecum : props.medicine?.idVademecum!
            }).then(response => {
                setMedicineSelected(props.setMedicine!(response));
                if(enModal){
                    setModalDetalleVisible(true);
                }
                
            });
        } else {
                setLoadedDescriptionVidal(true);
                setDetailsVidalVisible(true);
        }
    }
    const onSelectVidal = (codeNacional?: any, codeDcpf?: any) => {
        Rest<{type: string; codeNacional?: string; codeDcpf?: number}, any>().operation({type: 'GetMedicineVmpUrl', 
        codeNacional: codeNacional,
        codeDcpf: codeDcpf}).then(response => {
            setVmpUrl(response.url);
        }).then( () => {
                setVmpVisible(true);
                setVmpKey(new Date().getTime())
        })
    }

    const onSelectVidalFromDetailsVidal = (vidalUrl: string) => {
        setVmpUrl(vidalUrl);
        setVmpVisible(true);
        setVmpKey(new Date().getTime());
    }

    const onCloseVmp = () =>{
        setVmpVisible(false);
        setVmpUrl("");
    }

    const [detailsVidalVisible,setDetailsVidalVisible] = useState(false);
    const [loadedDescriptionVidal, setLoadedDescriptionVidal]= useState(false);
    const [modalDetalle, setModalDetalleVisible]= useState(false);
    const [medicineSelected, setMedicineSelected] = useState(props.medicine);
    const [popopverVisible, setPopoverVisible] = useState<boolean>(false);
    const [vmpUrl, setVmpUrl] = useState("");
    const [vmpVisible,setVmpVisible] = useState(false);
    const [vmpKey,setVmpKey] = useState(-1);

    const ctxValue = useContext(PrestadorConfigContext);
    const vidalEnabled = ctxValue.data.vidalEnabled;
    
    const content = ( //Content del popover si no hay vidal
        <div style={{width: '650px'}}>
            <Row gutter={8} style={{paddingBottom: '1rem'}}>
                <Col span={8} style={{textAlign: 'end'}}><b>{t('nationalCodeField')}</b></Col>
                <Col span={16}>{medicineSelected?.codeCodigoNacional}</Col>
            </Row>
            <Row gutter={8} style={{paddingBottom: '1rem'}}>
                <Col span={8} style={{textAlign: 'end'}}><b>{t('medicineNameField')}</b></Col>
                <Col span={16}>{medicineSelected?.nameProducto}</Col>
            </Row>
            <Row gutter={8} style={{paddingBottom: '1rem'}}>
                <Col span={8} style={{textAlign: 'end'}}><b>{t('laboratoryField')}</b></Col>
                <Col span={16}>{medicineSelected?.laboratory}</Col>
            </Row>
            <Row gutter={8} style={{paddingBottom: '1rem'}}>
                <Col span={8} style={{textAlign: 'end'}}><b>{t('pharmaceuticalFormField')}</b></Col>
                <Col span={16}>{medicineSelected?.codePharmaceuticalForm + ' - ' + medicineSelected?.namePharmaceuticalForm}</Col>
            </Row>
            <Row gutter={8} style={{paddingBottom: '1rem'}}>
                <Col span={8} style={{textAlign: 'end'}}><b>{t('viasField')}</b></Col>
                {medicineSelected?.vias && medicineSelected?.vias.length > 0 &&
                    <Col span={16}>{medicineSelected?.vias.map(via =>  via.codeVia + ' - ' + via.nameVia)}</Col>}
            </Row>
            <Row gutter={8} style={{paddingBottom: '1rem'}}>
                <Col span={8} style={{textAlign: 'end'}}><b>{t('dosageField')}</b></Col>
                <Col span={16}>{medicineSelected?.dosage}</Col>
            </Row>
            <Row gutter={8} style={{paddingBottom: '1rem'}}>
                <Col span={8} style={{textAlign: 'end'}}><b>{t('psychotropicField')}</b></Col>
                <Col span={16}>{medicineSelected?.psychotropic ? t('yes') : t('no')}</Col>
            </Row>
            <Row gutter={8} style={{paddingBottom: '1rem'}}>
                <Col span={8} style={{textAlign: 'end'}}><b>{t('narcoticField')}</b></Col>
                <Col span={16}>{medicineSelected?.narcotic ? t('yes') : t('no')}</Col>
            </Row>
            <Row gutter={8} style={{paddingBottom: '1rem'}}>
                <Col span={8} style={{textAlign: 'end'}}><b>{t('dcpfField')}</b></Col>
                <Col span={16}>{medicineSelected?.codeDcpf + ' - ' + medicineSelected?.nameDcpf}</Col>
            </Row>
            <Row gutter={8} style={{paddingBottom: '1rem'}}>
                <Col span={8} style={{textAlign: 'end'}}><b>{t('activePrinciplesField')}</b></Col>
                <Col span={16}>
                    {medicineSelected?.activePrinciples ?
                        medicineSelected?.activePrinciples.map(ap => ap.codeActivePrinciple + ' - ' + ap.nameActivePrinciple).join(',')
                    :
                        '-'}
                </Col>
            </Row>
            {medicineSelected?.photos &&
                <Row gutter={8} style={{paddingBottom: '1rem'}}>
                    <Col span={8} style={{textAlign: 'end'}}><b>{t('imagesField')}</b></Col>
                    <Col span={16}>
                        {medicineSelected?.photos.map((photo, i) => {
                            return <Image key={i} width={150} src={photo.image} />
                        })}
                    </Col>
                </Row>
            }
            {medicineSelected?.docs &&
                <Row gutter={8} style={{paddingBottom: '1rem'}}>
                    <Col span={8} style={{textAlign: 'end'}}><b>{t('documentsField')}</b></Col>
                    <Col span={16}>
                    {medicineSelected?.docs.map((doc, i) => {
                        return <Button key={i} type='link' onClick={() => onClickDoc(doc.urlDocument)}>{doc.urlDocument}</Button>
                    })}
                    </Col>
                </Row>
            }
        </div>
    )

    const closeMedicamentoDetailsVidal = () => {
        setLoadedDescriptionVidal(false);
        setDetailsVidalVisible(false);
    }


    return (
    <>
    {(props.idVademecum || props.codeDcpf) ? //Cuando es SelectMedicine y no hay medicine establecida
        ((props.codeNacional || props.codeDcpf) ? 
            (ctxValue.data.vidalEnabled ? //Vidal service
                
                <div className="selectMedicineDetalleVidalDiv">
                    {props.idVademecum && <Tooltip title={t('medicineDetailButton')}>
                        <Button size='small'
                                type={popopverVisible && medicineSelected?.idVademecum === props.idVademecum ? 'primary' : 'default'}
                                icon={TableIcons.getTableIcon(TableIcons.TableIcon.eye)}
                                style={{color: '#0091FF', fontSize: '0.8rem', marginRight: '8px'}}
                                onClick={() => onClickDetail(true)}/>
                    </Tooltip>}
                    
                    {vidalEnabled && 
                     <Tooltip title={t('vidalMedicineButton')}>
                        <Button size='small' type='default'
                            onClick={() => {onSelectVidal(props.codeNacional,props.codeDcpf)}}
                            icon={<img src={IconVidal} alt= 'iconVidal' height={16}
                            style={{marginTop: '-2px', marginRight: '8px'}}/>}
                        >
                            <span style={{color: '#AE2939', fontWeight: 'bold'}}>{t('medicineVMPSheet')}</span>
                        </Button>
                        </Tooltip>
                    }
                </div>
            :
                <Popover content={content} trigger='click' placement='leftTop' style={{width: '650px'}}
                        overlayClassName='no-arrow'
                        onVisibleChange={visible => setPopoverVisible(visible)}>
                    <Tooltip title={t('medicineDetailButton')}>
                        <Button size='small'
                                type={popopverVisible && medicineSelected?.idVademecum === props.idVademecum ? 'primary' : 'default'}
                                icon={TableIcons.getTableIcon(TableIcons.TableIcon.eye)}
                                style={{color: '#0091FF', fontSize: '0.8rem'}}
                                onClick={() => onClickDetail()}/>
                    </Tooltip>
                </Popover>)     
        : 
            (<FontAwesomeIcon icon={faInfoCircle} onClick={()=> onClickDetail((!ctxValue.data.vidalEnabled || (props.medicine != null && props.medicine.flagDetallePorBaja == true)
                || (props.flagDetallePorBaja != null && props.flagDetallePorBaja == true)) ? true:undefined)} className='icon'/>)
        )
    :   
         
        <div className='medication-card-info'>
            {props.medicine?.photos &&
                <div className='medication-photo'>
                    <div className='loading-img'>
                        <img alt='' src={props.medicine?.photos[0].image} onLoad={e => {
                            e.currentTarget.parentElement!.classList.remove('loading-img');
                        e.currentTarget.parentElement!.style.width='auto'}} />
                    </div>
                </div>
            }

            <div className='medication-info'>
                <div className='medication-name'>
                    {props.medicine?.nameProducto}
                    {!ctxValue.data.vidalEnabled? //No hay vidal enabled entonces solo popover, lo hay entonces abre modal
                    <Popover content={content} trigger='click' placement='rightTop' style={{width: '650px'}}><FontAwesomeIcon icon={faInfoCircle} className='icon'/></Popover>:
                    <FontAwesomeIcon icon={faInfoCircle} className='icon' onClick={()=> onClickDetail((!ctxValue.data.vidalEnabled || (props.medicine != null && props.medicine.flagDetallePorBaja == true)
                        || (props.flagDetallePorBaja != null && props.flagDetallePorBaja == true)) ? true:undefined)}/>
                    }
                </div>

                <div className='medication-cn'>{t('nationalCodeField') + ' ' + props.medicine?.codeCodigoNacional}</div>
            </div>
        </div>
        
    }



    {loadedDescriptionVidal && <MedicamentoDetailsVidalContainer //Detalle Vidal del medicamento
        MedicamentoDetailsVidalVisible={detailsVidalVisible}
        key={props.idVademecum? props.idVademecum : props.medicine?.idVademecum!}
        idVademecum={props.idVademecum? props.idVademecum : props.medicine?.idVademecum!}
        onSelectVidalFromDetailsVidal={onSelectVidalFromDetailsVidal}
        onCloseMedicamentoDetailsVidal={closeMedicamentoDetailsVidal}
    />}
    {<MedicineVmpModal //Vmp del medicamento Vidal
                key={vmpKey}
                url={vmpUrl}
                visible={vmpVisible}
                onCancel={onCloseVmp}
    />}
    {<Modal
            style={{top: 40}}
            visible={modalDetalle}
            onCancel={() => {setModalDetalleVisible(false)}}
            width={900}
            destroyOnClose
            footer={null}
    >
        {content}
    </Modal>
    }
    </>
    );
}
export default MedicineInfoPopOver;
