import { FormInstance, Modal } from 'antd';
import React from 'react';
import View from './newAditionalPatientInfo';
import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import FormErrorField from "../../utils/form/formErrorField";
import { AditionalPatientInfoData } from './entities/aditionalPatientInfoData';
import { Rest } from '../../utils/utils';
import { AlergieData } from './entities/alergieData';
import AlergiesSelectContainer from './alergiesSelectContainer';
import { PatologiaData } from './entities/patologiaData';
import PatologiesSelectContainer from './patologiesSelectContainer';

interface IProps {
    visible?: boolean;
    patientSelected: number;
    onClose: (save?:boolean) => void;
    flagFromInfoPatient?: number;
}
export interface IState {
    loaded?: boolean;
    altura?: number;
    peso?: number;
    embarazo?: number;
    semanas?: number;
    lactancia?: boolean;
    alergias?: AlergieData[];
    patologias?: PatologiaData[];
    newAditionalPatientInfoKey?: number;
    aditionalInfoVisible?: boolean;
    addAlergiesVisible?: boolean;
    addAlergiesViewKey?: number;
    addPatologiesVisible?: boolean;
    addPatologiesViewKey?: number;
    errorFields?:  FormErrorField[];
    formularioForm?: FormInstance;
    newAlergies?: number[];
    deleteAlergies?:number[];
    newPatologies?: number[];
    deletePatologies?:number[];

}

class AditionalPatientInfoContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public state: IState = {
        errorFields: [],
        newAlergies: [],
        deleteAlergies: [],
        newPatologies:[], 
        deletePatologies: [],
        aditionalInfoVisible: true,
        embarazo: 0
    };

    public componentDidMount() {
        if (this.props.visible) {
            this.loadData();
        }
    }
    
    public render() {
        return (
            <Modal title={""}
                   className= {"aditionalInfoModal"}
                   style={{top: 40}}
                   visible={this.props.visible}
                   onCancel={((!this.state.addAlergiesVisible && !this.state.addPatologiesVisible) ? () => this.props.onClose() : (this.state.addAlergiesVisible ? () => this.onCloseAddAlergies() : () => this.onCloseAddPatologies()))}
                   onOk={() => this.validateAndSave(this.state.formularioForm!)}
                   bodyStyle={{paddingTop: '0.5rem', paddingBottom: '1.5rem'}}
                   width={700}
                   okText={this.props.t('buttons:save')} cancelText={this.props.t('buttons:cancel')}
                   destroyOnClose
                   okButtonProps={(this.state.addAlergiesVisible || this.state.addPatologiesVisible) ? { style: { display: 'none' }}:{}}
            >
                {this.state.aditionalInfoVisible && <View
                {...this.state}
                flagFromInfoPatient={this.props.flagFromInfoPatient}
                visible={this.state.aditionalInfoVisible}
                key={this.state.newAditionalPatientInfoKey}
                onCancel={() => this.props.onClose()}
                onAddAlergies={this.onAddAlergies}
                onAddPatologies={this.onAddPatologies} 
                addToDeleteAlergies={this.addToDeleteAlergies}
                unmarkToDeleteAlergies={this.unmarkToDeleteAlergies}
                addToDeletePatologies={this.addToDeletePatologies}
                unmarkToDeletePatologies={this.unmarkToDeletePatologies}
                onSubmit={this.validateAndSave}
                asignateForm={this.asignateForm}/>}
                
                {this.state.addAlergiesVisible && <AlergiesSelectContainer
                    key={this.state.addAlergiesViewKey}
                    visible={this.state.addAlergiesVisible}
                    onClose={this.onCloseAddAlergies}
                />}

                {this.state.addPatologiesVisible && <PatologiesSelectContainer
                    key={this.state.addPatologiesViewKey}
                    fromDiagnostico={false}
                    visible={this.state.addPatologiesVisible}
                    onClose={this.onCloseAddPatologies}
                />}
            </Modal>
        );
    }

    private loadData = () => {
        Rest<{type: string, idPaciente: number}, AditionalPatientInfoData>().operation({ type: 'GetAditionalPatientInfo', idPaciente: this.props.patientSelected}).then(
            response => this.setState({embarazo:response.embarazo,
                                       semanas: response.embarazo ? response.embarazo: undefined,
                                       peso: response.peso,
                                       altura: response.altura,
                                       lactancia: response.lactancia,
                                       alergias: response.alergias,
                                       patologias: response.patologias,
                                       aditionalInfoVisible: true,
                                       newAditionalPatientInfoKey: new Date().getTime()}));
    }

    private onAddAlergies = (form: FormInstance) => {

        var peso = form.getFieldValue("peso")
        var altura = form.getFieldValue("altura")
        var embarazo = form.getFieldValue("embarazo") ? (form.getFieldValue("semanas") ? form.getFieldValue("semanas") : form.getFieldValue("embarazo")) : form.getFieldValue("embarazo");
        var semanas = form.getFieldValue("embarazo") ? (form.getFieldValue("semanas") ? form.getFieldValue("semanas") : undefined) : form.getFieldValue("embarazo");
        var lactancia = form.getFieldValue("lactancia")
        this.setState({aditionalInfoVisible: false,
            addAlergiesVisible: true, 
            addAlergiesViewKey: new Date().getTime(),
            peso,
            altura,
            embarazo,
            semanas,
            lactancia
           });
    }

    private addToDeleteAlergies = (idAlergia: number) => {
        this.setState({deleteAlergies: this.state.deleteAlergies?.concat(idAlergia)})
        
    }

    private unmarkToDeleteAlergies = (idAlergia: number) => {
        var cadenaAux: number[]= [];
        cadenaAux= this.state.deleteAlergies!;
        cadenaAux.splice(this.state.deleteAlergies!.indexOf(idAlergia),1);
        this.setState({deleteAlergies: cadenaAux})
    }

    private asignateForm = (form: FormInstance) => {
        this.setState({formularioForm: form});
    }

    private onCloseAddAlergies = (alergie?: AlergieData) => {   
        if(alergie!=undefined && alergie!=null && this.state.alergias?.filter(a => {return a.idAlergia==alergie.idAlergia}).length==0 && !this.state.newAlergies!.includes(alergie.idAlergia!)){
            this.setState({aditionalInfoVisible: true, addAlergiesVisible: false,alergias:this.state.alergias!.concat(alergie), newAlergies: this.state.newAlergies!.concat(alergie.idAlergia!)});
        }else{
            this.setState({aditionalInfoVisible: true, addAlergiesVisible: false,addAlergiesViewKey:undefined});
        }
    }

    private onAddPatologies = (form: FormInstance) => {
        var peso = form.getFieldValue("peso")
        var altura = form.getFieldValue("altura")
        var embarazo = form.getFieldValue("embarazo") ? (form.getFieldValue("semanas") ? form.getFieldValue("semanas") : form.getFieldValue("embarazo")) : form.getFieldValue("embarazo");
        var semanas = form.getFieldValue("embarazo") ? (form.getFieldValue("semanas") ? form.getFieldValue("semanas") : undefined) : undefined;
        var lactancia = form.getFieldValue("lactancia")
        this.setState({addPatologiesVisible: true,
            aditionalInfoVisible: false,
            peso,
            altura,
            embarazo,
            semanas,
            lactancia});
           
    }

    private addToDeletePatologies = (idCie10: number) => {
        this.setState({deletePatologies: this.state.deletePatologies?.concat(idCie10)})
    }

    private unmarkToDeletePatologies = (idCie10: number) => {
        var cadenaAux: number[]= [];
        cadenaAux= this.state.deletePatologies!;
        cadenaAux.splice(this.state.deletePatologies!.indexOf(idCie10),1);
        this.setState({deletePatologies: cadenaAux})
    }

    private onCloseAddPatologies= (patologie?: PatologiaData) => {   
        if(patologie!=undefined && patologie!=null && this.state.patologias?.filter(p => {return p.idCie10==patologie.idCie10}).length==0 && !this.state.newPatologies!.includes(patologie.idCie10!)){
            this.setState({aditionalInfoVisible: true,addPatologiesVisible: false,patologias:this.state.patologias!.concat(patologie), newPatologies: this.state.newPatologies!.concat(patologie.idCie10!)});
        }else{
            this.setState({aditionalInfoVisible: true,addPatologiesVisible: false,addPatologiesViewKey:undefined});
        }
    }

    private validateAndSave = (form: FormInstance) => {
       
       this.setState({ errorFields: [] }, () => {
            form.validateFields().then((values) => {
                const aditionalPatientInfo = {
                    peso: values.peso,
                    altura: values.altura,
                    embarazo: values.embarazo ? values.semanas : values.embarazo,
                    lactancia: values.lactancia
                } as AditionalPatientInfoData;
                Rest<{ type: string,idPaciente: number,valuePeso: any, valueAltura: any, flagEmbarazo: number, flagLactancia: number,newAlergies: number[],deleteAlergies: number[],newPatologies: number[],deletePatologies: number[], flagFromInfoPatient: number}, number>().operation({
                    type: 'UpdateAditionalPatientInfo',
                    idPaciente: this.props.patientSelected!,
                    valuePeso: aditionalPatientInfo.peso? aditionalPatientInfo.peso: null,
                    valueAltura: aditionalPatientInfo.altura? aditionalPatientInfo.altura : null,
                    flagEmbarazo:aditionalPatientInfo.embarazo == 0 ? 0 : aditionalPatientInfo.embarazo!,
                    flagLactancia:aditionalPatientInfo.lactancia! == true ? 1: 0,
                    newAlergies:this.state.newAlergies!,
                    deleteAlergies:this.state.deleteAlergies!,
                    newPatologies:this.state.newPatologies!, 
                    deletePatologies:this.state.deletePatologies!,
                    flagFromInfoPatient:this.props.flagFromInfoPatient!,
                }).then(() => {
                    this.props.onClose(true);
              });
                
            });
                
        });

        
    };

}

export default withTranslation('newPatientPrescription')(withRouter(AditionalPatientInfoContainer));