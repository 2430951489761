import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';
import {Form, Button, Row, Col, Tooltip} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHospitalAlt} from '@fortawesome/free-solid-svg-icons/faHospitalAlt';
import Modal from 'antd/lib/modal/Modal';
import cx from 'classnames';
import {LocationPrestador} from './entities/locationPrestador';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import {PrestadorLocationInfo} from '../../layout/entities/HeaderInfo';


const SelectLocation = (props: {
    visible?: boolean;
    // locationPrestadorList?: LocationPrestador[];
    onCancel: (saved: boolean) => void;
    // onClickLocationDetails: (idx: number, idLocation: number) => void;
    newLocation: () => void;


    localization?: PrestadorLocationInfo;
    onSelectLocation: (selectedLocation: LocationPrestador) => void;
    onEditLocation: (location: LocationPrestador) => void;


}) => {

    const {t} = useTranslation(['newLocationPrescription']);
    const [form] = Form.useForm();

    const [locationSelected, setLocationSelected] = useState<number | undefined>(
        props.localization ? props.localization?.idSelectedLocalization : undefined);



    const footer = (
        <Row justify='space-between'>
            <Col>
                <Button type='primary' onClick={() => props.newLocation()}
                        icon={<FontAwesomeIcon icon={faHospitalAlt}
                                               style={{marginRight: '0.5rem', cursor: 'pointer'}}/>}>
                    {t('newLocationButton')}
                </Button>
            </Col>

            <Col>
                <Button type='default' style={{cursor: 'pointer'}} onClick={() => props.onCancel(false)}>
                    {t('buttons:close')}
                </Button>
            </Col>
        </Row>
    );

    const locationPrestadorList = (props.localization?.prestadorLocationList || []).map((loc, i) => {
        const descLocalizacion = (loc.valueDireccion ? (' - ' + loc.valueDireccion) : '') + (loc.valueDireccion2 ? ', ' + loc.valueDireccion2 : '')
            + (loc.codeCodigoPostal ? ', ' + loc.codeCodigoPostal : '') + (loc.nameMunicipio ? ', ' + loc.nameMunicipio : '') +
            (loc.nameProvincia ? ', ' + loc.nameProvincia : '');

        return (
            <Row className={cx('location_container')} key={i}>
                <Col span={18} className='location__details'>
                    <b>{loc.nameLocalizacion}</b>
                    <span title={descLocalizacion}> {descLocalizacion} </span>
                </Col>
                <Col span={3}>
                    <span className='button_selected'>
                        {loc.selected ?
                            <Button size='small' className={'selected__button'}
                                    onClick={() => props.onSelectLocation(loc)}>
                                {t('selectedButton')}
                            </Button>
                            :
                            <Button size='small' className={'select__button'}
                                    onClick={() => props.onSelectLocation(loc)}>
                                {t('selectButton')}
                            </Button>}


                        <Tooltip title={loc.idPrestador === undefined ? undefined : t('prescription:editLocation')}>
                            <Button
                                disabled={loc.idPrestador === undefined}
                                className='button__details' size='small' style={{marginLeft: '0.2rem'}}
                                icon={<FontAwesomeIcon icon={faPencilAlt} className='anticon'/>}
                                onClick={() => props.onEditLocation(loc)}
                            />
                        </Tooltip>


                    </span>
                </Col>
            </Row>
        );
    });

    return (
        <Modal title={t('selectLocationTitle')}
               style={{top: 40}}
               visible={props.visible}
               cancelText={t('buttons:close')}
               onCancel={() => props.onCancel(false)}
               bodyStyle={{paddingTop: '0.5rem', paddingBottom: '1.5rem'}}
               width={600}
               footer={footer}
               destroyOnClose
        >

            <Form layout='vertical' form={form} size='large'>
                <div className='locations_title'>{t('locationsTitle')}</div>

                {locationPrestadorList && locationPrestadorList.length > 0 ?
                    locationPrestadorList :
                    <div className={cx('location_container', {'noData': true})}>{t('noLocationLabel')}</div>
                }
            </Form>
        </Modal>
    );
};
export default SelectLocation;