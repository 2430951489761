import React, { ReactText } from 'react';

import { Button, Col, Modal, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import EnumEmittedPrescriptionAction from '../../enum/EnumEmittedPrescriptionAction';
import { PrescripcionToCopy } from '../entities/prescripcionToCopy';
import { AlignType } from 'rc-table/lib/interface';
import { IState as IStateContainer } from './EmittedPrescriptionActionsContainer';

interface IProps {
    visible?: boolean;
    action?: number;
    onCancel: () => void;
    onSelectPrescriptions: (selectedPrescriptions: number[]) => void;
    onSelectAll: (checked: boolean) => void;
    validation: () => void;
}

const EmittedPrescriptionActions = (props: IProps & IStateContainer) => {

    const { t } = useTranslation(['emittedPrescriptionActions']);

    const title = () => {
        let title = '';
        if (props.action === EnumEmittedPrescriptionAction.NEW) {
            title = t('newTitle');
        }
        if (props.action === EnumEmittedPrescriptionAction.MODIFY) {
            title = t('modifyTitle');
        }
        if (props.action === EnumEmittedPrescriptionAction.VOID) {
            title = t('voidTitle');
        }
        return title;
    }

    const message = () => {
        let message = '';
        if (props.action === EnumEmittedPrescriptionAction.NEW) {
            message = t('newMessage');
        }
        if (props.action === EnumEmittedPrescriptionAction.MODIFY) {
            message = t('modifyMessage');
        }
        if (props.action === EnumEmittedPrescriptionAction.VOID) {
            message = t('voidMessage');
        }
        return message;
    }

    const choose = () => {
        let choose = '';
        if (props.action === EnumEmittedPrescriptionAction.NEW) {
            choose = t('newChoose');
        }
        if (props.action === EnumEmittedPrescriptionAction.MODIFY) {
            choose = t('modifyChoose');
        }
        if (props.action === EnumEmittedPrescriptionAction.VOID) {
            choose = t('voidChoose');
        }
        return choose;
    }

    const okButton = () => {
        let okButton = '';
        if (props.action === EnumEmittedPrescriptionAction.NEW) {
            okButton = t('newOkButton');
        }
        if (props.action === EnumEmittedPrescriptionAction.MODIFY) {
            okButton = t('modifyOkButton');
        }
        if (props.action === EnumEmittedPrescriptionAction.VOID) {
            okButton = t('voidOkButton');
        }
        return okButton;
    }

    const footer = (
        
        <Row justify='end' gutter={8}>
            <Col>
                <Button type='default' onClick={() => props.onCancel()}>
                    {t('buttons:cancel')}
                </Button>
            </Col>
            <Col>
                <Button type='primary' danger={props.action === EnumEmittedPrescriptionAction.VOID} onClick={props.validation}>
                    {okButton()}
                </Button>
            </Col>
        </Row>
    )
    
    const rowSelection = {
        onChange: (selectedRowKeys: ReactText[]) => {
            props.onSelectPrescriptions(selectedRowKeys.map(row => Number(row)));
        },
        
        onSelectAll: (selected: boolean) => {
            props.onSelectAll(selected);
        },

        getCheckboxProps: (record: PrescripcionToCopy) => ({
            disabled: (props.action === EnumEmittedPrescriptionAction.NEW && record.fechaBaja)?true:false, // Column configuration not to be checked
            name: record.valuePresentacion ? record.valuePresentacion : record.valueComposicionFmv,
          }),        

        selectedRowKeys: props.selectedPrescriptions
    }

    const prescriptionRender = (value: any, record: PrescripcionToCopy) => {
        return record.valuePresentacion ? record.valuePresentacion : record.valueComposicionFmv;
    }

    const columns = [
        {title: t('prescriptionColumn'), align: 'left' as AlignType, ellipsis: true, render: prescriptionRender}
    ];

    return (
        <Modal title={<b>{title()}</b>}
            style={{ top: 40 }}
            visible={props.visible}
            onCancel={() => props.onCancel()}
            footer={footer}
            width={800}
            //okText={t('buttons:save')} cancelText={t('buttons:cancel')}
            destroyOnClose
        >
            <p>{message()}</p>
            <p>{choose()}</p>

            <div className='table-container'>
                <Table 
                    columns={columns}
                    dataSource={props.prescripciones}
                    rowSelection={rowSelection}
                    size='small'
                    pagination={false}
                    rowClassName={(record, index) => (props.action === EnumEmittedPrescriptionAction.NEW && record.fechaBaja)?'ant-table_red':''}
                />
            </div>
        </Modal>
    );
    
}
export default EmittedPrescriptionActions;