import React from 'react';

import { Modal, Result } from 'antd';
import { useTranslation } from 'react-i18next';

interface IProps {
    visible?: boolean;
    onClose: () => void;
}

const CollegeErrorModal = (props: IProps) => {

    const { t } = useTranslation(['collegeError']);

    return (
        <Modal
            style={{ top: 40 }}
            visible={props.visible}
            onCancel={() => props.onClose()}
            closable
            bodyStyle={{ paddingTop: '2rem', paddingBottom: '1.5rem' }}
            width={430}
            footer={null}
            destroyOnClose
        >
            <Result
                status='error'
                title={t('title')}
                subTitle={t('subtitle')} />
        </Modal>
    )
}
export default CollegeErrorModal;