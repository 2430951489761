import { faCheckCircle, faExclamationTriangle, faEye, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Button } from "antd";
import IconVidal from '../../images/iconVidal3.png';
import loading from "../../images/loading.gif";
import React from "react";
import { useTranslation } from "react-i18next";
import prescriptionLineUnsecurized from "../savePrescription/entities/prescriptionLineUnsecurized";

const PrescriptionAlertsPanel = (
    props: {
        visible?: boolean;
        alertsMaxLevel?: string;
        nAlertsMaxLevel?: number;
        nAlertsRest?: number;
        prescriptionLinesList?: prescriptionLineUnsecurized[];
        pending?:boolean;
        waitingAlerts?: boolean;
        onClickAlertsVidalInforme: () => void;
        onClickShowAlertsModal: () => void;
    },
) => {
    const {t} = useTranslation(['prescription']);
    return (
        <>
            {(props.pending==true || props.pending==undefined) && (!props.nAlertsMaxLevel ?
                <>
                    <div className={"alerts "+" ICON"}>
                        {props.waitingAlerts==true?<img src={loading} alt="loading..." className='gifLoading'/>:(props.alertsMaxLevel=="INFO" ? <FontAwesomeIcon icon={faCheckCircle}/>: <FontAwesomeIcon icon={faExclamationTriangle}/>)}
                    </div>  
                    <div className={"alerts noData"}>
                            <Row align='middle'>   
                                <Col>
                                    {t("noAlerts")}
                                </Col>
                            </Row>
                    </div>
                </>
                : (props.prescriptionLinesList!.length>1 || props.prescriptionLinesList!.filter(i =>{ return i.flagUnsecurized==true}).length!=1) ?
                (<>
                    <div className={"alerts "+props.alertsMaxLevel+" ICON"}>
                        {props.waitingAlerts==true?<img src={loading} alt="loading..." className='gifLoading'/>:(props.alertsMaxLevel=="INFO" ? <FontAwesomeIcon icon={faCheckCircle}/>: <FontAwesomeIcon icon={faExclamationTriangle}/>)}
                    </div>  
                    <div className={"alerts "+props.alertsMaxLevel}>
                            <Row>   
                                <Col className='titleAlertas'>
                                    {t("alert_"+props.alertsMaxLevel,{n: props.nAlertsMaxLevel})}
                                </Col>
                                <Button className='button__details'
                                        icon={<FontAwesomeIcon icon={faEye} className='anticon' />}
                                        onClick={props.onClickShowAlertsModal} />
                                <Button className='button_vidal_alerts' size='small' type='default' onClick={() =>{props.onClickAlertsVidalInforme()}}
                                        icon={<img src={IconVidal} height={16} style={{marginTop: '-2px', marginRight: '8px'}}/>}>
                                    <span style={{color:'#AE2939', fontWeight:'bold'}}>{'Análisis prescripción'}</span>
                                </Button>
                            </Row>
                            <Row>
                                {t("otherAlertas",{n:props.nAlertsRest})}
                            </Row>
                            {props.prescriptionLinesList!.filter(i =>{return i.flagUnsecurized==true}).map(m =>
                                <Row> <div className="unsecurizedRow"><FontAwesomeIcon icon={faInfoCircle}/>{t("unsecurizedMultipleMessage",{name:m.name})}</div></Row>
                            )}
                    </div>
                </>) : (
                        <>
                            <div className={"alerts "+" ICON"}>
                                {props.waitingAlerts==true?<img src={loading} alt="loading..." className='gifLoading'/>:<FontAwesomeIcon icon={faExclamationTriangle}/>}
                            </div>  
                            <div className={"alerts noData unsecurized"}>
                                    <Row align='middle'>   
                                        <Col>
                                            {t("unsecurizedSingleMessage")}
                                        </Col>
                                    </Row>
                            </div>
                        </>
                )
            )}
        </>    
    );
    
};

export default PrescriptionAlertsPanel;