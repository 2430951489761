import { Modal} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    visible?: boolean;
    onClose: () => void; 
}

const PrivacyPolicyModal = (props: IProps) => {

    const { t } = useTranslation(['privacyPolicy']);

    return (
        <Modal
            visible={props.visible || false}
            footer={null}
            destroyOnClose
            closable={true}
            width='900px'
            style={{ top: 40 }}
            onCancel={props.onClose}
            maskClosable={true}>

            <div className='privacy-policy-modal'>
                <div className='title-policy-modal'>{"Política de privacidad de ereceta.es"}</div>
                    <div>{"Esta Aplicación recoge algunos Datos Personales de sus Usuarios"}</div>
                    <div>{"El presente documento puede imprimirse como referencia utilizando el comando de impresión en las opciones de configuración de cualquier navegador."}</div>
                <div className='section-policy-modal'><h1>{"Titular y Responsable del tratamiento de los Datos"}</h1></div>

                <div>{"El responsable del tratamiento de los datos personales recogidos en el Sitio Web es Delonia Software S.L (en adelante, Responsable del tratamiento), siendo sus datos de contacto en caso de que sea necesario dirigirse a él, dpd@delonia.com"}</div>

                <div className='section-policy-modal'><h1>{"Clases de Datos recogidos"}</h1></div>
                <div>{"Entre las clases de Datos Personales que recoge esta Aplicación, ya sea directamente o a través de terceros, se encuentran: Datos de uso; Rastreadores; nombre; apellido(s); dirección de correo electrónico."}</div>
                <div>{"La información completa referente a cada categoría de Datos Personales que se recogen se proporciona en las secciones de la presente política de privacidad dedicadas a tal fin o mediante textos explicativos específicos que se muestran antes de la recogida de dichos Datos."}
                <br></br>{"Los Datos Personales podrán ser proporcionados libremente por el Usuario o, en caso de los Datos de Uso, serán recogidos automáticamente cuando se utilice esta Aplicación."}
                <br></br>{"Todos los Datos solicitados por esta Aplicación son obligatorios y la negativa a proporcionarlos podrá imposibilitar que esta Aplicación pueda proceder a la prestación del Servicio. En los casos en los que esta Aplicación indique específicamente que ciertos Datos no son obligatorios, los Usuarios serán libres de no comunicar tales Datos sin que esto tenga consecuencia alguna sobre la disponibilidad o el funcionamiento del Servicio. Los Usuarios que tengan dudas sobre qué Datos son obligatorios pueden contactar con el Responsable del tratamiento."}
                <br></br>{"El uso de Cookies - o de otras herramientas de seguimiento - por parte de esta Aplicación o por los titulares de servicios de terceros utilizados por esta Aplicación tiene como finalidad la prestación del Servicio solicitado por el Usuario, además de cualesquiera otras finalidades que se describan en el presente documento y en la Política de Cookies, en caso de estar disponible."}
                </div>
                <div>{"El Usuario asume la responsabilidad respecto de los Datos Personales de terceros que se obtengan, publiquen o compartan a través de esta Aplicación y declara por la presente que tiene el consentimiento de dichos terceros para proporcionar dichos Datos al Responsable del tratamiento."}</div>

                <div className='section-policy-modal'><h1>{"Modalidad y lugar del tratamiento de los Datos recogidos"}</h1></div>
                <h2>{"Modalidades de Tratamiento"}</h2>
                <div>{"El Responsable del tratamiento tratará los Datos de los Usuarios de manera adecuada y adoptará las medidas de seguridad apropiadas para impedir el acceso, la revelación, alteración o destrucción no autorizados de los Datos."}
                <br></br>{"El tratamiento de Datos se realizará mediante ordenadores y/o herramientas informáticas, siguiendo procedimientos y modalidades organizativas estrictamente relacionadas con las finalidades señaladas. Además del Responsable del tratamiento, en algunos casos podrán acceder a los Datos ciertas categorías de personas autorizadas, relacionadas con el funcionamiento de esta Aplicación (administración, ventas, marketing, departamento jurídico y de administración de sistemas) o contratistas externos que presten servicios al Responsable del tratamiento (tales como proveedores externos de servicios técnicos, empresas de mensajería, empresas de hosting, empresas de informática, agencias de comunicación) que serán nombrados por el Responsable del tratamiento como Encargados del Tratamiento, si fuera necesario. Se podrá solicitar al Responsable del tratamiento en cualquier momento una lista actualizada de dichas personas."}
                </div>
                <h2>{"Base jurídica del Tratamiento"}</h2>
                <div>{"El Responsable del tratamiento podrá tratar los Datos Personales del Usuario, si se cumple una de las siguientes condiciones:"}</div>
                <ul>
                    <li>{"Cuando los Usuarios hayan dado su consentimiento para una o más finalidades específicas. Aviso: Al amparo de varias legislaciones diferentes, el Responsable del tratamiento podrá estar autorizado a tratar los Datos Personales hasta que el Usuario se oponga a ello (“opción de no participación”), sin necesidad de consentimiento o de cualquier otra base jurídica. Sin embargo, esto no será aplicable cuando el tratamiento de los Datos Personales esté sujeto a la normativa europea en materia de protección de Datos Personales;"}</li>
                    <li>{"Cuando la obtención de Datos sea necesaria para el cumplimiento de un contrato entre el Usuario y/o cualquier otra obligación precontractual del mismo;"}</li>
                    <li>{"Cuando el tratamiento sea necesario para el cumplimiento de una obligación legal de obligado cumplimiento por parte del Usuario;"}</li>
                    <li>{"Cuando el tratamiento esté relacionado con una tarea ejecutada en interés público o en el ejercicio de competencias oficiales otorgadas al Responsable del tratamiento;"}</li>
                    <li>{"Cuando el tratamiento sea necesario con el fin de un interés legítimo perseguido por el Responsable del tratamiento o un tercero."}</li>
                </ul>
                <div>{"En cualquier caso, el Responsable del tratamiento está a su disposición para definir las bases jurídicas específicas que se aplican al tratamiento y en particular, si la obtención de los Datos Personales es un requisito contractual o estatutario o un requisito necesario para formalizar un contrato."}</div>

                <h2>{"Lugar"}</h2>
                <div>{"Los Datos se tratan en las oficinas del Responsable del tratamiento, así como en cualquier otro lugar en el que se encuentren situadas las partes implicadas en dicho proceso de tratamiento."}
                <br></br>{"Dependiendo de la localización de los Usuarios, las transferencias de Datos pueden implicar la transferencia de los Datos de los Usuarios a otro país diferente al suyo propio. Para más información sobre el lugar de tratamiento de dichos Datos transferidos, los Usuarios podrán consultar la sección que contiene los detalles sobre el tratamiento de los Datos Personales."}</div>
                <div>{"Los Usuarios también tendrán derecho a conocer las bases legales de las transferencias de Datos a otro país fuera de la Unión Europea o a cualquier organismo internacional que se rija por el Derecho Público Internacional o que esté formado por dos o más países, como la ONU, y además conocer las medidas de seguridad tomadas por el Responsable del tratamiento para salvaguardar sus Datos."}</div>
                <div>{"En caso de que tuviera lugar dicha transferencia de Datos, los Usuarios podrán obtener más información consultando las secciones relevantes del presente documento o solicitándola al Responsable del tratamiento, a través de la información de contacto que aparece en la sección de contacto."}</div>

                <h2>{"Período de conservación"}</h2>
                <div>{"Los Datos Personales serán tratados y conservados durante el tiempo necesario y para la finalidad por la que han sido recogidos."}</div>
                <div>{"Por lo tanto:"}</div>
                <ul>
                    <li>{"Los Datos Personales recogidos para la formalización de un contrato entre el Responsable del tratamiento y el Usuario deberán conservarse como tales hasta en tanto dicho contrato se haya formalizado por completo."}</li>
                    <li>{"Los Datos Personales recogidos en legítimo interés del Responsable del tratamiento deberán conservarse durante el tiempo necesario para cumplir con dicha finalidad. Los Usuarios pueden encontrar información específica relacionada con el interés legítimo del Responsable del tratamiento consultando las secciones relevantes del presente documento o contactando con el Responsable del tratamiento."}</li>
                </ul>
                <div>{"El Responsable del tratamiento podrá conservar los Datos Personales durante un periodo adicional cuando el Usuario preste su consentimiento a tal tratamiento, siempre que dicho consentimiento siga vigente. Además, el Responsable del tratamiento estará obligado a conservar Datos Personales durante un periodo adicional siempre que se precise para el cumplimiento de una obligación legal o por orden que proceda de la autoridad."}</div>
                <div>{"Una vez terminado el período de conservación, los Datos Personales deberán eliminarse. Por lo tanto, los derechos de acceso, modificación, rectificación y portabilidad de datos no podrán ejercerse una vez haya expirado dicho periodo."}</div>

                <div className='section-policy-modal'><h1>{"Finalidad del Tratamiento de los Datos recogidos"}</h1></div>
                <div>{"Los Datos relativos al Usuario son recogidos para permitir al Responsable del tratamiento prestar su Servicio, cumplir sus obligaciones legales, responder a solicitudes de ejecución, proteger sus derechos e intereses (o los de sus Usuarios o terceros), detectar cualquier actividad maliciosa o fraudulenta, así como para las siguientes finalidades: Visualizar contenidos de plataformas externas y Contactar con el Usuario."}</div>
                <div>{"Los Usuarios pueden encontrar información específica los Datos personales utilizados para cada finalidad en la sección “Información detallada del Tratamiento de los Datos Personales”."}</div>
                
                <div className='section-policy-modal'><h1>{"Información detallada del Tratamiento de los Datos Personales"}</h1></div>
                <div>{"Los Datos Personales se recogen para las siguientes finalidades y utilizando los siguientes servicios:"}</div>
                <h2>{"Contactar con el Usuario"}</h2>
                <div><b>{"Formulario de contacto (esta Aplicación)"}</b></div>
                <div>{"Al rellenar el formulario de contacto con sus Datos, el Usuario autoriza a esta Aplicación a utilizar dichos datos para responder a solicitudes de información, de presupuestos o de cualquier otro tipo que esté indicado en el encabezamiento del formulario."}</div>
                <div>{"Datos Personales tratados: apellido(s); dirección de correo electrónico; nombre."}</div>

                <h2>{"Visualizar contenidos de plataformas externas"}</h2>
                <div>{"Este tipo de servicios permiten visualizar contenidos alojados en plataformas externas directamente desde las páginas de esta Aplicación e interactuar con estos."}
                <br></br>{"Este tipo de servicios puede recopilar datos de tráfico web para las páginas en las que estén instalados incluso en caso de que el Usuario no los utilice."}</div>
                <div><b>{"Font Awesome (Fonticons, Inc. )"}</b></div>
                <div>{"Font Awesome es un servicio de visualización de familias tipográficas prestado por Fonticons, Inc. que permite a esta Aplicación incorporar tales contenidos en sus páginas."}</div>
                <div>{"Datos Personales tratados: Datos de uso; Rastreadores."}</div>
                <div>{"Lugar de tratamiento: EE.UU. - "}<a href="https://fontawesome.com/privacy" rel="noopener noreferrer" target="_blank">{"Política de privacidad"}</a></div>
                
                <div><b>{"Google Fonts (Google LLC)"}</b></div>
                <div>{"Google Fonts es un servicio de visualización de familias tipográficas prestado por Google LLC que permite a esta Aplicación incorporar tales contenidos en sus páginas."}</div>
                <div>{"Datos Personales tratados: Datos de uso; Rastreadores."}</div>
                <div>{"Lugar de tratamiento: EE.UU. - "}<a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">{"Política de privacidad"}</a></div>

                <div className='section-policy-modal'><h1>{"Los derechos de los Usuarios"}</h1></div>
                <div>{"Los Usuarios podrán ejercer ciertos derechos con respecto al tratamiento de Datos por parte del Responsable del tratamiento."}</div>
                <div>{"Especialmente, los Usuarios tienen derecho a hacer lo siguiente:"}</div>
                <ul>
                    <li><b>{"Retirar su consentimiento en cualquier momento. "}</b>{"Los Usuarios tienen derecho a retirar su consentimiento cuando lo hubieran concedido con anterioridad para el tratamiento de sus Datos Personales."}</li>
                    <li><b>{"Objeción al tratamiento de sus Datos. "}</b>{"Los Usuarios tienen derecho a oponerse al tratamiento de sus Datos si dicho tratamiento se lleva a cabo con arreglo a una base jurídica distinta del consentimiento. Para más información pueden dirigirse a la sección correspondiente más adelante."}</li>
                    <li><b>{"Acceso a sus Datos. "}</b>{"Los Usuarios tienen derecho a conocer si sus Datos serán tratados por el Responsable del tratamiento, a obtener información sobre ciertos aspectos del tratamiento, además de obtener una copia de los Datos objeto del tratamiento"}</li>
                    <li><b>{"Verificar y solicitar la modificación. "}</b>{"Los Usuarios tienen derecho a verificar la exactitud de sus Datos y solicitar que los mismos se actualicen o corrijan."}</li>
                    <li><b>{"Restringir el tratamiento de sus Datos. "}</b>{"Los Usuarios tienen derecho, en ciertos supuestos, a restringir el tratamiento de sus Datos. En ese supuesto, el Responsable del tratamiento procesará sus Datos con el único propósito de almacenarlos."}</li>
                    <li><b>{"Borrar o eliminar los Datos Personales. "}</b>{"Los Usuarios tienen derecho, en ciertos supuestos, a obtener la eliminación de sus Datos por parte del Responsable del tratamiento."}</li>
                    <li><b>{"Recibir sus Datos y transferirlos a otro responsable. "}</b>{"Los Usuarios tienen derecho a recibir sus Datos en un formato estándar, estructurado, mecánicamente legible y, si fuera técnicamente posible, a que se dé traslado de los mismos a otro responsable sin ningún impedimento. Esta provisión será de aplicación siempre que los Datos se hayan tratado a través de medios automatizados y que el tratamiento se base en el consentimiento del Usuario, en un contrato del que el Usuario forme parte o que aparezca en las obligaciones precontractuales del mismo."}</li>
                    <li><b>{"Poner una reclamación. "}</b>{"Los Usuarios tienen derecho a poner una reclamación ante la autoridad competente en materia de protección de datos de carácter personal."}</li>
                </ul>

                <h2>{"Detalles sobre el derecho de oposición al tratamiento"}</h2>
                <div>{"Cuando el tratamiento de los Datos Personales sea de interés público, en el ejercicio de competencias oficiales otorgadas al Responsable del tratamiento o con motivo de un interés legítimo del Responsable del tratamiento, los Usuarios podrán oponerse a dicho tratamiento explicando un motivo con relación a su situación particular para justificar su objeción."}</div>
                <div>{"Los Usuarios deben saber que, sin embargo, en caso de que sus Datos Personales sean tratados con fines comerciales, pueden oponerse en cualquier momento a tal tratamiento sin necesidad de justificación. Para saber si los Datos Personales de los Usuarios están siendo tratados por parte del Responsable del tratamiento para fines comerciales, éstos deberán consultar las secciones relevantes del presente documento."}</div>
                
                <h2>{"Cómo ejercer estos derechos"}</h2>
                <div>{"Cualquier solicitud para ejercer los derechos del Usuario puede dirigirse al Responsable del tratamiento a través de los datos de contacto facilitados en el presente documento. Dichas solicitudes serán tramitadas por el Responsable del tratamiento sin coste alguno tan pronto como le sea posible y siempre dentro del plazo de un mes."}</div>
                
                <div className='section-policy-modal'><h1>{"Información adicional sobre la recogida de Datos y su tratamiento"}</h1></div>
                <h2>{"Defensa jurídica"}</h2>
                <div>{"Los Datos Personales del Usuario podrán ser utilizados para la defensa jurídica del Responsable del tratamiento ante un tribunal o en las fases judiciales previas a un posible pleito derivado del uso inapropiado de esta Aplicación o de los Servicios relacionados."
                +"El Usuario declara conocer que el Responsable del tratamiento puede ser requerido por las autoridades públicas a fin de revelar Datos Personales."}</div>
                <h2>{"Información adicional acerca de los Datos Personales del Usuario"}</h2>
                <div>{"Además de las informaciones contenidas en esta política de privacidad, esta Aplicación podrá proporcionar al Usuario información adicional y contextual relativa a Servicios específicos o a la recogida y tratamiento de los Datos Personales."}</div>
                <h2>{"Log del sistema y mantenimiento"}</h2>
                <div>{"Por motivos relativos al funcionamiento y mantenimiento, esta Aplicación y cualquier otro servicio, proporcionado por terceros, que se utilice, podrá recoger un registro del sistema; es decir, archivos que registren la interacción con esta Aplicación y que puedan contener Datos Personales, tales como la dirección IP del Usuario."}</div>
                <h2>{"Información no contenida en esta política de privacidad"}</h2>
                <div>{"Se podrá solicitar en cualquier momento información adicional sobre la recogida y el tratamiento de los Datos Personales al Responsable del tratamiento. La información de contacto se indica al inicio del presente documento."}</div>
                <h2>{"Cómo se procesan las solicitudes “Do Not Track”"}</h2>
                <div>{"Esta Aplicación no permite solicitudes “Do Not Track”."
                +"Para determinar si cualquiera de los servicios de terceros que utiliza acepta solicitudes “Do Not Track”, por favor lea sus políticas de privacidad"}</div>
                <h2>{"Modificación de la presente política de privacidad"}</h2>
                <div>{"El Responsable del tratamiento se reserva el derecho de modificar esta política de privacidad en cualquier momento, notificándolo a los Usuarios a través de esta página y, a ser posible, a través de esta Aplicación y/o de ser técnica y legalmente posible notificando directamente a los Usuarios, en caso de que el Responsable del tratamiento cuente con la información de contacto necesaria a tal fin. Se recomienda encarecidamente que revisen esta página con frecuencia, tomando como referencia la fecha de la última actualización indicada al final de la página."}</div>
                <div>{"En el caso de que los cambios afectasen a las actividades de tratamiento realizadas en base al consentimiento del Usuario, el Responsable del tratamiento deberá obtener, si fuera necesario, el nuevo consentimiento del Usuario."}</div>


                <div className='section-policy-modal'><h1>{"Definiciones y referencias legales"}</h1></div>
                <h2>{"Definiciones y referencias legales"}</h2>
                <div>{"Constituye un dato personal cualquier información que, directa, indirectamente o en relación con otra información – incluyendo un número de identificación personal – permita identificar a una persona física."}</div>
                <h2>{"Datos de Uso"}</h2>
                <div>{"Las informaciones recogidas de forma automática por esta Aplicación (o por servicios de terceros utilizados por esta Aplicación), podrán incluir: las direcciones IP o nombres de dominio de los ordenadores utilizados por el Usuario que se conecte a esta Aplicación, las direcciones URI (Uniform Resource Identifier), la hora de la solicitud, el método utilizado para realizar la solicitud al servidor, las dimensiones del archivo obtenido en respuesta, el código numérico indicando el estado de la respuesta del servidor (resultado satisfactorio, error, etc.), el país de origen, las características del navegador y del sistema operativo utilizados por el visitante, las diversas coordenadas temporales de la visita (por ejemplo, el tiempo de permanencia en cada una de las páginas) y los detalles relativos al itinerario seguido dentro de la Aplicación, con especial referencia a la secuencia de páginas consultadas, a los parámetros relativos al sistema operativo y al entorno informático del Usuario."}</div>
                <h2>{"Usuario"}</h2>
                <div>{"El individuo que utilice esta Aplicación, quien, a menos que se indique lo contrario deberá coincidir con el Interesado."}</div>
                <h2>{"Interesado"}</h2>
                <div>{"La persona física a la que se refieren los Datos Personales."}</div>
                <h2>{"Encargado del Tratamiento (o Supervisor de Datos)"}</h2>
                <div>{"La persona física o jurídica, administración pública, agencia o cualquier otra institución, que procese los Datos Personales en nombre del Responsable del Tratamiento, descrita en la presente política de privacidad."}</div>
                <h2>{"Responsable del Tratamiento (o Responsable del tratamiento)"}</h2>
                <div>{"La persona física o jurídica, administración pública, agencia o cualquier otra institución, que actuando en solitario o conjuntamente con otras, determine las finalidades y las medidas del tratamiento de los Datos Personales, incluyendo las medidas de seguridad relativas al funcionamiento y al uso de esta Aplicación. A menos que se especifique lo contrario, el Responsable del Tratamiento es el Responsable del tratamiento de esta Aplicación."}</div>
                <h2>{"Esta Aplicación"}</h2>
                <div>{"El medio a través del cual se han recogido y tratado los Datos Personales del Usuario."}</div>
                <h2>{"Servicio"}</h2>
                <div>{"El servicio proporcionado por esta Aplicación, tal y como se describe en las definiciones y referencias legales (en caso de estar disponibles) y en esta página o aplicación."}</div>
                <h2>{"Unión Europea (o UE)"}</h2>
                <div>{"A menos que se indique lo contrario, todas las referencias hechas a la Unión Europea en el presente documento incluyen todos los actuales Estados miembros de la Unión Europea y del Espacio Económico Europeo."}</div>
                <h2>{"Cookie"}</h2>
                <div>{"Las Cookies son Rastreadores que consisten en pequeñas cantidades de datos almacenados en el navegador del Usuario."}</div>
                <h2>{"Rastreador"}</h2>
                <div>{"Rastreador designa cualquier tecnología - p.ej. Cookies, identificadores únicos, balizas web, scripts incrustados, etiquetas de entidad y creación de huella digital - que permite rastrear a los Usuarios, por ejemplo, accediendo a información o almacenándola en el dispositivo del Usuario."}</div>
                <div className='section-policy-modal'><h1>{"Información legal"}</h1></div>
                <div>{"Esta política de privacidad se ha redactado en base a las provisiones de múltiples legislaciones, incluyendo los Arts. 13 y14 del Reglamento (UE) 2016/679 (Reglamento general de protección de datos)."}</div>
                <div>{"Esta política de privacidad se refiere sólo a esta Aplicación, a menos que se indique lo contrario en el presente documento."}</div>



                {/*<div className='section-policy-modal'><h1>{""}</h1></div>
                <h2>{""}</h2>
                <div>{""}</div>*/}

            </div>

        </Modal>
    );
}

export default PrivacyPolicyModal;