import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {IState} from '../newPrescriptionsContainer';
import PrescriptionAlerts from './PrescriptionAlerts';
import {IAlertsDataArray} from './alertsData';

interface IProps {
    visible?: boolean;
    onCancel: () => void;
    arrayOfAlerts: IAlertsDataArray[];
}
class PrescriptionAlertsContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public render() {
        return (
            <PrescriptionAlerts
            visible={this.props.visible}
            arrayOfAlerts={this.props.arrayOfAlerts}
            onCancel={this.props.onCancel}
            />
        );
    }

}

export default withTranslation('prescription')(withRouter(PrescriptionAlertsContainer));


