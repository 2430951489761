import { Button, Dropdown, Menu } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { TableIcons } from "../../utils/utils";

interface IProps {
    hipDownload: () => void;
    openResendHipModal: () => void;
}

const PrescriptionOptions = (props: IProps) => {

    const { t } = useTranslation(['prescriptionOptions']);

    const onClick = (value: string) => {
        switch (value) {
            case '1':
                props.hipDownload();
                break;
            case '2':
                props.openResendHipModal();
                break;
            default:
                break;
        }
    }

    const addServicesDropdown = (
        <Menu onClick={(e: any) => onClick(e.key)}>
            <Menu.Item key='1'>
                {t('downloadHip')}
            </Menu.Item>
            <Menu.Item key='2'>
                {t('resendHipToPatient')}
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={addServicesDropdown} trigger={['click']}>
            <Button type='primary' icon={TableIcons.getTableIcon(TableIcons.TableIcon.more)}></Button>
        </Dropdown>
    );
}

export default PrescriptionOptions;