import React, { ReactNode } from 'react';

import { Form, Row, Col, Button, DatePicker, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd/lib/form';
import { Option } from 'rc-select';
import PatientData from '../entities/patientData';


const ListadoFilters = (props: {
    lastFilter?: any;
    applyFilters: (form: FormInstance) => void;
    resetFilters: () => void;
}) => {

    const [form] = Form.useForm();

    const { t } = useTranslation(['patientList']);

    const { RangePicker } = DatePicker;

    const resetFilters = () => {
        form.resetFields();
        props.resetFilters();
    }

    return (<>
        <Form size='large' layout='vertical' className='form__smaller-margin' form={form} style={{width: '100%'}}>
            {<Form.Item name='nifPatient' label={t('nifPatient')} initialValue={props.lastFilter?.nifPatient}>
                <Input placeholder={t('nifPatient')} />
            </Form.Item>}
            {<Form.Item name='nameNombre' label={t('nameNombre')} initialValue={props.lastFilter?.nameNombre}>
                <Input placeholder={t('nameNombre')} />
            </Form.Item>}
            {<Form.Item name='nameApellido1' label={t('nameApellido1')} initialValue={props.lastFilter?.nameApellido1}>
                <Input placeholder={t('nameApellido1')} />
            </Form.Item>}
            {<Form.Item name='nameApellido2' label={t('nameApellido2')} initialValue={props.lastFilter?.nameApellido2}>
                <Input placeholder={t('nameApellido2')} />
            </Form.Item>}
            
        </Form>
        <Row gutter={16}>
            <Col span={12}><Button type='primary' block onClick={() => props.applyFilters!(form)}>{t('buttons:apply')}</Button></Col>
            <Col span={12}><Button danger block onClick={resetFilters}>{t('buttons:reset')}</Button></Col>
        </Row>
   </> );
}

export default ListadoFilters;