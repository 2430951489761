import React from 'react';

import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {concatName, Rest, validateDNI} from '../../utils/utils';
import View from './selectPatient';
import PersonaSelected from './entities/personaSelected';
import PatientData from './entities/patientData';
import {Store} from 'antd/lib/form/interface';
import EnumCodeIdentificationType from "../../enum/EnumCodeIdentificationType";

interface IProps {
    visible?: boolean;
    selectedPatient?: PersonaSelected;
    onClose: () => void;
    newPatient: (newPatientData?: PatientData) => void;
    checkConsentimiento: (idPersona: number) => void;
    addPatientSelected: (idPatient: number) => void;
    editPatientSelectedWithoutMail: (idPaciente: number) => void; 
}

export interface IState {
    resultPatientList?: PersonaSelected[];
    noResult?: boolean;
    noResultPastedData?: boolean;
    invalidFormat?: boolean;
    errorMessage?: string;
}

interface SearchPersonFilter {
    codeIdentificacion?: string;
    codeIdentificacionTutor?: string;
}

class SelectPatientContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    public componentDidMount() {
        if (this.props.visible) {
            this.setState({resultPatientList: typeof this.props.selectedPatient !== 'undefined' ? [this.props.selectedPatient] : undefined});
        }
    }

    public render() {
        return (
            <View
                {...this.state}
                visible={this.props.visible}
                selectedPatient={this.props.selectedPatient}
                onCancel={() => this.props.onClose()}
                searchPatient={this.searchPatient}
                newPatient={this.props.newPatient}
                newPatientFromPastedData={this.newPatientFromPastedData}
                setSelectedPatient={this.setSelectedPatient}
            />
        );
    }

    private searchPatient = (values: Store) => {
        let filters = {} as SearchPersonFilter;

        if(values.patientPastedData==undefined){
            var ciPaciente: string = values.codeIdentificacion ? values.codeIdentificacion.trim() : '';
            var ciTutor: string = values.codeIdentificacionTutor ? values.codeIdentificacionTutor.trim() : '';

            if (ciPaciente.length < 6 && ciTutor.length < 6) {
                this.setState({resultPatientList: undefined, noResult: true, invalidFormat: true, errorMessage: this.props.t('incorrectNif')});
                return;
            }

            if ((ciPaciente !== '' && [EnumCodeIdentificationType.DNI, EnumCodeIdentificationType.NIE].includes(values.typeDocPaciente) &&
                    !validateDNI(ciPaciente, values.typeDocPaciente ===  EnumCodeIdentificationType.NIE)) ||
                (ciTutor !== '' && [EnumCodeIdentificationType.DNI, EnumCodeIdentificationType.NIE].includes(values.typeDocTutor) &&
                    !validateDNI(ciTutor, values.typeDocTutor ===  EnumCodeIdentificationType.NIE))) {
                this.setState({resultPatientList: undefined, noResult: true, invalidFormat: true, errorMessage: this.props.t('incorrectNif')});
                return;
            }
        }else{
            var ciPaciente: string =  values.patientPastedData.split("\n")[2];
            var ciTutor = '';
            if(ciPaciente==""){
                this.setState({resultPatientList: undefined, noResultPastedData: true, invalidFormat: true, errorMessage: this.props.t('incorrectNif')});
                return;
            }
        }
        
        filters.codeIdentificacion = ciPaciente === '' ? undefined : ciPaciente;
        filters.codeIdentificacionTutor = ciTutor === '' ? undefined : ciTutor;

        this.setState({noResult: values.patientPastedData ? undefined: false,noResultPastedData: values.patientPastedData ? false : undefined, invalidFormat: false}, () => {
            Rest<{ type: string & SearchPersonFilter }, PersonaSelected[]>().operation({
                type: 'SearchPatientByIdentification',
                ...filters
            }, true).then(response => {
                if (response) {
                    const psl: PersonaSelected[] =
                    response.map<PersonaSelected>(e => {
                            let ps: PersonaSelected;
                            ps = {...e};
                            ps.namePersona = concatName(e.nameNombre, e.nameApellido1, e.nameApellido2);
                            return ps;
                        }
                    );
                    if (psl.length === 0) {
                        this.setState({
                            resultPatientList: [],
                            noResult: values.patientPastedData? undefined: true,
                            noResultPastedData: values.patientPastedData ? true: undefined,
                            errorMessage: this.props.t('seleccionarNingunResultado')
                        });
                    } else {
                        this.setState({
                            noResult: values.patientPastedData ? undefined: false,
                            noResultPastedData: values.patientPastedData ? false : undefined,
                            resultPatientList: psl
                        });
                    }
                } else {
                    this.setState({noResult: values.patientPastedData ? undefined: true,noResultPastedData: values.patientPastedData ? true: undefined, errorMessage: values.patientPastedData=="" ? this.props.t('pacientePastedDataError'):this.props.t('seleccionarNingunResultado')});
                }
            });
        });
    }

    private setSelectedPatient = (idPersona: number) => {
        const selectedPatient = this.state.resultPatientList?.find(pat => pat.idPersona === idPersona);
        if (!selectedPatient) {
            return;
        }

        const patient = selectedPatient;
        let patientChanged = false;
        if (!this.props.selectedPatient || this.props.selectedPatient.idPersona !== idPersona) {
            patientChanged = true;
        }

        if (!patient.idPaciente) { // Create new patient from the existing person
            const newPatientData: PatientData = {
                namePersona: patient.nameNombre + ' ' + patient.nameApellido1 + (patient.nameApellido2 ? ' ' + patient.nameApellido2 : ''),
                nameNombre: patient.nameNombre,
                nameApellido1: patient.nameApellido1,
                nameApellido2: patient.nameApellido2,
                dateNacimiento: patient.dateNacimiento,
                typeIdentificacion: patient.typeIdentificacion,
                codeIdentificacion: patient.codeIdentificacion,
                typeSexo: patient.typeSexo,
                idPersona: patient.idPersona,
                idPaciente: patient.idPaciente
            };
            this.props.newPatient(newPatientData);
        } else {
            // el 'patient.hasMailContacto == undefined' esta puesto porque si vuelve a seleccionar, sin buscar, el mismo paciente ya puesto en la
            // pantalla de prescripciones debe de chequear el consentimiento.
            if (patient.hasMailContacto == undefined || patient.hasMailContacto == false || patient.hasMailContacto) {
                this.props.checkConsentimiento(idPersona)
            } else {
                this.props.editPatientSelectedWithoutMail(idPersona);
            }
        }
    }

    private newPatientFromPastedData = (patientData: string) => {
        const splitArray = patientData.split("\n");
        const [dia,mes,ano] = splitArray[3].split(".");
        var date = undefined
        date = new Date(new Date().setFullYear(Number(ano),Number(mes)-1,Number(dia)));
        if(isNaN(date.getDate()) || Number(mes)-1>11 || Number(dia)>31){date=undefined}
        this.props.newPatient({
            namePersona: splitArray[0] + ' ' + splitArray[1],
            nameNombre: splitArray[0],
            nameApellido1: splitArray[1],
            dateNacimiento: splitArray[3] == "" ? undefined : date,
            valueTelefono: splitArray[5],
            valueEmail: splitArray[6],
            typeIdentificacion: 4, //pasaporte
            codeIdentificacion: splitArray[2],
            typeSexo: splitArray[4] == 'Male' ? 1 : splitArray[4] == 'Female' ? 2 : undefined,
        } as unknown as PatientData
        )
    }
}

export default withTranslation('newPatientPrescription')(withRouter(SelectPatientContainer));