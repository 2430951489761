import React from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Rest } from '../../utils/utils';
import { URL } from '../../utils/rest';
import SignPrescriptionResponseOtp from './operations/signPrescriptionResponseOtp';
import SignOtpModalContainer, { OtpCallerProps } from './signOtpModalContainer';
import SignSuccessModal from './signSuccessModal';
import SignPrescriptionContainer from './signPrescriptionContainer';
import SignPrescriptionResult from './entities/signPrescriptionResult';

interface IProps {
    visible?: boolean;
    idPrescripcionGrupo: number;
    idPaciente?: number;
    emailPaciente?: string;
    onClose: () => void;
}

export interface IState extends OtpCallerProps {
    errorMessage?: string;
    warningMessage?: string;
    errorFields?: string[];
    codeResult?: number;
    signSuccessVisible?: boolean;
    signSuccessKey?: number;
    signPrescriptionVisible?: boolean;
    signPrescriptionKey?: number;
    emailPatient?: string;
    codeHip?: string;
    codeHipConfidencial?: string;
}

class LocalSaveSingContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    public componentDidMount() {
        if (this.props.visible) {
            this.startSigningProcess();
        }
    }

    public state: IState = {};

    public render() {

        return (
            <>
                <SignOtpModalContainer
                    otpType={this.state.otpType}
                    valueUuId={this.state.valueUuId!}
                    email={this.state.email}
                    dispositivo={this.state.dispositivoMovil}
                    key={this.state.signOTPkey}
                    visible={this.state.signOtpVisible}
                    oncloseSignOTP={this.oncloseSignOTP}
                    tryAgain={this.startSigningProcess}
                    sendByEmail={() => this.onCreateValidateOtp(true)}
                    onValidationSuccess={this.onValidOtp}
                />
                <SignPrescriptionContainer
                    key={this.state.signPrescriptionKey}
                    visible={this.state.signPrescriptionVisible}
                    onCreateValidateOtp={this.onCreateValidateOtp}
                    onClose={this.onCloseSignPrescription}
                    onSuccess={this.onSuccess}
                />
                <SignSuccessModal
                    key={this.state.signSuccessKey}
                    visible={this.state.signSuccessVisible}
                    emailPatient={this.state.emailPatient}
                    onClose={this.onCloseSignSuccess}
                    hipDownload={this.hipDownload}
                    sendEmailHip={this.sendEmailHip}
                />
            </>
        );
    }

    private sendEmailHip = (email: string) => {
        Rest<{type: string, idPaciente: number, idPrescripcionGrupo:number, saveEmail: boolean, newEmail?: string}, any>().operation({
            type: 'ResendDocumentoHipToUser',
            idPaciente: this.props.idPaciente!,
            idPrescripcionGrupo: this.props.idPrescripcionGrupo,
            saveEmail: true,
            newEmail: email
        }).then(response => {
            this.onCloseSignSuccess();
        });
    }

    private onCloseSignPrescription = () => {
        this.setState({signPrescriptionVisible: false});
    }

    private oncloseSignOTP = () => {
        this.setState({ signOtpVisible: false });
    }

    private onCreateValidateOtp = (sendByEmail?: boolean) => {
        Rest<{type: string, idPrescripcionGrupo: number, useEmail?: boolean}, SignPrescriptionResponseOtp>().operation(
            { type: 'CreateOtpForSignature',
              idPrescripcionGrupo: this.props.idPrescripcionGrupo,
              useEmail: sendByEmail})
            .then(response => {
                if (!response.otpNeeded) {
                    this.onSignPrescription(undefined, undefined);
                } else {
                    this.setState({
                        otpType: response.otpType,
                        valueUuId: response.valueUuId,
                        email: response.email,
                        dispositivoMovil: response.dispositivoMovil,
                        errorMessage: undefined,
                        warningMessage: undefined,
                        signOtpVisible: true,
                        signOTPkey: new Date().getTime(),
                        signPrescriptionVisible: false
                    });
                }
            });        
    }

    private onValidOtp = (valueUuId: string, otp?: string) => {
        this.setState({
            signOtpVisible: false
        }, () => this.onSignPrescription(valueUuId, otp));        
    }

    private onSignPrescription = (valueUuId?: string, otp?: string) => {
        Rest<{ type: string, valueUuId?: string,  otp?: string}, SignPrescriptionResult>().operation({
            type: 'SignPrescription',
            valueUuId : valueUuId,
            otp: otp,
        }).then(response => {
            this.setState({ warningMessage: this.props.t('passwordError'), errorMessage: undefined });
            this.onSuccess(response);
        })
        /*.catch(error => {
            notification['error']({ 
                message: this.props.t('genericErrorSign'),
                duration : 20
            });            
        });*/
    }    

    private onCloseSignSuccess = () => {
        this.setState({
            signSuccessVisible: false
        }, () => this.props.onClose());

    }

    private hipDownload = () => {
        const file_path = URL + '/file?ddp' +
            (this.props.idPrescripcionGrupo ? '&idPrescripcionGrupo=' + this.props.idPrescripcionGrupo : '');
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = file_path;
        a.click();
        document.body.removeChild(a);

        this.onCloseSignSuccess();
    }

    private startSigningProcess = () => {
        Rest<{ type: string, idPrescripcionGrupo: number }, number>().operation({
            type: 'StartLocalSigningProcess',
            idPrescripcionGrupo: this.props.idPrescripcionGrupo!
        }).then(response => {
            if (response !== null) {
                this.setState({
                    errorMessage: this.props.t('codeResultLogin:' + response),
                    warningMessage: undefined,
                    codeResult: response
                })
            } else {
                this.setState({
                    signOtpVisible: false,
                    signPrescriptionVisible: true,
                    signPrescriptionKey: new Date().getTime()  
                });
            }
        });        
    }

    private onSuccess = (resultSign: SignPrescriptionResult) => {
        this.setState({
            signPrescriptionVisible: false,
            signSuccessVisible: true,
            signSuccessKey: new Date().getTime(),
            emailPatient: resultSign.emailPatient ? resultSign.emailPatient : this.props.emailPaciente,
            codeHip: resultSign.codeHip,
            codeHipConfidencial: resultSign.codeHipConfidencial
        });
    }

}

export default withTranslation('signPrescripction')(withRouter(LocalSaveSingContainer));