import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Rest } from '../../utils/utils';
import View from './patologiesSelect';
import _ from 'lodash';
import i18n from '../../i18n/i18n';
import { PatologiaData } from './entities/patologiaData';

interface IProps {
    visible?: boolean;
    fromDiagnostico?: boolean;
    onClose: (patologie?: PatologiaData) => void;
}

export interface IState {
    page: number;
    limit: number;
    dataCount: number;
    search?: string;
    patologieList?: PatologiaData[];
    patologieSelected?: PatologiaData;
}

class patologiesSelectContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {
    
    public state: IState = {
        page: 1,
        limit: 20,
        dataCount: 0
    }

    public render() {
        return (
            this.props.visible ? <View
                {...this.state}
                visible={this.props.visible}
                onCancel={() => this.props.onClose()}
                searchPatology={this.searchPatology}
                onChangePage={this.onChangePage}
                fromDiagnostico={this.props.fromDiagnostico}
                onSelect={this.onSelectPatology}
                nameTranslation={this.nameTranslation}
                onSearchPatology={this.doSearchPatology} />: <></>
        )
    }

    private searchPatology = _.debounce((value?: string) => this.doSearchPatology(value), 250);

    private doSearchPatology = (value?: string) => {
        let searchValue = this.state.search;
        let page = this.state.page;

        if (value) {
            page = 1;
            searchValue = value;
        }

        if (!searchValue || searchValue.trim().length === 0) {
            return;
        }

        const searchValues = searchValue?.includes(' ') ? searchValue.split(' ') : [searchValue];

        Rest<{type: string; filters: string[]; language:string, limit: number, offset: number}, any>().operation({
            type: 'SelectPatologyByQuery',
            filters: searchValues,
            language: i18n.language.substring(0, 2),
            limit: this.state.limit,
            offset: (page-1)* this.state.limit
        }, true).then(response => {
            this.setState({patologieList:undefined});
            this.setState({patologieList: response.data, dataCount: response.dataCount, search: searchValue, page, patologieSelected: undefined});
        });
    }

    private onChangePage = (page: number) => {
        this.setState({ page }, () => this.searchPatology());
    }

    private setPatology = (codeCie10: string, nameCie10Es: string, nameCie10En: string, idCie10: number) => {
        return {
            codeCie10: codeCie10,
            nameCie10Es: nameCie10Es,
            nameCie10En: nameCie10En,
            idCie10: idCie10
        }
    }

    private onSelectPatology = (codeCie10: string, nameCie10Es: string, nameCie10En:string, idCie10: number) => {
       this.setState({patologieList: undefined});
       this.props.onClose(this.setPatology(codeCie10, nameCie10Es, nameCie10En, idCie10));
    }

    private  nameTranslation = (record: PatologiaData) => {
        if(i18n.language.substring(0, 2)=="es"){
            
            if(record.nameCie10Es!=undefined && record.nameCie10Es!=null && record.nameCie10Es!=""){
                return record.nameCie10Es!;
            }
            else{
                return record.nameCie10En!;
            }
        }
        else{
            if(record.nameCie10En!=undefined && record.nameCie10En!=null && record.nameCie10En!=""){
                return record.nameCie10En!;
            }
            else{
                return record.nameCie10Es!;
            }
        } 
    }

}

export default withTranslation('newPatientPrescription')(withRouter(patologiesSelectContainer));