import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {FormInstance, Store} from 'rc-field-form/lib/interface';
import {Rest, FormUtils, validarEmail} from '../utils/utils';
import PrestadorRegistroData from './entities/prestadorRegistroData';
import Provincia from '../prescriptions/locations/entities/provincia';
import Municipio from '../prescriptions/locations/entities/municipio';
import FormErrorField from '../utils/form/formErrorField';
import ValidateAltaPasswordResult from './entities/validateAltaPasswordResult';
import WelcomeView from './welcomeView';
import WelcomeStepOneView from './welcomeStepOneView';
import WelcomeStepTwoView from './welcomeStepTwoView';
import WelcomeStepThreeView from './welcomeStepThreeView';
import WelcomeStepCompletedView from './welcomeStepCompletedView';
import AltaPrestadorLocalizacion from './entities/altaPrestadorLocalizacion';

export interface IState {
    prestadorRegistroData?: PrestadorRegistroData;
    loaded?: boolean;
    stepHigh?: boolean;
    stepOne?: boolean;
    stepTwo?: boolean;
    stepThree?: boolean;
    stepCompleted?: boolean;
    codeProvincia?: string;
    provinciaList?: Provincia[];
    municipioList?: Municipio[];
    errorFields: FormErrorField[];
    locationPrestador?: AltaPrestadorLocalizacion;
}

class WelcomeContainer extends React.Component<WithTranslation & RouteComponentProps<{ token: string }>, IState> {

    public token: string = this.props.match.params.token;

    public state: IState = {
        errorFields: [],
    };

    public componentDidMount() {
        this.loadData();
    }

    public render() {
        return (
            this.state.loaded ?
                <>
                    {this.state.stepHigh &&
                        <WelcomeView
                            prestadorRegistroData={this.state.prestadorRegistroData}
                            continueHigh={this.continueHigh}
                        />
                    }
                    {this.state.stepOne &&
                        <WelcomeStepOneView
                            prestadorRegistroData={this.state.prestadorRegistroData}
                            continueSecondStep={
                                this.state.prestadorRegistroData?.idLocalizacion ? this.skipSecondStep : this.continueSecondStep
                            }
                        />
                    }
                    {this.state.stepTwo &&
                        <WelcomeStepTwoView
                            codeProvincia={this.state.codeProvincia}
                            provinciaList={this.state.provinciaList}
                            municipioList={this.state.municipioList}
                            errorFields={this.state.errorFields}
                            onChangeCP={this.onChangeCP}
                            continueThirdStep={this.validateAndSave}
                            completeLater={this.continueThirdStep}
                        />
                    }
                    {this.state.stepThree &&
                        <WelcomeStepThreeView
                            errorFields={this.state.errorFields}
                            prestadorRegistroData={this.state.prestadorRegistroData}
                            onSubmit={this.submit}
                        />
                    }
                    {this.state.stepCompleted &&
                        <WelcomeStepCompletedView
                            access={this.access}
                        />
                    }
                </>
                :
                <></>
        );
    }

    private loadData = () => {
        Rest<{ type: string, token: string }, PrestadorRegistroData>().operation({
            type: 'SelectPrestadorDetails',
            token: this.token,
        }).then(response => {
            if (response) {
                this.setState({
                    prestadorRegistroData: response,
                    loaded: true,
                    stepHigh: true,
                }, () => this.loadProvinces());
            }
        });
    };

    private loadProvinces = () => {
        Rest<{ type: string }, Provincia[]>().operation({type: 'SelectProvincia'}).then(
            response => this.setState({provinciaList: response}));
    };

    private continueHigh = () => {
        this.setState({stepHigh: false, stepOne: true});
    };

    private continueSecondStep = () => {
        this.setState({stepHigh: false, stepOne: false, stepTwo: true});
    };

    private skipSecondStep = () => {
        this.setState({stepHigh: false, stepOne: false, stepTwo: false, stepThree: true});
    };

    private continueThirdStep = () => {
        this.setState({stepHigh: false, stepOne: false, stepTwo: false, stepThree: true});
    };

    private onChangeProvince = (codeProvincia: string) => {
        Rest<{ type: string, codeProvincia: string }, Municipio[]>().operation(
            {type: 'SelectMunicipioByProvincia', codeProvincia: codeProvincia}).then(response =>
            this.setState({municipioList: response, codeProvincia}));
    };

    private onChangeCP = (form: FormInstance, e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        if (isNaN(Number(value))) {
            const cp = form.getFieldValue('codigoPostal').slice(0, -1);
            form.setFieldsValue({...form.getFieldsValue(), ['codigoPostal']: cp});
        }

        const cp = form.getFieldValue('codigoPostal');
        if (cp.length === 5) {
            form.setFieldsValue({
                ...form.getFieldsValue(),
                codeProvincia: cp.substring(0, 2),
                codeMunicipio: undefined,
            });
            this.onChangeProvince(cp.substring(0, 2));
        }
    };

    private validateAndSave = (form: FormInstance) => {
        this.setState({errorFields: []}, () => {
            form.validateFields().then((values) => {
                this.validate(values).then(errors => {
                    if (!errors || errors.length === 0) {
                        const location = {
                            nameLocalizacion: values.nameLocation,
                            valueDireccion: values.address,
                            valueDireccion2: values.addressDetail,
                            codeCodigoPostal: values.codigoPostal,
                            codeProvincia: values.codeProvincia,
                            codeMunicipio: values.codeMunicipio,
                            valueEmail: values.valueEmail,
                            valueTelefono: values.valueTelefono,
                        } as AltaPrestadorLocalizacion;

                        this.setState({locationPrestador: location}, () => this.continueThirdStep());
                    } else {
                        this.setState({errorFields: errors});
                    }
                });
            });
        });
    };

    private validate = (values: Store) => {

        return new Promise((resolve: (f: FormErrorField[]) => void) => {
            if (!validarEmail(values.valueEmail)) {
                const emailError = {fieldName: 'valueEmail', errorMessage: this.props.t('formatoIncorrectoError')};
                const errors: FormErrorField[] = FormUtils.addError(this.state.errorFields, emailError);

                resolve(errors);

            } else {
                resolve([]);
            }
        });
    };

    private submit = (form: FormInstance) => {
        this.setState({errorFields: []}, () => {
            this.formValidation(form);
        });
    };

    private formValidation = (form: FormInstance) => {
        form.validateFields().then(values => {
            this.internalValidation(values, true);
        }).catch(info => {
            this.internalValidation(info.values, false);
        });
    };

    private internalValidation = (values: Store, formValidationSucceded: boolean) => {
        this.validatePassword(values)?.then(errors => {

            if (formValidationSucceded && (!errors || errors.length === 0)) {
                this.save(values);
            } else {
                this.setState({errorFields: errors});
            }
        });
    };

    private validatePassword = (values: Store) => {

        return new Promise((resolve: (f: FormErrorField[]) => void) => {
            Rest<{
                type: string,
                newPassword: string,
                confirmNewPassword: string
            }, ValidateAltaPasswordResult>().operation({
                type: 'ValidateAltaPassword',
                newPassword: values.newPassword,
                confirmNewPassword: values.confirmNewPassword,
            })
                .then(result => {
                    let errors: FormErrorField[] = [];

                    if (result && result.incorrectNewPassword) {
                        const passError = {
                            fieldName: 'newPassword',
                            errorMessage: this.props.t('incorrectPasswordSecurity'),
                        };

                        errors = FormUtils.addError(errors, passError);
                    }

                    if (result && result.incorrectPasswordConfirmation) {
                        const passError = {
                            fieldName: 'confirmNewPassword',
                            errorMessage: this.props.t('incorrectPasswordConfirmation'),
                        };

                        errors = FormUtils.addError(errors, passError);
                    }

                    resolve(errors);
                });
        });
    };

    private save = (values: Store) => {
        Rest<{
            type: string,
            token: string,
            newPassword: string,
            confirmNewPassword: string,
            locationPrestador?: AltaPrestadorLocalizacion
        }, void>()
            .operation({
                type: 'CreateUserPrestador',
                token: this.token,
                newPassword: values.newPassword,
                confirmNewPassword: values.confirmNewPassword,
                locationPrestador: this.state.locationPrestador,
            }).then(() => {
            this.setState({stepHigh: false, stepOne: false, stepTwo: false, stepThree: false, stepCompleted: true});
        });
    };

    private access = () => {
        this.props.history.push('/login');
    };
}

export default withTranslation('welcome')(withRouter(WelcomeContainer));