import React from 'react';

import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import View from './selectLocation';
import {LocationPrestador} from './entities/locationPrestador';
import {PrestadorLocationInfo} from '../../layout/entities/HeaderInfo';
import {Rest} from '../../utils/utils';

interface IProps {
    visible?: boolean;
    // locationPrestadorList?: LocationPrestador[];
    onClose: () => void;
    newLocation: () => void;
    // onEditLocationDetails: (idx: number, idLocation: number) => void;


    idSelectedLocation?: number;
    selectLocationContainerKey?: number;
    onSelectLocation: (selectedLocation: LocationPrestador) => void;
    onEditLocation: (location: LocationPrestador) => void;

}

export interface IState {
    localization?: PrestadorLocationInfo;
}

class SelectLocationContainer extends React.Component<IProps & WithTranslation & RouteComponentProps> {

    state: IState = {};

    componentDidMount() {
        if (this.props.visible) {
            this.loadData();
        }
    }

    loadData = () => {
        Rest<{ type: string; }, PrestadorLocationInfo>().operation({type: 'GetPrestadorLocationInfo'}, true,
        ).then(response => {
            const localization: PrestadorLocationInfo = response;
            if (localization !== undefined && localization.prestadorLocationList !== undefined) {
                if (this.props.idSelectedLocation !== undefined) {
                    localization.idSelectedLocalization = this.props.idSelectedLocation;
                }

                localization.prestadorLocationList = response.prestadorLocationList.map(location => {
                    location.selected = location.idLocalizacion === localization.idSelectedLocalization;
                    return location;
                });
            }

            this.setState({localization: localization});
        });

    };



    onSelectLocation = (selectedLocation: LocationPrestador) => {
        this.props.onSelectLocation(selectedLocation);
    };

    onEditLocation = (location: LocationPrestador) => {
        this.props.onEditLocation(location);
    }


    public render() {
        return (
            <View
                visible={this.props.visible}
                // locationPrestadorList={this.props.locationPrestadorList}
                onCancel={() => this.props.onClose()}
                newLocation={this.props.newLocation}


                //
                localization={this.state.localization}
                onSelectLocation={this.onSelectLocation}
                onEditLocation={this.onEditLocation}


            />
        );
    }
}

export default withTranslation('newLocationPrescription')(withRouter(SelectLocationContainer));