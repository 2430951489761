import { stringify } from 'querystring';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DatosContactoPrestador from './entities/DatosContactoPrestador'
import { Rest } from '../utils/utils';
import View from './ConsentView';
import PatientData from '../prescriptions/patient/entities/patientData';
import i18n from '../i18n/i18n';

export interface IState {
    descConsentimiento?: string;
    loaded?: boolean;
    prestadorInfo?: DatosContactoPrestador;
    pacienteInfo?: PatientData;
}

class ConsentContainer extends React.Component<WithTranslation & RouteComponentProps<{ token: string }>, IState> {

    public token: string = this.props.match.params.token;

    public state: IState = {};

    public componentDidMount() {
        this.loadData();
        this.loadPrescriptorData();
    }

    public render() {
        return (
            this.state.loaded ?
                <View
                    descConsentimiento={this.state.descConsentimiento}
                    prestadorData={this.state.prestadorInfo}
                    aceptConsentimiento={this.aceptConsentimiento}
                    refuseConsentimiento={this.refuseConsentimiento} />
            :
            <></>
        )
    }

    private loadData = () => {
        Rest<{type: string, token: string}, any>().operation({
            type: 'GetTextosConsentimiento',
            token: this.token,
        }).then(response => {
            if(response.error) {
                this.props.history.push(response.error);
            } else {
                this.setState({descConsentimiento: response.descConsentimiento,loaded: true})
            }
        });
    }

    private loadPrescriptorData = () => {
        Rest<{type: string, token: string}, any>().operation({
            type: 'GetPrestadorInfo',
            token: this.token,
        }).then(response => {
                this.setState({prestadorInfo: response})
        });
    }

    private aceptConsentimiento = () => {
        Rest<{type: string, token: string}, string>().operation({
            type: 'AceptConsentimiento',
            token: this.token,
        }).then(response => {
            this.props.history.push(response);
        });
    }

    private refuseConsentimiento = () => {
        Rest<{type: string, token: string}, string>().operation({
            type: 'RefuseConsentimiento',
            token: this.token,
        }).then(response => {
            this.props.history.push(response);
        });
    }
}

export default withTranslation('consent')(withRouter(ConsentContainer));