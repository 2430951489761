import { Button, Col, Form, FormInstance, Input, Radio, Row, Select } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LOGOERECETA from '../images/logoEReceta.png';
import SubscriptionData from "./entities/subscriptionData";
import {FormUtils, removeAccents} from '../utils/utils';
import Municipio from "../prescriptions/locations/entities/municipio";
import Provincia from "../prescriptions/locations/entities/provincia";
import EnumCodeIdentificationType from "../enum/EnumCodeIdentificationType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import FormErrorField from "../utils/form/formErrorField";
import { useHistory } from "react-router-dom";

interface IProps {
    subscriptionData?: SubscriptionData;
    provinciaList?:  Provincia[];
    municipioList?: Municipio[];
    codeProvincia?: string;
    errors?: FormErrorField[];
    validateAndSave: (form: FormInstance) => void;
    onChangeCP: (form: FormInstance, e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SubscriptionRenovation = (props: IProps) => {

    const { t } = useTranslation(['subscription']);
    const [form] = Form.useForm();
    const [cpChanged,setCpChanged] = useState(false);

    const history = useHistory();

    const initialValues = props.subscriptionData && {
        ...props.subscriptionData,
        codeIdentificacion: props.subscriptionData.valueNif,
        tipoCliente: props.subscriptionData.valueTipoFacturador,
        valueNombre: props.subscriptionData.valueNombre,
        valuePrimerApellido: props.subscriptionData.valuePrimerApellido,
        valueSegundoApellido: props.subscriptionData.valueSegundoApellido,
        valueDireccion: props.subscriptionData.valueDireccion,
        valueDetalleDireccion: props.subscriptionData.valueDetalleDireccion,
        codigoPostal: props.subscriptionData.valueCodigoPostal,
        codeProvincia: props.subscriptionData.valueProvincia,
        codeMunicipio: props.subscriptionData.valuePoblacion,
        valueEmail: props.subscriptionData.valueEmail,
        valueTelefono: props.subscriptionData.valueTelefono,
        typeDocParticular: props.subscriptionData.valueTipoNif,
    };
    const checkChar = (e: any) => {
        if ('0123456789'.indexOf(e.key) === -1) {
            e.preventDefault();
        }
    };
    const {Option} = Select;

    const typeDocList = [
        <Option key={EnumCodeIdentificationType.DNI}
                value={EnumCodeIdentificationType.DNI}>{t('codeIdentificationType:' + EnumCodeIdentificationType.DNI)}</Option>,
        <Option key={EnumCodeIdentificationType.NIE}
                value={EnumCodeIdentificationType.NIE}>{t('codeIdentificationType:' + EnumCodeIdentificationType.NIE)}</Option>,
        <Option key={EnumCodeIdentificationType.PASAPORTE}
                value={EnumCodeIdentificationType.PASAPORTE}>{t('codeIdentificationType:' + EnumCodeIdentificationType.PASAPORTE)}</Option>
    ];

    const typeDocEmpresaList = [
        <Option key={EnumCodeIdentificationType.CIF}
                value={EnumCodeIdentificationType.CIF}>{t('codeIdentificationType:' + EnumCodeIdentificationType.CIF)}</Option>,
    ];


    const typeDocParticular = (
        <Form.Item noStyle name='typeDocParticular' rules={[{required: true}]}>
            <Select dropdownStyle={{minWidth: '8rem'}}>
                {typeDocList}
            </Select>
        </Form.Item>);

    const typeDocEmpresa = (
        <Form.Item noStyle name='typeDocEmpresa' rules={[{required: true}]} initialValue={EnumCodeIdentificationType.CIF}>
            <Select dropdownStyle={{minWidth: '8rem'}} defaultValue={EnumCodeIdentificationType.CIF}>
                {typeDocEmpresaList}
            </Select>
        </Form.Item>
 
 );

    return (
        <>
        <div className='enlace_token_container subscription'>
            <div className='enlace_token_card_container subscription'>
                <div className='enlace_token_card subscription' style={{width: '780px'}}>
                    <div className='enlace_token_logo'><img src={LOGOERECETA}  alt=''/></div>
                    <div className='enlace_token_title suscripcion'>{t('renewSubscriptionTitle')}</div>
                    
                    <Form layout='vertical' form={form} size='large' initialValues={initialValues}
                        /*onValuesChange={(v: any, k: any) => props.onFormValuesChanged(form, v, k)}*/
                    >
                    <Row gutter={24}>
                        <Col span={12} className="colTextoSuscripcion">
                            <div className="datosDeFacturacionTitle textoSuscripcion" 
                            dangerouslySetInnerHTML={{__html: 
                             t("suscripcionTexto", {n:props.subscriptionData?.valueColegiado,date:props.subscriptionData?.dateHasta.toLocaleString(undefined,{ year: 'numeric', month: '2-digit', day: '2-digit'})})}}></div>
                        </Col>
                        <Col className="colLineOfPoints" span={8}>
                            <div className="lineOfPoints"></div>
                        </Col>
                        <Col span={3} className="colMoneyDiv">
                            <div className="moneyDiv">
                                <div>{t('dineroBase',{n:props.subscriptionData?.amntPrice})}</div>
                                <div>{t('dineroIva',{n:(props.subscriptionData?.amntIva!)})}</div>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <div className="datosDeFacturacionTitle">
                                <span>{t("formaDePago")}</span>
                                <span className="tipoPago">{t("tarjetaDeCredito")}</span>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={8}>
                            <div className="datosDeFacturacionTitle">{t("datosDeFacturacionTitle")}</div>
                        </Col>
                    </Row>
                    
                    <Row gutter={24}>
                        <Col span={16}>       
                            <div className="tipoClienteForm">
                                <div className="tipoDeClienteTextDiv">{t("tipoDeCliente")}</div>
                                <Form.Item name='tipoCliente'>
                                    <Radio.Group>
                                        <Radio value={1}> {t('particular')} </Radio>
                                        <Radio value={2}> {t('empresa')} </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </Col>
                        <Form.Item shouldUpdate noStyle>
                            {() => form.getFieldValue("tipoCliente")==1 && 
                            <Col span={8}>
                                <Form.Item name='codeIdentificacion' label={t('seleccionarNif')} rules={[{required: true}]}
                                validateStatus={FormUtils.isError('codeIdentificacion', props.errors) ? 'error' : undefined}
                                help={FormUtils.getErrorMessage('codeIdentificacion', props.errors)}
                                >
                                    <Input addonBefore={typeDocParticular} autoFocus={true} />
                                </Form.Item>
                            </Col>}
                        </Form.Item>
                    </Row>
                    <Row gutter={24}>
                        <Form.Item shouldUpdate noStyle>
                            {() => 
                                <Col span={form.getFieldValue("tipoCliente")==1 ? 8 : 16 }>
                                    <Form.Item label={form.getFieldValue("tipoCliente")==1 ? t('nombre'): t('razonSocial')} name='valueNombre' rules={[{required: true}]}>
                                        <Input maxLength={120}/>
                                    </Form.Item>
                                </Col>
                            }
                        </Form.Item>
                        <Form.Item shouldUpdate noStyle>
                            {() => form.getFieldValue("tipoCliente")==1 && 
                            <Col span={8}>
                                <Form.Item label={t('primerApellido')} name='valuePrimerApellido' rules={[{required: true}]}>
                                    <Input maxLength={120}/>
                                </Form.Item>
                            </Col>}
                        </Form.Item>
                        <Form.Item shouldUpdate noStyle>
                            {() => form.getFieldValue("tipoCliente")==1 && 
                            <Col span={8}>
                                <Form.Item label={t('segundoApellido')} name='valueSegundoApellido'>
                                    <Input maxLength={120}/>
                                </Form.Item>
                            </Col>}
                        </Form.Item>
                        <Form.Item shouldUpdate noStyle>
                            {() => form.getFieldValue("tipoCliente")==2 && 
                            <Col span={8}>
                                <Form.Item name='codeIdentificacion' label={t('seleccionarNif')} style={{marginBottom: 0}} 
                                validateStatus={FormUtils.isError('codeIdentificacion', props.errors) ? 'error' : undefined}
                                help={FormUtils.getErrorMessage('codeIdentificacion', props.errors)}>
                                    <Input addonBefore={typeDocEmpresa} autoFocus={true} />
                                </Form.Item>
                            </Col>}
                        </Form.Item>
                    </Row>

                    <Row gutter={24}>
                        <Col span={16}>
                            <Form.Item label={t('direccion')} name='valueDireccion' rules={[{required: true}]}>
                                <Input maxLength={120}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t('detalleDireccion')} name='valueDetalleDireccion'>
                                <Input maxLength={120}/>
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label={t('codigoPostal')} name='codigoPostal' rules={[{required: true}]}>
                                <Input maxLength={5} minLength={5} onKeyPress={checkChar} onChange={() => setCpChanged(true)} onBlur={(e) => {if(cpChanged===true){setCpChanged(false);props.onChangeCP(form, e)}}}
                                    placeholder={t('introduceCodigoPostal')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t('provincia')} name='codeProvincia' rules={[{required: true}]}>
                                <Select
                                    placeholder={t('selectProvincia')}
                                    showSearch={true}
                                    disabled={props.codeProvincia!=undefined && props.codeProvincia!=""}
                                    defaultActiveFirstOption={true}
                                    filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}
                                >
                                    {props.provinciaList?.map(pi => {
                                        return (
                                            <Option
                                                key={pi.codeProvincia}
                                                label={removeAccents(pi.nameProvincia)}
                                                value={pi.codeProvincia}>
                                                {pi.nameProvincia}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t('poblacion')} name='codeMunicipio' rules={[{required: true}]}>
                                <Select
                                    showSearch={true}
                                    disabled={!props.codeProvincia}
                                    placeholder={t('selectPoblacion')}
                                    filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}
                                >
                                    {props.municipioList?.map(pi => {
                                        return (
                                            <Option
                                                key={pi.codeMunicipio}
                                                value={pi.codeMunicipio}
                                                label={removeAccents(pi.nameMunicipio)}
                                            >
                                                {pi.nameMunicipio}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={t('email')} name='valueEmail'  validateStatus={FormUtils.isError('valueEmail', props.errors) ? 'error' : undefined}
                                help={FormUtils.getErrorMessage('valueEmail', props.errors)} rules={[{
                                required: true,
                                type: 'email', message: t('incorrectEmail'), validateTrigger: 'props.onSubmit',
                            }]}>
                                <Input maxLength={128}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={t('telefono')} name='valueTelefono' rules={[{required: true}]}>
                                <Input maxLength={128}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    </Form>

                    <div className='subscription-buttons'>
                        <Button type='primary' icon={<FontAwesomeIcon icon={faLock} style={{ color:"white", fontSize: '1rem', marginRight:"0.5rem"}} />} onClick={() => props.validateAndSave(form)} size='middle'>
                            {t('goToTpv')}
                        </Button>
                        <Button type='dashed' onClick={() =>{ history.push('/login') }} size='middle'>
                            {t('buttons:cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default SubscriptionRenovation;