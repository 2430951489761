import React from 'react';


import { IState as IStateContainer } from './listaPacientesContainer';
import { Table, Drawer, Space, Button, Tooltip, Badge, FormInstance } from 'antd';
import { concatName, TableIcons, Functions } from '../../../utils/utils';
import { AlignType } from 'rc-table/lib/interface';
import { useTranslation } from 'react-i18next';
import ListadoFilters from './listadoFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartbeat } from '@fortawesome/free-solid-svg-icons';




interface IProps {
    refresh: () => void;
    onChangePage: (page: number) => void;
    onCollapseFilters: () => void;
    applyFilters: (form: FormInstance) => void;
    resetFilters: () => void;
    onClickDetail: (idPaciente: number, nombrePaciente: string, pending: boolean) => void;
    onNewPatient: () => void;
    onClickPatientNewPrescription: (idpaciente: number) => void;
}

const ListadoPacientes = (props: IProps & IStateContainer) => {
    const { t } = useTranslation(['patientList']);

    const columns = [
        {
            title: t('titlePaciente'), dataIndex: 'paciente', align: 'left' as AlignType, width: '13rem',
            render: (value: any, record: any) => {
                const name = concatName(record.nameNombre, record.nameApellido1, record.nameApellido2);

                const date = Functions.dateToString(record.dateNacimiento);
                const localDate = new Date();
                const diff = localDate.getFullYear() - record.dateNacimiento.getFullYear();

                const onClick = () => {
                    props.onClickDetail(record.idPaciente, record.nameNombre + ' ' + record.nameApellido1 + ' ', true);
                }

                return <><span>
                    <button
                        onClick={onClick}
                        style={{
                            background: 'none',
                            border: 'none',
                            padding: 0,
                            margin: 0,
                            cursor: 'pointer'   
                        }}
                    >
                        <strong>{record.codeIdentificacion}</strong> {name}
                    </button>
                </span>
                    <span style={{ marginLeft: '0.5rem' }}>
                        <Tooltip title={t('edadPaciente')} >
                            <span style={{
                                border: 'solid 1px lightgray',
                                color: 'black',
                                borderRadius: '0.2rem',
                                padding: '2px',
                                justifyContent: 'center',
                                fontSize: '0.75rem'
                            }}>
                                {diff}
                            </span>
                        </Tooltip>
                    </span>
                    {record.valueDuracion >= 365 && (
                        <span style={{ marginLeft: '0.5rem' }}>
                            <Tooltip title={t('pacienteCronico')}>
                                <span
                                    style={{
                                        border: 'solid 1px lightgray',
                                        color: 'black',
                                        borderRadius: '0.2rem',
                                        padding: '2px',
                                        justifyContent: 'center',
                                        fontSize: '0.75rem',
                                    }}
                                >
                                    {<FontAwesomeIcon icon={faHeartbeat} />}

                                </span>
                            </Tooltip>
                        </span>
                    )}

                </>


            }
        },
        {
            title: t('titleClasificacion'), dataIndex: 'idMutualidad', ellipsis: true, align: 'left' as AlignType, width: '4rem',
            render: (value: any, record: any) => {
                var clasificacion = '';
                if (record.idAseguradora) {
                    clasificacion = t('pacienteAsegurado');
                }
                if (record.idMutualidad) {
                    clasificacion = t('pacienteMutualista');
                }
                if (!record.idAseguradora && !record.idMutualidad) {
                    clasificacion = t('pacientePrivado');
                }
                return <span>
                    {clasificacion}
                </span>;
            }
        },
        {
            title: t('titleTelefono'), dataIndex: 'valueTelefono', ellipsis: true, align: 'left' as AlignType, width: '8%',
            render: (value: any, record: any) => {
                return <span>{record.valueTelefono}</span>;
            }
        },
        {
            title: t('titleEmail'), dataIndex: 'valueEmail', align: 'left' as AlignType, width: '20%', ellipsis: true,
            render: (value: any, record: any) => {
                return <span>{record.valueEmail}</span>;
            }
        },
        {
            title: t('titleAlta'), dataIndex: 'dateAlta', ellipsis: true, align: 'left' as AlignType, width: '7%',
            render: (dateAlta: Date) => {
                return dateAlta && Functions.dateToString(dateAlta);
            }
        },
        {
            title: t('titleUltPresc'), dataIndex: 'fechaPrescripcion', ellipsis: true, align: 'left' as AlignType, width: '7%',
            render: (fechaPrescripcion: Date) => {
                return fechaPrescripcion && Functions.dateToString(fechaPrescripcion);
            }
        },
        {
            title: t('titleEstadoTratamiento'), dataIndex: 'estadoTratamientos', ellipsis: true, align: 'left' as AlignType, width: '8%',
            render: (value: any, record: any) => {
                var color;

                if (record.estadoTratamientos == "t") {
                    var estado = t('tratamientoActivo');
                    color = "#8EAD38";
                } else {
                    var estado = t('tratamientoNoActivo');
                    color = "#FF0000";
                }

                return (
                    <span>
                        <div
                            style={{ backgroundColor: color, width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }}>
                        </div>
                        {estado}
                    </span>
                );
            }
        },
        {
            align: 'right' as AlignType, width: '8.5rem', render: (value: any, record: any) => {

                const onClick = () => {
                    props.onClickDetail(record.idPaciente, record.nameNombre + ' ' + record.nameApellido1 + ' ', true);
                }

                return (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Tooltip title={t('AcercaDePaciente')}>
                            <Button type='default' onClick={onClick} style={{ marginRight: '10px' }}>{t('buttons:details')}</Button>
                        </Tooltip>
                        <Button type='primary' onClick={() => props.onClickPatientNewPrescription(record.idPaciente!)} icon={TableIcons.getTableIcon(TableIcons.TableIcon.plus)}></Button>

                    </div>
                );
            }
        }
    ];




    return (
        <>
            <Drawer className='filters-drawer' visible={!props.filtersCollapsed}
                onClose={props.onCollapseFilters}
                width='300px' placement='left' getContainer={false}>
                <ListadoFilters {...props}
                    key={props.filterKey}
                />
            </Drawer>

            <Space size='small' className='table-button-bar'>

                <Badge className='filter-badge' count={props.nFilters}>
                    <Button type={props.filtersCollapsed ? 'primary' : 'default'} icon={TableIcons.getTableIcon(TableIcons.TableIcon.filter)}
                        onClick={props.onCollapseFilters} />
                </Badge>

                <Button type='primary' icon={TableIcons.getTableIcon(TableIcons.TableIcon.reload)}
                    onClick={props.refresh} />

                {/*<Button type='primary' icon={TableIcons.getTableIcon(TableIcons.TableIcon.plus)}
                    onClick={props.onNewPatient}>
                    <span>&nbsp;</span>
                    {t('botonNuevoPaciente')}
                </Button>*/}
            </Space>


            <div className='table-container'>
                <Table
                    columns={columns}
                    dataSource={props.patientList}
                    size='small'
                    pagination={{
                        position: ['bottomCenter'],
                        hideOnSinglePage: true,
                        showSizeChanger: false,
                        current: props.page,
                        pageSize: props.limit,
                        total: props.dataCount,
                        onChange: props.onChangePage
                    }}
                />
            </div>


        </>
    )
}

export default ListadoPacientes;