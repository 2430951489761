import { Button, Checkbox } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LOGOERECETA from '../images/logoEReceta.png';
import ConsentTerms from "./ConsentTerms";
import DatosContactoPrestador from './entities/DatosContactoPrestador';
interface IProps {
    descConsentimiento?: string;
    prestadorData?: DatosContactoPrestador;
    aceptConsentimiento?: () => void;
    refuseConsentimiento?: () => void;
}
const ConsentView = (props: IProps) => {

    const { t } = useTranslation(['consent']);
    
    const [disabledContent, setDisabledContent] = useState(true);

    return (
        <div className='enlace_token_container'>
            <div className='enlace_token_card_container'>
                <div className='enlace_token_card'>
                    <div className='enlace_token_logo'><img src={LOGOERECETA}  alt=''/></div>
                    <div className='enlace_token_title'>{t('consentTitle')}</div>
                    <div className='enlace_token_prestador'>{props.descConsentimiento && props.descConsentimiento.split(':')[0] + ':'}</div>
                    <ConsentTerms prestadorData={props.prestadorData}></ConsentTerms>
                    <div className='acept-consent'>
                        <Checkbox onChange={() => setDisabledContent(!disabledContent)} />
                        <div className='acept-enlace_token_text'>{props.descConsentimiento && props.descConsentimiento.split(':')[1]}</div>
                    </div>
                    <div className='enlace_token_buttons'>
                        <Button type='default' onClick={props.refuseConsentimiento}>
                            {t('noConsentButton')}
                        </Button>
                        <Button type='primary' style={{marginLeft: '1rem'}} disabled={disabledContent} onClick={props.aceptConsentimiento}>
                            {t('consentButton')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
            
    )
}

export default ConsentView;