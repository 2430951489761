export default {
    USUARIO_CONTRASENA_ERROR_TYPE_MODULO_EXISTENTE_PRIMER_INTENTO: 1,
    USUARIO_CONTRASENA_ERROR_TYPE_MODULO_EXISTENTE_OTROS_INTENTOS: 2,
    USUARIO_CONTRASENA_ERROR_TYPE_MODULO_NO_EXISTENTE: 3,
    USUARIO_INACTIVO: 4,
    USUARIO_BLOQUEADO_POR_INTENTOS_SUPERADOS: 5,
    USUARIO_BLOQUEADO_POR_INACTIVIDAD: 6,
    CODE_OTP_INCORRECTO: 7,
    CODE_OTP_CADUCADO: 8,
    ACCESO_NO_PERMITIDO: 9,
    SUSCRIPCION_CADUCADA: 10,
}
