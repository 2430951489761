import { Button, Col, Popover, Row, Tooltip } from 'antd';
import React from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import EnumPrescriptionType from '../../../enum/EnumPrescriptionType';
import { Rest, TableIcons } from '../../../utils/utils';

interface IProps {
    type?: number,
    id?: number,
    visible: boolean
}

export interface IState {
    popoverVisible?: boolean;
    content?: JSX.Element;
    productSelected?: any;
    alertsDismiss?: boolean;
    alertsSave?: boolean;
}

class ProductPopOver extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {
    public state: IState = {
        popoverVisible: false
    };

    
    public content = () => (
        this.state.productSelected ? <div style={{width: '650px'}}>
                    
            {Object.values(this.state.productSelected).map((value: any, key: any) =>  
               
                <Row gutter={8} style={{paddingBottom: '1rem'}}>
                    <Col span={8} style={{textAlign: 'end'}}><b>{this.props.t(Object.keys(this.state.productSelected!)[key].toString())}</b></Col>
                    <Col span={16}>{(Object.keys(this.state.productSelected!)[key].toString() == "date_added" || 
                                                Object.keys(this.state.productSelected!)[key].toString() == "registrationDate" || 
                                                Object.keys(this.state.productSelected!)[key].toString() == "commercializationDate" ||
                                                Object.keys(this.state.productSelected!)[key].toString() == "cancellationDate") ? new Date(value).toLocaleDateString() : 
                                                Object.keys(this.state.productSelected!)[key].toString() == "price" ? new Number(value).toFixed(2) : this.props.t(value.toString())}</Col>
                </Row>
            )}
        </div>: 
        <></>);
    
    public render() {
        return (
            <>
            <Popover content={this.content} trigger='click' placement='leftTop' style={{width: '650px'}}
                    overlayClassName='no-arrow'
                    visible={this.state.popoverVisible}
                    onVisibleChange={visible => this.setState({popoverVisible:visible})}>
                {this.props.type != EnumPrescriptionType.FORMULA_MAGISTRAL_CATALOGADA && <Tooltip title={this.props.t('medicineDetailButton')}>
                    <Button size='small'
                            type={this.state.popoverVisible ? 'primary' : 'default'}
                            icon={TableIcons.getTableIcon(TableIcons.TableIcon.eye)}
                            style={{color: '#0091FF', fontSize: '0.8rem'}}
                            onClick={() => this.onClickDetail()}/>
                </Tooltip>}
            </Popover>    
        </>
        )
    }
    public onClickDetail = () => {
        
       Rest<{ type: string; id: number }, any>().operation({
            type: this.props.type == EnumPrescriptionType.NUTRICION_CATALOGADA ? 'GetVidalPackageInfo': 'GetVidalAccesoryEffectInfo',
            id: this.props.id!
        }).then(response => {
            
            this.setState({popoverVisible: true, productSelected:response})
        });
    
}

    
}

export default withTranslation('vidalTablesProductInfo')(withRouter(ProductPopOver));