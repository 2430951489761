import { Button, Col, FormInstance, Input, Modal, Row, Form, Checkbox } from 'antd';
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { IState as IStateContainer } from './FormularioFormContainer';
import TextArea from 'antd/lib/input/TextArea';

interface IProps {
    formVisible: boolean;
    onSubmit: (form: FormInstance) => void;
    onCancel: (form: FormInstance) => void;
    onClickPrivacyPolicy?: (fromContact: boolean) => void;
}

const FormularioForm = (props: IProps & IStateContainer) => {

    const { t } = useTranslation(['formularioForm']);
    const [form] = Form.useForm();
    const [btnEnabled, setBtnEnabled] = useState<boolean>(false);
    const initialValues = {}

    useEffect( () => {
        if(form && props.formVisible) {
            setBtnEnabled(form.getFieldValue('typeNotification_'));
        }
    }, [form, props.formVisible]);


    return (
        <Modal
            visible={props.formVisible || false}
            footer={null}
            destroyOnClose
            closable={true}
            onOk={() => props.onSubmit(form)}
            onCancel={() => props.onCancel(form)}
            width='600px'
            style={{ top: 40 }}
            maskClosable={false}>

            <Form layout='vertical' form={form} size='large' initialValues={initialValues}>
                <Row style={{ justifyContent: 'center' }}>
                    <Col>
                        <h1 style={{ fontWeight: 'bold' }}> FORMULARIO DE CONTACTO</h1>
                    </Col>
                </Row>
                <Row>
                    <span>
                        {t('intro')}
                    </span>
                </Row>
                <Row gutter={12} style={{ marginTop: '20px' }}>
                    <Col span={12}>
                        <Form.Item label={t('nombre')} name='valueNombre' rules={[{ required: true }]}>
                            <Input maxLength={120} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('apellidos')} name='valueApellidos' rules={[{ required: true }]}>
                            <Input maxLength={120} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item label={t('email')} name='valueEmail' rules={[{
                            required: true,
                            type: 'email', message: t('incorrectEmail'), validateTrigger: 'props.onSubmit'
                        }]}>
                            <Input maxLength={120} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('asunto')} name='valueAsunto' rules={[{
                            required: true, min: 5,
                            validateTrigger: 'props.onSubmit'
                        }]}>
                            <Input maxLength={120} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col>
                        <Form.Item label={t('mensaje')} name='valueMensaje' rules={[{ required: true }]}>

                            <TextArea rows={8} cols={100} style={{ resize: 'none' }} />
                        </Form.Item>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <Form.Item
                            style={{ fontWeight: 'bold' }}
                            name={'typeNotification_'} valuePropName='checked' shouldUpdate>
                            <Checkbox
                                onChange={() => setBtnEnabled(!btnEnabled)}
                            >{t('aceptarPoliticaDePrivacidad')}</Checkbox>
                            <div className='view-policy-link' onClick={() => {props.onClickPrivacyPolicy!(true)}}>
                                {t('verPolitica')}</div>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ textAlign: 'center' }}>
                    <Button style={{ width: '100px' }} disabled={!btnEnabled} type='primary' size='middle'
                        onClick={() => props.onSubmit(form)}>{t('buttons:enviar')}</Button>
                </div>
            </Form>

        </Modal>
    );
}

export default FormularioForm;


