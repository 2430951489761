import React from 'react';

import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import EnumEmittedPrescriptionAction from '../../enum/EnumEmittedPrescriptionAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    visible?: boolean;
    action?: number;
    confirm(): void;
}

const EmittedPrescriptionActionsConfirmation = (props: IProps) => {

    const { t } = useTranslation(['emittedPrescriptionActions']);

    const message = () => {
        let message = '';
        if (props.action === EnumEmittedPrescriptionAction.NEW) {
            message = t('newConfirmation');
        }
        if (props.action === EnumEmittedPrescriptionAction.MODIFY) {
            message = t('modifyConfirmation');
        }
        if (props.action === EnumEmittedPrescriptionAction.VOID) {
            message = t('voidConfirmation');
        }
        return message;
    }

    return (
        <Modal
            style={{ top: 40 }}
            visible={props.visible}
            footer={null}
            width={400}
            closable={false}
            destroyOnClose
        >
            <div className='prescription-confirmation'>
                <div className='prescription-confirmation-logo'><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'yellowGreen', fontSize: '3rem' }} /></div>
                <div className='prescription-confirmation-text'>{message()}</div>
                <div className='prescription-confirmation-button'><Button type='primary' onClick={props.confirm}>{t('buttons:continue')}</Button></div>
            </div>
        </Modal>
    );
    
}
export default EmittedPrescriptionActionsConfirmation;