import React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Rest } from '../../utils/utils';
import PrescripcionPosologyInfo from '../entities/prescripcionPosologyInfo';
import { notification } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import DetallePrescripcionEmitida from './DetallePrescripcionEmitida';

interface IProps {

}

interface IState {
    pres: PrescripcionPosologyInfo;
}

class DetallePrescripcionEmitidaContainer extends React.Component<WithTranslation & RouteComponentProps<{ token: string }> & IProps, IState> {

    public token: string = this.props.match.params.token;

    componentDidMount(): void {
        this.loadData();
    }

    private loadData = () => {
        Rest<{type: string, token: string}, any>().operation({
            type: 'GetPrescriptionDetailsByExtAccessToken',
            token: this.token,
        }).then(response => {
            this.setState({pres: {...response.prescripcion, recetas: response.recetas}});
        });
    }

    render(): React.ReactNode {
        
        return (this.state && <DetallePrescripcionEmitida pres={this.state.pres}
                reactivatePrescription={this.reactivatePrescription}
                voidPrescription={this.voidPrescription}
                consultReplace={this.consultReplace}/>);
    }

    private reactivatePrescription = (idPrescription: number) => {
        Rest<{ type: string, idPrescripcion: number }, any>()
            .operation({
                type: 'ReactivatePrescription',
                idPrescripcion: idPrescription,
            }).then(() => {
            this.loadData();
        });
    };

    private voidPrescription = (idPrescription: number) => {
        Rest<{ type: string, idPrescripcion: number }, any>()
            .operation({
                type: 'VoidPrescription',
                idPrescripcion: idPrescription,
            }).then(() => {
            this.loadData();
        });
    };

    private consultReplace = (idPrescripcion: number, idTransaccion: number) => {
        Rest<{ type: string, idPrescripcion: number, idTransaccion: number }, any>()
            .operation({
                type: 'CodeNacionalReceiptReplace',
                idPrescripcion,
                idTransaccion,
            }).then(response => {
            if (response) {
                notification['success']({
                    message: this.props.t('prescriptionReceipt:receiptCnReplaceSuccess'),
                });
                this.loadData();
            } else {
                notification['error']({
                    message: this.props.t('prescriptionReceipt:receiptCnReplaceError'),
                });
            }
        });
    };
}

export default withTranslation('prescription')(withRouter(DetallePrescripcionEmitidaContainer));