import React from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import LOGOERECETA from '../images/logoEReceta.png';
import cx from 'classnames';

interface IProps {
    access: () => void;
}
const WelcomeStepCompletedView = (props: IProps) => {

    const { t } = useTranslation(['welcome']);

    return (
        <div className='enlace_token_container'>
            <div className='enlace_token_card_container'>
                <div className='enlace_token_card' style={{width: '410px'}}>
                    <div className='enlace_token_logo'><img src={LOGOERECETA}  alt=''/></div>
                    <div className='enlace_token_title'>{t('configurationCompletedTitle')}</div>
                    <div className={cx('enlace_token_prestador', {'welcome': true})}>{t('configurationCompletedLabel1')}</div>
                    <div className={cx('enlace_token_prestador', {'welcome': true})}>{t('configurationCompletedLabel2')}</div>

                    <div className='alta-buttons'>
                        <Button type='primary' onClick={() => props.access()} size='middle'>
                            {t('buttons:access')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomeStepCompletedView;