import { faShieldAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Modal, Result, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EnumFlagAceptacion from '../enum/EnumFlagAceptacion';

import { IState as IStateContainer } from './WaitingConsentContainer';

interface IProps {
    visible?: boolean;
    patientName?: string;
    patientMail?: string;
    onClose: (acepted?: boolean) => void;
    reSend: () => void;
}

const WaitingConsentView = (props: IProps & IStateContainer) => {

    const { t } = useTranslation(['waitingConsent']);
    
    const footer = (
        <Row justify='end'>
            <Col>
                <Button type='link' onClick={() => props.reSend()}>
                    {t('reSendButton')}
                </Button>
            </Col>

        </Row>
    )

    return (
        <Modal
            style={{ top: 40 }}
            visible={props.visible}
            onCancel={() => props.onClose()}
            closable={props.consent === undefined}
            maskClosable={false}
            bodyStyle={{ paddingTop: '2rem', paddingBottom: '1.5rem' }}
            width={430}
            footer={props.consent === undefined ? footer : null}
            destroyOnClose
        >

            <PageRender consent={props.consent} patientName={props.patientName} patientMail={props.patientMail} onClose={props.onClose} reSend={props.reSend}/>
        </Modal>
    )
}

const PageRender = (props: {consent?: number, patientName?: string, patientMail?: string, onClose: (acepted?: boolean) => void, reSend: () => void}) => {
    const { t } = useTranslation(['waitingConsent']);

    switch(props.consent) {
        case EnumFlagAceptacion.NO_ACEPTADO:
            return (
                <Result
                    status='error'
                    title={t('consentRefusedTitle')}
                    subTitle={t('consentRefusedText', {patientName: props.patientName})}
                    extra={<> <Button type='default' style={{ cursor: 'pointer' }} onClick={props.reSend}>
                            {t('refuseResend')}
                        </Button>
                        <Button type='primary' style={{ cursor: 'pointer' }} onClick={() => props.onClose(false)}>
                            {t('buttons:close')}
                        </Button> </>}/>
            )
        case EnumFlagAceptacion.ACEPTADO:
            return (
                <Result
                    status='success'
                    title={t('consentAceptedTitle')}
                    subTitle={t('consentAceptedText', {patientName: props.patientName})}
                    extra={
                        <Button type='primary' style={{ cursor: 'pointer' }} onClick={() => props.onClose(true)}>
                            {t('buttons:continue')}
                        </Button>} />
                    
            )
        default:
            return (
                <Row justify='center'>
                    <FontAwesomeIcon icon={faShieldAlt} style={{ color: 'gold', fontSize: '4rem' }} />
                    <Col span={24} style={{marginTop: '1rem', textAlign: 'center'}}>{t('consentText', {patientName: props.patientName})}</Col>
                    <Col span={24} style={{marginTop: '1rem', fontSize: '1.25rem', fontWeight: 'bold', textAlign: 'center'}}>{props.patientMail}</Col>
                    <Col span={24} style={{marginTop: '1rem', textAlign: 'center'}}>{t('waitingText', {patientName: props.patientName})}</Col>
                    <Col span={24} style={{marginTop: '1rem', textAlign: 'center'}}><FontAwesomeIcon icon={faSpinner} className='fa-pulse' style={{ fontSize: '4rem', color: 'gray' }} /></Col>
                </Row>
            )
    }
}

export default WaitingConsentView;