import React from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Rest } from '../../utils/utils';
import { Store } from 'rc-field-form/lib/interface';

import SignPrescriptionResult from './entities/signPrescriptionResult';
import View from './signPrescription';

interface IProps {
    visible?: boolean;
    onCreateValidateOtp: () => void;
    onClose: () => void;
    onSuccess: (resultSign: SignPrescriptionResult) => void;
}

export interface IState {
    errorMessage?: string;
    warningMessage?: string;
    errorFields?: string[];
    otpFormVisible?: boolean;
    codeResult?: number;
}

class SignPrescriptionContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    public state: IState = {};

    public render() {
        return (
            <View
                visible={this.props.visible}
                onClose={this.props.onClose}
                onCreateValidateOtp={this.onCreateValidateOtp}
            />
        );
    }

    private onCreateValidateOtp = () => {
        this.props.onCreateValidateOtp();      
    }    
}

export default withTranslation('signPrescripction')(withRouter(SignPrescriptionContainer));