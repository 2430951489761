export default {
    MEDICAMENTO: 0,
    PRODUCTO_SANITARIO: 1,
    NUTRICION: 2,
    VACUNA: 3,
    FORMULA_MAGISTRAL: 4,

    // ONLY FRONT
    VACUNA_CATALOGADA: 5,
    FORMULA_MAGISTRAL_CATALOGADA: 6,
    FAVORITA: 7,
    NUTRICION_CATALOGADA: 8,
    PRODUCTO_SANITARIO_CATALOGADO: 9
}
