import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import View from './OtpAuthenticationForm';
import { Rest } from "../utils/utils";
import { FormInstance, notification } from "antd";
import SignOtpModalContainer, { OtpCallerProps } from "../prescriptions/saveSing/signOtpModalContainer";
import OtpAuthenticationConfirmation from "./OtpAuthenticationConfirmation";

interface IProps {
    visible?: boolean;
    isExternalAccess?: boolean;
    reconfigExt?: boolean;
    onClose: () => void;
}

export interface IState extends OtpCallerProps {
    errorMessage?: string;
    warningMessage?: string;
    otpOnNextStep?: boolean;
    nextStepType?: number;
    otpActive?: boolean;
    otpErecetaMobileAppActive?: boolean;
    errorMsg?: string;
    otpSigned?: boolean;
    valueUuidPending?: string;
    signOtpKey?: number;
}

class OtpAuthenticationFormContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    private retryTask?: number;
    public state: IState = {
        otpType: 0
    }

    public componentDidMount() {
        if (this.props.visible && (this.props.isExternalAccess != true || this.props.reconfigExt == true)) {
            this.createOtpToAccess();
            
        }else{
            this.generateNewTotp();   
            this.setState({otpSigned: true});
        }
    }

    public componentWillUnmount() {
        clearInterval(this.retryTask!);
    }

    private createOtpToAccess = (sendByEmail?: boolean) => {
        Rest<{type: string, useEmail?: boolean}, any>().operation({
            type: 'CreateOtp',
            useEmail: sendByEmail
        }).then(response => {
            if (response && response.valueUuId) {
                this.setState({
                    otpType: response.otpType,
                    dispositivoMovil: response.dispositivoMovil,
                    valueUuId: response.valueUuId!,
                    email: response.email,
                    errorMessage: undefined,
                    warningMessage: undefined,
                    otpSigned: undefined,
                    signOtpKey: response.valueUuId!
                })
            } else {
                this.setState({warningMessage: this.props.t('passwordError'), errorMessage: undefined});
            }
        });
    }

    public render() {
        return (
            <>
                {this.state.valueUuId && <SignOtpModalContainer
                    otpType={this.state.otpType}
                    valueUuId={this.state.valueUuId}
                    key={this.state.signOtpKey}
                    email={this.state.email}
                    dispositivo={this.state.dispositivoMovil}
                    visible={this.props.visible && !this.state.otpSigned}
                    oncloseSignOTP={this.oncloseSignOTP}
                    tryAgain={this.tryAgain}
                    sendByEmail={() => this.createOtpToAccess(true)}
                    onValidationSuccess={this.onValidOtp}
                />}

                <View {...this.state}
                    visible={this.props.visible && this.state.otpSigned}
                    onActiveMail={this.onActiveMail}
                    onActive2FA={this.onActive2FA}
                    onActiveErecetaMobileApp={this.onActiveErecetaMobileApp}
                    onSubmit={this.onSubmit2FACode}
                    onCancel={this.props.onClose}
                    poolUserMobileAppBounded={this.poolUserMobileAppBounded}
                    isExternalAccess={this.props.isExternalAccess}
                    reconfigExt={this.props.reconfigExt}
                />
                <OtpAuthenticationConfirmation
                    visible={this.state.otpOnNextStep}
                    onCancel={this.onConfirmationCancel}
                    onContinue={this.onConfirmationContinue} />
            </>
        );
    }

    private oncloseSignOTP = () => {
        this.props.onClose();
    }
    
    private tryAgain = () => {
        this.setState({valueUuId: undefined, signOTPkey: undefined}, () => this.createOtpToAccess())
       
    }
    private onValidOtp = (valueUuid: string, otp?: string) => {
        Rest<{type: string}, any>().operation({
            type: 'GetOTPConfig'
        }).then(response => {
            this.setState({otpSigned: true, otpType: response.otpType, email: response.otpEmail});
        });
    }

    private onConfirmationCancel = () => {
        this.setState({otpOnNextStep: false, nextStepType: undefined});
    }

    private onConfirmationContinue = () => {
        if (this.state.nextStepType === 0) {
            this.setState({otpOnNextStep: false});
            Rest<{type: string, otpType: number}, any>().operation({
                type: 'ChangeOtpType',
                otpType: 0
            }).then(response => {
                if (response) {
                    this.props.onClose();
                    notification['success']({
                        message: this.props.t('totpCorrect')
                    });
                }
            });
        } else if (this.state.nextStepType === 1) {
           this.generateNewTotp();
        } else if (this.state.nextStepType === 2) {
            this.setState({otpOnNextStep: false, otpErecetaMobileAppActive: true});
        }
    }

    private onActiveMail = () => {
        this.setState({otpOnNextStep: true, nextStepType: 0});
    }

    private onActive2FA = () => {
        this.setState({otpOnNextStep: true, nextStepType: 1});
    }

    private generateNewTotp = () => {
        Rest<{type: string}, any>().operation({
            type: 'GenerateNewTOTP'
        }).then(response => {
            this.setState({otpOnNextStep: false, otpActive: true});
        });
    }

    private onActiveErecetaMobileApp = () => {

        this.setState({otpOnNextStep: true, nextStepType: 2});
    }
    private poolUserMobileAppBounded = () => {
        Rest<{type: string, valueUuidPending?: string}, string>().operation({
            type: 'ObtenerMobileAppPendingUuid'
        }, true).then(response => {
            
            this.retryTask = window.setInterval(() => {
                Rest<{type: string, valueUuidPending?: string}, any>().operation({
                    type: 'PoolUserMobileAppBounded',
                    valueUuidPending: response
                }, true).then(response => {
                    if (response != null) {
                            clearInterval(this.retryTask!);
                            notification['success']({
                                message: this.props.t('userMobileBounded',{valueModeloDispositivo:response.valueModeloDispositivo})
                            });
                            this.props.onClose();
                    }
                    
                });
            }, 5000);
            
        });
        
    }

    private onSubmit2FACode = (form: FormInstance) => {
        form.validateFields().then(values => {
            if (values.codeInput.length !== 6) {
                this.setState({errorMsg: this.props.t('codeFormatError')})
            }
            else {
                Rest<{type: string, otpCode: string, changeMethod: boolean}, any>().operation({
                    type: 'TotpValidation',
                    otpCode: values.codeInput,
                    changeMethod: true
                }).then(response => {
                    if (response && response.valid) {
                        Rest<{type: string, otpCode: string, otpType: number}, any>().operation({
                            type: 'ChangeOtpType',
                            otpCode: values.codeInput,
                            otpType: 1
                        }).then(response => {
                            if (response) {
                                this.props.onClose();
                                notification['success']({
                                    message: this.props.t('totpCorrect')
                                });
                            }
                        });
                    }
                    else {
                        this.setState({errorMsg: this.props.t('totpIncorrect')});
                    }
                });
            }
        });
    }

}

export default withTranslation('otpAuthenticationForm')(withRouter(OtpAuthenticationFormContainer));