import { faPlus, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Menu, Space } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EnumPrescriptionType from '../../enum/EnumPrescriptionType';
import { TableIcons } from '../../utils/utils';
import PrescripcionAnterior from '../anteriores/entities/PrescripcionAnteriorData';
import PrescripcionMedicacionList from '../anteriores/PrescripcionMedicacionList';

interface IProps {
    visible?: boolean;
    onSearch: (evt: any) => void;
    fromNewPrescription?: boolean;
    prescriptions?: PrescripcionAnterior[];
    getPrescriptionTypeIcon: (type: number) => IconDefinition;
    searchResult?: string;
    delete : (id: number) => void;
    refresh: () => void;
    newOrEditPrescription: (idPrescriptionFav?:number, typePrescription?: number) => void;
    onSelectFav?: (idPrescriptionFav:number) => void;
    onOpenMenu: () => void;
    onClickMenu: (e: any) => void;
    changeMenuVisible: (visible: boolean) => void;
    menuVisible?: boolean;
}

const FavPrescriptionsList = (props: IProps) => {
    const { t } = useTranslation(['favPrescriptionList']);

    const receiptMenu = (
        <Menu onClick={props.onClickMenu}>
            <Menu.Item key={EnumPrescriptionType.MEDICAMENTO}>{t('prescriptionType:' + EnumPrescriptionType.MEDICAMENTO)}</Menu.Item>
            <SubMenu title={t('prescriptionType:' + EnumPrescriptionType.PRODUCTO_SANITARIO)}>
                    <Menu.Item key={EnumPrescriptionType.PRODUCTO_SANITARIO_CATALOGADO}>{t('cataloged')}</Menu.Item>
                    <Menu.Item key={EnumPrescriptionType.PRODUCTO_SANITARIO}>{t('noCataloged')}</Menu.Item>
            </SubMenu>
            <SubMenu title={t('prescriptionType:' + EnumPrescriptionType.NUTRICION)}>
                <Menu.Item key={EnumPrescriptionType.NUTRICION_CATALOGADA}>{t('cataloged')}</Menu.Item>
                <Menu.Item key={EnumPrescriptionType.NUTRICION}>{t('noCataloged')}</Menu.Item>
            </SubMenu>
            <Menu.Item key={EnumPrescriptionType.VACUNA}>{t('prescriptionType:' + EnumPrescriptionType.VACUNA)} </Menu.Item>
            <SubMenu title={t('prescriptionType:' + EnumPrescriptionType.FORMULA_MAGISTRAL)}>
                <Menu.Item key={EnumPrescriptionType.FORMULA_MAGISTRAL_CATALOGADA}>{t('cataloged')}</Menu.Item>
                <Menu.Item key={EnumPrescriptionType.FORMULA_MAGISTRAL}>{t('noCataloged')}</Menu.Item>
            </SubMenu>
        </Menu>
    )
    return (
        <>
            {!props.fromNewPrescription && <Space size='small' className='table-button-bar'>    
                <Button type='primary' icon={TableIcons.getTableIcon(TableIcons.TableIcon.reload)}
                    onClick={props.refresh}/>
                
                {
                    <Dropdown overlay={receiptMenu} visible={props.menuVisible} trigger={['click']}
                    onVisibleChange={(visible: boolean) => props.changeMenuVisible(visible)}>
                        <Button icon={<FontAwesomeIcon icon={faPlus} className='anticon'/>}
                            onClick={props.onOpenMenu}>
                            {t('newPrescriptionFavButton')}

                        </Button>
                    </Dropdown>
                }
            </Space>}
            <PrescripcionMedicacionList
                visible={props.visible}
                prescriptions={props.prescriptions}
                searchResult={props.searchResult}
                getPrescriptionTypeIcon={props.getPrescriptionTypeIcon}
                onSearch={props.onSearch}  
                fav={true}
                editPrescription={props.newOrEditPrescription}
                delete={props.delete}
                fromNewPrescription={props.fromNewPrescription}
                onSelectFav={props.onSelectFav}
            />
            
           
        </>
    );
}

export default FavPrescriptionsList;