import React from 'react';
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import PrescripcionAnterior from "./entities/PrescripcionAnteriorData";
import PatientData from "../patient/entities/patientData";
import PrescripcionMedicacionList from './PrescripcionMedicacionList';

interface IProps {

    visible?: boolean;
    patientSelected?: PatientData;
    onCancel: () => void;
    onSearch: (evt: any) => void;
    prescriptions?: PrescripcionAnterior[];
    getPrescriptionTypeIcon: (type: number) => IconDefinition;
    searchResult?: string;
    prescripcionesActivas?: PrescripcionAnterior[];
}


const PrescripcionesAnteriores = (
    props: IProps
) => {

    const {t} = useTranslation(['prescripcionesAnteriores']);

    return (
        !props.prescripcionesActivas ?
        <Modal
            visible={props.visible}
            destroyOnClose
            closable
            title={props.patientSelected ? t('prescriptionesDe', {patient: props.patientSelected.namePersona}) : undefined}
            onCancel={props.onCancel}
            style={{ top: 40 }}
            width={'1200px'}
            footer={null}
        >
            <PrescripcionMedicacionList
                visible={props.visible}
                prescriptions={props.prescriptions}
                searchResult={props.searchResult}
                getPrescriptionTypeIcon={props.getPrescriptionTypeIcon}
                onSearch={props.onSearch}
            />
        </Modal>
        :
        <PrescripcionMedicacionList
                visible={props.visible}
                prescriptions={props.prescripcionesActivas}
                searchResult={props.searchResult}
                getPrescriptionTypeIcon={props.getPrescriptionTypeIcon}
                onSearch={props.onSearch}  
                showActives={true}
        />
    )
}

export default PrescripcionesAnteriores;
