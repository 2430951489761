import React from 'react';

export interface IPrestadorConfigData {
    defaultDocumentTypePassport?: boolean;
    allowPatientDataPasting?: boolean;
    vidalEnabled?: boolean;
    vidalAppToken?: string;
    hideHipEmailSending?: boolean;
}

export interface IPrestadorConfigContext {
    data: IPrestadorConfigData;
    fn: (ctxData: IPrestadorConfigData) => void;
}

const PrestadorConfigContext = React.createContext<IPrestadorConfigContext>({
    data: {}, fn: () => {
    },
});

export default  PrestadorConfigContext;

