import React from 'react';

import { Badge, Tooltip } from 'antd';

import { useTranslation } from 'react-i18next';
import { concatName } from '../utils/utils';

import LogoCosalud from './styles/images/logo.jpeg';
import LogoSegurosBilbao from './styles/images/LOGO_HOME_SB_ESP.svg';
import LogoCOSeguros from './styles/images/LOGO_HOME_SCO_ESP.svg';
import LogoPlusUltra from './styles/images/PU_svg_H_ESP.svg';
import LogoPlusUltraAntares from './styles/images/Logo_PlusUltraAntares.png';
import LogoNorteHispania from './styles/images/norte_hispana_cast.jpg';

import { Props as ContainerProps } from './HeaderGCOContainer';
import PrestadorPrescripcionData from '../layout/entities/PrestadorPrescripcionData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faTimes } from '@fortawesome/free-solid-svg-icons';

import cx from 'classnames';

const AppHeaderGCO = (props: {
    onClickClose: () => void;
    onClickBlocked: () => void;
    prestadorData?: PrestadorPrescripcionData;
    nPrescripcionesBloqueadas?: number;
    idCompaniaCosalud: number;
} & ContainerProps) => {

    const { t } = useTranslation(['header']);

    return (
        <div className='gco-header'>
            <div>
                <div className='logos-container'>
                    <div className='logo-cosalud'>
                        <img src={LogoCosalud} />
                    </div>
                    <div className='logo-compania'>
                        {props.idCompaniaCosalud === 1 && <img src={LogoCOSeguros} />}
                        {props.idCompaniaCosalud === 2 && <img src={LogoPlusUltra} />}
                        {props.idCompaniaCosalud === 3 && <img src={LogoPlusUltraAntares} />}
                        {props.idCompaniaCosalud === 4 && <img src={LogoSegurosBilbao} />}
                        {props.idCompaniaCosalud === 5 && <img src={LogoNorteHispania} />}
                    </div>
                </div>
            </div>
            <div>
                <div className='prestador'>
                    <div className='prestador--inner'>
                        {props.prestadorData && <div><b>{concatName(props.prestadorData.nameNombre, props.prestadorData.nameApellido1, props.prestadorData.nameApellido2)}</b></div>}
                        {props.prestadorData && <div>{`${props.prestadorData.especialidad}  #${props.prestadorData.numeroColegiado}`}</div>}
                    </div>
                </div>
                <div className={cx('info-bloqueo', {active: props.nPrescripcionesBloqueadas})} onMouseEnter={e => e.currentTarget.classList.add('stop-animation')} onClick={props.onClickBlocked}>
                    <Tooltip title={props.nPrescripcionesBloqueadas === 0 ? t('prescripcionesBloqueadas_zero') 
                        : t('prescripcionesBloqueadas', {count: props.nPrescripcionesBloqueadas})}>
                        <Badge count={props.nPrescripcionesBloqueadas} showZero>
                            <div className='icon-container'> <FontAwesomeIcon icon={faBell} /> </div>
                        </Badge>
                    </Tooltip>
                </div>
                <div className='header-options--container' style={{lineHeight: '0'}}>
                    <FontAwesomeIcon icon={faTimes} className='header-options--option' onClick={props.onCloseSession}/>
                </div>
            </div>
        </div>
    );
}

export default AppHeaderGCO;