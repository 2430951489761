
import React, { Component, ReactNode } from 'react';

import { Layout } from 'antd'; 
import { WithTranslation, withTranslation } from 'react-i18next';

import Header from './HeaderGCOContainer';
import PrescriptionsListContainer from '../prescriptions/prescriptionsListContainer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faTimes } from '@fortawesome/free-solid-svg-icons';

import './styles/css/stylesGCO.scss';

import cx from 'classnames';
import { Rest } from '../utils/utils';
import PrestadorConfigContext, { IPrestadorConfigData } from '../context/PrestadorConfigContext';

interface IState {
    title: string;
    defaultTitle: string;
    namePrestador: string;
    component: ReactNode;
    idCompaniaCosalud: number;
    sessionClosed?: boolean;
    prestadorConfigData: IPrestadorConfigData;
}

class AppGCO extends React.Component<WithTranslation, IState> {
    
    private setPrestadorConfigData = (ctxData: IPrestadorConfigData) => {
        this.setState({prestadorConfigData: ctxData});
    };

    public componentDidMount = () => {

        document.body.classList.add('gco');

        const promises: Array<Promise<any>> = [];
        const req1 = Rest<{ type: string }, any>();
        const req2 = Rest<{ type: string }, IPrestadorConfigData>();
        promises.push(req1.operation({type: 'GetExternalAccessSessionInfo'}, false, true));
        promises.push(req2.operation({type: 'GetPrestadorConfig'})); // PrestadorConfig

        Promise.all(promises).then(response => {
            const defaultTitle = this.props.t('prescripcionesDe', {namePaciente: response[0].namePaciente});
            this.setState({ title: defaultTitle, defaultTitle, idCompaniaCosalud: response[0].idCompaniaCosalud, prestadorConfigData: { hideHipEmailSending: response[1].hideHipEmailSending,
                    vidalEnabled: response[1].vidalEnabled, vidalAppToken: response[1].vidalAppToken}});
        },() => {
            this.setState({sessionClosed: true});
        });

    }

    public componentWillUnmount = () => {
        document.body.classList.remove('gco');
    }

    public render() {

        return (!this.state ? null : this.state.sessionClosed ? <Layout className='gco-layout'>
                <div className='session-closed'>{this.props.t('sesionCerrada')}</div>
            </Layout> : 
            <PrestadorConfigContext.Provider
                    value={{data: this.state.prestadorConfigData, fn: this.setPrestadorConfigData}}>
                <Layout className='gco-layout'>
                    <Layout.Header className='header-app'>
                        <Header onClickBlocked={this.onHeaderClickBlocked} 
                            idCompaniaCosalud={this.state.idCompaniaCosalud} 
                            onCloseSession={this.onCloseSession}/>
                    </Layout.Header>
                    <Layout.Content>
                        <div>
                            <div className='gco-titulo'>
                                <span onClick={this.resetView} className={cx({active: !this.state.component})}>{this.state.defaultTitle}</span>
                                {this.state.component &&
                                    <span className='active'>
                                        <FontAwesomeIcon icon={faAngleRight} />
                                        {this.state.title}
                                        <FontAwesomeIcon className='close-icon' icon={faTimes} onClick={this.resetView} />
                                    </span>}
                            </div>
                        </div>
                        <div className='gco-content'>
                            {this.renderView()}
                        </div>
                    </Layout.Content>
                </Layout>
            </PrestadorConfigContext.Provider>
        );
    }

    private renderView = () => {

        if (this.state.component) {
            return this.state.component;
        } else {
            return <PrescriptionsListContainer addTab={this.changeView} closeTab={this.resetView} fixedPatient hideOptions/>
        }
    }

    private changeView = (title: string, component: ReactNode) => {
        this.setState({title, component});
    }

    private resetView = () => {
        this.changeView(this.state.defaultTitle, undefined);
    }

    private onHeaderClickBlocked = () => {
        this.changeView(this.state.defaultTitle, <PrescriptionsListContainer addTab={this.changeView} closeTab={this.resetView} fixedPatient blocked hideOptions/>)
    }

    private onCloseSession = () => {
        Rest<{type: string},{}>().operation({type: 'Logout'}).then(() => {
            this.setState({sessionClosed: true});
        });
    }

}

export default withTranslation(['gco'])(AppGCO);