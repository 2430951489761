import React from 'react';
import { faCircle, faExclamationCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import EnumDispensacionStatus from '../enum/EnumDispensacionStatus';
import RecetaDetail, { Receta, Transaccion } from './entities/recetaDetail';
import { Button, Popconfirm, Tooltip } from 'antd';
import { Functions } from '../utils/utils';
import EnumTransaccionAccion from '../enum/EnumTransaccionAccion';
import EnumPrescriptionStatus from '../enum/EnumPrescriptionStatus';
import classNames from 'classnames';

interface IProps {
    idPrescription: number;
    recetas: RecetaDetail[];
    prescripcionEstado: number;
    reactivatePrescription: (idPrescription: number) => void;
    voidPrescription: (idPrescription: number) => void;
    consultReplace: (idPrescripcion: number, idTransaccion: number) => void;
    adherencia?: number;
}

const PrescriptionReceipts = (props: IProps) => {

    const { t } = useTranslation(['prescriptionReceipt']);
    
    const statusReceiptRenderer = (rec: Receta) => {

        let color;

        let status = rec.typeEstado;

        if (!status) {
            return;
        }

        switch (status) {
            case EnumDispensacionStatus.PENDIENTE:
                color =  "#A9A9A9";
                break;
            case EnumDispensacionStatus.DISPENSADA:
                color = "#8EAD38";
                break;
            case EnumDispensacionStatus.CADUCADA:
                color = "#FADB15";
                break;
            case EnumDispensacionStatus.BLOQUEADA:
                color = "#FF4D4F";
                break;
            case EnumDispensacionStatus.FORMULA_MAGISTRAL:
                color = "#002E50";
                break;
            case EnumDispensacionStatus.DISPENSADA_SUSTITUCION_1:
                color = "#1890FF";
                break;
            case EnumDispensacionStatus.DISPENSADA_SUSTITUCION_2:
                color = "#1890FF";
            break;
        }

        return (
            <span style={{marginLeft: '1rem'}}>
                {status === EnumDispensacionStatus.DISPENSADA_PARCIALMENTE ? 
                    <div className='dipensada_parcialmente_status' style={{marginRight: '0.5rem'}}/> :
                    <FontAwesomeIcon icon={faCircle} style={{color: color, marginRight: '0.5rem'}}/> } 
                {t('dispensacionStatuses:' + status)}
            </span>
        
        )
    }
    
    const bloqTransacciones = props.recetas?.find(rec => rec.receta.typeEstado === EnumDispensacionStatus.BLOQUEADA) &&
        props.recetas?.find(rec => rec.receta.typeEstado === EnumDispensacionStatus.BLOQUEADA)?.transacciones;

    const transaccion = bloqTransacciones && bloqTransacciones.find(trans => trans.typeBloqueo);
    return (
        <>
        {props.prescripcionEstado === EnumPrescriptionStatus.EMITIDA && props.recetas?.find(rec => rec.receta.typeEstado === EnumDispensacionStatus.BLOQUEADA) &&
            <div className='receipt-blocked-container'>
                <div>
                <FontAwesomeIcon icon={faTimesCircle} className='icon'/>
                </div>
                <div>
                    <div>{t('blockedTitle')} el <b>{Functions.dateToString(transaccion?.dateAccion)}</b></div>
                    <div>{t('blockedReason')} <b>{t('recetaBloqueoType:' + transaccion?.typeBloqueo)}</b></div>
                    <div><b>{t('blockedComment')} {transaccion?.valueObservaciones ? transaccion?.valueObservaciones : '-'}</b></div>
                    <div style={{marginTop: '0.5rem'}}>
                        <Popconfirm title={t('reactivateButtonConfirmation')} onConfirm={() => props.reactivatePrescription(props.idPrescription)} okText={t('buttons:confirmar')} cancelText={t('buttons:cancel')}>
                            <Button type='primary' size='small' style={{marginRight: '0.5rem'}}>
                                {t('reactivateButton')}
                            </Button>
                        </Popconfirm>
                        <Popconfirm title={t('voidButtonConfirmation')} onConfirm={() => props.voidPrescription(props.idPrescription)} okText={t('buttons:confirmar')} cancelText={t('buttons:cancel')}>
                            <Button size='small'>
                                {t('cancelPrescriptionButton')}
                            </Button>
                        </Popconfirm>
                    </div>
                </div>
            </div>
            }
            <div className='receipt-container'>
                <b>{t('receiptHistoryTitle')}</b>
                <div className='adherencia__container--outer'>
                    <span className={classNames('adherencia__container', props.adherencia && {
                        'alta': props.adherencia>=80,
                        'media': props.adherencia>=60 && props.adherencia<80,
                        'baja': props.adherencia<60
                    })}>
                        {t('adherencia')}
                        <span className='adherencia__valor'>{props.adherencia ? props.adherencia+'%' : '-'}</span>
                    </span>
                    <Tooltip title={t('calculoAdherencia')}>
                        <FontAwesomeIcon icon={faExclamationCircle} />
                    </Tooltip>
                </div>
                {props.recetas?.map((rec, i) => {
                    return (
                        <div className='receipt-card'>
                            <div>
                                <span><b>{t('receiptTitle', {nReceta: i + 1})}</b> {statusReceiptRenderer(rec.receta)}</span>
                            </div>
                            <div className='dispensed-dates'>{t('dispensableLabel', {dateInicio: Functions.dateToString(rec.receta.dateInicio), dateFin: Functions.dateToString(rec.receta.dateFin)})}</div>
                            {rec.transacciones && rec.transacciones.length > 0 &&
                                <div className='transacciones'>
                                    <ul>
                                        {rec.transacciones.map(transaccion => {
                                            return <TransaccionInfo transaccion={transaccion} idPrescripcion={rec.receta.idPrescripcion} consultReplace={props.consultReplace}/>
                                        })}
                                    </ul>
                                </div>
                            }
                        </div>
                    )
                })}
                
            </div>
        </>
    )
}

const TransaccionInfo = (props: {transaccion: Transaccion, idPrescripcion: number, consultReplace: (idPrescripcion: number, idTransaccion: number) => void }) => {

    const { t } = useTranslation(['prescriptionReceipt']);

    if (props.transaccion.typeAccion === EnumTransaccionAccion.BLOQUEAR) {
        return (
            <li>
                <span className='blocked'>{t('blockedTrans')}</span> {t('pharmacy')} <b>{props.transaccion.idFarmacia}</b> el <b>{Functions.dateToString(props.transaccion.dateAccion)}</b>
                <div style={{paddingLeft: '1rem'}}>
                    <div><i>{t('reason')}</i> {t('recetaBloqueoType:' + props.transaccion?.typeBloqueo)}</div>
                    <div><i>{t('comment')}</i> {props.transaccion.valueObservaciones}</div>
                </div>
            </li>
        )
    }
    if (props.transaccion.typeAccion === EnumTransaccionAccion.DISPENSAR) {
        return (
            <li>
                {t('dispensed')} <b>{props.transaccion.idFarmacia}</b> el <b>{Functions.dateToString(props.transaccion.dateAccion)}</b>
                <div style={{paddingLeft: '1rem'}}>
                    <div><i>{t('retired')}</i> <b>{props.transaccion.valueDniNieRetirada ? props.transaccion.valueDniNieRetirada : t('notInformed')}</b></div>
                </div>
            </li>
        )
    }
    if (props.transaccion.typeAccion === EnumTransaccionAccion.SUSTITUIR) {

        const dispensation = () =>  {
            if (props.transaccion.valuepresentacion) {
                return props.transaccion.valuepresentacion;
            }
            if (props.transaccion.valuecomposicionfmv) {
                return props.transaccion.valuecomposicionfmv;
            }
            if (props.transaccion.codeCodigoNacional && !props.transaccion.valuepresentacion) {
                return props.transaccion.codeCodigoNacional;
            }
            return (
                <>{t('notInformed')}<Button size='small' type='primary' style={{marginLeft: '1rem'}} onClick={() => props.consultReplace(props.idPrescripcion, props.transaccion.idTransaccion)}>{t('consultReplace')}</Button></>
            )
        }
        return (
            <li>
                {t('replaced1')} <span className='replaced'>{t('replaced2')}</span> {t('pharmacy')} <b>{props.transaccion.idFarmacia}</b> el <b>{Functions.dateToString(props.transaccion.dateAccion)}</b>
                <div style={{paddingLeft: '1rem'}}>
                    <div><i>{t('replaced4')}</i>{dispensation()}</div>
                    <div><i>{t('reason')}</i> {props.transaccion?.typeSustitucion ? t('recetaSustitucionType:' + props.transaccion?.typeSustitucion) : t('notInformed')}</div>
                    <div><i>{t('replaced6')}</i> {props.transaccion.descSustitucion ? props.transaccion.descSustitucion : t('notInformed')}</div>
                    <div><i>{t('retired')}</i> <b>{props.transaccion.valueDniNieRetirada ? props.transaccion.valueDniNieRetirada : t('notInformed')}</b></div>
                </div>
            </li>
        )
    }
    if (props.transaccion.typeAccion === EnumTransaccionAccion.ANULAR || props.transaccion.typeAccion === EnumTransaccionAccion.ANULAR_FM) {
        return (
            <li>
                {t('voided')} <b>{props.transaccion.idFarmacia}</b> el <b>{Functions.dateToString(props.transaccion.dateAccion)}</b>
                <div style={{paddingLeft: '1rem'}}>
                    <div><i>{t('reason')}</i> {props.transaccion?.typeAnulacion ? t('recetaAnulacionType:' + props.transaccion?.typeAnulacion) : t('notInformed')}</div>
                </div>
            </li>
        )
    }
    if (props.transaccion.typeAccion === EnumTransaccionAccion.ELABORAR_FM) {
        return (
            <li>{t('iniElaboration')} <b>{props.transaccion.idFarmacia}</b> el <b>{Functions.dateToString(props.transaccion.dateAccion)}</b></li>
        )
    }

    return <></>
}
export default PrescriptionReceipts;