import { FormInstance } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import FormErrorField from '../../../utils/form/formErrorField';
import { FormUtils } from '../../../utils/utils';
import Dcpf from '../entities/dcpf';
import Medicine from '../entities/medicine';
import OtherDetailsInfo from '../entities/otherDetailsInfo';
import View from './OtherDetails';

interface IProps {
    visible?: boolean;
    medicine?: Medicine;
    dcpf?: Dcpf;
    otherProduct?: any;
    otherDetails?: OtherDetailsInfo;
    setMedicine: (medicine: any) => Medicine;
    productSpecification?: string;
    onClose: (details?: OtherDetailsInfo) => void;
}

export interface IState {
    errors: FormErrorField[];
}

class OtherDetailsContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    public state: IState = {
        errors: [],
    }

    public render() {
        return (
            <View
                {...this.state}
                visible={this.props.visible}
                medicine={this.props.medicine}
                dcpf={this.props.dcpf}
                otherProduct={this.props.otherProduct}
                otherDetails={this.props.otherDetails}
                productSpecification={this.props.productSpecification}
                onCancel={() => this.props.onClose()}
                setMedicine={this.props.setMedicine}
                validateAndSave={this.validateAndSave} />
        )
    }

    private validateAndSave = (form: FormInstance) => {
        form.validateFields().then(values => {
            this.internalValidation(values);
        });
    }

    private internalValidation = (values: Store) => {
        let error = false;
        let errors: FormErrorField[] = [];

        if (values.pin) {
            if (values.pin.length < 4) {
                error = true;
                const pinError = {fieldName: 'pin', 
                errorMessage: this.props.t('pinError')};
                errors = FormUtils.addError(errors, pinError);
            }
        }
        if (values.visadoStart && values.visadoEnd) {
            if (values.visadoEnd.isBefore(values.visadoStart)) {
                error = true;
                const visadoError = {fieldName: 'visadoEnd', 
                errorMessage: this.props.t('visadoError')};
                errors = FormUtils.addError(errors, visadoError);
            }
        }
        this.setState({errors}, () => {
            if (!error) {
                this.saveOtherDetails(values);
            }
        });
        
    }

    private saveOtherDetails = (values: Store) => {
        const details = {
            pin: values.pin,
            isVisado: values.isVisado,
            visadoStart: values.visadoStart,
            visadoEnd: values.visadoEnd,
            visadoInput: values.visadoInput,
        };
        
        this.props.onClose(details);
    }
}

export default withTranslation('medicineConfig')(withRouter(OtherDetailsContainer));