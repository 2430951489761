import React from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Rest, UserInfo } from '../utils/utils';
import View from './Index';
import FormularioFormContainer from '../prescriptions/formulario/FormularioFormContainer';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import LoginModalContainer from './LoginModalContainer';
import FormularioUsuarioPruebaContainer from './FormularioUsuarioPruebaContainer';
import VidalVademecumModal from './VidalVademecumModal';
import ResponseGetEnvConfig from './entities/responseGetEnvConfig';
import AltaPrescriptorFormContainer from './AltaPrescriptorFormContainer';

export interface IState {
    loaded: boolean;
    showForm?: boolean;
    privacyPolicyVisible?: boolean;
    comesFromContact?:boolean;
    loginVisible?: boolean;
    loginKey?: number;
    formTestUserVisible?: boolean;
    formTestUserKey?: number;
    vidalVademecumVisible?: boolean;
    vidalVademecumKey?: number;
    botonTestVisible?: boolean;
    altaPrescriptorFormVisible?: boolean;
    altaPrescriptorFormKey?: number;
}

class IndexContainer extends React.Component<WithTranslation & RouteComponentProps, IState> {

    public componentDidMount() {
        if (UserInfo.isPresent()) {
            this.props.history.push('/app');
        }
        this.onLoad();
    }

    public state: IState = {
        loginVisible:false,
        loaded:false
    };

    public render() {
        return (
            <>
            <View
                {...this.state}
                openContactForm={this.openContactForm}
                onClickPrivacyPolicy={this.onClickPrivacyPolicy}
                onClickLogin={this.onClickLogin}
                onClickTestUser= {this.onClickTestUser}
                onClickVidalVademecum = {this.onClickVidalVademecum}
                onClickAltaPrescriptor = {this.onClickAltaPrescriptor}
            />
            
            <LoginModalContainer
                visible={this.state.loginVisible}
                onClose={this.onCloseLogin}
            />

            <FormularioUsuarioPruebaContainer 
                key={this.state.formTestUserKey}
                showForm={this.state.formTestUserVisible}
                closeForm={this.onCloseFormTestUser}
                onClickPrivacyPolicy={this.onClickPrivacyPolicy}
            />

            <FormularioFormContainer
                showForm={this.state.showForm}
                closeForm={this.closeContactForm}
                onClickPrivacyPolicy={this.onClickPrivacyPolicy}
            />

            <PrivacyPolicyModal
                visible={this.state.privacyPolicyVisible}
                onClose={this.onClosePrivacyPolicy}
            />

            <VidalVademecumModal
                key={this.state.vidalVademecumKey}
                visible={this.state.vidalVademecumVisible}
                onClose={this.onCloseVidalVademecum}
            />

            <AltaPrescriptorFormContainer
                key={this.state.altaPrescriptorFormKey}
                showForm={this.state.altaPrescriptorFormVisible}
                closeForm={this.onCloseAltaPrescriptor}
                onClickPrivacyPolicy={this.onClickPrivacyPolicy}
            />
        </>

        );
    }

    private onLoad = () => {
        Rest<{type: string}, ResponseGetEnvConfig>().operation({
            type: 'GetEnvConfig',
        }).then(response => {
            this.setState({botonTestVisible: response.test, loaded: true});
        });
    }

    private onClickLogin = () => {
        this.setState({loginVisible: true, loginKey: new Date().getTime()});
    }

    private onCloseLogin = ()=> {
        this.setState({loginVisible: false});
    }

    private onClickTestUser = () => {
        this.setState({formTestUserVisible: true, formTestUserKey: new Date().getTime()});
    }

    private onCloseFormTestUser = () => {
        this.setState({formTestUserVisible:false})
    }

    private openContactForm = () => {
        this.setState({showForm: true});
    }

    private closeContactForm = () => {
        this.setState({showForm: false});
    }

    private onClickPrivacyPolicy = (fromContact: boolean) => {
        this.setState({comesFromContact: fromContact, showForm: false, privacyPolicyVisible: true})
    }

    private onClosePrivacyPolicy = () => {
        if(this.state.comesFromContact){
            this.setState({comesFromContact: false, privacyPolicyVisible: false, showForm: true})
        }else{
            this.setState({privacyPolicyVisible: false})
        }
    }

    private onClickVidalVademecum = () => {
        this.setState({vidalVademecumVisible: true, vidalVademecumKey: new Date().getTime()});
    }

    private onCloseVidalVademecum = () => {
        this.setState({vidalVademecumVisible:false})
    }

    private onClickAltaPrescriptor = () => {
        this.setState({altaPrescriptorFormVisible:true, altaPrescriptorFormKey: new Date().getTime()});
    }

    private onCloseAltaPrescriptor = () => {
        this.setState({altaPrescriptorFormVisible:false})
    }

}

export default withTranslation('login')(withRouter(IndexContainer));