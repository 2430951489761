import React from 'react';

import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Rest, FormUtils} from '../../utils/utils';
import {FormInstance, Store} from 'rc-field-form/lib/interface';
import FormErrorField from '../../utils/form/formErrorField';
import View from './tutorForm';
import TutorData from './entities/tutorData';
import ValidatePatientData from './entities/validatePatientData';

interface IProps {
    visible?: boolean;
    currentTutor?: TutorData;
    onClose: () => void;
    setTutor: (tutor: TutorData) => void;
    tutorFromExistingPerson?: boolean;
    newTutorFromPerson?: TutorData;
}

export interface IState {
    errorFields: FormErrorField[];
}

class TutorFormContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    public state: IState = {
        errorFields: []
    };

    public render() {
        return (
            <View
                {...this.state}
                visible={this.props.visible}
                onCancel={() => this.props.onClose()}
                onSave={this.validateAndSave}
                currentTutor={this.props.tutorFromExistingPerson ? this.props.newTutorFromPerson : this.props.currentTutor}
            />
        );
    }

    private validateAndSave = (form: FormInstance) => {
        this.setState({errorFields: []}, () => {
            form.validateFields().then((values) => {
                this.validate(values).then(errors => {
                    if (!errors || errors.length === 0) {
                        this.setTutor(values);
                    } else {
                        this.setState({errorFields: errors});
                    }
                });
            });
        });
    }

    private validate = (values: Store) => {

        return new Promise((resolve: (f: FormErrorField[]) => void) => {
            Rest<{ type: string; codeIdentification: string, typeIdentificacion: number, email: string, dateBirtTutor: Date,
                idPersona?: number }, ValidatePatientData>().operation({
                type: 'ValidateTutor',
                typeIdentificacion: values.typeDocTutor,
                codeIdentification: values.codeIdentificationTutor,
                email: values.emailTutor,
                dateBirtTutor: values.dateBirtTutor,
                idPersona: this.props.newTutorFromPerson?.idPersona || this.props.currentTutor?.idPersona
            }, true).then(response => {
                if (response) {
                    let errors: FormErrorField[] = [];

                    if (response.isMailError) {
                        const emailError = {
                            fieldName: 'emailTutor',
                            errorMessage: this.props.t('formatoIncorrectoError')
                        };
                        errors = FormUtils.addError(this.state.errorFields, emailError);
                    }
                    if (response.isMayorEdad) {
                        const dateBirtError = {
                            fieldName: 'dateBirtTutor',
                            errorMessage: this.props.t('mayorEdadError')
                        };
                        errors = FormUtils.addError(this.state.errorFields, dateBirtError);
                    }

                    if (response.docIdentidadError) {
                        const dniError = {
                            fieldName: 'codeIdentificationTutor',
                            errorMessage: this.props.t('formatoIncorrectoError')
                        };
                        errors = FormUtils.addError(this.state.errorFields, dniError);
                    }

                    if (response.isDniExistente) {
                        const dniError = {
                            fieldName: 'codeIdentificationTutor',
                            errorMessage: this.props.t('tutorExistente')
                        };
                        errors = FormUtils.addError(this.state.errorFields, dniError);
                    }

                    resolve(errors);

                } else {
                    resolve([]);
                }
            });
        });
    }

    private setTutor = (values: Store) => {
        const tutor = {
            nameNombre: values.nameTutor,
            nameApellido1: values.tutorSurname1,
            nameApellido2: values.tutorSurname2 ? values.tutorSurname2 : undefined,
            dateNacimiento: values.dateBirtTutor,
            typeSexo: values.genderTutor,
            typeIdentificacion: values.typeDocTutor,
            codeIdentificacion: values.codeIdentificationTutor,
            valueTelefono: values.phoneTutor,
            valueEmail: values.emailTutor,
            idTutor: this.props.tutorFromExistingPerson ? this.props.newTutorFromPerson?.idTutor : this.props.currentTutor?.idTutor,
            idPersona: this.props.tutorFromExistingPerson ? this.props.newTutorFromPerson?.idPersona : this.props.currentTutor?.idPersona,
            idDatosContacto: this.props.tutorFromExistingPerson ? this.props.newTutorFromPerson?.idDatosContacto : this.props.currentTutor?.idDatosContacto
        } as TutorData;

        this.props.setTutor(tutor);
    }
}

export default withTranslation('tutorForm')(withRouter(TutorFormContainer));