import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Result, Button, Checkbox, Form, Row, Col, Input, FormInstance } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import PrestadorConfigContext from '../../context/PrestadorConfigContext';

export interface IState {
    downloadFlag?: boolean;
    emailFlag?: boolean;
    form: FormInstance;
}

const SignSuccessModal  = (props: { visible?: boolean,
    emailPatient?: string,
    codeHip?: string,
    codeHipConfidencial?: string,
    onClose: () => void,
    hipDownload: () => void,
    sendEmailHip: (email: string) => void;}) => {
        const {t} = useTranslation(['signPrescripction']);
        const [emailFlag, setEmailFlag] = useState(true);
        const [downloadFlag, setDonwloadFlag] = useState(false);
        const [form] = Form.useForm();
        
        const ctxValue = useContext(PrestadorConfigContext);

        const sendEmailAndHipDownload = (email: string) => {
            props.hipDownload();
            props.sendEmailHip(email);
        }
        const doSetEmailFlag = () => {
            setEmailFlag(!emailFlag);
        }
    
        const doSetDonwloadFlag = () => {
            setDonwloadFlag(!downloadFlag);
        }

        if(ctxValue.data.hideHipEmailSending!=true){    
            return (
                
                <Modal visible={props.visible} footer={null} closable={false} width={490}>
                    <Result
                        status='success'
                        title={t('signSuccessSubtitle')}
                        subTitle={
                            <div style={{display:"flex", flexDirection:"column",alignItems: "start", marginLeft: "1rem"}} >
                                <span style={{marginTop: '1rem',color:'black'}}>{t('elegirViaHip')}</span>
                                <Checkbox checked={emailFlag} style={{marginTop: '1rem'}} onChange={() => doSetEmailFlag()}>{t("emailHipSendText")}</Checkbox>
                                {emailFlag && <Form style={{marginTop: '1rem', marginLeft:'1rem'}} layout='vertical' form={form} size='large' initialValues={{email: props.emailPatient}} > 
                                    <Form.Item name='email' rules={[{required: true, type: 'email', message: t('incorrectEmail')}]}>
                                        <Input style={{width:"20rem"}} disabled={!emailFlag}/>
                                    </Form.Item>              
                                </Form>}
                                <Checkbox value={downloadFlag} style={{marginTop: '1rem',marginLeft: "0"}} onChange={() => doSetDonwloadFlag()}>{t("downloadHipSendText")}</Checkbox>
                            </div>}
                        extra={
                            <>
                                {
                                <Button type='primary' 
                                    onClick={() => (downloadFlag==true && emailFlag==false) ? props.hipDownload(): 
                                                    (downloadFlag==false && emailFlag==true) ? form.validateFields().then(values => props.sendEmailHip(values.email)) :
                                                    (downloadFlag==false && emailFlag == false) ? props.onClose() :
                                                    form.validateFields().then(values => sendEmailAndHipDownload(values.email))
                                    }
                                >
                                    {(downloadFlag && emailFlag) ? 
                                        t('downloadAndEmailHip') :
                                        (downloadFlag==false && emailFlag== false) ?
                                        t('buttons:close') :
                                        (downloadFlag==false && emailFlag== true) ? 
                                            t('emailHip') : t('downloadHip')
                                        
                                    }
                                </Button>}
                            </>
                        }
                    />
                </Modal>
            )
        }
        else{
            return (
                <Modal visible={props.visible} footer={null} onCancel={() => props.onClose()} width={546}>
                    <Result
                        status='success'
                        title={t('signSuccessSubtitle')}
                        subTitle={
                            <>
                                <span style={{marginTop: '1rem'}} dangerouslySetInnerHTML={{__html: 
                                    props.emailPatient? t('signSuccessInfo', {emailPatient: props.emailPatient}) + '.':'' }}/> {t('hipDowload')}
                            </>}
                        extra={
                            <>
                                
                                <Button type='primary' onClick={() => props.hipDownload()}>
                                    {t('hipDowloadButton')}
                                </Button>
                                {props.codeHip && props.codeHipConfidencial && 
                                    <Button type='primary' onClick={() => props.hipDownload()}>
                                        {t('hipConfidencialDowloadButton')}
                                    </Button>}
                                <Button type='default' onClick={() => props.onClose()}>
                                    {t('buttons:close')}
                                </Button>
                            </>
                        }
                    />
                </Modal>

            )

        }
        

  
}

export default SignSuccessModal;