import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Row, Tooltip } from 'antd';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Functions, getPrescriptionTypeIcon } from '../../utils/utils';
import PrescriptionReceipts from '../prescriptionReceipts';
import PrescripcionPosologyInfo from '../entities/prescripcionPosologyInfo';
import { faCheckCircle, faExclamationCircle, faKey, faStamp } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import EnumPrescriptionStatus from '../../enum/EnumPrescriptionStatus';

interface IProps {
    reactivatePrescription: (idPrescription: number) => void;
    voidPrescription: (idPrescription: number) => void;
    consultReplace: (idPrescripcion: number, idTransaccion: number) => void;
    pres: PrescripcionPosologyInfo;
}

class DetallePrescripcionEmitida extends React.Component<IProps & WithTranslation, {}> {

    render(): React.ReactNode {

        const pres = this.props.pres;

        return <div className='centered-frame'><div className='new_prescription_container'><div className='data_container'>
            <Row align='middle'>
                <FontAwesomeIcon icon={faCheckCircle} className='number' />
                <span className='text_title'>
                    {this.props.t('prescriptionPosologyTitle')}
                </span>
                {pres.codeConfidencial &&
                    <Tooltip title={pres.codeConfidencial}>
                        <Button className='button__details' type="primary" icon={<FontAwesomeIcon icon={faKey} style={{ color: '#ffffff', fontSize: '0.8rem' }} />} />
                    </Tooltip>
                }

                {!!pres.flagVisado &&
                    <Tooltip title={() => this.visadoTooltip(pres)} >
                        <Button className={!pres.codeConfidencial ? 'button__details' : 'button__prescription'} type="primary" icon={<FontAwesomeIcon icon={faStamp} style={{ color: '#ffffff', fontSize: '0.8rem' }} />} />
                    </Tooltip>
                }

            </Row>
            <div className='medication-card__container'>
                <div className='medication-card-info'>
                    {pres.valueImagen ?
                        <div className='medication-photo'>
                            <div className='loading-img'>
                                <img alt='' src={pres.valueImagen} onLoad={e => {
                                    e.currentTarget.parentElement!.classList.remove('loading-img')
                                }} />
                            </div>
                        </div> :
                        <div className='medication-photo-placeholder'>
                            <FontAwesomeIcon icon={getPrescriptionTypeIcon(pres.typeProducto)} />
                        </div>
                    }
                    <div className='medication-info'>
                        <div className='medication-type--container'>
                            <span className='medication-type'>
                                <FontAwesomeIcon icon={getPrescriptionTypeIcon(pres.typeProducto)} style={{ marginRight: '6px' }} /> {this.props.t('prescriptionType:' + pres.typeProducto).toUpperCase()}
                            </span>
                            {(pres.codeDcpf || pres.codeCodigoNacional || pres.codeCodigoNacionalPres) && <span className='medication-type'>
                                {pres.codeDcpf ? this.props.t('genericoDcpf') : this.props.t('nationalCode')}
                                <span className='code-medication'>{pres.codeDcpf ? pres.codeDcpf : pres.codeCodigoNacional ? pres.codeCodigoNacional : pres.codeCodigoNacionalPres}</span>
                            </span>}
                            <span className='medication-type'>
                                {this.props.t('prescription')}
                                <span className='code-medication'>{pres.idPrescripcion}</span>
                            </span>
                            {this.getPrescriptionStatus(pres.typeEstado, pres.dateEmision, pres.dateAnulacion)}
                        </div>
                        <div className='medication-name'>
                            {pres.valueComposicionFmv ?
                                <b>{pres.valueComposicionFmv}</b>
                                :
                                <><b>{pres.nameDcpf ? pres.nameDcpf : pres.valuePresentacion}</b></>
                            }
                        </div>
                        <div>
                            {pres.typePosologia != 1 && <><b style={{ paddingRight: '1rem' }}>{this.props.t('medicineConfig:posologyTitle')}</b> <b>{pres.valueDosis} {pres.valueUnidadContenido}</b> {this.props.t('medicineConfig:posologyFrequencyPres')} <b>{pres.valueFrecuencia} {this.props.t('prescriptionFrequencyType:' + pres.typeFrecuencia)}</b> {this.props.t('medicineConfig:posologyFrequencyDuring')}
                                <b style={{ paddingLeft: '4px' }}>{pres.valueDuracion} {this.props.t('medicineConfig:durationSuffix')}</b>, {this.props.t('medicineConfig:posologyFrequencyBegining')} <b>{Functions.dateToString(pres.dateInicioTratamiento)}</b></>}
                            {pres.typePosologia == 1 && <div style= {{display:'flex'}}> <b style={{ paddingRight: '1rem' }}>{this.props.t('medicineConfig:posologyTitle')}</b> <div  dangerouslySetInnerHTML={{__html: this.props.t('medicineConfig:withoutPosology',{during: pres.valueDuracion, date: Functions.dateToString(pres.dateInicioTratamiento)})}}></div></div>}
                        </div>
                        {pres.descIndicacionesPaciente && <div><b style={{ paddingRight: '1rem' }}>{this.props.t('medicineConfig:posologySelectedPatientIndications')}</b> {pres.descIndicacionesPaciente}</div>}
                        {pres.descIndicacionesFarmaceutico && <div><b style={{ paddingRight: '1rem' }}>{this.props.t('medicineConfig:posologySelectedPharmacyIndications')}</b> {pres.descIndicacionesFarmaceutico}</div>}
                    </div>
                </div>
            </div>
            {pres.recetas &&
                <PrescriptionReceipts
                    recetas={pres.recetas}
                    idPrescription={pres.idPrescripcion}
                    prescripcionEstado={pres.typeEstado}
                    reactivatePrescription={this.props.reactivatePrescription}
                    voidPrescription={this.props.voidPrescription}
                    consultReplace={this.props.consultReplace} />
            }
        </div></div></div>;
    }

    visadoTooltip = (pres: PrescripcionPosologyInfo) => {
        return (
            <div>
                <div>{pres.pctRegAportacion && <>{this.props.t('medicineConfig:aportacionVisado')} <b>{pres.pctRegAportacion}%</b></>}</div>
                <div>{pres.dateIniVisado && <>{this.props.t('medicineConfig:desdeVisado')} <b>{Functions.dateToString(pres.dateIniVisado)}</b></>} {pres.dateFinVisado && <>{this.props.t('medicineConfig:hastaVisado')} <b>{Functions.dateToString(pres.dateFinVisado)}</b></>}</div>
            </div>
        )
    }



    private getPrescriptionStatus = (type: number, dateEmision?: Date, dateAnulacion?: Date) => {

        let className = '';
        let title = '';
        let icon = faCheckCircle;
        let tooltip = '';

        if (type === EnumPrescriptionStatus.PTE_FIRMA) {
            className = 'pending';
            title = this.props.t('pendingPrescriptionLabel');
            icon = faExclamationCircle;
        }
        if (type === EnumPrescriptionStatus.EMITIDA) {
            className = 'emitted';
            title = this.props.t('emittedPrescriptionLabel');
            icon = faCheckCircle;
            tooltip = this.props.t('emittedTooltip', {fecha: Functions.dateToString(dateEmision)});
        }
        if (type === EnumPrescriptionStatus.ANULADA) {
            className = 'cancelled';
            title = this.props.t('cancelledPrescriptionLabel');
            icon = faTimesCircle;
            tooltip = this.props.t('cancelledTooltip', {fecha: Functions.dateToString(dateAnulacion)});
        }

        return (
            <Tooltip title={tooltip}>
                <span className={'prescription-status ' + className}>   
                    <FontAwesomeIcon icon={icon} style={{marginRight: '6px'}}/> {title}
                </span>
            </Tooltip>

        );
    }
}

export default withTranslation('prescription')(DetallePrescripcionEmitida);