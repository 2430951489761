import React from 'react';

import { Button, Input, Pagination, Space, Table} from 'antd';
import { useTranslation } from 'react-i18next';
import { AlignType } from 'rc-table/lib/interface';
import { IState as IStateContainer } from './patologiesSelectContainer';
import Highlighter from 'react-highlight-words';
import { PatologiaData } from './entities/patologiaData';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { removeAccents } from '../../utils/utils';

const SelectPatology = (props: {
    visible?: boolean;
    onCancel: () => void;
    searchPatology: (value?: string) => void;
    fromDiagnostico?: boolean;
    onSearchPatology: (value?: string) => void;
    onChangePage: (page: number) => void;
    nameTranslation: (record: PatologiaData) => string;
    onSelect: (codeCie10: string, nameCie10Es: string, nameCie10En:string, idCie10: number) => void;
} & IStateContainer) => {

    const { t } = useTranslation(['newPatientPrescription']);
    const { Search } = Input;
  
    const nameRenderer = (value: any, record: PatologiaData) => {

        if (props.search) {
            const searchValues = props.search.includes(' ') ? props.search.split(' ') : [props.search];

            return props.search ?
                <Highlighter
                    highlightStyle={{ backgroundColor: '#f8d19b', padding: 0 }}
                    searchWords={searchValues}
                    autoEscape
                    textToHighlight={props.nameTranslation(record)!}
                    sanitize={removeAccents}
                />
                : props.nameTranslation(record) 
        }
        return props.nameTranslation(record);
        
    }
    
    const actionsRenderer = (value: any, record: PatologiaData) => {
        return (
            <Space size='small'>
                <Button size='small' type='default' icon={<FontAwesomeIcon icon={faCheck} style={{color: "#0091FF", fontSize: '0.8rem' }}/>} onClick={() => props.onSelect(record.codeCie10!, record.nameCie10Es!, record.nameCie10En!,record.idCie10!)}/>
            </Space>
        )
    }
    
    const columns = [
        {title: t('idColumn'), width: "1rem", dataIndex: 'codeCie10', align: 'left' as AlignType, render: (value: any, record: PatologiaData) => {return record.codeCie10}},
        {title: t('patologyColumn'), dataIndex: 'name', align: 'left' as AlignType, render: nameRenderer},
        {align: 'right' as AlignType, render: actionsRenderer}
    ]

    return (<div className='alergiesSearch'> 
            <div className='alergiesSearchTitle'>{!props.fromDiagnostico ? t('addPatology'): t('addDiagnostico')}</div>
            <Search size='large' enterButton onSearch={props.onSearchPatology} onChange={(e: any) => props.searchPatology(e.currentTarget.value)} 
                placeholder={t('searchPlaceholder')} autoFocus={true}/>

            {props.patologieList ?
                <div className='table-container' style={{marginTop: '1rem'}}>
                    
                    <Table
                        columns={columns} 
                        size='small'
                        dataSource={props.patologieList}
                        rowKey='idCie10'
                        pagination={false}/>

                    {<Pagination
                        className='table-pagination-patology'
                        size='small'
                        current={props.page}
                        pageSize={props.limit}
                        total={props.dataCount}
                        showSizeChanger={false}
                        hideOnSinglePage={true}
                        onChange={props.onChangePage} />}
                </div>: <div></div>}
                </div>)

}
export default SelectPatology;