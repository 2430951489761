enum PrescripcionEstado {
    PENDIENTE = 1,
    BLOQUEADA = 2,
    DISPENSADA = 3,
    DISPENSADA_SUSTITUCION = 4,
    CADUCADA = 5,
    DISPENSADA_PARCIALMENTE = 8,
    FORMULA_MAGISTRAL = 9
}

export default {
    getEstadosRecetas (estado: PrescripcionEstado) {
        switch (estado) {
            case PrescripcionEstado.PENDIENTE:
                return [1];
            case PrescripcionEstado.BLOQUEADA:
                return [2];
            case PrescripcionEstado.DISPENSADA:
                return [3];
            case PrescripcionEstado.DISPENSADA_SUSTITUCION:
                return [4, 10];
            case PrescripcionEstado.CADUCADA:
                return [5];
            case PrescripcionEstado.DISPENSADA_PARCIALMENTE:
                return [8];
            case PrescripcionEstado.FORMULA_MAGISTRAL:
                return [9];
        }
    }
}