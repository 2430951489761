import React from 'react';

import { useTranslation } from 'react-i18next';
import { Functions } from '../utils/utils';
import { Button, Row, Col, Input, Form } from 'antd';
import LOGOERECETA from '../images/logoEReceta.png';
import PrestadorRegistroData from './entities/prestadorRegistroData';
import cx from 'classnames';

interface IProps {
    prestadorRegistroData?: PrestadorRegistroData;
    continueSecondStep: () => void;
}

const WelcomeStepOneView = (props: IProps) => {

    const { t } = useTranslation(['welcome']);

    const colegiadoTipoPrefix= (
        props.prestadorRegistroData && props.prestadorRegistroData.colegiadoTipo === 1 ? 'Nº' : 'TIM'  
    );

    const typeIdentificationPrefix = (
        props.prestadorRegistroData && props.prestadorRegistroData.typeIdentificacion ? t('codeIdentificationType:' + props.prestadorRegistroData.typeIdentificacion) : ''  
    );
    
    return (
        <div className='enlace_token_container'>
            <div className='enlace_token_card_container'>
                <div className='enlace_token_card' style={{width: '720px'}}>
                    <div className='enlace_token_logo'><img src={LOGOERECETA}  alt=''/></div>
                    <div className='enlace_token_title'>{t('stepOneTitle', {fromTo: props.prestadorRegistroData?.idLocalizacion? "1/2": "1/3"})}</div>
                    <div className={cx('enlace_token_prestador', {'welcome': true})}>
                        <p dangerouslySetInnerHTML={{__html: 
                            t('stepOneLabel') }}/>
                    </div>

                    <Form layout='vertical' size='large' className='form-alta-prescriptor'>
                        <Row gutter={12}>
                            <Col span={9}>
                                <Form.Item label={t('nameLabel')} name='nameNombre'>
                                    <Input defaultValue={props.prestadorRegistroData && props.prestadorRegistroData.nameNombre} disabled />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={t('surnameLabel')} name='nameApellido1'>
                                    <Input defaultValue={props.prestadorRegistroData && props.prestadorRegistroData.nameApellido1} disabled />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item label={t('secondSurnameLabel')} name='nameApellido2'>
                                    <Input defaultValue={props.prestadorRegistroData && props.prestadorRegistroData.nameApellido2} disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={15}>
                                <Form.Item label={t('colegio')} name='nameColegio'>
                                    <Input defaultValue={props.prestadorRegistroData && props.prestadorRegistroData.nombreColegio} disabled />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item label={t('specialtyLabel')} name='descEspecialidad'>
                                    <Input defaultValue={props.prestadorRegistroData && props.prestadorRegistroData.descEspecialidad} disabled />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={12}>
                            <Col span={9}>
                                <Form.Item label={t('codeIdentificationLabel')} name='codeIdentificacion'>
                                    <Input addonBefore={typeIdentificationPrefix}
                                        defaultValue={props.prestadorRegistroData && props.prestadorRegistroData.codeIdentificacion} disabled />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={t('birthDateLabel')} name='dateNacimiento'>
                                    <Input defaultValue={props.prestadorRegistroData && props.prestadorRegistroData.dateNacimiento 
                                        && Functions.dateToString(props.prestadorRegistroData.dateNacimiento)} disabled />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item label={t('nColegiadoLabel')} name='numeroColegiado'>
                                    <Input addonBefore={colegiadoTipoPrefix}
                                        defaultValue={props.prestadorRegistroData && props.prestadorRegistroData.numeroColegiado} disabled />
                                </Form.Item>
                            </Col>
                        </Row>

                        
                    </Form>

                    <div className='alta-buttons'>
                        <Button type='primary' onClick={() => props.continueSecondStep()} size='middle'>
                            {t('buttons:continue')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomeStepOneView;