import Modal from 'antd/lib/modal/Modal';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import DatosContactoPrestador from './entities/DatosContactoPrestador';

interface IProps {
    prestadorData?: DatosContactoPrestador;
}

const ConsentTerms = (props: IProps) => {

    const [modalState,setModalState] = useState(false);

    return (
        <><Terms setModalState={setModalState} namePrestator={props.prestadorData?.nameNombre} lastNamePrestator1={props.prestadorData?.nameApellido1} lastNamePrestator2={props.prestadorData?.nameApellido2} />
        <MoreInfo modalState={modalState}  setModalState={setModalState} prestadorData={props.prestadorData}/>
        </>
    )
}

const Terms = (props: {setModalState: any; namePrestator: any; lastNamePrestator1: any; lastNamePrestator2: any;}) => {

    const { t } = useTranslation(['terms']);

    return (
    <div className='enlace_token_terms'>
        <ul>
        <li><span><b>{t('term1title')}</b><br></br></span>
        <span>{t('term1value',{prestator: props.namePrestator, lastNamePrestator1: props.lastNamePrestator1, lastNamePrestator2: props.lastNamePrestator2})}<b><a onClick={() => {props.setModalState(true)}}> + Info</a></b><br></br></span></li>
        <li><span><b>{t('term2title')}</b><br></br></span>
        <span>{t('term2value')}<br></br></span></li>
        <li><span><b>{t('term3title')}</b><br></br></span>
        <span>{t('term3value')}<br></br></span></li>
        <li><span><b>{t('term4title')}</b><br></br></span>
        <span>{t('term4value')}<b><a onClick={() => {props.setModalState(true)}}> + Info</a></b><br></br></span></li>
        <li><span><b>{t('term5title')}</b><br></br></span>
        <span>{t('term5value')}<br></br></span></li>
        <li><span><b>{t('term6title')}</b><br></br></span>
        <span>{t('term6value')}<br></br></span></li>
        <li><span><b>{t('term7title')}</b><br></br></span>
        <span>{t('term7value')}<b><a onClick={() => {props.setModalState(true)}}> + Info</a></b><br></br></span></li>
        <li><span><b>{t('term8title')}</b><br></br></span>
        <span>{t('term8value')}<br></br></span></li>
        <li><span><b>{t('term9title')}</b><b><a onClick={() => {props.setModalState(true)}}> + Info</a></b></span></li>
        </ul>
    </div>
    )
}


const MoreInfo  = (props: {modalState: any;  setModalState: any; prestadorData: any;}) =>{

    return (
        <Modal
            style={{ top: 40 }}
            visible={props.modalState}
            onCancel={() => props.setModalState(false)}
            bodyStyle={{ paddingTop: '2rem', paddingBottom: '1.5rem' }}
            width={1000}
            footer={null}
            closable={true}
            destroyOnClose
        >
            <ModalRender prestadorData={props.prestadorData}/>
        </Modal>
    )
}

const ModalRender = (props: {prestadorData: DatosContactoPrestador;}) => {
    const { t } = useTranslation(['terms']);
    return (
        <div className="capa2">
            <h1 className="tituloCapa2">{t('capa2text1')}</h1>
            <span className="textoCapa2">{t('capa2text2', {nombre: props.prestadorData?.nameNombre, apellido1: props.prestadorData?.nameApellido1, apellido2: props.prestadorData?.nameApellido2})}</span>
            <h2 className="tituloCapa2"><br></br>{t('capa2text3')}<br></br></h2>
            <span className="textoCapa2">{props.prestadorData?.nameNombre} {props.prestadorData?.nameApellido1} {props.prestadorData?.nameApellido2}<br></br></span>
            <span className="textoCapa2">{props.prestadorData.valueDireccion}<br></br></span>
            <span className="textoCapa2">{t('capa2text4')}{props.prestadorData.valueTelefono}<br></br>{t('capa2text5')}{props.prestadorData.valueEmail}<br></br></span>
            <h2 className="tituloCapa2"><br></br>{t('capa2text6')}</h2>
            <span className="textoCapa2">{t('capa2text7')}<br></br><br></br></span>
            <table className="tablaCapa2">
                <tr>
                    <th>{t('capa2text8')}</th>
                    <th>{t('capa2text9')}</th>
                </tr>
                <tr>
                    <td>{t('capa2text10')}</td>
                    <td>{t('capa2text11')}</td>
                </tr>
            </table><br></br>
            <span className="textoCapa2">{t('capa2text12')}</span>
            <h2 className="tituloCapa2"><br></br>{t('capa2text13')}</h2>
            <span className="textoCapa2">{t('capa2text14')}</span>
            <h2 className="tituloCapa2"><br></br>{t('capa2text15')}</h2>
            <h3 className="tituloCapa2">{t('capa2text16')}</h3>
            <h3 className="tituloCapa2">{t('capa2text17')}</h3>
            <ul className="textoCapa2">
                <li>{t('capa2text18')}</li>
                <li>{t('capa2text19')}</li>
                <li>{t('capa2text20')}</li>
                <li>{t('capa2text21')}</li>
                <li>{t('capa2text22')}</li>
                <li>{t('capa2text23')}</li>
                <li>{t('capa2text24')}</li>
            </ul>
            <span className="textoCapa2">{t('capa2text25')}<br></br></span>
            <span className="textoCapa2">{props.prestadorData?.nameNombre} {props.prestadorData?.nameApellido1} {props.prestadorData?.nameApellido2}<br></br></span>
            <span className="textoCapa2">{props.prestadorData.valueDireccion} {props.prestadorData.valueDireccion2}<br></br></span>
            <span className="textoCapa2">{t('capa2text4')}{props.prestadorData.valueTelefono}<br></br>{t('capa2text5')}{props.prestadorData.valueEmail}<br></br></span>
            <h2 className="tituloCapa2"><br></br>{t('capa2text26')}</h2>
            <span className="textoCapa2">{t('capa2text27')}</span>
            <h2 className="tituloCapa2"><br></br>{t('capa2text28')}</h2>
            <span className="textoCapa2">{t('capa2text29')}</span>
            <h2 className="tituloCapa2"><br></br>{t('capa2text30')}</h2>
            <span className="textoCapa2">{t('capa2text31')}</span>
            <h2 className="tituloCapa2"><br></br>{t('capa2text32')}</h2>
            <span className="textoCapa2">{t('capa2text33')}</span>
        </div>
    )
}

export default ConsentTerms;