import React from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Input, Form, Select } from 'antd';
import { Option } from 'rc-select';
import { FormUtils } from '../utils/utils';
import { FormInstance } from 'antd/lib/form';
import LOGOERECETA from '../images/logoEReceta.png';
import Provincia from '../prescriptions/locations/entities/provincia';
import Municipio from '../prescriptions/locations/entities/municipio';
import FormErrorField from '../utils/form/formErrorField';
import cx from 'classnames';

interface IProps {
    codeProvincia?: string;
    provinciaList?: Provincia[];
    municipioList?: Municipio[];
    errorFields: FormErrorField[];
    onChangeCP: (form: FormInstance, e: React.ChangeEvent<HTMLInputElement>) => void;
    continueThirdStep: (form: FormInstance) => void;
    completeLater: () => void;
}

const WelcomeStepTwoView = (props: IProps) => {

    const { t } = useTranslation(['welcome']);
    const [form] = Form.useForm();

    const provinceList = (props.provinciaList || []).map(loc => {
        return(
            <Option key={loc.codeProvincia} value={loc.codeProvincia}>{loc.nameProvincia}</Option>
        );
    });

    return (
        <div className='enlace_token_container'>
            <div className='enlace_token_card_container'>
                <div className='enlace_token_card' style={{width: '720px'}}>
                    <div className='enlace_token_logo'><img src={LOGOERECETA}  alt=''/></div>
                    <div className='enlace_token_title'>{t('stepTwoTitle')}</div>
                    <div className={cx('enlace_token_prestador', {'welcome': true})}>{t('locationLabel')}</div>

                    <Form layout='vertical' form={form} size='large' className='form_step2'> 
                        <Row gutter={8}>
                            <Col span={16}>
                                <Form.Item label={t('nameLocationLabel')} name='nameLocation' rules={[{ required: true }]}>
                                    <Input maxLength={256} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={16}>
                                <Form.Item label={t('addressLabel')} name='address' rules={[{ required: true }]}>
                                    <Input maxLength={256} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={t('addressDetailLabel')} name='addressDetail'>
                                    <Input maxLength={256} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={8}>
                                <Form.Item label={t('cpLabel')} name='codigoPostal' rules={[{ required: true }]}>
                                    <Input maxLength={5} onChange={(e) => props.onChangeCP(form, e)}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={t('provinciaLabel')} name='codeProvincia' rules={[{ required: true }]}>
                                    <Select disabled
                                        filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}>
                                        {provinceList}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={t('townLabel')} name='codeMunicipio' rules={[{ required: true }]}>
                                    <Select showSearch notFoundContent={true} allowClear dropdownStyle={{minWidth: '20rem'}} disabled={!props.codeProvincia}
                                        filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}>
                                        {(props.municipioList || []).map(municipio => 
                                            <Option key={municipio.codeMunicipio} value={municipio.codeMunicipio} label={municipio.nameMunicipio}>
                                                {municipio.nameMunicipio}
                                            </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={16}>
                                <Form.Item label={t('emailLabel')} name='valueEmail' rules={[{ required: true }]} validateStatus={FormUtils.isError('valueEmail', props.errorFields) ? 'error' : undefined}
                                        help={FormUtils.getErrorMessage('valueEmail', props.errorFields)}>
                                    <Input maxLength={256} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={t('telefonoLabel')} name='valueTelefono' rules={[{ required: true }]}>
                                    <Input maxLength={256} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                    <div className='alta-buttons'>
                        <Button type='primary' onClick={() => props.continueThirdStep(form)} size='middle'>
                            {t('buttons:continue')}
                        </Button>
                        <Button onClick={() => props.completeLater()} size='middle' style={{marginLeft: '1rem'}}>
                            {t('completeLaterbutton')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomeStepTwoView;