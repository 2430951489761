export default {
    dateToString(date?: Date) {
        return date && date.toLocaleDateString();
    },

    formatPorcentage(value: number) {
        return typeof value !== 'undefined' && value.toLocaleString(undefined, {minimumFractionDigits: 2}) + ' %';
    },

    calculateAge(dateBirth: Date) {
        const today = new Date();
        const birth = new Date(dateBirth);
        let age = today.getFullYear() - birth.getFullYear();
        const m = today.getMonth() - birth.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
            age--;
        }

        return age;
    },
}