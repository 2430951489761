import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Rest } from '../../utils/utils';
import FormErrorField from '../../utils/form/formErrorField';
import WelcomeStepCompletedView from '../welcomeStepCompletedView';
import RegistroExternoDocumentModal from './registroExternoDocumentModal';
import AltaPrestadorFromExternalData from '../../login/entities/altaPrescriptorFromExternalData';
import { FormInstance, notification } from 'antd';
import SignOtpModalContainer, { OtpCallerProps } from '../../prescriptions/saveSing/signOtpModalContainer';
import SignContractOtpResponse from '../operations/SignContractOtpResponse';
import SignPrescriptionResult from '../../prescriptions/saveSing/entities/signPrescriptionResult';
import AltaPrescriptorPage from '../../login/altaPrescriptorPage';
import '../../login/AltaPrescriptor.scss';
import StoreContactFormData from '../../prescriptions/entities/formulario';

export interface IState extends OtpCallerProps {
    prestadorRegistroData?: AltaPrestadorFromExternalData;
    loaded?: boolean;
    stepOne?: boolean;
    stepCompleted?: boolean;
    errorFields: FormErrorField[];
    documentModalVisible?: boolean;
    errorMessage?: string;
    warningMessage?: string;
    codeResult?: number;
    idPrestador?: number;
    newDataForm?: FormInstance;
}

class RegistroExternoContainer extends React.Component<WithTranslation & RouteComponentProps<{ token: string }>, IState> {

    public token: string = this.props.match.params.token;

    public state: IState = {
        errorFields: [],
    };

    public componentDidMount() {
        this.loadData();
    }

    public render() {
        return (
            this.state.loaded ?
                <>
                    {this.state.stepOne &&
                        <AltaPrescriptorPage
                            downloadDocList={[]}
                            fileList={[]}
                            disablePoblacion={false}
                            closeForm={this.closeForm}
                            fromExternalAccess={true}
                            showForm={true}
                            prestadorRegistroData={this.state.prestadorRegistroData}
                            sendContact={this.sendContact}
                        />
                    }
                    {this.state.documentModalVisible &&
                        <RegistroExternoDocumentModal onClose={this.onCloseDocumentModal}
                            visible={this.state.documentModalVisible}
                            onAcceptDocumentModal={this.onCreateValidateOtp}
                        />
                    }
                    {this.state.signOtpVisible &&
                        <SignOtpModalContainer
                            otpType={this.state.otpType}
                            valueUuId={this.state.valueUuId!}
                            email={this.state.email}
                            dispositivo={this.state.dispositivoMovil}
                            key={this.state.signOTPkey}
                            visible={this.state.signOtpVisible}
                            oncloseSignOTP={this.oncloseSignOTP}
                            tryAgain={this.startSigningProcess}
                            sendByEmail={() => this.onCreateValidateOtp()}
                            onValidationSuccess={this.onValidOtp}
                        />
                    }
                    {this.state.stepCompleted &&
                        <WelcomeStepCompletedView
                            access={this.access}
                        />
                    }
                </>
                :
                <></>
        );
    }

    private loadData = () => {
        Rest<{ type: string, token: string }, AltaPrestadorFromExternalData>().operation({
            type: 'ValidateTokenRegistroPrestador',
            token: this.token,
        }).then(response => {
            if (response.error) {
                this.props.history.push(response.error);
            } else {
                this.setState({
                    prestadorRegistroData: response,
                    idPrestador: response.idPrestador,
                    loaded: true,
                    stepOne: true,
                });
            }
        });
    };

    private closeForm = (form?: FormInstance) => {
        form?.validateFields().then(() => {
            this.setState({ newDataForm: form });
            this.startSigningProcess();
        });

    };

    private onCloseDocumentModal = () => {
        this.setState({ stepOne: true, documentModalVisible: false });
    };

    private access = () => {
        this.props.history.push('/login');
    };

    private onCreateValidateOtp = () => {
        var data: AltaPrestadorFromExternalData = {
            nameNombre: this.state.newDataForm?.getFieldValue('valueNombre'),
            nameApellido1: this.state.newDataForm?.getFieldValue('valuePrimerApellido'),
            nameApellido2: this.state.newDataForm?.getFieldValue('valueSegundoApellido'),
            numeroColegiado: this.state.newDataForm?.getFieldValue('valueColegiado'),
            numeroColegio: this.state.newDataForm?.getFieldValue('valueColegio'),
            codeIdentificacion: this.state.newDataForm?.getFieldValue('valueNif'),
            valueEmail: this.state.newDataForm?.getFieldValue('valueEmail'),
            valueTelefono: this.state.newDataForm?.getFieldValue('valueTelefono'),
            valueDireccion: this.state.newDataForm?.getFieldValue('valueDireccion'),
            valueDetalleDireccion: this.state.newDataForm?.getFieldValue('valueDetalleDireccion'),
            valueCodigoPostal: this.state.newDataForm?.getFieldValue('valueCodigoPostal'),
            codeProvincia: this.state.newDataForm?.getFieldValue('valueProvincia'),
            codeMunicipio: this.state.newDataForm?.getFieldValue('valuePoblacion'),
            codeEspecialidad: this.state.newDataForm?.getFieldValue('valueEspecialidad')
        };

        Rest<{ type: string, idPrestador: number, prestadorData: AltaPrestadorFromExternalData }, SignContractOtpResponse>().operation(
            {
                type: 'CreateOtpForSignatureContract',
                idPrestador: this.state.idPrestador!,
                prestadorData: data
            })
            .then(response => {
                this.setState({
                    otpType: response.otpType,
                    valueUuId: response.valueUuId,
                    email: response.email,
                    dispositivoMovil: response.dispositivoMovil,
                    signOtpVisible: true,
                    signOTPkey: new Date().getTime(),
                    documentModalVisible: false
                });
            }
            );
    }

    private oncloseSignOTP = () => {
        this.setState({
            signOtpVisible: false,
            documentModalVisible: true
        });
    }

    private startSigningProcess = () => {
        Rest<{ type: string, idPrestador: number }, number>().operation({
            type: 'StartLocalSigningContract',
            idPrestador: this.state.idPrestador!
        }).then(response => {
            if (response !== null) {

            } else {
                this.setState({
                    stepOne: true,
                    signOtpVisible: false,
                    documentModalVisible: true
                });
            }
        });
    }

    private onValidOtp = (valueUuId: string, otp?: string) => {
        this.setState({
            signOtpVisible: false
        }, () => this.onSignPrescription(valueUuId, otp));
    }

    private onSignPrescription = (valueUuId?: string, otp?: string) => {
        Rest<{ type: string, valueUuId?: string, otp?: string }, SignPrescriptionResult>().operation({
            type: 'SignContract',
            valueUuId: valueUuId,
            otp: otp,
        }).then(response => {
            this.setState({ warningMessage: this.props.t('passwordError'), errorMessage: undefined, stepOne: false, signOtpVisible: false, stepCompleted: true });

        })
    }

    private sendContact = (form: FormInstance) => {
        form.validateFields().then(() => {
            const opData: StoreContactFormData = {
                valueNombre: form.getFieldValue('nombre').substring(0, form.getFieldValue('nombre').indexOf(' ')),
                valueApellidos: form.getFieldValue('nombre').substring(form.getFieldValue('nombre').indexOf(' ') + 1),
                valueEmail: form.getFieldValue('email'),
                valueAsunto: "MENSAJE DE CONTACTO EN REGISTRO EXTERNO PRESTADOR",
                valueMensaje: "Mensaje automático del contacto del registro externo del prestador. Nº telefono proporcionado: " + form.getFieldValue('telefono'),
                flagFormularioExterno: true
            }
    
            //Save formulario 
            Rest<{ type: string, value: StoreContactFormData }, string>().operation({
                type: 'StoreContactFormData',
                value: opData
            }).then(() => {
                notification['success']({ 
                    message: this.props.t('mensajeContactoSuccess')
                });
            });
        });
    }
}

export default withTranslation('welcome')(withRouter(RegistroExternoContainer));