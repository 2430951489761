import React, { ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { TabExtendedProps } from "../../MainLayout";
import { Rest, getPrescriptionTypeIcon } from "../../utils/utils";
import PrescripcionAnterior from "../anteriores/entities/PrescripcionAnteriorData";
import View from './FavPrescriptionList';
import _ from "lodash";
import EnumPrescriptionType from "../../enum/EnumPrescriptionType";
import { faFlask, faPills, faPumpMedical, faSyringe, faUtensils } from "@fortawesome/free-solid-svg-icons";
import NewEditFavPrescriptionContainer from "./newEditFavPrescriptionContainer";

interface IProps {
    visible?: boolean;
    fromNewPrescription?: boolean;
    onSelectFav?: (idPrescripcionFav: number) => void;
    addTab?: (tabCaption: string, component: ReactNode, extendedProps?: TabExtendedProps) => void;
    closeTab?: () => void;
}

export interface IState {
    loaded: boolean;
    prescriptionesFav?: PrescripcionAnterior[];
    searchResult?: string;
    prescriptionMod?: number;

    newEditVisible: boolean;
    newEditKey?: number;
    menuVisible?: boolean;
    typeChosed?: number;
}

class FavPrescriptionListContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    state: IState = {
        newEditVisible: false,
        loaded: false
    };
    
    public componentDidMount() {
        this.loadData();
    }

    loadData = (nameSearch?: string) => {
        Rest<{ type: string; nameMedicamento?: string }, PrescripcionAnterior[]>()
        .operation({
                type: 'SelectPrescripcionesFavPrestador',
                nameMedicamento: nameSearch ? nameSearch : undefined
            }, true
        ).then(response => {
            if (response && nameSearch) {
                this.setState({
                    prescriptionesFav: this.setPrescripcionesFav(response),
                    searchResult: nameSearch
                });
            } else { //Load init data (all pres)
                this.setState({
                    prescriptionesFav: this.setPrescripcionesFav(response),
                    loaded:true,
                    searchResult: undefined
            });
        }
        });
    }

    private setPrescripcionesFav= (prescriptions: PrescripcionAnterior[]) => {
        return _(prescriptions)
            .groupBy('idPrescripcion')        
            .map((value) => ({
                idPrescripcion: value[0].idPrescripcion,
                typeProducto: value[0].typeProducto,
                valueComposicionFmv: value[0].valueComposicionFmv,
                valuePresentacion: value[0].valuePresentacion,
                codeCodigoNacional: value[0].codeCodigoNacional,
                codeDcpf: value[0].codeDcpf,
                nameDcpf: value[0].nameDcpf,
                valueDosis: value[0].valueDosis,
                valueUnidadContenido: value[0].valueUnidadContenido,
                typePosology: value[0].typePosologia,
                typeFrecuencia: value[0].typeFrecuencia,
                valueFrecuencia: value[0].valueFrecuencia,
                typeDuracion: value[0].typeDuracion,
                valueDuracion: value[0].valueDuracion,
                descIndicacionesPaciente: value[0].descIndicacionesPaciente,
                descIndicacionesFarmaceutico: value[0].descIndicacionesFarmaceutico,
                photos: value[0].idVademecumImagen && _(value)
                    .groupBy('idVademecumImagen')
                    .map((value) => ({
                        idVademecumImagen: value[0].idVademecumImagen,
                        valueImagen: value[0].valueImagen,
                    })).value()
            })).orderBy('idPrescripcion', 'desc')
            .value() as PrescripcionAnterior[];
    }

    private onSearch = _.debounce((evt: any) => this.doSearch(evt), 500);

    private doSearch = (evt: any) => {
        const nameMedicamento: string = evt.target.value;

        if (nameMedicamento.length < 3) {
            this.setState({searchResult: undefined});
            if (nameMedicamento.length === 0) {
                this.loadData();
            }
            return;
        }

        //Load filtered data
        this.loadData(nameMedicamento);
    }

    private deleteFav = (id: number) => {
        Rest<{ type: string; idPrescripcionFav?: number }, void>()
        .operation({
                type: 'DeletePrescripcionFav',
                idPrescripcionFav: id
            },true
            ).then(() => {
                this.loadData();
        })

        
    }

    private refresh = () => {
        this.loadData(this.state.searchResult);
    }
    private changeMenuVisible = (visible: boolean) =>{
        this.setState({menuVisible:visible});
    }

    private onOpenMenu = () =>{
        this.setState({menuVisible:true});
    }

    private onClickMenu = (e: any) =>{
        this.setState({menuVisible:false, typeChosed:e.key}, () => this.onClickNewEditFavPrescription());
    }

    private setMedicine = (medicine: any) => {
        return {
            idVademecum: medicine[0].idVademecum,
            codeCodigoNacional: medicine[0].cn,
            nameProducto:medicine[0].name,
            laboratory: medicine[0].laboratory,
            dosage: medicine[0].dosage,
            psychotropic: medicine[0].psychotropic,
            narcotic: medicine[0].narcotic,
            codePharmaceuticalForm: medicine[0].codePharmaceuticalForm,
            namePharmaceuticalForm: medicine[0].namePharmaceuticalForm,
            codeUnitContent: medicine[0].codeUnitContent,
            valueUnitContent: medicine[0].valueUnitContent,
            codeDcpf: medicine[0].codeDcpf,
            nameDcpf: medicine[0].nameDcpf,
            activePrinciples: medicine[0].codeActivePrinciple && _(medicine).groupBy('codeActivePrinciple').map((value) => ({ codeActivePrinciple: value[0].codeActivePrinciple, nameActivePrinciple: value[0].nameActivePrinciple})).value(),
            vias: _(medicine).groupBy('codeVia').map((value) => ({ codeVia: value[0].codeVia, nameVia: value[0].nameVia})).value(),
            docs: medicine[0].idDocument && _(medicine).groupBy('idDocument').map((value) => ({ idDocument: value[0].idDocument, urlDocument: value[0].urlDocument})).value(),
            photos: medicine[0].idImage && _(medicine).groupBy('idImage').map((value) => ({ idImage: value[0].idImage, image: value[0].image})).value()
        }
    }

    private onNewEditClose = (idPrescripcionFav?: number) => {
        
        this.setState({newEditVisible:false});
        if(idPrescripcionFav){
            this.loadData();
        }
    }

    private onClickNewEditFavPrescription = (idPrescriptionFav?:number,typePrescription?: number) => {
        this.setState({newEditVisible:true, newEditKey: new Date().getTime(), prescriptionMod: idPrescriptionFav, typeChosed: typePrescription!=null ? typePrescription : this.state.typeChosed})
    }

    public render() {
        return (
            <>
            {this.state?.loaded ? <View
                onSearch={this.onSearch}
                visible={this.props.visible}
                prescriptions={this.state.prescriptionesFav}
                searchResult={this.state.searchResult}
                getPrescriptionTypeIcon={getPrescriptionTypeIcon}
                delete={this.deleteFav}
                refresh={this.refresh}
                newOrEditPrescription={this.onClickNewEditFavPrescription}
                fromNewPrescription={this.props.fromNewPrescription}
                onSelectFav={this.props.onSelectFav}
                onClickMenu={this.onClickMenu}
                onOpenMenu={this.onOpenMenu}
                menuVisible={this.state.menuVisible}
                changeMenuVisible={this.changeMenuVisible}
            />: null}

            <NewEditFavPrescriptionContainer
                    key={this.state.newEditKey}
                    visible={this.state.newEditVisible}
                    setMedicine={this.setMedicine}
                    onClose={this.onNewEditClose}
                    typePrescription={this.state.typeChosed}
                    idPrescriptionMod={this.state.prescriptionMod}
            />
            </>
        );
    }

}
export default withTranslation('favPrescriptionsList')(withRouter(FavPrescriptionListContainer));
