import { Button, FormInstance, Modal, Result } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Rest } from "../utils/utils";
import TestUserData from "./entities/testUserData";
import View from './FormularioUsuarioPrueba';

interface IProps {
    closeForm: () => void;
    showForm?: boolean;
    onClickPrivacyPolicy?: (fromContact: boolean) => void;
}

export interface IState {
    loaded?: boolean;
    formVisible?: boolean;
    errorMessage?: string;
    warningMessage?: string;
    errorFields?: string[];
    email?: string;
    formularioForm?: FormInstance;
    confirmDialogVisible?: boolean;
    incorrectOtpCodeMessage?: string;
}


class FormularioUsuarioPruebaContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public state: IState = {};

    public componentDidMount() {
        this.loadData();
    }

    public render() {
        return (
            this.state && this.state.loaded ?
                <>
                    <View
                        onSubmit={this.onSubmitFormulario}
                        onCancel={this.onClose}
                        onClickPrivacyPolicy={this.props.onClickPrivacyPolicy!}
                        formVisible={this.props.showForm || false}
                    />

                    {this.ResultDialog(this.state.confirmDialogVisible!)}
                </>
                : <></>
        )
    }

    private loadData = () => {
        this.setState({ formVisible: this.props.showForm, loaded: true,  });
    }

    private onSubmitFormulario = (form: FormInstance) => {
        form.validateFields().then(() => {
            const opData: TestUserData = {
                valueNombre: form.getFieldValue('valueNombre'),
                valueEmail: form.getFieldValue('valueEmail'),
                valueTelefono: form.getFieldValue('valueTelefono'),
                valueClinica: form.getFieldValue('valueClinica'),
                valueMensaje: form.getFieldValue('valueMensaje'),
            }
    
            //Save formulario 
            Rest<{ type: string, value: TestUserData }, string>().operation({
                type: 'StoreTestUserFormData',
                value: opData
            }).then(() => {
                this.setState({ confirmDialogVisible: true, formularioForm: undefined})
                this.props.closeForm();
            });
        });
    }

    private onClose = (form: FormInstance) => {
        form.setFieldsValue({
            valueNombre: undefined, valueApellidos: undefined, valueEmail: undefined, valueAsunto: undefined, valueMensaje: undefined,
            typeNotification_: false
        });
        this.props.closeForm();
    }

    private closeConfirmDlg = () => {
        this.setState({ confirmDialogVisible: false });
    }

    private ResultDialog = (mVisible: boolean) => {
        return (
            <Modal
                visible={mVisible}
                footer={null}
                onCancel={this.closeConfirmDlg}
            >
                <Result
                    status='success'
                    title={this.props.t('messageSent')}
                    subTitle={this.props.t('messageSentDetail')}
                    extra={<Button type='primary' style={{ cursor: 'pointer' }} onClick={this.closeConfirmDlg}>
                            {this.props.t('buttons:close')}
                        </Button>} />
            </Modal>
        );
    }
}

export default withTranslation('formularioTestUser')(withRouter(FormularioUsuarioPruebaContainer));