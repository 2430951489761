import { Button, FormInstance, Modal, Result } from "antd";
import React, { ReactNode } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Rest } from "../../utils/utils";
import View from './FormularioForm';
import StoreContactFormData from '../entities/formulario';
import SignOtpModalContainer, { OtpCallerProps } from './../saveSing/signOtpModalContainer';

interface IProps {
    addTab?: (tabCaption: string, component: ReactNode) => void;
    closeForm: () => void;
    showForm?: boolean;
    onClickPrivacyPolicy?: (fromContact: boolean) => void;
}

export interface IState extends OtpCallerProps {
    loaded?: boolean;
    formVisible?: boolean;
    errorMessage?: string;
    warningMessage?: string;
    errorFields?: string[];
    codeResult?: number;
    signSuccessVisible?: boolean;
    signSuccessKey?: number;
    password?: string;
    formularioForm?: FormInstance;
    confirmDialogVisible?: boolean;
    incorrectOtpCodeMessage?: string;
}


class FormularioFormContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public state: IState = {};

    public componentDidMount() {
        this.loadData();
    }

    public render() {
        return (
            this.state && this.state.loaded ?
                <>
                    <View
                        onSubmit={this.onSubmitFormulario}
                        onCancel={this.onClose}
                        onClickPrivacyPolicy={this.props.onClickPrivacyPolicy!}
                        formVisible={this.props.showForm || false}
                    />
                    <SignOtpModalContainer
                        valueUuId={this.state.valueUuId!}
                        email={this.state.email}
                        key={this.state.signOTPkey}
                        visible={this.state.signOtpVisible}
                        oncloseSignOTP={this.oncloseSignOTP}
                        tryAgain={this.tryAgain}
                        onValidationSuccess={this.onValidationSuccess}
                    />
                    {this.ResultDialog(this.state.confirmDialogVisible!)}
                </>
                : <></>
        )
    }

    private loadData = () => {
        this.setState({ formVisible: this.props.showForm, loaded: true,  });
    }

    private onValidationSuccess = (valueUuId: string, otpCode?: string) => {
        const opData: StoreContactFormData = {
            valueNombre: this.state.formularioForm?.getFieldValue('valueNombre'),
            valueApellidos: this.state.formularioForm?.getFieldValue('valueApellidos'),
            valueEmail: this.state.formularioForm?.getFieldValue('valueEmail'),
            valueAsunto: this.state.formularioForm?.getFieldValue('valueAsunto'),
            valueMensaje: this.state.formularioForm?.getFieldValue('valueMensaje'),
            valueUuId: valueUuId,
            code: otpCode!
        }

        //Save formulario 
        Rest<{ type: string, value: StoreContactFormData }, string>().operation({
            type: 'StoreContactFormData',
            value: opData
        }).then(() => {
            const f: FormInstance = this.state.formularioForm!;
            f.setFieldsValue({ valueNombre: undefined, valueApellidos: undefined, valueEmail: undefined, valueAsunto: undefined, valueMensaje: undefined });
            this.setState({ confirmDialogVisible: true, formularioForm: f, signOtpVisible: false })
            this.props.closeForm();
        });
    }

    private onSubmitFormulario = (form: FormInstance) => {
        form.validateFields().then(() => {
            Rest<{ type: string, userEmail: string }, string>().operation({
                type: 'RequestOtpCodeForContactForm',
                userEmail: form.getFieldValue('valueEmail')
            }).then(response => {
                // Show OTP input form
                this.setState({
                    valueUuId: response,
                    email: form.getFieldValue('valueEmail'),
                    signOtpVisible: true, signOTPkey: new Date().getTime(),
                    formularioForm: form,
                });
            });
        });
    }

    private onClose = (form: FormInstance) => {
        form.setFieldsValue({
            valueNombre: undefined, valueApellidos: undefined, valueEmail: undefined, valueAsunto: undefined, valueMensaje: undefined,
            typeNotification_: false, signOperationType: undefined, valueUuId: undefined
        });
        this.props.closeForm();
    }

    private oncloseSignOTP = () => {
        this.setState({
            signOtpVisible: false
        });
    }

    private tryAgain = () => {
        this.onSubmitFormulario(this.state.formularioForm!);
     }

    private closeConfirmDlg = () => {
        this.setState({ confirmDialogVisible: false });
    }

    private ResultDialog = (mVisible: boolean) => {
        return (
            <Modal
                visible={mVisible}
                footer={null}
                onCancel={this.closeConfirmDlg}
            >
                <Result
                    status='success'
                    title={this.props.t('messageSent')}
                    subTitle={this.props.t('messageSentDetail')}
                    extra={<Button type='primary' style={{ cursor: 'pointer' }} onClick={this.closeConfirmDlg}>
                            {this.props.t('buttons:close')}
                        </Button>} />
            </Modal>
        );
    }
}

export default withTranslation('formularioForm')(withRouter(FormularioFormContainer));