import React from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Result, Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';

interface IProps {
    visible?: boolean;
    onClose: () => void;
    onChange: () => void;
}

class SubscriptionLinkExpiredModal extends React.Component<WithTranslation & RouteComponentProps & IProps> {

    public render() {
        return (
            <Modal visible={this.props.visible} footer={null} onCancel={() => this.props.onClose()}>
                <Result
                    status='warning'
                    title={this.props.t('expiredLinkSubscriptionTitle')}
                    subTitle={this.props.t('expiredLinkSubscriptionSubtitle')}
                    extra={<>
                        <Button type='primary' onClick={() => this.props.onChange()}>
                            {this.props.t('backHome')}
                        </Button>
                    </>}
                />
            </Modal>
        );
    }
}

export default withTranslation('subscription')(withRouter(SubscriptionLinkExpiredModal));