import React, { createContext, ReactNode, useState } from "react";
import prescriptionLineUnsecurized from '../prescriptions/savePrescription/entities/prescriptionLineUnsecurized';

export interface IAlertsSummaryData {
    alertsMaxLevel?: string;
    nAlertsMaxLevel?: number;
    nAlertsRest?: number;
    prescriptionLinesList?: prescriptionLineUnsecurized[];
}

export interface AlertsSummaryContext {
    alertsSummaryData: IAlertsSummaryData[],
    setAlertsSummaryData: React.Dispatch<React.SetStateAction<IAlertsSummaryData[]>>}

const PrescriptionAlertsContext = createContext<any>({});

interface Props {
    children?: ReactNode
}

function PrescriptionAlertsProvider({children}: Props) {

    const [alertsSummaryData, setAlertsSummaryData]= useState<IAlertsSummaryData[]>([]);

    const getSummaryData = () => {
        return alertsSummaryData.length > 0 ? alertsSummaryData[alertsSummaryData.length - 1] : undefined;
    }

    const saveTempSummaryData = (summaryData: IAlertsSummaryData, level: number) => {
        const newAlertsSummaryData = alertsSummaryData.slice(0);
        newAlertsSummaryData[level - 1] = summaryData;
        /**
         * La inserción en determinado índice anterior puede dar lugar 
         * a que el array se rellene con elementos undefined para rellenar
         * los huecos hasta dicho índice
         */
        setAlertsSummaryData(newAlertsSummaryData);
    }

    const saveSummaryData = () => {
        const newAlertsSummaryData = alertsSummaryData.slice(0);
        const alertsSummaryToSave = newAlertsSummaryData.pop();
        if (newAlertsSummaryData.length > 0) {
            newAlertsSummaryData.pop();
        }
        newAlertsSummaryData.push(alertsSummaryToSave!);
        setAlertsSummaryData(newAlertsSummaryData);
    }

    const deleteSummaryData = (level: number) => {
        if (level === alertsSummaryData.length) {
            const newAlertsSummaryData = alertsSummaryData.slice(0);
            newAlertsSummaryData.pop();

            /**
             * Cuando borramos un nivel borramos también los posibles valores
             * undefined que se hubieran rellenado al insertar las alertas de este nivel
             */
            while (!newAlertsSummaryData[newAlertsSummaryData.length - 1] && newAlertsSummaryData.length!=0) {
                newAlertsSummaryData.pop();   
            }

            setAlertsSummaryData(newAlertsSummaryData);
        }
    }

    return(
        <PrescriptionAlertsContext.Provider value={{getSummaryData, saveTempSummaryData, saveSummaryData, deleteSummaryData}}>
            {children}
        </PrescriptionAlertsContext.Provider>
    )
}
export {PrescriptionAlertsContext,PrescriptionAlertsProvider};