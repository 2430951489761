import { Button, Checkbox, Col, Form, Input, Modal, Row } from "antd";
import { FormInstance } from "antd/es/form/Form";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    visible?: boolean;
    patientEmail?: string;
    emptyEmail?: boolean;
    saveEmail: () => void;
    onClose: () => void;
    onSubmit: (form: FormInstance, isDifferentEmail: boolean) => void;
}

const ResendHipPatientModal = (props: IProps) => {

    const { t } = useTranslation(['prescriptionOptions']);
    const [form] = Form.useForm();
    const [differentEmail, setDifferentEmail] = useState(false);

    return (
        <Modal
            visible={props.visible}
            footer={null}
            onCancel={() => props.onClose()}
            destroyOnClose
            bodyStyle={{paddingTop: '3rem', paddingBottom: '1.5rem'}}
            width={500} >
            
            {!props.emptyEmail ? <div>
                <p>{t('resendHipDescription')}<br/><b>{props.patientEmail}</b></p>
                <Checkbox onChange={() => setDifferentEmail(!differentEmail)}>{t('sendToDifferentEmail')}</Checkbox>
                {differentEmail && <div style={{marginTop: '0.5rem'}}>
                    <Checkbox onChange={() => props.saveEmail()} style={{marginLeft: '20px'}}>{t('saveNewEmail')}</Checkbox>
                    
                    <Form form={form} size='large' style={{marginTop: '1rem'}}>
                        <Form.Item name='newEmail' rules={[{required: true}]}>
                            <Input />
                        </Form.Item>
                    </Form>
                </div>}
                <Row align='middle' justify='space-around' style={{marginTop: '1rem'}}>
                    <Col>
                        <Button type='primary' onClick={() => props.onSubmit(form, differentEmail)}>{t('sendHip')}</Button>
                    </Col>
                    <Col>
                        <Button type='default' onClick={() => props.onClose()}>{t('buttons:cancel')}</Button>
                    </Col>
                </Row>
            </div>
            : <div>
                <p>{t('emptyPatientEmail')}</p>
                <div style={{marginTop: '0.5rem'}}>
                    <Checkbox onChange={() => props.saveEmail()} style={{marginLeft: '20px'}}>{t('saveNewEmail')}</Checkbox>

                    <Form form={form} size='large' style={{marginTop: '1rem'}}>
                        <Form.Item name='newEmail' rules={[{required: true}]}>
                            <Input />
                        </Form.Item>
                    </Form>
                </div>
                <Row align='middle' justify='space-around' style={{marginTop: '1rem'}}>
                    <Col>
                        <Button type='primary' onClick={() => props.onSubmit(form, true)}>{t('sendHip')}</Button>
                    </Col>
                    <Col>
                        <Button type='default' onClick={() => props.onClose()}>{t('buttons:cancel')}</Button>
                    </Col>
                </Row>
            </div>}

        </Modal>
    );

}

export default ResendHipPatientModal;