import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import EnumFlagAceptacion from '../enum/EnumFlagAceptacion';
import { Rest } from '../utils/utils';
import View from './WaitingConsentView';

interface IProps {
    visible?: boolean;
    idPersona: number;
    patientName: string;
    patientMail: string;
    token?: string;
    onClose: (idPersona: number, acepted?: boolean) => void;
    checkConsentimiento: (idPersona: number) => void;
}

export interface IState {
    consent?: number;
}

class WaitingConsentContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    private retryTask?: number;

    public state: IState = {};

    public componentDidMount() {
        if (this.props.visible) {
            this.poolConsentimiento();
        }
    }

    public componentWillUnmount() {
        clearInterval(this.retryTask!);
    }

    public render() {
        return (
            <View
                {...this.state}
                visible={this.props.visible}
                patientName={this.props.patientName}
                patientMail={this.props.patientMail}
                reSend={this.reSend}
                onClose={this.onClose} />
        );
    }

    private poolConsentimiento = () => {

        this.retryTask = window.setInterval(() => {
            Rest<{type: string, token: string}, any>().operation({
                type: 'PoolConsentimientoPersona',
                token: this.props.token!,
            }, true).then(response => {
                if (typeof response !== 'undefined') {
                    if (EnumFlagAceptacion.ACEPTADO === response || EnumFlagAceptacion.NO_ACEPTADO === response) {
                        clearInterval(this.retryTask!);
                        this.setState({consent: response});
                    }
                }
                
            });
        }, 10000);
    }
    
    private reSend = () => {
        clearInterval(this.retryTask!);
        this.props.checkConsentimiento(this.props.idPersona);
    }

    private onClose = (accepted?: boolean) => {
        clearInterval(this.retryTask!);
        this.props.onClose(this.props.idPersona, accepted);
    }
}

export default withTranslation('waitingConsent')(withRouter(WaitingConsentContainer));