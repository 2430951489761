import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';
import {Button, Checkbox} from 'antd';
import {URL} from '../../utils/rest';
import Modal from 'antd/lib/modal/Modal';

interface IProps {
    visible?: boolean;
    onClose: () => void;
    onAcceptDocumentModal: () => void;
}

const RegistroExternoDocumentModal = (props: IProps) => {

    const {t} = useTranslation(['signContractPrescriptor']);
    const [disabledSign, setDisabledSign] = useState(true);

    return (
        <Modal title={t('signContractTitle')} style={{top: 40}} visible={props.visible} footer={null}
            onCancel={() => props.onClose()} bodyStyle={{paddingTop:'0.5rem',
            paddingBottom: '1.5rem'}} width={1000}
        >
            <div className='sign__container'>
                <object data={URL + '/file?frep' + '#toolbar=0'} width='100%' height='575' type='application/pdf'/>
                <div className='acept-sign'>
                    <Checkbox onChange={() => setDisabledSign(!disabledSign)}/>
                    <div className='enlace_text'>{t('signContractCheck')}</div>
                </div>
                <div className='sign_buttons'>
                    <Button type='default' onClick={() => props.onClose()}>
                        {t('buttons:cancel')}
                    </Button>
                    <Button type='primary' style={{marginLeft: '1rem'}} disabled={disabledSign} onClick={() => props.onAcceptDocumentModal()}>
                        {t('buttons:confirmar')}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default RegistroExternoDocumentModal;