import { notification } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import moment from 'moment';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Rest } from '../../../utils/utils';
import Dcpf from '../entities/dcpf';
import Medicine from '../entities/medicine';
import Posology from '../entities/posology';
import View from './PosologyConfig';

interface IProps {
    visible?: boolean;
    medicine?: Medicine;
    otherProduct?: any;
    idPatient?: number;
    codeDiagnostico?: string;
    idPrescriptionGroup?: number;
    dcpf?: Dcpf;
    posologySelected?: Posology;
    productSpecification?: string;
    alertsRefreshFlag?: number;
    onClose: (posology?: Posology) => void;
    getEditingPrescription?: (form?: FormInstance) => any;
    alertsRefresh?: () => void;
    setMedicine: (medicine: any) => Medicine;
}

export interface IState {
    alertsDismiss?: boolean;
    alertsSave?: boolean;
    typePosology?: number;
}

class PosologyConfigContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    public state: IState = {
        typePosology: (this.props.dcpf?.valueUnidadContenido || this.props.medicine?.valueUnitContent || this.props.otherProduct?.valueUnidadContenido) ? 2: (this.props.posologySelected?.typePosology ? this.props.posologySelected?.typePosology:1),
    };

    public render() {
        return (
            <View
                {...this.state}
                visible={this.props.visible}
                medicine={this.props.medicine}
                otherProduct={this.props.otherProduct}
                idPatient={this.props.idPatient}
                codeDiagnostico={this.props.codeDiagnostico}
                idPrescriptionGroup={this.props.idPrescriptionGroup}
                dcpf={this.props.dcpf}
                posologySelected={this.props.posologySelected}
                productSpecification={this.props.productSpecification}
                calculatePackaging={this.calculatePackaging}
                alertsRefreshFlag={this.props.alertsRefreshFlag}
                alertsDismiss={this.state.alertsDismiss}
                alertsSave={this.state.alertsSave}
                alertsRefresh={this.props.alertsRefresh!}
                onCancel={() => this.onClose()}
                savePosology={this.validateAndSave}
                setMedicine={this.props.setMedicine}
                getEditingPrescription={this.props.getEditingPrescription!}
                onChangeTypePosology={this.onChangeTypeOfPosology}
                />
        )
    }

    private calculatePackaging = (form: FormInstance, packageModification: boolean) => {

        const values = form.getFieldsValue();
            Rest<{
                type: string;
                idVademecum?: number;
                codeDcpf?: string;
                dosage?: number;
                duration?: number;
                codeCodigoNacional?: string;
                typeFrequency?: number
                valueFrequency?: number;
                packageDuration?: number;
            }, any>().operation({
                type: 'CalculatePackageDuration',
                idVademecum: this.props.medicine?.idVademecum,
                codeDcpf: this.props.dcpf?.codeDcpf,
                codeCodigoNacional: this.props.otherProduct?.codeCodigoNacional,
                dosage: values.dosage,
                duration: values.duration,
                typeFrequency: values.frequencyType,
                valueFrequency: values.frequency,
                packageDuration: packageModification ? values.packageDuration : undefined,
            }).then(response => {
                if (response.error) {
                    notification['error']({ 
                        message: this.props.t('posologyPackageDurationError'),
                        duration : 20
                    });

                    form.setFieldsValue({
                        ...form.getFieldsValue(),
                        packageDuration: undefined,
                        nPackages: undefined
                    });
                } else {
                    form.setFieldsValue({
                        ...form.getFieldsValue(),
                        packageDuration: response.packageDuration,
                        nPackages: response.nPackages
                    });
                }
            });
    }

    private validateAndSave = (form: FormInstance) => {
        form.validateFields().then(values => {
            this.internalValidation(values);
        });
    }

    private internalValidation = (values: Store) => {
        Rest<{type: string; codeDcpf?: string; idVademecum?: number; duration: number; nPackages: number}, number>().operation(
            {type: 'CheckNarcoticDaysAndPackages', codeDcpf: this.props.dcpf?.codeDcpf, idVademecum: this.props.medicine?.idVademecum, duration:values.duration, nPackages:values.nPackages}).then((response)=>{
                if(response==1){
                    notification['error']({ 
                        message: this.props.t('durationNarcolError')
                    });
                } else if (response==2){
                    notification['error']({ 
                        message: this.props.t('nPackagesNarcolError')
                    });
                } else if (Number(values.duration) > 365) {
                    notification['error']({ 
                        message: this.props.t('durationNormallError')
                    });
                } else if (values.dateStart != null && values.dateStart.isBefore(moment(), 'day')) {
                    notification['error']({
                        message: this.props.t('iniTreatmentError')
                    });
                } else {
                    this.savePosology(values);
                }
        }); 
       
    }

    private savePosology = (values: Store) => {

        this.setState({alertsSave: true}, () => {
            
            const posology = {
                dateStart: values.dateStart,
                dateStartString: values.dateStartString,
                duration: values.duration,
                dosage: this.state.typePosology != 1 ? values.dosage : 1,
                frequency: this.state.typePosology != 1 ? values.frequency: 1,
                frequencyType: this.state.typePosology != 1 ? values.frequencyType: 2,
                packageDuration: this.state.typePosology != 1 ? values.packageDuration: (values.duration / values.nPackages),
                nPackages: values.nPackages,
                patientIndications: values.patientIndications,
                pharmacyIndications: values.pharmacyIndications,
                typePosology: this.state.typePosology
            };
    
            this.props.onClose(posology);
        });
    }

    private onChangeTypeOfPosology = (type: any) =>{

        this.setState({typePosology: type.target.value});
    }

    private onClose = () => {
        this.setState({alertsDismiss: true}, () => {
            this.props.onClose();
        });
    }
}

export default withTranslation('medicineConfig')(withRouter(PosologyConfigContainer));