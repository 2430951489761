import React from 'react';

import { useTranslation } from 'react-i18next';
import { IState as IStateContainer } from './prescriptionsListContainer';
import { Table, Drawer, Space, Button, Tooltip, Badge } from 'antd';
import { concatName, TableIcons, Functions } from '../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrescription } from '@fortawesome/free-solid-svg-icons/faPrescription';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { AlignType } from 'rc-table/lib/interface';
import { FormInstance } from 'antd/lib/form';
import PrescriptionFilters from './prescriptionFilters';
import Highlighter from 'react-highlight-words';
import PrescripcionData from './entities/prescripcionData';
import EnumPrescriptionStatus from '../enum/EnumPrescriptionStatus';
import EnumDispensacionStatus from '../enum/EnumDispensacionStatus';
import EnumCodeIdentificationType from '../enum/EnumCodeIdentificationType';
import PrescriptionOptionsContainer from './options/PrescriptionOptionsContainer';

interface IProps {
    fromPatient?: boolean;
    fixedPatient?: boolean;
    hideOptions?: boolean;
    refresh: () => void;
    onCollapseFilters: () => void;
    onChangePage: (page: number) => void;
    applyFilters: (form: FormInstance) => void;
    resetFilters: () => void;
    onClickDetail: (idPrescripcionGrupo: number, nombrePaciente: string, pending: boolean) => void;
    onAddNewPrescripcionTab?: () => void;
}

const PrescriptionsList = (props: IProps & IStateContainer) => {
    const { t } = useTranslation(['prescriptionsList']);

    const statusPrescriptionRenderer = (value: number, record?: any) => {
        let color;
        let colorVisa;
        switch (value) {
            case EnumPrescriptionStatus.PTE_FIRMA:
                color = "#FADB15";
                break;
            case EnumPrescriptionStatus.EMITIDA:
                color = "#8EAD38";
                break;
            case EnumPrescriptionStatus.ANULADA:
                color = "#FF4D4F";
                break;
        }
        if(record!=null){
            switch (record.estadoVisado) {
                case 2:
                    colorVisa = "#FADB15";
                    break;
                case 3:
                    colorVisa = "#8EAD38";
                    break;
                case 4:
                    colorVisa = "#FF4D4F";
                    break;
            }
        } 

        return <div style={{display:'flex', flexDirection:'column'}} className='statusPrescriptionDiv'>
            <span><FontAwesomeIcon icon={faCircle} style={{color: color, marginRight: '0.5rem'}}/>{t('prescriptionStatuses:' + value)}</span>
           {record && record.flagMutualista!=0 && record.estadoVisado && record.estadoVisado!=1 && value != EnumPrescriptionStatus.PTE_FIRMA && <span className='visaStatus'><FontAwesomeIcon icon={faCircle} style={{color: colorVisa, marginLeft: '0.075rem', marginRight: '0.58rem'}}/>{t('visadoStatuses:' + record.estadoVisado)}</span>}
        </div>;
    }

    const statusDispensacionRenderer = (value: any, record?: PrescripcionData) => {

        let color;
        let status = record && record.estadoDispensacion ? record.estadoDispensacion : value; 

        if (!status) {
            return;
        }

        switch (status) {
            case EnumDispensacionStatus.PENDIENTE:
                color =  "#A9A9A9";
                break;
            case EnumDispensacionStatus.DISPENSADA:
                color = "#8EAD38";
                break;
            case EnumDispensacionStatus.CADUCADA:
                color = "#FADB15";
                break;
            case EnumDispensacionStatus.BLOQUEADA:
                color = "#FF4D4F";
                break;
            case EnumDispensacionStatus.FORMULA_MAGISTRAL:
                color = "#002E50";
                break;
            case EnumDispensacionStatus.DISPENSADA_SUSTITUCION_1:
                color = "#1890FF";
                break;
            case EnumDispensacionStatus.DISPENSADA_SUSTITUCION_2:
                color = "#1890FF";
            break;
        }

        return (
            <div className='dispensacion-column'>
                <div>
                    {status === EnumDispensacionStatus.DISPENSADA_PARCIALMENTE ? 
                        <div className='dipensada_parcialmente_status' style={{marginRight: '0.5rem'}}/> :
                        <FontAwesomeIcon icon={faCircle} style={{color: color, marginRight: '0.5rem'}}/> } 
                    {t('dispensacionStatuses:' + status)}
                </div>
                {record && record.totalRecetas > 1 && 
                    <div className='message_receta'>({t('messageRecetaLabel', {numReceta: record.numReceta, totalReceta: record.totalRecetas})})</div>}
            </div>
        
        )
    }

    const columns = [
        { title: t('pacienteTitle'), dataIndex: 'paciente', ellipsis: true, align: 'left' as AlignType, width: '20%', render: (value: any, record: any) => {
            const name = concatName(record.nombrePaciente, record.apellido1Paciente, record.apellido2Paciente);
    
            return props.highlightName && !props.fromPatient ?
                <Highlighter
                    highlightStyle={{ backgroundColor: '#f8d19b', padding: 0 }}
                    searchWords={[props.highlightName]}
                    autoEscape
                    textToHighlight={record.identificacionPaciente + ' ' + name}
                    />
                : <span> <b>{record.typeIdentificacionPaciente === EnumCodeIdentificationType.MENOR ? 
                        t('others:menorSinNIF') : record.identificacionPaciente}</b>  {name}</span>
        }},
        { title: t('fechaPrescripcionTitle'), align: 'center' as AlignType, width: '9rem', dataIndex: 'dateCreacion', render: (dateCreacion: Date) => {
            return dateCreacion && Functions.dateToString(dateCreacion);
        }},
        { title: t('prescripcionTitle'), dataIndex: 'valuePrescripcion', ellipsis: true, align: 'left' as AlignType, render: (valuePrescripcion: string) => {
            return props.highlightPrescriptionName ?
                <Highlighter
                    highlightStyle={{ backgroundColor: '#f8d19b', padding: 0 }}
                    searchWords={[props.highlightPrescriptionName]}
                    autoEscape
                    textToHighlight={valuePrescripcion}
                />
                : valuePrescripcion
        }},
        { title: t('estadoTitle'), dataIndex: 'estadoPrescripcion', align: 'left' as AlignType, width: '12rem', render: statusPrescriptionRenderer},
        { title: t('dispensacionTitle'), dataIndex: 'estadoDispensacion', align: 'left' as AlignType, width: '12rem', render: statusDispensacionRenderer},
        { align: 'right' as AlignType, width: '8.5rem', render: (value: any, record: PrescripcionData) => {

            const onClick = () => {
                if (record.estadoPrescripcion === EnumPrescriptionStatus.PTE_FIRMA) {
                    props.onClickDetail(record.idPrescripcionGrupo, record.nombrePaciente + ' ' + record.apellido1Paciente, true);
                } else {
                    props.onClickDetail(record.idPrescripcionGrupo, record.nombrePaciente + ' ' + record.apellido1Paciente, false);
                }
            }
            
            return (
                <div style={{display:'flex', justifyContent:'flex-start'}}>
                    <Tooltip title={t('detailButton')}>  
                        <Button type='default' onClick={onClick} style={{marginRight: '10px'}}>{t('buttons:details')}</Button>
                    </Tooltip>
                    {record.estadoPrescripcion === 1 && !props.hideOptions && <PrescriptionOptionsContainer idPatient={record.idPaciente} idPrescripcionGrupo={record.idPrescripcionGrupo}/>}
                </div>
            );
        }}
    ];

    if (props.fixedPatient)  {
        columns.shift(); // Removes patient column
    }

    return (
        <>
            <Drawer className='filters-drawer' visible={!props.filtersCollapsed}
                onClose={props.onCollapseFilters}
                width='300px' placement='left' getContainer={false}>
                    <PrescriptionFilters {...props}
                        key={props.filterKey}
                        statusPrescriptionRenderer={statusPrescriptionRenderer}
                        statusDispensacionRenderer={statusDispensacionRenderer}
                    />
            </Drawer>

            <Space size='small' className='table-button-bar'>
                {!props.fromPatient &&
                <Badge className='filter-badge' count={props.nFilters}>
                <Button type={props.filtersCollapsed ? 'primary' : 'default'} icon={TableIcons.getTableIcon(TableIcons.TableIcon.filter)}
                        onClick={props.onCollapseFilters}/>
                        </Badge>
                }
                
                <Button type='primary' icon={TableIcons.getTableIcon(TableIcons.TableIcon.reload)}
                    onClick={props.refresh}/>
                
                {!props.fromPatient &&
                <Button icon={<FontAwesomeIcon icon={faPrescription} className='anticon'/>}
                    onClick={props.onAddNewPrescripcionTab}>
                    {t('newPrescriptionButton')}
                </Button>
                }
            </Space>

            <div className='table-container'>
                <Table 
                    columns={columns}
                    dataSource={props.prescriptionList}
                    size='small'
                    scroll={{y: props.tableBodyHeight}}
                    pagination={{
                        position: ['bottomCenter'],
                        hideOnSinglePage: true,
                        showSizeChanger: false,
                        current: props.page, 
                        pageSize: props.limit, 
                        total: props.dataCount, 
                        onChange: props.onChangePage}}
                />
            </div>
        </>
    )
}

export default PrescriptionsList;