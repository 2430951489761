import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const OtpAuthenticationConfirmation = (props: {
    visible?: boolean;
    onCancel: () => void;
    onContinue: () => void;
}) => {

    const { t } = useTranslation(['otpAuthenticationForm']);

    return (
        <Modal
            visible={props.visible}
            okText={t('buttons:confirmar')}
            className={t('buttons:cancel')}
            onOk={props.onContinue}
            onCancel={props.onCancel}
            destroyOnClose
            width={586} >

            <div>{t('stepContinue')}</div>

        </Modal>
    );

}

export default OtpAuthenticationConfirmation;