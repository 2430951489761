import React from 'react';

import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'rc-field-form/lib/interface';
import { IState as IStateContainer } from './ChangePasswordFormContainer';
import { FormUtils } from '../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import Modal from 'antd/lib/modal/Modal';

const ChangePasswordForm = (props: {
    visible?: boolean;
    requiredChangePass?: boolean;
    onCancel: () => void;
    onSubmit: (form: FormInstance) => void;
} & IStateContainer) => {

    const [form] = Form.useForm();
    const { t } = useTranslation(['changePasswordForm']);

    return (
       <Modal
            title={<>
                <FontAwesomeIcon icon={faKey} style={{ color: '#707070', marginRight: '0.5rem' }}/><span>{t('changePasswordTitle')}</span></>}
            style={{ top: 40 }}
            onOk={() => props.onSubmit(form)}
            onCancel={() => props.onCancel()}
            visible={props.visible}
            destroyOnClose
            width={586}
            okText={t('buttons:save')} cancelText={t('buttons:cancel')}
        >
            {props.requiredChangePass && 
                <p style={{color: 'red'}}><b>{t('passwordExpiredLabel')}</b></p>
            }

            <div className='paragraphs__container'>
                <p style={{marginBottom: '0.5rem'}}>{t('newPasswordConditions')}</p>
                <p style={{marginLeft: '0.5rem'}}>{'- ' + t('charactersLength')}</p>
                <p style={{marginLeft: '0.5rem'}}>{'- ' + t('lowercaseConditionLabel')}</p>
                <p style={{marginLeft: '0.5rem'}}>{'- ' + t('capitalConditionLabel')}</p>
                <p style={{marginLeft: '0.5rem'}}>{'- ' + t('numberConditionLabel')}</p>
                <p style={{marginLeft: '0.5rem'}}>{'- ' + t('symbolConditionLabel')}</p>
            </div>

            <Form layout='vertical' form={form} size='large' style={{marginTop: '1rem'}}>
                <Form.Item
                    label={t('currentPassword')}
                    name='currentPassword'
                    rules={[{required: true}]}
                    validateStatus={FormUtils.isError('currentPassword', props.errorFields) ? 'error' : undefined}
                    help={FormUtils.getErrorMessage('currentPassword', props.errorFields)}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label={t('newPassword')}
                    name='newPassword'
                    rules={[{required: true}]}
                    validateStatus={FormUtils.isError('newPassword', props.errorFields) ? 'error' : undefined}
                    help={FormUtils.getErrorMessage('newPassword', props.errorFields)}
                >
                    <Input.Password />
                </Form.Item>
                
                <Form.Item
                    label={t('confirmNewPassword')}
                    name='confirmNewPassword'
                    rules={[{required: true}]}
                    validateStatus={FormUtils.isError('confirmNewPassword', props.errorFields) ? 'error' : undefined}
                    help={FormUtils.getErrorMessage('confirmNewPassword', props.errorFields)}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ChangePasswordForm;