import React, {ReactNode} from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TabExtendedProps } from '../MainLayout';
import { Rest } from '../utils/utils';
import Dispensation from './entities/dispensation';
import View from './HomePage';
import NewPrescriptionsContainer from '../prescriptions/newPrescriptionsContainer';
import MenuConfig, { COMPONENT_IDS } from '../layout/menuConfig';
import Patient from './entities/patient';
import PrescriptionsListContainer from '../prescriptions/prescriptionsListContainer'
import ListaPacientesContainer from '../prescriptions/patient/listadoPacientes/listaPacientesContainer';
import InformacionPacienteContainer from '../prescriptions/patient/listadoPacientes/informacionPacienteContainer';

interface IProps {
    addTab?: (tabCaption: string, component: ReactNode, extendedProps?: TabExtendedProps) => void;
    closeTab?: () => void;
}

export interface IState {
    loaded?: boolean;
    dispensations?: Dispensation[];
    patients?: Patient[];
    dataCount: number;
    limit: number;
    page: number;
}


class HomePageContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public state: IState = {
        limit: 5,
        dataCount: 0,
        page: 1,
    };

    public componentDidMount() {
        this.loadData();
    }

    public render() {
        return (
            this.state.loaded ?
                <View
                    {...this.state}
                    onChangePage={this.onChangePage}
                    onClickDispensation={this.onClickDispensation}
                    onClickPatientNewPrescription={this.onClickPatientNewPrescription}
                    onClickDetail={this.onClickDetail}
                    onClickPatientPrescriptions={this.onClickPatientPrescriptions} />
            : <></>
        )
    }

    private loadData = () => {

        Rest<{type: string, dispensacionesLimit: number, dispensacionesOffset: number}, any>().operation({
            type: 'GetHomeData',
            dispensacionesLimit: this.state.limit,
            dispensacionesOffset: (this.state.page - 1) * this.state.limit,
            }).then(response => {
                this.setState({
                    loaded: true,
                    dispensations: response.dispensaciones.data,
                    dataCount: response.dispensaciones.dataCount,
                    patients: response.ultimosPacientes,
                });
            });
    }

    private onChangePage = (page: number) => {

        Rest<{type: string, limit: number, offset: number}, any>().operation({
            type: 'SelectDispensacionesByPrestador',
            limit: this.state.limit,
            offset: (page - 1) * this.state.limit,
            }).then(response => {
                this.setState({
                    loaded: true,
                    dispensations: response.data,
                    dataCount: response.dataCount,
                    page
                });
            });
    }

    private onClickDetail = (idPaciente: number, nombrePaciente: string) => {
        if (this.props.addTab) {
            
            const tabCaption = `${nombrePaciente}`;
            const component = (
                <InformacionPacienteContainer 
                    idPaciente={idPaciente} 
                    addTab={this.props.addTab} 
                    closeTab={this.props.closeTab}
                />
            );
            const extendedProps = MenuConfig.getConfigById(COMPONENT_IDS.infoPacienteContainer);
    
            this.props.addTab(tabCaption, component, extendedProps);
        }            
    }

    private onClickDispensation = (nombrePaciente: string, idPrescripcionGrupo: number) => {
        if(this.props.addTab) {
            this.props.addTab(this.props.t('prescriptionsList:prescriptionDetailTabTitle', {nombrePaciente, idPrescripcionGrupo}), <NewPrescriptionsContainer idPrescripcionGrupo={idPrescripcionGrupo} addTab={this.props.addTab} closeTab={this.props.closeTab} pending={false} />, MenuConfig.getConfigById(COMPONENT_IDS.newPrescriptionContainer));
        }
    }

    private onClickPatientPrescriptions = (nombrePaciente: string, codeidentificacion?: string) => {
        if(this.props.addTab) {
            const title = this.props.t('prescriptionList', {nombrePaciente});
            this.props.addTab(title, <PrescriptionsListContainer addTab={this.props.addTab} patientNif={codeidentificacion} patientName={!codeidentificacion ? nombrePaciente : undefined}/>, MenuConfig.getConfigById(COMPONENT_IDS.prescriptionsListContainer));
        }
    }

    private onClickPatientNewPrescription = (idPaciente: number) => {
        if(this.props.addTab) {
            this.props.addTab(this.props.t('menu:newPrescription'), <NewPrescriptionsContainer idPaciente={idPaciente} addTab={this.props.addTab} closeTab={this.props.closeTab} pending={true} />, MenuConfig.getConfigById(COMPONENT_IDS.newPrescriptionContainer));
        }
    }
}
export default withTranslation('homePage')(withRouter(HomePageContainer));