import React from 'react';

import { Modal } from 'antd';
import './login.scss';
import vidalVademecum from '../images/VIDAL_Vademecum_españa_FINAL.png';
import { URL } from '../utils/rest';

interface IProps {
    visible?: boolean;
    onClose: () => void;
}

const VidalVademecumModal = (props: IProps) => {

    return (
        <Modal
            style={{ top: 40 }}
            visible={props.visible}
            onCancel={() => props.onClose()}
            closable
            bodyStyle={{ paddingTop: '3rem', paddingRight:'3rem', paddingLeft: "3rem" , paddingBottom: '3rem' }}
            width={900}
            footer={null}
            destroyOnClose
        >
            <div className='vidalVademecumModal'>
                <div className='vidalVademecumModal-Image'><img src={vidalVademecum} alt=''/></div>
                <div>
                    <div className='vidalVademecumModal-textConColor'>{"¡"}<span className='vidalVademecumModal-textLink'>{"ViDAL "}</span><span className='textMarginBottom'>{" marca la pauta en exigencias de calidad!"}</span></div>

                    <div className='vidalVademecumModal-textMarginTop'>{"Estamos orgullosos de comunicar que ViDAL es el primer proveedor de Conocimiento Farmacológico Europeo CDS en obtener el"}</div>
                    <div className='vidalVademecumModal-textMarginBottom'>{"marcado CE de clase IIb MDR para su dispositivo médico de análisis de las prescripciones, dispensaciones y administraciones."}</div>

                    <div className='vidalVademecumModal-textNoMargin'>{"Esta certificación es una prueba más de la fiabilidad de las soluciones de ViDAL para los profesionales de la salud y su compromiso"}</div>
                    <div className='vidalVademecumModal-textMarginBottom'>{"con el control de calidad de sus tecnologías."}</div>

                    <div className='vidalVademecumModal-textNoMargin'>{"ViDAL ya cuenta con la certificación ISO 13485:2016, la norma internacional de gestión de la calidad para las actividades"}</div>
                    <div className='vidalVademecumModal-textNoMargin'>{"relacionadas con el ciclo de vida de los productos sanitarios y medicamentos. Esta certificación garantiza que nuestras actividades,"}</div>
                    <div className='vidalVademecumModal-textMarginBottom'>{"desde el diseño hasta la comercialización, cumplen los requisitos de calidad más rigurosos requisitos de calidad."}</div>

                    <div className='vidalVademecumModal-textConColor'>{"Esta Alianza Delonia-ViDAL, apoyada en las certificaciones de ViDAL ("}<span className='vidalVademecumModal-textLink' onClick={() => window.open(URL + '/pdf/Certificat_GMED_Juin_23.pdf', '_blank' )}>{"certificación ISO 13485:2016 y marcado CE de clase IIb"}</span>{")"}</div>
                    <div className='vidalVademecumModal-textNoMargin'>{"proporciona a los usuarios de eReceta (pacientes, profesionales sanitarios, Instituciones Sanitarias) las garantías adicionales de"}</div>
                    <div className='vidalVademecumModal-textMarginBottom'>{"calidad y cumplimiento de los requisitos normativos vigentes."}</div>

                </div>
            </div>
            
        </Modal>
    )
}
export default VidalVademecumModal;