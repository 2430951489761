import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import MedicamentoDetailsVidal from './MedicamentoDetailsVidal';
import {IMedicamentoDescriptionVidal} from '../entities/IMedicamentoDescriptionVidal';
import {Rest} from '../../../utils/utils';

interface IProps {
    MedicamentoDetailsVidalVisible: boolean;
    onCloseMedicamentoDetailsVidal: () => void;
    onSelectVidalFromDetailsVidal: (vidalUrl: string) => void;
    idVademecum?: number;

}

export interface IState {
    medicamentoDescriptionVidal?: IMedicamentoDescriptionVidal;
    activeTabIndex: number;
    tabKey: string;
    tabArray: JSX.Element[];

}

class MedicamentoDetailsVidalContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    state: IState = {
        activeTabIndex: 0,
        tabKey: '0',
        tabArray: [],

    };


    componentDidMount() {
        if (this.props.MedicamentoDetailsVidalVisible) {
            this.getSelectedMedicine(this.props.idVademecum);
        }
    }

    public render() {
        if (!this.state.medicamentoDescriptionVidal) {
            return null;
        }

        return (
            <MedicamentoDetailsVidal
                key={this.props.idVademecum!.toString()}
                visible={this.props.MedicamentoDetailsVidalVisible}
                onSelectVidalFromDetailsVidal={this.props.onSelectVidalFromDetailsVidal}
                onCancel={this.onCloseMedicamentoDetailsVidal}
                medicamentoDescriptionVidal={this.state.medicamentoDescriptionVidal}
                onSelectMedicine={this.onSelectMedicine}
                activeTabIndex={this.state.activeTabIndex}
                tabKey={this.state.tabKey}
                onTabChange={this.onTabChange}
            />
        );
    }


    onTabChange = (selectedKey: string) => {
        this.setState({activeTabIndex: +selectedKey});
    };

    onSelectMedicine = (idVademecum: number) => {
        this.getSelectedMedicine(idVademecum);
    };


    onCloseMedicamentoDetailsVidal = () => {
        this.props.onCloseMedicamentoDetailsVidal();

    };

    getSelectedMedicine = (idVademecum?: number) => {
        let _activeTabKey = +this.state.tabKey + 1;
        Rest<{ type: string; idVademecum: number }, IMedicamentoDescriptionVidal>().operation({
            type: 'GetPackageDetail',
            idVademecum: idVademecum!,
        }).then((response: IMedicamentoDescriptionVidal) => {
            this.setState({
                medicamentoDescriptionVidal: response,
                tabKey: _activeTabKey.toString(),
                activeTabIndex: _activeTabKey,
            });
        });
    };
}

export default withTranslation('medicineConfig')(withRouter(MedicamentoDetailsVidalContainer));