import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps, withRouter} from "react-router-dom";
import React from "react";
import View from './PrescripcionesAnteriores';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import PrescripcionAnterior from "./entities/PrescripcionAnteriorData";
import {concatName, Rest} from "../../utils/utils";
import _ from "lodash";
import PatientData from "../patient/entities/patientData";

interface IProps {
    visible?: boolean;
    patientSelected?: PatientData;
    prescripcionesActivas?: PrescripcionAnterior[];
    getPrescriptionTypeIcon: (type: number) => IconDefinition;
    onClose?: () => void;
    setPrescriptionesAnteriores: (prescriptions: PrescripcionAnterior[]) => PrescripcionAnterior[];
}

interface IState {
    loaded: boolean;
    prescriptionesAnteriores?: PrescripcionAnterior[];
    searchResult?: string;
}

class PrescripcionesAnterioresContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    public componentDidMount() {
        if (this.props.visible) {
            this.loadData();
        }
    }

    public render() {
        return ( this.state?.loaded ?
            <View
                visible={this.props.visible}
                patientSelected={this.props.patientSelected}
                onCancel={this.props.onClose!}
                prescriptions={this.state.prescriptionesAnteriores}
                searchResult={this.state.searchResult}
                getPrescriptionTypeIcon={this.props.getPrescriptionTypeIcon}
                onSearch={this.onSearch}
                prescripcionesActivas={this.props.prescripcionesActivas}
            /> : null
        );
    }

    loadData = (nameSearch?: string) => {
        if(!this.props.prescripcionesActivas){
            Rest<{ type: string; idPaciente: number; typeEstado: number; namePrestadorOrMedicamento?: string }, PrescripcionAnterior[]>()
            .operation({
                    type: 'SelectPrescripcionesAnteriores',
                    idPaciente: this.props.patientSelected?.idPaciente!,
                    typeEstado: 1,
                    namePrestadorOrMedicamento: nameSearch ? nameSearch : undefined
                }, true
            ).then(response => {
                response.forEach(p => {
                    p.namePrestador = concatName(p.nameNombrePrestador!, p.nameApellido1Prestador!, p.nameApellido2Prestador);
                });
                if (response && nameSearch) {
                    this.setState({
                        prescriptionesAnteriores: this.props.setPrescriptionesAnteriores(response),
                        searchResult: nameSearch
                    });
                } else {
                    this.setState({
                        prescriptionesAnteriores: this.props.setPrescriptionesAnteriores(response),
                        loaded:true,
                        searchResult: undefined
                });
            }
            });
        }else{
            this.setState({loaded:true});
        }
        
    }

    private onSearch = _.debounce((evt: any) => this.doSearch(evt), 500);

    private doSearch = (evt: any) => {
        const namePrestadorOrMedicamento: string = evt.target.value;

        if (namePrestadorOrMedicamento.length < 3) {
            this.setState({searchResult: undefined});
            if (namePrestadorOrMedicamento.length === 0) {
                this.loadData();
            }
            return;
        }

        //Load filtered data
        this.loadData(namePrestadorOrMedicamento);
    }
}


export default withTranslation('prescription')(withRouter(PrescripcionesAnterioresContainer));