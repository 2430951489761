import React, { ReactNode } from 'react';

import { Form, Row, Col, Button, DatePicker, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd/lib/form';
import { Option } from 'rc-select';
import EnumPrescriptionStatus from '../enum/EnumPrescriptionStatus';
import EnumDispensacionStatus from '../enum/EnumDispensacionStatus';
import PrescripcionData from './entities/prescripcionData';

const PrescriptionFilters = (props: {
    lastFilter?: any;
    prescriptionFilter?: {value: string}[];
    applyFilters: (form: FormInstance) => void;
    resetFilters: () => void;
    statusPrescriptionRenderer: (value: any, record?: PrescripcionData) => ReactNode;
    statusDispensacionRenderer: (value: any, record?: PrescripcionData) => ReactNode;
    patientId?: number;
    fixedPatient?: boolean;
}) => {

    const [form] = Form.useForm();

    const { t } = useTranslation(['prescriptionsList']);

    const { RangePicker } = DatePicker;

    const resetFilters = () => {
        form.resetFields();
        props.resetFilters();
    }

    const statusPrescriptionOptions = (status: number) => {
        return <Option key={status} value={status} label={t('prescriptionStatuses:' + status)}>
                    {props.statusPrescriptionRenderer(status)}
                </Option>;
    }
    const statusDispensacionOptions = (status: number) => {
        return <Option key={status} value={status} label={t('dispensacionStatuses:' + status)}>
                    {props.statusDispensacionRenderer(status)}
                </Option>;
    }

    return (<>
        <Form size='large' layout='vertical' className='form__smaller-margin' form={form} style={{width: '100%'}}>
            {(!props.patientId && !props.fixedPatient) && <Form.Item name='nifPatient' label={t('nifFilter')} initialValue={props.lastFilter?.nifPatient}>
                <Input placeholder={t('nifFilter')} />
            </Form.Item>}
            {(!props.patientId && !props.fixedPatient) && <Form.Item name='nifTutor' label={t('nifTutorFilter')} initialValue={props.lastFilter?.nifTutor}>
                <Input placeholder={t('nifTutorFilter')} />
            </Form.Item>}
            <Form.Item name='statusPrescription' label={t('estadoEmisionFilter')}>
                <Select mode='multiple' filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}>
                    {statusPrescriptionOptions(EnumPrescriptionStatus.PTE_FIRMA)}
                    {statusPrescriptionOptions(EnumPrescriptionStatus.EMITIDA)}
                    {statusPrescriptionOptions(EnumPrescriptionStatus.ANULADA)}
                </Select>
            </Form.Item>
            <Form.Item name='statusDispensacion' label={t('estadoDispensacionFilter')} initialValue={props.lastFilter?.statusDispensacion}>
                <Select mode='multiple' filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}>
                    {statusDispensacionOptions(EnumDispensacionStatus.PENDIENTE)}
                    {statusDispensacionOptions(EnumDispensacionStatus.DISPENSADA)}
                    {statusDispensacionOptions(EnumDispensacionStatus.DISPENSADA_PARCIALMENTE)}
                    {statusDispensacionOptions(EnumDispensacionStatus.CADUCADA)}
                    {statusDispensacionOptions(EnumDispensacionStatus.BLOQUEADA)}
                    {statusDispensacionOptions(EnumDispensacionStatus.FORMULA_MAGISTRAL)}
                    {statusDispensacionOptions(EnumDispensacionStatus.DISPENSADA_SUSTITUCION_1)}
                </Select>
            </Form.Item>
            <Form.Item name='prescriptionName' label={t('prescripcionFilter')}>
                <Input placeholder={t('prescripcionFilter')} />
            </Form.Item>
            <Form.Item name='creationDate' label={t('creatinDateFilter')} rules={[{ type: 'array'}]}>
                <RangePicker style={{width:'100%'}} allowEmpty={[true, true]}/>
            </Form.Item>
        </Form>
        <Row gutter={16}>
            <Col span={12}><Button type='primary' block onClick={() => props.applyFilters!(form)}>{t('buttons:apply')}</Button></Col>
            <Col span={12}><Button danger block onClick={resetFilters}>{t('buttons:reset')}</Button></Col>
        </Row>
   </> );
}

export default PrescriptionFilters;