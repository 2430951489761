import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Rest } from '../../utils/utils';
import View from './alergiesSelect';
import _ from 'lodash';
import { AlergieData } from './entities/alergieData';
import i18n from '../../i18n/i18n';

interface IProps {
    visible?: boolean;
    onClose: (alergie?: AlergieData) => void;
}

export interface IState {
    page: number;
    limit: number;
    dataCount: number;
    search?: string;
    alergieList?: AlergieData[];
    alergieSelected?: AlergieData;
}

class alergiesSelectContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {
    
    public state: IState = {
        page: 1,
        limit: 20,
        dataCount: 0
    }

    public render() {
        return (
            this.props.visible ? <View
                {...this.state}
                visible={this.props.visible}
                onCancel={() => this.props.onClose()}
                searchAlergie={this.searchAlergie}
                onChangePage={this.onChangePage}
                onSelect={this.onSelectAlergie}
                nameTranslation={this.nameTranslation}
                onSearchAlergie={this.doSearchAlergie} />:<></>
        )
    }

    private searchAlergie = _.debounce((value?: string) => this.doSearchAlergie(value), 250);

    private doSearchAlergie = (value?: string) => {
        let searchValue = this.state.search;
        let page = this.state.page;

        if (value) {
            page = 1;
            searchValue = value;
        }

        if (!searchValue || searchValue.trim().length === 0) {
            return;
        }

        const searchValues = searchValue?.includes(' ') ? searchValue.split(' ') : [searchValue];

        Rest<{type: string; filters: string[]; language:string, limit: number, offset: number}, any>().operation({
            type: 'SelectAlergyByQuery',
            filters: searchValues,
            language: i18n.language.substring(0, 2),
            limit: this.state.limit,
            offset: (page-1) * this.state.limit
        }, true).then(response => {
            this.setState({alergieList:undefined});
            this.setState({alergieList: response.data, dataCount: response.dataCount, search: searchValue, page, alergieSelected: undefined});
        });
    }

    private onChangePage = (page: number) => {
        this.setState({ page }, () => this.searchAlergie());
    }

    private setAlergie = (idVidal: string, nameAlergiaEs: string, nameAlergiaEn: string, idAlergia: number) => {
        return {
            idVidal: idVidal,
            nameAlergiaEs: nameAlergiaEs,
            nameAlergiaEn: nameAlergiaEn,
            idAlergia: idAlergia
        }
    }

    private onSelectAlergie = (idVidal: string, nameAlergiaEs: string, nameAlergiaEn:string, idAlergia: number) => {
       this.setState({alergieList: undefined});
       this.props.onClose(this.setAlergie(idVidal, nameAlergiaEs, nameAlergiaEn, idAlergia));
    }

    private  nameTranslation = (record: AlergieData) => {
        if(i18n.language.substring(0, 2)=="es"){
            if(record.nameAlergiaEs!=undefined && record.nameAlergiaEs!=null && record.nameAlergiaEs!=""){
                return record.nameAlergiaEs!;
            }
            else{
                return record.nameAlergiaEn!;
            }
        }
        else{
            if(record.nameAlergiaEn!=undefined && record.nameAlergiaEn!=null && record.nameAlergiaEn!=""){
                return record.nameAlergiaEn!;
            }
            else{
                return record.nameAlergiaEs!;
            }
        }
    }

}

export default withTranslation('newPatientPrescription')(withRouter(alergiesSelectContainer));