import React, { useContext, useReducer } from 'react';

import { Menu } from 'antd'; 
import { MenuInfo } from 'rc-menu/lib/interface';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrescription } from '@fortawesome/free-solid-svg-icons/faPrescription';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import MenuConfig from './menuConfig';
import { TabExtendedProps } from '../MainLayout';

import IconVidal from '../images/iconVidal.webp'
import PrestadorConfigContext from '../context/PrestadorConfigContext';
import { faStar, faUserInjured } from '@fortawesome/free-solid-svg-icons';


const AppMenu = (props: {onMenuOptionSelect: (param: MenuInfo, extendedProps: TabExtendedProps) => void}) => {

    const { t } = useTranslation(['menu']);
    const prescriptionOption = MenuConfig.getConfigById('prescriptions');
    const newPrescriptionOption = MenuConfig.getConfigById('newPrescription');
    const vidalVademecumOption = MenuConfig.getConfigById('vidalVademecum');
    const favPrescriptionOption = MenuConfig.getConfigById('favPrescriptions');
    const patientListOption = MenuConfig.getConfigById('listaPacientes');

    const ctxValue = useContext(PrestadorConfigContext);

    const vidalEnabled = ctxValue.data.vidalEnabled;

    const token = ctxValue.data.vidalAppToken;
    
    const onMenuOptionSelect = (param: MenuInfo) => {
        props.onMenuOptionSelect(param, MenuConfig.getConfigById(param.key as string));
    }
    
    return (
        <>
            <Menu mode='inline' style={{background: '#F0F2F5'}}>
                <Menu.Item onClick={menuInfo => onMenuOptionSelect(menuInfo)} key={newPrescriptionOption.id} icon={<FontAwesomeIcon icon={faPlus}  style={{width: '0.9rem'}}/>}>
                    {t(newPrescriptionOption.menuCaption)}
                </Menu.Item>
                <Menu.Item onClick={menuInfo => onMenuOptionSelect(menuInfo)} key={prescriptionOption.id} icon={<FontAwesomeIcon icon={faPrescription} style={{width: '0.9rem'}}/>}>
                    {t(prescriptionOption.menuCaption)}
                </Menu.Item>
                <Menu.Item onClick={menuInfo => onMenuOptionSelect(menuInfo)} key={favPrescriptionOption.id} icon={<FontAwesomeIcon icon={faStar} style={{width: '0.9rem'}}/>}>
                    {t(favPrescriptionOption.menuCaption)}
                </Menu.Item>
                {<Menu.Item onClick={menuInfo => onMenuOptionSelect(menuInfo)} key={patientListOption.id} icon={<FontAwesomeIcon icon={faUserInjured} style={{width: '0.9rem'}}/>}>
                    {t(patientListOption.menuCaption)}
                </Menu.Item>}
                {vidalEnabled &&
                <Menu.Item className='menu-vidal-option' onClick={()=>{window.open("https://es.vidal-consult.com/?tk="+token)}} key={vidalVademecumOption.id} 
                        icon={<img src={IconVidal} width={24} height={24}/>}>
                    {t(vidalVademecumOption.menuCaption)}
                </Menu.Item>
                }
            </Menu>
            
            
        </>
    );
}

export default AppMenu;