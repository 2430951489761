import React, { ReactNode } from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { concatName, Rest, TableUtils } from '../../../utils/utils';
import { FormInstance } from 'antd/lib/form';
import { MainTableBodyProps } from '../../../utils/tableUtils'
import { TabExtendedProps } from '../../../MainLayout';
import View from './infoPaciente';
import FilterData from './../../entities/filterData';
import PatientData from '../entities/patientData';
import GetPacientesResult from '../../entities/getPacientesResult';
import MenuConfig, { COMPONENT_IDS } from '../../../layout/menuConfig';
import PatientDataDetails from '../entities/patientDataDetails';
import PersonaSelected from '../entities/personaSelected';
import TutorData from '../entities/tutorData';
import { PatologiaData } from '../entities/patologiaData';
import { AlergieData } from '../entities/alergieData';
import PrescripcionData from '../../entities/prescripcionData';
import PatientCommonContainer from '../PatientCommonContainer';
import AditionalPatientInfoContainer from '../aditionalPatientInfoContainer';
import NewPrescriptionsContainer from '../../newPrescriptionsContainer';
import AlergiesSelectContainer from '../alergiesSelectContainer';
import PatologiesSelectContainer from '../patologiesSelectContainer';
import { Modal } from 'antd';
import { concat } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { ExclamationCircleFilled } from '@ant-design/icons';


interface IProps {
    addTab?: (tabCaption: string, component: ReactNode, extendedProps?: TabExtendedProps) => void;
    closeTab?: () => void;
    idPaciente?: number;
    hideOptions?: boolean;
}

export interface IState extends MainTableBodyProps {
    patientInfo?: PatientData;
    alergiasList?: AlergieData[];
    patologiasList?: PatologiaData[];
    prescriptionListAct?: PrescripcionData[];
    prescriptionListFin?: PrescripcionData[];
    tutor?: TutorData;
    dataCount: number;
    limit: number;
    page?: number;
    idPrescripcion?: number;
    lastFilter?: any;
    filterKey?: number;
    filtersCollapsed?: boolean;
    highlightName?: string;
    highlightPrescriptionName?: string;
    prescriptionFilter?: { value: string }[];
    nFilters?: number;
    selectedPatient?: PersonaSelected;
    keyEditInfoPatient?: number;
    visibleEditInfoPatient?: boolean;
    aditionalPatientInfoKey?: number;
    aditionalPatientInfoContainer?: boolean;
    flagInfo?: number;
    aditionalInfoVisible?: boolean;
    addAlergiesVisible?: boolean;
    addAlergiesViewKey?: number;
    addPatologiesViewKey?: number;
    addPatologiesVisible?: boolean;
    confirmacionModal?: boolean;

}

class informacionPacienteContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public state: IState = {
        limit: 15,
        dataCount: 0,
        filtersCollapsed: true,

    };

    public render() {

        return (
            <>

                <View
                    {...this.state}
                    refresh={this.refresh}
                    onChangePage={this.onChangePage}
                    idPaciente={this.props.idPaciente}
                    patientInfo={this.state.patientInfo}
                    alergiasList={this.state.alergiasList}
                    patologiasList={this.state.patologiasList}
                    prescriptionListAct={this.state.prescriptionListAct}
                    prescriptionListFin={this.state.prescriptionListFin}
                    onclickEditInfoPatient={this.onclickEditInfoPatient}
                    onclickDatosSalud={this.onclickDatosSalud}
                    onClickPatientNewPrescription={this.onClickPatientNewPrescription}
                    onClickDetail={this.onClickDetail}
                    onAddAlergies={this.onAddAlergies}
                    onAddPatologies={this.onAddPatologies}
                    deleteAlergia={this.deleteAlergia}
                    deletePatologia={this.deletePatologia}
                    deletePaciente={this.deletePaciente}


                />
                <PatientCommonContainer

                    onClose={this.onCloseEditPatientInfo
                    } addPatientSelected={() => { }

                    } visible={this.state.visibleEditInfoPatient}
                    key={this.state.keyEditInfoPatient}
                    editPatient={true}
                    idPatient={this.props.idPaciente}
                    patientSelected={this.state.patientInfo}

                />
                <AditionalPatientInfoContainer
                    key={this.state.aditionalPatientInfoKey}
                    visible={this.state.aditionalPatientInfoContainer}
                    patientSelected={this.props.idPaciente!}
                    onClose={this.oncloseDatosSalud}
                    flagFromInfoPatient={this.state.flagInfo}
                />
                <Modal title={""}
                    className={"aditionalInfoModal"}
                    style={{ top: 40 }}
                    visible={(this.state.addAlergiesVisible || this.state.addPatologiesVisible)}
                    onCancel={() => this.setState({ addAlergiesVisible: false, addPatologiesVisible: false })}
                    bodyStyle={{ paddingTop: '0.5rem', paddingBottom: '1.5rem' }}
                    width={700}
                    okText={this.props.t('buttons:save')} cancelText={this.props.t('buttons:cancel')}
                    destroyOnClose
                    okButtonProps={(this.state.addAlergiesVisible || this.state.addPatologiesVisible) ? { style: { display: 'none' } } : {}}

                >
                    {this.state.addAlergiesVisible && <AlergiesSelectContainer
                        key={this.state.addAlergiesViewKey}
                        visible={this.state.addAlergiesVisible}
                        onClose={this.onCloseAddAlergies}
                    />}

                    {this.state.addPatologiesVisible && <PatologiesSelectContainer
                        key={this.state.addPatologiesViewKey}
                        fromDiagnostico={false}
                        visible={this.state.addPatologiesVisible}
                        onClose={this.onCloseAddPatologies}
                    />}
                </Modal>

                <Modal title={""}
                    className={"aditionalInfoModal"}
                    style={{ top: 40 }}
                    visible={this.state.confirmacionModal}
                    onCancel={() => this.setState({ confirmacionModal: false })}
                    bodyStyle={{ paddingTop: '0.5rem', paddingBottom: '1.5rem' }}
                    width={400}
                    okText={this.props.t('buttons:save')} cancelText={this.props.t('buttons:cancel')}
                    destroyOnClose
                    okButtonProps={!(this.state.confirmacionModal) ? { style: { display: 'none' } } : {}}
                    onOk={() => {
                        this.refresh();
                        this.setState({ confirmacionModal: false });
                    }}

                >
                    <div style={{ textAlign: 'center', marginTop:'1rem' }}>
                        <strong><span><FontAwesomeIcon icon={faExclamationCircle} style={{ color:'orange', marginLeft: '0.075rem', marginRight: '0.58rem', marginTop:'0.5rem' }} />¿Está seguro de añadirlo?</span></strong>
                    </div>
                </Modal>
                
            </>
        );
    }

    private onClickPatientNewPrescription = (idPaciente: number) => {
        if (this.props.addTab) {
            this.props.addTab(this.props.t('menu:newPrescription'), <NewPrescriptionsContainer idPaciente={idPaciente} closeTab={this.props.closeTab} addTab={this.props.addTab} hideOptions={this.props.hideOptions} refresh={this.refresh} pending={true} />,  MenuConfig.getConfigById(COMPONENT_IDS.listaPacientesContainer));
        }
    }

    public componentDidMount() {
        this.loadData();
    }

    private deletePaciente = (idPaciente: number) => {
        Rest<{ type: string, idPaciente: number }, number>().operation({
            type: 'DeletePatient',
            idPaciente: idPaciente,
        }).then(() => {
            this.refresh();
        })
    }

    private onCloseAddAlergies = (alergie?: AlergieData) => {
        var listaAlergias: number[] = [];

        Rest<{ type: string, idPaciente: number, newAlergies: number[], deleteAlergies: number[], newPatologies: number[], deletePatologies: number[], flagFromInfoPatient: number }, number>().operation({
            type: 'UpdateAditionalPatientInfo',
            idPaciente: this.props.idPaciente!,

            newAlergies: listaAlergias.concat(alergie!.idAlergia!),
            deleteAlergies: [],
            newPatologies: [],
            deletePatologies: [],
            flagFromInfoPatient: 2,
        }).then(() => {
            this.setState({ addAlergiesVisible: false, addPatologiesVisible: false})
            this.refresh();
        })
    }

    private onAddAlergies = () => {
        this.setState({ addAlergiesVisible: true, addAlergiesViewKey: new Date().getTime() })
    }

    private onCloseAddPatologies = (patologia?: PatologiaData) => {

        var listaPatologias: number[] = [];
        Rest<{ type: string, idPaciente: number, newAlergies: number[], deleteAlergies: number[], newPatologies: number[], deletePatologies: number[], flagFromInfoPatient: number }, number>().operation({
            type: 'UpdateAditionalPatientInfo',
            idPaciente: this.props.idPaciente!,

            newAlergies: [],
            deleteAlergies: [],
            newPatologies: listaPatologias.concat(patologia!.idCie10!),
            deletePatologies: [],
            flagFromInfoPatient: 3,
        }).then(() => {
            this.setState({ addAlergiesVisible: false, addPatologiesVisible: false})
            this.refresh();
        })
    }

    private onAddPatologies = () => {
        this.setState({ addPatologiesVisible: true, addPatologiesViewKey: new Date().getTime() })
    }














    private deleteAlergia = (alergie?: AlergieData) => {
        if (!alergie) return;

        const listaAlergias: number[] = [alergie.idAlergia!];

        Rest<{
            type: string;
            idPaciente: number;
            newAlergies: number[];
            deleteAlergies: number[];
            newPatologies: number[];
            deletePatologies: number[];
            flagFromInfoPatient: number;
        }, number>().operation({
            type: 'UpdateAditionalPatientInfo',
            idPaciente: this.props.idPaciente!,
            newAlergies: [],
            deleteAlergies: listaAlergias,
            newPatologies: [],
            deletePatologies: [],
            flagFromInfoPatient: 2,
        }).then(() => {
            this.setState({ addAlergiesVisible: false, addPatologiesVisible: false });
            this.refresh();
        });
    }

    private deletePatologia = (patologie?: PatologiaData) => {
        if (!patologie) return;

        const listaPatologias: number[] = [patologie.idCie10!];

        Rest<{
            type: string;
            idPaciente: number;
            newAlergies: number[];
            deleteAlergies: number[];
            newPatologies: number[];
            deletePatologies: number[];
            flagFromInfoPatient: number;
        }, number>().operation({
            type: 'UpdateAditionalPatientInfo',
            idPaciente: this.props.idPaciente!,
            newAlergies: [],
            deleteAlergies: [],
            newPatologies: [],
            deletePatologies: listaPatologias,
            flagFromInfoPatient: 3,
        }).then(() => {
            this.setState({ addAlergiesVisible: false, addPatologiesVisible: false });
            this.refresh();
        });
    }

























    private loadData() {
        console.log("load");
        Rest<{ type: string, idPaciente?: number }, any>().operation({
            type: 'GetCompletePatientDetail',
            idPaciente: this.props.idPaciente,

        }).then(response => {

            this.setState({ patientInfo: response.infoPaciente });
            this.setState({ alergiasList: response.alergias });
            this.setState({ patologiasList: response.patologias });
            this.setState({ prescriptionListAct: response.prescripcionesActivas });
            this.setState({ prescriptionListFin: response.prescripcionesFinalizadas });
        });
    }

    private onChangePage = (page: number) => {
        this.setState({ page: page });
        this.loadData();
    }

    private refresh = () => {
        this.setState({ page: 1 }, () => this.loadData());
    }

    private onclickEditInfoPatient = () => {
        this.setState({ keyEditInfoPatient: new Date().getTime(), visibleEditInfoPatient: true })
    }

    private onCloseEditPatientInfo = () => {
        this.loadData();
    }

    private onclickDatosSalud = (flagInfo: number) => {
        this.setState({ aditionalPatientInfoKey: new Date().getTime(), aditionalPatientInfoContainer: true, flagInfo: flagInfo })
    }

    private oncloseDatosSalud = (flagEdited?: boolean) => {
        this.setState({ aditionalPatientInfoContainer: false, flagInfo: undefined })
        if (flagEdited) {
            this.loadData();
        }


    }

    private onClickDetail = (idPrescripcionGrupo: number, nombrePaciente: string, pending: boolean) => {
        if (this.props.addTab) {

            const tabCaption = `${nombrePaciente}: ${idPrescripcionGrupo}`;
            const component = (
                <NewPrescriptionsContainer
                    idPrescripcionGrupo={idPrescripcionGrupo}
                    addTab={this.props.addTab}
                    closeTab={this.props.closeTab}
                    pending={pending}
                    hideOptions={this.props.hideOptions}
                />
            );
            const extendedProps = MenuConfig.getConfigById(COMPONENT_IDS.newPrescriptionContainer);

            this.props.addTab(tabCaption, component, extendedProps);
        }
    }

}

export default withTranslation('InformacionPaciente')(withRouter(informacionPacienteContainer));  
