import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import View from './selectTutor';
import {concatName, Rest, validateDNI} from '../../utils/utils';
import TutorSelected from './entities/tutorSelected';
import TutorData from './entities/tutorData';
import {Store} from 'antd/lib/form/interface';
import EnumCodeIdentificationType from "../../enum/EnumCodeIdentificationType";

interface IProps {
    tutorSelectVisible?: boolean;
    noResult?: boolean;
    onCloseSelectTutor: () => void;
    tutorSelected: (selectedData: TutorData) => void;
    newTutor: (tutor?: TutorSelected) => void;
    setNoResultValue: (result: boolean) => void;
}

export interface IState {
    resultTutor?: TutorSelected;
    newTutorButtonVisible?: boolean;
    errorMessage?: string;
}

class SelectTutorContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    public state: IState = {};

    public render() {
        return (
            <View
                {...this.state}
                noResult={this.props.noResult}
                visible={this.props.tutorSelectVisible}
                searchTutor={this.searchTutor}
                onCancel={this.props.onCloseSelectTutor}
                newTutor={this.props.newTutor}
                selectTutor={this.selectTutor}
            />
        );
    }

    private searchTutor = (values: Store) => {

        let ciTutor: string = values.codeIdentificacion ? values.codeIdentificacion.trim() : '';

        if (ciTutor.length < 6) {
            this.props.setNoResultValue(true);
            this.setState({resultTutor: undefined, newTutorButtonVisible: false, errorMessage: this.props.t('incorrectNif')});
            return;
        }

        if ([EnumCodeIdentificationType.DNI, EnumCodeIdentificationType.NIE].includes(values.typeDocTutor) &&
                !validateDNI(ciTutor,  values.typeDocTutor ===  EnumCodeIdentificationType.NIE)) {
            this.props.setNoResultValue(true);
            this.setState({resultTutor: undefined, newTutorButtonVisible: false, errorMessage: this.props.t('incorrectNif')});
            return;
        }

        Rest<{ type: string; codeIdentificacion: string }, any>().operation({
            type: 'SelectTutorDataByNameAndCi',
            codeIdentificacion: values.codeIdentificacion,
        }, true).then(response => {
            if (response) {
                this.props.setNoResultValue(false);
                this.setState({
                    resultTutor: {
                        ...response,
                        namePersona: concatName(response.nameNombre, response.nameApellido1, response.nameApellido2)
                    }
                });
            } else {
                this.props.setNoResultValue(true);
                this.setState( {resultTutor: undefined,  newTutorButtonVisible: true, errorMessage: this.props.t('seleccionarNingunResultado')});
            }
        });
    }

    private selectTutor = () => {

        const tutor = this.state.resultTutor!;

        if (typeof tutor.idTutor !== "undefined") {
            this.props.tutorSelected(tutor);
        } else { // Create new patient from the existing person
            this.props.newTutor(this.state.resultTutor!);
        }
    }
}

export default withTranslation('newPatientPrescription')(withRouter(SelectTutorContainer));