import React, { ReactNode } from 'react';

import PrescriptionsListContainer from '../prescriptions/prescriptionsListContainer';
import NewPrescriptionsContainer from '../prescriptions/newPrescriptionsContainer';
import ListaPacientesContainer from '../prescriptions/patient/listadoPacientes/listaPacientesContainer';
import { TabExtendedProps } from '../MainLayout';
import FavPrescriptionListContainer from '../prescriptions/favoritePrescriptions/FavPrescriptionListContainer';
import InformacionPacienteContainer from '../prescriptions/patient/listadoPacientes/informacionPacienteContainer';

export interface MenuOptionConfig {
    id: string;
    menuCaption: string;
    tabCaption: string;
    closable: boolean;
}

export enum COMPONENT_IDS {
    prescriptionsListContainer = 'prescriptions',
    newPrescriptionContainer = 'newPrescription',
    listaPacientesContainer = 'listaPacientes',
    infoPacienteContainer = 'infoPaciente',
    vidalVademecumConsult = 'vidalVademecum',
    homePageContainer = 'homePage',
    favPrescriptionListContainer = 'favPrescriptions',
    formulario = 'formulario',
}


export default {
    menuConfig: [
        {id: COMPONENT_IDS.prescriptionsListContainer, menuCaption: 'menu:prescriptionList', tabCaption: 'menu:prescriptionList', closable: true},
        {id: COMPONENT_IDS.listaPacientesContainer, menuCaption: 'menu:listaPacientes', tabCaption: 'menu:listaPacientes', closable: true},
        {id: COMPONENT_IDS.infoPacienteContainer, menuCaption: 'menu:info paciente', tabCaption: 'menu:info paciente', closable: true},
        {id: COMPONENT_IDS.newPrescriptionContainer, menuCaption: 'menu:newPrescription', tabCaption: 'menu:newPrescription', closable: true},
        {id: COMPONENT_IDS.vidalVademecumConsult, menuCaption: 'menu:vidalVademecum', tabCaption: 'menu:vidalVademecum'},
        {id: COMPONENT_IDS.homePageContainer, menuCaption: 'menu:homePage', tabCaption: 'menu:homePage', closable: false},
        {id: COMPONENT_IDS.favPrescriptionListContainer, menuCaption: 'menu:favPrescriptionList', tabCaption: 'menu:favPrescriptionList', closable: true},
    ] as MenuOptionConfig[],

    getConfigById(id: string) {
        const menuConfig = this.menuConfig.find(c => c.id === id);
        if (menuConfig) {
            return menuConfig;
        } else {
            return {id: id, menuCaption: 'Not implemented yet ' + id, tabCaption: 'Not implemented yet ' + id};
        }
    },

    getComponentById(id: string, addTab?: (tabCaption: string, component: ReactNode, extendedProps?: TabExtendedProps) => void, closeActiveTab?: () => void) {

        switch(id) {
            case COMPONENT_IDS.prescriptionsListContainer:
                return <PrescriptionsListContainer addTab={addTab} closeTab={closeActiveTab}/>
            case COMPONENT_IDS.listaPacientesContainer:
                return <ListaPacientesContainer addTab={addTab} closeTab={closeActiveTab}/>
            case COMPONENT_IDS.infoPacienteContainer:
                return <InformacionPacienteContainer addTab={addTab} closeTab={closeActiveTab}/>
            case COMPONENT_IDS.newPrescriptionContainer:
                return <NewPrescriptionsContainer addTab={addTab} closeTab={closeActiveTab} pending/>
            case COMPONENT_IDS.favPrescriptionListContainer:
                return <FavPrescriptionListContainer addTab={addTab} closeTab={closeActiveTab}/>
            default:
                return <div/>;
        }
    }
}
