import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Rest } from "../utils/utils";
import RenovacionLicenciaData from "./entities/renovacionLicenciaData";
import SubscriptionDetail from "./SubscriptionDetail";
import { URL as _URL} from '../utils/rest';
import { notification } from "antd";

interface IProps {
    visible?: boolean;
    onClose: () => void;
}

export interface IState {
    renovacionLicenciaData?: RenovacionLicenciaData;
    loaded: boolean;
}
class SubscriptionDetailContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    state: IState = {
        loaded:false
    };

    public componentDidMount() {
        if (this.props.visible) {
            this.loadData();
        }
    }

    private loadData = () => {
        Rest<{type: string}, RenovacionLicenciaData >().operation(
            {type: 'GetRenovacionLicenciaData'}).then(response =>
                this.setState({renovacionLicenciaData: response,loaded:true})
        );
    }

    private downloadBill = (idLicencia: string) => {
        const fileUrl = _URL + '/file?inv&idLicencia=' + idLicencia;

        fetch(fileUrl)
            .then((response) => {
                if (response.ok) {
                    notification['success']({ 
                        message: this.props.t('invoiceDownload'),
                        duration: 5
                    });
                    return response.blob();
                } else {
                    notification['info']({ 
                        message: (this.props.t('invoiceState')),
                        duration : 5
                    });
                }
            })
            .then((blob) => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob!);
                link.download = idLicencia + '.pdf';
                link.click();
                URL.revokeObjectURL(link.href);
                link.remove();
            })
            .catch((error) => {
                console.error('An error occurred during the download:', error);
            });    
    }
    public render() {
        return (<>
            {this.state.loaded && <SubscriptionDetail
                renovacionLicenciaData={this.state.renovacionLicenciaData}
                visible={this.props.visible}
                onClose={this.props.onClose}
                downloadBill={this.downloadBill}
            />}
            </>
        );
    };

}

export default withTranslation('subscriptionDetail')(withRouter(SubscriptionDetailContainer));
