import React from 'react';

import { Modal } from 'antd';


const MedicineVmpModal = (props: {
  visible?: boolean;
  onCancel: () => void;
  url?: string;
}) => {

    return (
        <Modal
                style={{ top: 40 }}
                visible={props.visible}
                onCancel={() => props.onCancel()}
                footer={null}
                className='modal-vidal-vmp'
                width={window.innerWidth - 180}
                destroyOnClose>
            <div className='modal-vidal-vmp-iframe-wrapper'>
                <iframe frameBorder={0} src={props.url!} />
            </div>
        </Modal>
    )

}
export default MedicineVmpModal;