import React, {useEffect} from 'react';

import {useTranslation} from 'react-i18next';
import {Form, Button, Row, Col, Select, Alert, Input} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Modal from 'antd/lib/modal/Modal';
import TutorSelected from './entities/tutorSelected';
import {faUserTie} from '@fortawesome/free-solid-svg-icons';
import {Store} from 'antd/lib/form/interface';
import {IState as IStateContainer} from './selectTutorContainer';
import {Option} from "rc-select";
import EnumCodeIdentificationType from "../../enum/EnumCodeIdentificationType";

interface IProps extends IStateContainer {
    visible?: boolean;
    noResult?: boolean;
    tutorOptions?: TutorSelected[];
    tutorSelected?: TutorSelected;
    searchTutor: (values: Store) => void;
    onCancel: (saved: boolean) => void;
    newTutor: (tutor: TutorSelected) => void;
    selectTutor: () => void;
    key?: number;
}

const SelectTutor = (props: IProps) => {

    const {t} = useTranslation(['newTutorPrescription']);
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form, props.key]);

    const footer = (
        <Row justify='space-between'>
            <Col>
                <Button type='default' style={{cursor: 'pointer'}} onClick={() => props.onCancel(false)}>
                    {t('buttons:cancel')}
                </Button>
            </Col>
        </Row>
    )

    const typeDocList = [
        <Option key={EnumCodeIdentificationType.DNI}
                value={EnumCodeIdentificationType.DNI}>{t('codeIdentificationType:' + EnumCodeIdentificationType.DNI)}</Option>,
        <Option key={EnumCodeIdentificationType.NIE}
                value={EnumCodeIdentificationType.NIE}>{t('codeIdentificationType:' + EnumCodeIdentificationType.NIE)}</Option>,
        <Option key={EnumCodeIdentificationType.PASAPORTE}
                value={EnumCodeIdentificationType.PASAPORTE}>{t('codeIdentificationType:' + EnumCodeIdentificationType.PASAPORTE)}</Option>
    ];

    const typeDocTutor = (
        <Form.Item noStyle name='typeDocTutor' rules={[{required: true}]}>
            <Select dropdownStyle={{minWidth: '8rem'}}>
                {typeDocList}
            </Select>
        </Form.Item>);

    const initialValues = {
        typeDocTutor: EnumCodeIdentificationType.DNI,
    }


    return (
        <Modal title={t('selectTutorTitle')}
               style={{top: 40}}
               visible={props.visible}
               onCancel={() => props.onCancel(false)}
               bodyStyle={{paddingTop: '0.5rem', paddingBottom: '1rem'}}
               width={500}
               footer={footer}
               destroyOnClose>
            <Form layout='vertical' form={form} size='middle' onFinish={props.searchTutor}
                  initialValues={initialValues}>
                <Row gutter={16} wrap={false} align='bottom' style={{marginBottom: '1rem'}}>
                    <Col span={15}>
                        <Form.Item name='codeIdentificacion' label={t('selectTutorNIF')} style={{marginBottom: 0}}>
                            <Input addonBefore={typeDocTutor}/>
                        </Form.Item></Col>
                    <Col><Button type='primary' htmlType='submit'>{t('seleccionarBuscar')}</Button></Col>
                </Row>
            </Form>
            {props.resultTutor &&
            <Alert type='info' message={null} description={
                <Row gutter={16} wrap={false} align='middle'>
                    <Col style={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>{props.resultTutor.codeIdentificacion}</Col>
                    <Col flex='1 1 100%'>
                        {props.resultTutor.namePersona}
                    </Col>
                    <Col><Button type='default' size='small' className={'select__button'}
                                 onClick={() => props.selectTutor()}>{t('seleccionarBoton')}</Button></Col>
                </Row>}/>}
            {props.noResult &&
            <Alert type='error' message={
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        {props.errorMessage}
                        {props.newTutorButtonVisible && <Button type='primary' icon={<FontAwesomeIcon icon={faUserTie} style={{ marginRight: '0.5rem', cursor: 'pointer' }} />} 
                                onClick={() => props.newTutor({
                                    typeIdentificacion: form.getFieldValue('typeDocTutor'),
                                    codeIdentificacion: form.getFieldValue('codeIdentificacion')} as TutorSelected)}>
                            {t('newTutorButton')}
                        </Button>}
                    </div>
                } style={{marginTop: '1rem'}} />}
        </Modal>
    )
}
export default SelectTutor;