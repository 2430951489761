import React, {ReactNode} from 'react';

import {withTranslation, WithTranslation} from 'react-i18next';
import {TabExtendedProps} from '../MainLayout';
import {Rest} from '../utils/utils';
import AppHeader from './AppHeader';
import PrestadorPrescripcionData from './entities/PrestadorPrescripcionData';
import PrescriptionsListContainer from '../prescriptions/prescriptionsListContainer';
import EventHub, {Event} from '../utils/eventHub';
import MenuConfig, {COMPONENT_IDS} from '../layout/menuConfig';
import SelectLocationContainer from '../prescriptions/locations/selectLocationContainer';
import {LocationPrestador} from '../prescriptions/locations/entities/locationPrestador';
import LocationSelected from '../prescriptions/locations/entities/locationSelected';
import NewLocationContainer from '../prescriptions/locations/newLocationContainer';
import {HeaderInfo} from './entities/HeaderInfo';
import SubscriptionDetailContainer from '../subscription/SubscriptionDetailContainer';

export interface Props {
    logout: () => void;
    changePassword: () => void;
    otpAuthentication: () => void;
    addTab?: (tabCaption: string, component: ReactNode, extendedProps?: TabExtendedProps) => void;
    closeTab?: () => void;
    prestadorPrescripcionData?: PrestadorPrescripcionData;
}

export interface IState {
    prestadorData?: PrestadorPrescripcionData;
    nPrescripcionesBloqueadas?: number;
    selectLocationKey?: number;
    newLocationVisible?: boolean;
    modeNewLocation?: boolean;
    newLocationKey?: number;
    dateSuscription?: string;
    allowRenovacion?: boolean;

    //
    idSelectedLocation?: number;
    selectLocationVisible: boolean;
    selectedLocation?: LocationPrestador;
    locationForEdit?: LocationPrestador;

    subscriptionDetailVisible: boolean;
    subscriptionDetailKey?: number;
}

class AppHeaderContainer extends React.Component<Props & WithTranslation> {

    state: IState = {
        selectLocationVisible: false,
        subscriptionDetailVisible: false
    };

    public componentDidMount() {
        this.loadData();
    }

    private loadData() {
        Rest<{ type: string; }, HeaderInfo>().operation({
            type: 'GetHeaderInfo',
        }).then(response => {
            this.setState({
                prestadorData: response.prestadorData,
                selectedLocation: response.locationSelected,
                idSelectedLocation: response.locationSelected?.idLocalizacion,
                nPrescripcionesBloqueadas: response.nPrescripcionesBloqueadas,
                dateSuscription: response.dateHasta? response.dateHasta.toLocaleDateString(undefined,{ year: 'numeric', month: '2-digit', day: '2-digit'}): undefined,
                allowRenovacion: response.allowRenovacion,
            });
        });
    }

    private onCloseSelectLocation = () => {
        this.setState({selectLocationVisible: false});
    };

    onSelectLocation = (selectedLocation: LocationPrestador) => {
        Rest<{ type: string, idLocalization: number }, LocationSelected>()
            .operation({
                type: 'GetAndUpdateLocationDataSelected',
                idLocalization: selectedLocation.idLocalizacion,
            }).then(() => {

            this.setState({
                idSelectedLocation: selectedLocation.idLocalizacion, selectLocationVisible: false,
                selectedLocation: selectedLocation,
            });

        });
    };


    private onClickSelectLocation = () => {
        this.setState({
            selectLocationVisible: true,
            selectLocationKey: new Date().getTime(),
            idSelectedLocation: this.state.idSelectedLocation,
        });

    };

    onEditLocation = (location: LocationPrestador) => {
        this.setState({
            newLocationVisible: true, modeNewLocation: false, locationForEdit: location,
            newLocationKey: new Date().getTime(), selectLocationVisible: false,
        });

    };

    private newLocation = () => {
        this.setState({
            locationForEdit: undefined,
            selectLocationVisible: false,
            newLocationVisible: true,
            newLocationKey: new Date().getTime(),
            modeNewLocation: true,
        });
    };


    private reloadSelectedLocation = (idLocalizacion: number) => {
        Rest<{ type: string, idLocalizacion: number }, LocationSelected>()
            .operation({
                type: 'GetLocationDataSelected',
                idLocalizacion: idLocalizacion,
            }).then(response => {
            if (idLocalizacion === this.state.idSelectedLocation) {
                this.setState({selectedLocation: response, newLocationVisible: false});
            } else {
                this.setState({newLocationVisible: false});
            }
        });
    };

    public render() {

        return this.state ?
            <>
                <AppHeader
                    {...this.state}
                    logout={this.props.logout}
                    changePassword={this.props.changePassword}
                    otpAuthentication={this.props.otpAuthentication}
                    onClickBlocked={this.onClickBlocked}
                    onClickSelectLocation={this.onClickSelectLocation}
                    onClickOpenDetalleSuscripcion = {this.onClickOpenDetalleSuscripcion}
                    selectedLocation={this.state.selectedLocation}
                />

                <SelectLocationContainer
                    key={this.state.selectLocationKey}
                    visible={this.state.selectLocationVisible}
                    onClose={this.onCloseSelectLocation}
                    newLocation={this.newLocation}
                    onSelectLocation={this.onSelectLocation}
                    idSelectedLocation={this.state.idSelectedLocation}
                    onEditLocation={this.onEditLocation}

                />
                <NewLocationContainer
                    key={this.state.newLocationKey}
                    visible={this.state.newLocationVisible}
                    modeNewLocation={this.state.modeNewLocation ? this.state.modeNewLocation : false}
                    locationForEdit={this.state.locationForEdit}
                    addNewLocation={this.addOrUpdateLocation}
                    onClose={this.onCloseNewLocation}
                    reloadSelectedLocation={this.reloadSelectedLocation}
                />

                <SubscriptionDetailContainer
                    key={this.state.subscriptionDetailKey}
                    visible={this.state.subscriptionDetailVisible}
                    onClose={this.onCloseOpenDetalleSuscripcion}
                />
            </>

            : null;
    }


    private onCloseNewLocation = () => {
        this.setState({
            selectLocationVisible: this.state.modeNewLocation,
            selectLocationKey: new Date().getTime(),
            newLocationVisible: false,
        }, () => this.loadData());
    };

    private addOrUpdateLocation = () => {
        this.onCloseNewLocation();
    };


    private onClickBlocked = () => {
        if (this.props.addTab) {
            EventHub.trigger(Event.LOAD_BLOCKED_PRESCRIPTIONS);
            this.props.addTab(this.props.t('menu:prescriptionList'), <PrescriptionsListContainer
                addTab={this.props.addTab}
                blocked/>, MenuConfig.getConfigById(COMPONENT_IDS.prescriptionsListContainer));
        }
    };

    private onClickOpenDetalleSuscripcion = () => {
        this.setState({subscriptionDetailKey: new Date().getTime(),subscriptionDetailVisible: true})
    }

    private onCloseOpenDetalleSuscripcion = () => {
        this.setState({subscriptionDetailVisible: false});
    }

}

export default withTranslation()(AppHeaderContainer);