import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Input, Checkbox, Button, Select, Table, Space } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { IState as IStateContainer } from './aditionalPatientInfoContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { AlergieData } from './entities/alergieData';
import { AlignType } from 'rc-table/lib/interface';
import i18n from '../../i18n/i18n';
import { PatologiaData } from './entities/patologiaData';

const weekData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];

const NewAditionalPatientInfo = (props: {
    visible: boolean;
    onCancel: () => void;
    onSubmit: (form: FormInstance) => void;
    onAddAlergies: (form: FormInstance) => void;
    unmarkToDeleteAlergies?: (idAlergia: number) => void;
    addToDeleteAlergies?: (idAlergia: number) => void;
    onAddPatologies: (form: FormInstance) => void;
    unmarkToDeletePatologies?: (idCie10: number) => void;
    addToDeletePatologies?: (idCie10: number) => void;
    asignateForm: (form: FormInstance) => void;
    flagFromInfoPatient?: number;
} & IStateContainer) => {

    const { t } = useTranslation(['newPatientPrescription']);
    const [form] = Form.useForm();
    const initialValues = ({ altura: props.altura, peso: props.peso, lactancia: props.lactancia, embarazo: props.embarazo ? props.embarazo : false, semanas: props.embarazo == 0 ? undefined : props.semanas });


    const alergiesNameRenderer = (value: any, record: AlergieData) => {
        if (i18n.language.substring(0, 2) == "es") {

            if (record.nameAlergiaEs != undefined && record.nameAlergiaEs != null && record.nameAlergiaEs != "") {
                return <div className='tableNameDiv'>{record.nameAlergiaEs!}</div>;
            }
            else {
                return <div className='tableNameDiv'>{record.nameAlergiaEn!}</div>;
            }
        }
        else {
            if (record.nameAlergiaEn != undefined && record.nameAlergiaEn != null && record.nameAlergiaEn != "") {
                return <div className='tableNameDiv'>{record.nameAlergiaEn!}</div>;
            }
            else {
                return <div className='tableNameDiv'>{record.nameAlergiaEs!}</div>;
            }
        }
    }

    const alergiesActionsRenderer = (value: any, record: AlergieData) => {
        return (
            !props.deleteAlergies!.includes(record.idAlergia!) ? <Space size='small'>
                {props.newAlergies!.includes(record.idAlergia!) ? <text className='newText'> {t("new")}</text> : <></>}
                <Button size='small' type='default' onClick={() => props.addToDeleteAlergies!(record.idAlergia!)}>{t('delete')}</Button>
            </Space> :
                <Space size='middle'>
                    <text className='checkedToDeleteText'>{t("checkedToDelete")}</text>
                    <Button size='small' type='default' onClick={() => props.unmarkToDeleteAlergies!(record.idAlergia!)}>{t('anulate')}</Button>
                </Space>
        )
    }

    const alergiesColumns = [
        { title: t('idColumn'), width: "5rem", dataIndex: 'idVidal', align: 'left' as AlignType, render: (value: any, record: AlergieData) => { return record.idVidal } },
        { title: t('alergieColumn'), dataIndex: 'nameAlergia', ellipsis: true, align: 'left' as AlignType, render: alergiesNameRenderer },
        { align: 'right' as AlignType, width: "16rem", render: alergiesActionsRenderer }
    ]

    const patologiesNameRenderer = (value: any, record: PatologiaData) => {
        if (i18n.language.substring(0, 2) == "es") {

            if (record.nameCie10Es != undefined && record.nameCie10Es != null && record.nameCie10Es != "") {
                return <div className='tableNameDiv'>{record.nameCie10Es!}</div>;
            }
            else {
                return <div className='tableNameDiv'>{record.nameCie10En!}</div>;
            }
        }
        else {
            if (record.nameCie10En != undefined && record.nameCie10En != null && record.nameCie10En != "") {
                return <div className='tableNameDiv'>{record.nameCie10En!}</div>;
            }
            else {
                return <div className='tableNameDiv'>{record.nameCie10Es!}</div>;
            }
        }
    }

    const patologiesActionsRenderer = (value: any, record: PatologiaData) => {
        return (
            !props.deletePatologies!.includes(record.idCie10!) ? <Space size='small'>
                {props.newPatologies!.includes(record.idCie10!) ? <text className='newText'> {t("new")}</text> : <></>}
                <Button size='small' type='default' onClick={() => { props.addToDeletePatologies!(record.idCie10!) }}>{t('delete')}</Button>
            </Space> :
                <Space size='small'>
                    <text className='checkedToDeleteText'>{t("checkedToDelete")}</text>
                    <Button size='small' type='default' onClick={() => { props.unmarkToDeletePatologies!(record.idCie10!) }}>{t('anulate')}</Button>
                </Space>
        )
    }


    const patologiesColumns = [
        { title: t('idColumn'), width: "5rem", dataIndex: 'codeCie10', align: 'left' as AlignType, render: (value: any, record: PatologiaData) => { return record.codeCie10 } },
        { title: t('patologyColumn'), dataIndex: 'namePatologie', ellipsis: true, align: 'left' as AlignType, render: patologiesNameRenderer },
        { align: 'right' as AlignType, width: "16rem", render: patologiesActionsRenderer }
    ]

    useEffect(() => {
        props.asignateForm(form);
        
    }, [form])

    return (<>
        {props.visible && <div className='aditionalPatientInfoForm' style={{ padding: '1rem' }}>
            {(props.flagFromInfoPatient == null || props.flagFromInfoPatient != 2 && props.flagFromInfoPatient != 3) &&
                <Form layout='vertical' form={form} size='large' initialValues={initialValues}>

                    <Row gutter={24}>
                        <Col span={24}>
                            <span>
                                <text>{t('aditionalPatientInfoText1')}</text>
                                <b>{t('aditionalPatientInfoText2')}</b>
                                <text>{t('aditionalPatientInfoText3')}</text>
                            </span>
                        </Col>
                    </Row>
                    <div className='alturaPesoDiv'>
                        <Row gutter={24}>
                            <Col span={10}>
                                <Form.Item label={t('height')} name='altura' rules={[]}>
                                    <Input type={'number'} min={'0'} step={'1'} />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label={t('weight')} name='peso' rules={[]}>
                                    <Input type={'number'} min={'0'} step={'1'} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item name='embarazo' valuePropName='checked'>
                                <Checkbox>{t('pregnant')}</Checkbox>
                            </Form.Item>
                        </Col>
                        <Form.Item shouldUpdate noStyle>
                            {() => form.getFieldValue('embarazo') && <Col span={14}>
                                <Form.Item name='semanas' rules={[{ required: true }]}>
                                    <Select
                                        options={weekData.map((week) => ({
                                            label: t('week') + week,
                                            value: week
                                        }))}
                                        placeholder={t('selectWeek')}
                                    />
                                </Form.Item>
                            </Col>}
                        </Form.Item>
                    </Row>
                    <Row gutter={12}>
                        <Col span={6}>
                            <Form.Item name='lactancia' valuePropName='checked'>
                                <Checkbox>{t('lactation')}</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            }
            {(props.flagFromInfoPatient == null || props.flagFromInfoPatient == 2) &&  
                
                <Row gutter={24}>
                    <Col span={24}>
                        <div className='addAlergiesDiv'>
                            {t('alergies')}
                            <Button className='button__addtable' type={'primary'} onClick={() => props.onAddAlergies(form)} icon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: '0.8rem' }} />}></Button>
                        </div>
                    </Col>
                    <Col span={24}>
                        {(props.alergias && props.alergias.length != 0) ?
                            <div className='tableContainer'>
                                <Table
                                    columns={alergiesColumns}
                                    size='small'
                                    dataSource={props.alergias}
                                    rowClassName={() => "tableRow"}
                                    rowKey='idVidal'
                                    pagination={false} />
                            </div> :
                            <div className='emptyAlergiesTable'>
                                <span>{t('noAlergies')}</span>
                            </div>}

                    </Col>
                </Row>
            }
            
            {(props.flagFromInfoPatient == null || props.flagFromInfoPatient == 3) &&
                <Row gutter={24}>
                    <Col span={24}>
                        <div className='addAlergiesDiv'>
                            {t('patologies')}
                            <Button className='button__addtable' type={'primary'} onClick={() => props.onAddPatologies(form)} icon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: '0.8rem' }} />}></Button>
                        </div>
                    </Col>
                    <Col span={24}>
                        {(props.patologias && props.patologias.length != 0) ?
                            <div className='tableContainer'>
                                {<Table
                                    columns={patologiesColumns}
                                    size='small'
                                    dataSource={props.patologias}
                                    rowClassName={() => "tableRow"}
                                    rowKey='codeCie10'
                                    pagination={false} />}
                            </div> :
                            <div className='emptyAlergiesTable'>
                                <span>{t('noPatologies')}</span>
                            </div>}

                    </Col>
                </Row>
            }
        </div>}
    </>
    );
};

export default NewAditionalPatientInfo;