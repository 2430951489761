import { DownOutlined } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import RenovacionLicenciaData from "./entities/renovacionLicenciaData";


export interface IProps {
    visible?: boolean;
    renovacionLicenciaData?: RenovacionLicenciaData;
    onClose: () => void;
    downloadBill: (idLicencia: string) => void;
};

const SubscriptionDetail = (props: IProps) => {
    const { t } = useTranslation(['subscriptionDetail']);

    return ( <Modal
        style={{ top: 40 }}
        title={t('subscriptionDetailTitle')}
        visible={props.visible}
        onCancel={() => props.onClose()}
        closable={true}
        width={500}
        footer={[<Button key='close' type='dashed' onClick={props.onClose}>
        {t('buttons:close')}
    </Button>]}
        destroyOnClose
    >
        <div dangerouslySetInnerHTML={{__html: t('renovacionDateText',{date:props.renovacionLicenciaData?.dateHasta.toLocaleDateString(undefined,{ year: 'numeric', month: '2-digit', day: '2-digit'})})}}>
        </div>
        <div className="suscripcionDetalleDiv">
            {props.renovacionLicenciaData?.allowRenovacion && <div dangerouslySetInnerHTML={{__html: t('renovarPermitidoText',{price:props.renovacionLicenciaData?.amntPrice,date:props.renovacionLicenciaData?.dateHastaRenovacion.toLocaleDateString(undefined,{ year: 'numeric', month: '2-digit', day: '2-digit'})})}}/>}
            <div><Button disabled={!props.renovacionLicenciaData?.allowRenovacion} type="primary" onClick={()=>{window.open(props.renovacionLicenciaData?.linkRenovacion);props.onClose()}}>{t('renovarSuscripcionText')}</Button></div>
            {!props.renovacionLicenciaData?.allowRenovacion && <div dangerouslySetInnerHTML={{__html:t('renovarBloqueadoText')}}/>}
        </div>
        {props.renovacionLicenciaData?.facturas.length!=0 && <div className="facturasTitle">{t("facturasTitle")}</div>}
        <>{props.renovacionLicenciaData?.facturas.map((f)=> <div style={{display:"flex"}}>
            <div>{t("downloadFacturaText",{date:f.dateCreacion.toLocaleDateString(undefined,{ year: 'numeric', month: '2-digit', day: '2-digit'})})}</div>
                <a onClick={() => props.downloadBill(f.idLicencia)} style={{marginLeft:"0.5rem", marginBottom:"1rem"}}>
                    <Space>
                        {t('download')}
                    </Space>
                </a>
                        
            </div>)
        }</>
    </Modal>
    );

}

export default SubscriptionDetail;