import React from 'react';

import {Badge, Col, Row, Dropdown, Menu, Divider, Tooltip, Button} from 'antd';
import { useTranslation } from 'react-i18next';
import { Props as ContainerProps } from './AppHeaderContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../images/logo500.png';
import PrestadorPrescripcionData from './entities/PrestadorPrescripcionData';
import { faBell, faDoorOpen, faExclamationTriangle, faKey, faLock, faUserLock } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import { concatName } from '../utils/utils';
import {LocationPrestador} from '../prescriptions/locations/entities/locationPrestador';

const AppHeader = (props: {
    logout: () => void;
    changePassword: () => void;
    onClickBlocked: () => void;
    onClickSelectLocation: () => void;
    onClickOpenDetalleSuscripcion: () => void;
    prestadorData?: PrestadorPrescripcionData;
    nPrescripcionesBloqueadas?: number;
    dateSuscription?: string;
    allowRenovacion?: boolean;
    selectedLocation?: LocationPrestador;

} & ContainerProps) => {

    const { t } = useTranslation(['header']);


    const handleMenuClick = (e: any) => {
        if (e.key === '1') {
            props.changePassword();
        }
        if (e.key == '2') {
            props.otpAuthentication();
        }
        if (e.key === '3') {
            props.logout();
        }
    }

    const buttonsMenu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key='1' icon={<FontAwesomeIcon icon={faKey} className='anticon' />} > {t('changePassword')} </Menu.Item>
            <Divider orientation='left' style={{ margin: 0 }} />
            <Menu.Item key='2' icon={<FontAwesomeIcon icon={faLock} className='anticon' />}> {t('otpAuthentication')} </Menu.Item>
            <Divider orientation='left' style={{ margin: 0 }} />
            <Menu.Item key='3' icon={<FontAwesomeIcon icon={faDoorOpen} className='anticon'/> }> {t('logout')} </Menu.Item>
        </Menu>
    )


    return (
        <>
            <Row align='middle'>
                <Col flex='1 1 auto'>
                    <div className='header-logo'>
                        <div className='header-logo--inner'>
                            <img src={logo} alt=''/>
                        </div>
                    </div>
                </Col>
                {props.dateSuscription && 
                <Col className='suscription'>
                    <div className='suscription--inner'>
                        <div>{t('suscriptionValidTo')}</div>
                        {<div className={(props.allowRenovacion ? "renovar" : "detalle")}> {props.allowRenovacion && <span><FontAwesomeIcon icon={faExclamationTriangle}/></span>}{props.dateSuscription}</div>}
                        {<Button type='link' onClick={props.onClickOpenDetalleSuscripcion}>{props.allowRenovacion ? t("renovarButtonText") :t("detalleButtonText")}</Button>}
                    </div>
                </Col>}
                <Col className='prestador'>
                    <div className='prestador--inner'>
                        {props.prestadorData && <div><b>{concatName(props.prestadorData.nameNombre, props.prestadorData.nameApellido1, props.prestadorData.nameApellido2)}</b></div>}
                        {props.prestadorData && <div>{`${props.prestadorData.especialidad}  #${props.prestadorData.numeroColegiado}`}</div>}
                        <Button type='link'
                                onClick={props.onClickSelectLocation}
                        >

                            {`${t('localizacion')}: ${props.selectedLocation? props.selectedLocation.nameLocalizacion: t('header:selectLocalization')}`}


                        </Button>
                    </div>
                </Col>
                <Col className={cx('info-bloqueo', {active: props.nPrescripcionesBloqueadas})} onMouseEnter={e => e.currentTarget.classList.add('stop-animation')} onClick={props.onClickBlocked}>
                    <Tooltip title={props.nPrescripcionesBloqueadas === 0 ? t('prescripcionesBloqueadas_zero')
                        : t('prescripcionesBloqueadas', {count: props.nPrescripcionesBloqueadas})}>
                        <Badge count={props.nPrescripcionesBloqueadas} showZero>
                            <div className='icon-container'> <FontAwesomeIcon icon={faBell} /> </div>
                        </Badge>
                    </Tooltip>
                </Col>
                <Col>
                    <div className='header-options--container' style={{lineHeight: '0'}}>
                        <Dropdown overlay={buttonsMenu} trigger={['click']}>
                            <FontAwesomeIcon icon={faUserLock} className='header-options--option'/>
                        </Dropdown>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default AppHeader;