import { notification } from 'antd';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import EnumEmittedPrescriptionAction from '../../enum/EnumEmittedPrescriptionAction';
import { Rest } from '../../utils/utils';
import { PrescripcionToCopy } from '../entities/prescripcionToCopy';
import prescriptionsListContainer from '../prescriptionsListContainer';
import EmittedPrescriptionActions from './EmittedPrescriptionActions';
import EmittedPrescriptionActionsConfirmation from './EmittedPrescriptionActionsConfirmation';

interface IProps {
    visible?: boolean;
    action?: number;
    idPrescriptionGroup?: number;
    onClose: (action?: number, idPrescripcionGrupo?: number) => void;
}

export interface IState {
    loaded?: boolean;
    prescripciones?: PrescripcionToCopy[];
    selectedPrescriptions: number[];
    selectionVisible?: boolean;
    confirmationVisible?: boolean;
    idPrescripcionGrupo?: number;
}

class EmittedPrescriptionActionsContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    public state: IState = {
        selectedPrescriptions: [],
    };

    public componentDidMount() {
        if (this.props.visible)
            this.loadData();
    }

    public render() {
        return (
            this.state.loaded ?
                <>
                <EmittedPrescriptionActions
                    {...this.state}
                    visible={this.state.selectionVisible}
                    action={this.props.action}
                    onCancel={this.cancel}
                    onSelectAll={this.onSelectAll}
                    onSelectPrescriptions={this.onSelectPrescriptions}
                    validation={this.validation} />

                <EmittedPrescriptionActionsConfirmation
                    visible={this.state.confirmationVisible}
                    action={this.props.action}
                    confirm={this.confirm} />
                </>
            :
                <></>
        )
    }

    private loadData = () => {
        Rest<{ type: string, idPrescripcionGrupo: number }, any>()
        .operation({
            type: 'GetPrescriptionByIdPrescriptionGrupo',
            idPrescripcionGrupo: this.props.idPrescriptionGroup!,
        }).then(response => {

            const prescripciones = (response.prescripciones || []).map((pres: PrescripcionToCopy) => {
                pres.key = pres.idPrescripcion;
                return pres;
            });

            this.setState({loaded: true, prescripciones, selectionVisible: true});
        });
    }

    private cancel = () => {
        this.setState({selectionVisible: false}, () => this.props.onClose())
    }

    private onSelectPrescriptions = (selectedPrescriptions: number[]) => {
        this.setState({selectedPrescriptions});
    }

    private onSelectAll = (checked: boolean) => {
        if (checked) {

            const selectedPrescriptions:number[] = [];
            this.state.prescripciones?.map((pres, index) => (
                (this.props.action === EnumEmittedPrescriptionAction.NEW && !pres.fechaBaja) || 
                (this.props.action === EnumEmittedPrescriptionAction.VOID || this.props.action === EnumEmittedPrescriptionAction.MODIFY)?
                selectedPrescriptions.push(pres.idPrescripcion):undefined
            ));

            //const selectedPrescriptions = (this.state.prescripciones || []).map(pres => pres.idPrescripcion)
            //.filter((fechaBaja) => {return !fechaBaja?true:false});
            this.setState({selectedPrescriptions});
        } else {
            this.setState({selectedPrescriptions: []});
        }
    }

    private validation = () => {
        if (this.state.selectedPrescriptions.length === 0) {
            notification['info']({
                message: this.props.t('emptyPrescriptions')
            });
            return;
        }

        this.save();
    }

    private save = () => {
        let operation = '';
        if (this.props.action === EnumEmittedPrescriptionAction.NEW) {
            operation = 'NewPrescriptionsFromAnother';
        }
        if (this.props.action === EnumEmittedPrescriptionAction.MODIFY) {
            operation = 'ModifyPrescriptionsFromAnother';
        }
        if (this.props.action === EnumEmittedPrescriptionAction.VOID) {
            operation = 'VoidPrescriptions';
        }

        Rest<{ type: string, idPrescripciones: number[] }, any>()
        .operation({
            type: operation,
            idPrescripciones: this.state.selectedPrescriptions,
        }).then(response => {
            this.setState({idPrescripcionGrupo: response.idPrescripcionGrupo, selectionVisible: false, confirmationVisible: true});
        });
    }

    private confirm = () => {
        this.setState({confirmationVisible: false}, () => this.props.onClose(this.props.action, this.state.idPrescripcionGrupo))
    }
}

export default withTranslation('emittedPrescriptionActions')(withRouter(EmittedPrescriptionActionsContainer));