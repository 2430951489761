import { Button, Collapse, Form, FormInstance, Input } from 'antd';
import React from 'react';
import AltaPrescriptorFormContainer, { IState as IStateContainer } from './AltaPrescriptorFormContainer';
import logoEreceta from '../images/logoEReceta.png';
import logoErecetaW from '../images/logoERecetaW.png';
import logoVidal from '../images/VIDAL_Vademecum_españa_FINAL.png';
import logoDelonia from '../images/logo_delonia_nd7.png';
import logoDeloniaSoftware from '../images/logoDeloniaW.png';
import logoLinkedin from '../images/linkedinIcon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faEgg, faLock, faPills, faUser, faShoppingCart, faFileInvoiceDollar, faQuestionCircle, faLaptopMedical } from '@fortawesome/free-solid-svg-icons';

import AltaPrestadorFromExternalData from '../login/entities/altaPrescriptorFromExternalData';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { DownOutlined } from '@ant-design/icons';

interface IProps {
    closeForm: (form?: FormInstance) => void;
    prestadorRegistroData?: AltaPrestadorFromExternalData;
    fromExternalAccess?: boolean;
    showForm?: boolean;
    sendContact: (form: FormInstance) => void; 
}

const AltaPrescriptorPage = (props: IProps & IStateContainer) => {
    const [form] = Form.useForm();
    const { t } = useTranslation('altaPrescriptor');
    const isSmallDevice = useMediaQuery({ query: '(max-width: 500px)' });
    const history = useHistory();

    return (
        <>
            <div className='enlace_token_container prescriptorRegistration'>
                <div className='enlace_token_card_container'>
                    <div className='header'>
                        <a href="https://www.delonia.com/ereceta/" target='_blank'>
                            <img src={logoErecetaW} alt='' />
                        </a>
                        <Button type='link' size='large' icon={isSmallDevice ? <FontAwesomeIcon icon={faUser} /> : undefined}
                            onClick={() => history.push('/login')}>
                            {isSmallDevice ? "" : 'Acceso de usuarios registrados'}</Button>
                    </div>
                    <div className='bloque-altaPrescriptor'>
                        <div className="altaPrescriptor">
                            <div className='informacionReceta-altaPrescriptor'>
                                <div className='logos'>
                                    <img src={logoEreceta} className="logoReceta" alt='' />
                                    <img src={logoDelonia} className="logoDelonia" alt='' />
                                    <img src={logoVidal} className="logoVidal" alt='' />
                                </div>
                                <h2>
                                    <strong>
                                        <div dangerouslySetInnerHTML={{__html: t("altaPrescriptorTitle")}}/>
                                    </strong>
                                </h2>
                                <div className='item-informacionReceta'>
                                    <p>
                                        <div dangerouslySetInnerHTML={{__html: t("altaPrescriptorSubtitle1")}}/>
                                    </p>
                                    <FontAwesomeIcon size='3x' color='#0cb7f2' icon={faExternalLinkAlt} />
                                </div>
                                <div className='item-informacionReceta'>
                                    <p>
                                        <div dangerouslySetInnerHTML={{__html: t("altaPrescriptorSubtitle2")}}/>
                                    </p>
                                    <FontAwesomeIcon size='3x' color='#d2054f' icon={faExternalLinkAlt} flip='both' />
                                </div>



                            </div>
                            <div className='formulario-altaPrescriptor'>
                                <AltaPrescriptorFormContainer
                                    closeForm={props.closeForm}
                                    fromExternalAccess={true}
                                    showForm={true}
                                    existingPrestadorData={props.prestadorRegistroData}
                                />
                            </div>
                        </div>

                        <div className='bloqueVentajas-altaPrescriptor'>
                            <div className='bloqueVentajas--inner'>
                                <h2 className='bloqueVentajas-title'><div dangerouslySetInnerHTML={{__html: t("bloqueVentajasTitle")}}/></h2>
                                <div className='ventajas-bloqueVentajas'>
                                    <div className='item-ventajas'>
                                        <FontAwesomeIcon className="iconVentaja" size='4x' icon={faEgg} />
                                        <div className='def-item-ventajas'>
                                            <div dangerouslySetInnerHTML={{__html: t("bloqueVentajasTextFacilidad")}}/>
                                        </div>
                                    </div>
                                    <div className='item-ventajas'>
                                        <FontAwesomeIcon className="iconVentaja" size='4x' icon={faLock} />
                                        <div className='def-item-ventajas'>
                                            <div dangerouslySetInnerHTML={{__html: t("bloqueVentajasTextSeguridad")}}/>
                                        </div>
                                    </div>
                                    <div className='item-ventajas'>
                                        <FontAwesomeIcon className="iconVentaja" size='4x' icon={faPills} />
                                        <div className='def-item-ventajas'>
                                            <div dangerouslySetInnerHTML={{__html: t("bloqueVentajasTextDispensacion")}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="alta-prescriptor-collapse-container">
                            <h1 className="alta-prescriptor-collapse-title"><div dangerouslySetInnerHTML={{__html: t("altaPrestadorCollapseContainerTitle")}}/></h1>
                            <Collapse accordion defaultActiveKey={2} expandIconPosition={"right"}  bordered={false} expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? -180 : 0}/>} style={{backgroundColor: "white", width: "80%"}}  >
                                <CollapsePanel style={{backgroundColor: "white"}}
                                    header={
                                        <div style={{display:'flex'}}>
                                            <div className="alta-prescriptor-collapse-header-icon">
                                                <FontAwesomeIcon icon={faFileInvoiceDollar} color="white"/>
                                            </div>
                                            <div className="alta-prescriptor-collapse-header" style={{backgroundColor: "white"}}>
                                                <span className="alta-prescriptor-collapse-header-text"><div dangerouslySetInnerHTML={{__html: t("altaPrestadorCollapseHeaderCosto")}}/></span>
                                            </div>
                                        </div>
                                    }
                                    
                                    key="1"
                                >
                                   <div className= "alta-prescriptor-collapse-inner-text" dangerouslySetInnerHTML={{__html: t("altaPrestadorCollapseHeaderCostoInner")}}/>
                                </CollapsePanel>

                                <CollapsePanel
                                    header={
                                        <div style={{display:'flex'}}>
                                            <div className="alta-prescriptor-collapse-header-icon">
                                                <FontAwesomeIcon icon={faShoppingCart}  color="white" />
                                            </div>
                                            <div className="alta-prescriptor-collapse-header">
                                                <span className="alta-prescriptor-collapse-header-text"><div dangerouslySetInnerHTML={{__html: t("altaPrestadorCollapseHeaderHow")}}/></span>
                                            </div>
                                        </div>
                                    }
                                    key="2"
                                >
                                    <div className= "alta-prescriptor-collapse-inner-text impar" dangerouslySetInnerHTML={{__html: t("altaPrestadorCollapseHeaderHowInner1")}}/>
                                    <div className= "alta-prescriptor-collapse-inner-text par" dangerouslySetInnerHTML={{__html: t("altaPrestadorCollapseHeaderHowInner2")}}/>
                                    <div className= "alta-prescriptor-collapse-inner-text impar" dangerouslySetInnerHTML={{__html: t("altaPrestadorCollapseHeaderHowInner3")}}/>
                                    <div className= "alta-prescriptor-collapse-inner-text par" dangerouslySetInnerHTML={{__html: t("altaPrestadorCollapseHeaderHowInner4")}}/>
                                </CollapsePanel>

                                <CollapsePanel
                                    header={
                                        <div style={{display:'flex'}}>
                                            <div className="alta-prescriptor-collapse-header-icon">
                                                <FontAwesomeIcon icon={faLaptopMedical} color="white" />
                                            </div>
                                            <div className="alta-prescriptor-collapse-header">
                                                <span className="alta-prescriptor-collapse-header-text"><div dangerouslySetInnerHTML={{__html: t("altaPrestadorCollapseHeaderUso")}}/></span>
                                            </div>
                                        </div>
                                    }
                                    key="3"
                                >
                                    <div className= "alta-prescriptor-collapse-inner-text" dangerouslySetInnerHTML={{__html: t("altaPrestadorCollapseHeaderUsoInner")}}/>
                                </CollapsePanel>

                                <CollapsePanel
                                    header={
                                        <div style={{display:'flex'}}>
                                            <div className="alta-prescriptor-collapse-header-icon">
                                                <FontAwesomeIcon icon={faQuestionCircle} color="white" />
                                            </div>
                                            <div className="alta-prescriptor-collapse-header">
                                                <span className="alta-prescriptor-collapse-header-text"><div dangerouslySetInnerHTML={{__html: t("altaPrestadorCollapseHeaderDudas")}}/></span>
                                            </div>
                                        </div>
                                    }
                                    key="4"
                                >
                                    <div className= "alta-prescriptor-collapse-inner-text" dangerouslySetInnerHTML={{__html: t("altaPrestadorCollapseHeaderDudasInner")}}/>
                                </CollapsePanel>
                            </Collapse>
                        </div>
                        <div className='alta-prescriptor-video-container'>
                            <div className='alta-prescriptor-video-container-div'>
                                <h1 className= "alta-prescriptor-video-container-title" dangerouslySetInnerHTML={{__html: t("altaPrestadorVideoContainerTitle")}}/>
                                <iframe className="alta-prescriptor-video-iframe"
                                    src="https://www.youtube.com/embed/RSCKg3cioYM?si=AQGCWnIp8AWU6Tbh" 
                                    title="YouTube video player" 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen>
                                </iframe>
                            </div>
                        </div>
                        <div className="alta-prescriptor-contacto-container">
                            <p className="intro-text">
                                <h1>{t("contactTitle")}</h1>
                                {t("contactInfo")}
                            </p>

                            <div className="contact-details">
                                <div className="contact-item">
                                    <span>{t('whastAppContact')}<a href={"tel:"+t('whatsAppTel')} dangerouslySetInnerHTML={{__html: t("whatsAppTel")}}/></span>
                                </div>
                                <div className="contact-item">
                                    <span>{t('telefonoContact')}<a href={"tel:"+t('telefonoTel')} dangerouslySetInnerHTML={{__html: t("telefonoTel")}}/></span>
                                </div>
                                <div className="contact-item">
                                    <span>{t('emailContact')}<a href={"mailto:"+t('emailMailto')} dangerouslySetInnerHTML={{__html: t("emailMailto")}}/></span>
                                </div>
                            </div>

                            <div className="contact-form-title">
                                {t("contactFormTitle")}
                            </div>

                            <div className="contact-form">
                                <Form layout='vertical' form={form} >

                                    <Form.Item name={"nombre"} rules={[{ required: true }]}>
                                        <Input type="text" className="contact-input first-row" placeholder={t("namePlaceholder")} />
                                    </Form.Item>
                                    <div className="contact-form-row">
                                    <Form.Item name={"email"} rules={[{ required: true }]}>
                                        <Input type="email" className="contact-input second-row" placeholder={t("emailPlaceholder")} />
                                    </Form.Item>
                                    <Form.Item name={"telefono"} rules={[{ required: true }]}>
                                        <Input type="text" className="contact-input second-row" placeholder={t("phonePlaceholder")} />
                                    </Form.Item>
                                        <Button className="contact-submit-btn" onClick={() => props.sendContact(form)}>{t("submitButton")}</Button>
                                    </div>
                                </Form>
                            </div>
                        </div>



                        <div className="alta-prescriptor-footer-container">
                                <div className="alta-prescriptor-footer-div">
                                    <div className='alta-prescriptor-footer-icon'>
                                        <img src={logoDeloniaSoftware} className="logoDeloniaSoftware" alt='' />
                                    </div>
                                    <div className='alta-prescriptor-footer-texto'>
                                        <span>{t('contactoMadrid')}<a href={"tel:"+t('madridTel')} dangerouslySetInnerHTML={{__html: t("madridTel")}}/></span>
                                        <span>{t('contactoLondres')}<a href={"tel:"+t('londresTel')} dangerouslySetInnerHTML={{__html: t("londresTel")}}/></span>
                                        <span>{t('contactoSegovia')}<a href={"tel:"+t('segoviaTel')} dangerouslySetInnerHTML={{__html: t("segoviaTel")}}/></span>
                                    </div>
                                    <div className='alta-prescriptor-footer-socialmedia'>
                                        <div className= "alta-prescriptor-footer-socialmedia" dangerouslySetInnerHTML={{__html: t("footerSocialTexto")}}/>
                                        <img src={logoLinkedin} onClick={() => window.open('https://es.linkedin.com/company/delonia-software')} className="logoDelonia" alt='' />
                                    </div>
                                    <div className='alta-prescriptor-footer-iconos-div'>
                                        <img src={logoDelonia} className="logoDelonia" alt='' />
                                        <img src={logoVidal} className="logoVidal" alt='' />
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AltaPrescriptorPage;