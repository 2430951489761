import React from 'react';

import { Modal } from 'antd';
import {URL} from '../../utils/rest';

const PrescriptionAlertsInformModal = (props: {
  visible?: boolean;
  onCancel: () => void;
}) => {

    return (
        <Modal  style={{ top: 40 }}
                visible={props.visible}
                onCancel={() => props.onCancel()}
                footer={null}
                className='modal-informe-alertas'
                destroyOnClose>
            <div className='modal-informe-alertas-iframe-wrapper'>
                <iframe frameBorder={0} src={URL + '/vidal-html'} />
            </div>
        </Modal>
    )

}
export default PrescriptionAlertsInformModal;