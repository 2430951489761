import React, { ReactNode } from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Rest, TableUtils } from '../utils/utils';
import { FormInstance } from 'antd/lib/form';
import { MainTableBodyProps } from '../utils/tableUtils'
import { TabExtendedProps } from '../MainLayout';
import NewPrescriptionsContainer from './newPrescriptionsContainer';
import MenuConfig, { COMPONENT_IDS } from '../layout/menuConfig';
import View from './prescriptionsList';
import SelectPrescripcionByPrestadorOperation from './operations/selectPrescripcionByPrestadorOperation';
import SelectPrescripcionByPrestadorResult from './entities/selectPrescripcionByPrestadorResult';
import PrescripcionData from './entities/prescripcionData';
import EnumEstadosRecetas from '../enum/EnumEstadosRecetas';
import EventHub, { Event } from '../utils/eventHub';
import EnumDispensacionStatus from '../enum/EnumDispensacionStatus';
import FilterData from './entities/filterData';

interface IProps {
    addTab?: (tabCaption: string, component: ReactNode, extendedProps?: TabExtendedProps) => void;
    closeTab?: () => void;
    blocked?: boolean;
    patientNif?: string;
    patientName?: string;
    fixedPatient?: boolean;
    hideOptions?: boolean;
    onAddNewPrescripcionTab?: () => void;
}

export interface IState extends MainTableBodyProps {
    prescriptionList?: PrescripcionData[];
    dataCount: number;
    limit: number;
    page?: number;
    idPrescripcion?: number;
    lastFilter?: any;
    filterKey?: number;
    filtersCollapsed?: boolean;
    highlightName?: string;
    highlightPrescriptionName?: string;
    prescriptionFilter?: { value: string }[];
    nFilters?: number;
}

class PrescriptionsListContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public state: IState = {
        limit: 0,
        dataCount: 0,
        filtersCollapsed: true,
    };

    public componentDidMount() {
        EventHub.on(Event.RELOAD_PRESCRIPTIONS, () => {
            this.loadData(this.state.page);
        });

        EventHub.on(Event.LOAD_BLOCKED_PRESCRIPTIONS, () => {
            const values = {...this.state.lastFilter};
            values.statusDispensacion = [EnumDispensacionStatus.BLOQUEADA];
            this.setState({lastFilter: values, filterKey: new Date().getTime()},() => this.loadData());
        });

        if (this.props.blocked) {
            const values = {...this.state.lastFilter};
            values.statusDispensacion = [EnumDispensacionStatus.BLOQUEADA];
            this.setState({lastFilter: values, filterKey: new Date().getTime(), ...TableUtils.calculatePageSizeForMainTable()},() => this.loadData());

        } else if (this.props.patientNif || this.props.patientName) {
            const values = {...this.state.lastFilter};
            values.nifPatient = this.props.patientNif;
            values.patientName = this.props.patientName;
            this.setState({lastFilter: values, filterKey: new Date().getTime(), ...TableUtils.calculatePageSizeForMainTable()},() => this.loadData());

        } else { 
            const tableHeightData = !this.props.fixedPatient ? 
                TableUtils.calculatePageSizeForMainTable() :
                TableUtils.calculatePageSizeForMainTable(48, ['gco-titulo', 'table-button-bar'], 48);
            this.setState(tableHeightData, () => this.loadData());
        }
    }

    public render() {
        return (

            <View
                {...this.state}
                fixedPatient={this.props.fixedPatient}
                fromPatient={!!this.props.patientNif || !!this.props.patientName}
                hideOptions={this.props.hideOptions}
                refresh={this.refresh}
                onCollapseFilters={this.onCollapseFilters}
                onChangePage={this.onChangePage}
                applyFilters={this.applyFilters}
                resetFilters={this.resetFilters}
                onClickDetail={this.onClickDetail}
                onAddNewPrescripcionTab={this.onAddNewPrescripcionTab}
            />
        );
    }

    private loadData = (page?: number) => {
        const values = this.state.lastFilter;
        const filter = {} as any;
        
        if (values) {
            filter.nifPatient = values && values.nifPatient ? values.nifPatient : undefined;
            filter.nifTutor = values && values.nifTutor ? values.nifTutor : undefined;
            filter.statusPrescription = values.statusPrescription && values.statusPrescription.length > 0 ? values.statusPrescription : undefined;

            let statusDispensacionFilter: number[] = [];
            values.statusDispensacion && values.statusDispensacion.forEach((v: number) => {
                statusDispensacionFilter = statusDispensacionFilter.concat(EnumEstadosRecetas.getEstadosRecetas(v));
            });

            filter.statusDispensacion = statusDispensacionFilter && statusDispensacionFilter.length > 0 ? statusDispensacionFilter : undefined;
            filter.valuePrescripcion = values && values.prescriptionName ? values.prescriptionName : undefined;
            filter.dateFrom = values && values.creationDate ? new Date(values.creationDate[0]) : undefined;
            filter.dateTo =  values && values.creationDate && values.creationDate[1] ? new Date(values.creationDate[1]) : undefined;
        }

        Rest<SelectPrescripcionByPrestadorOperation, SelectPrescripcionByPrestadorResult>().operation({
            type: 'SelectPrescripcionByPrestador',
            limit: this.state.limit,
            offset: (((page || 1) -1) * this.state.limit),
            ...filter
            }).then(response => {
                this.setState({
                    prescriptionList: response.data.map(e => {
                        e.key = e.idPrescripcion.toString();
                        return e;
                    }),
                    highlightName: (values && values.patientName ) ? values.patientName : undefined,
                    highlightPrescriptionName: (values && values.prescriptionName ) ? values.prescriptionName : undefined,
                    dataCount: response.dataCount,
                    lastFilter: values, 
                    idPrescripcion: undefined,
                    page
                });
            });
    }
    

    private onChangePage = (page: number) => {
        this.setState({page: page});
        this.loadData(page);
    }

    private onCollapseFilters = () => {
        this.setState({ filtersCollapsed: !this.state.filtersCollapsed});
    }

    private applyFilters = (form: FormInstance) => {
        var nFilters = 0;
        const values = form.getFieldsValue();
        var filters = values as FilterData;
        if(filters.creationDate?.length!=undefined ){
            nFilters = nFilters+1;
        } if(filters.nifPatient != undefined && filters.nifPatient != ""){
            nFilters = nFilters+1;
        } if (filters.nifTutor != undefined && filters.nifTutor != ""){
            nFilters = nFilters+1;
        } if(filters.prescriptionName != undefined && filters.prescriptionName != ""){
            nFilters = nFilters+1;
        } if (filters.statusDispensacion != undefined &&filters.statusDispensacion?.length!=0){
            nFilters = nFilters+1;
        } if (filters.statusPrescription != undefined && filters.statusPrescription?.length!=0){
            nFilters = nFilters+1;
        }
        console.log(values);
        this.setState({nFilters: nFilters, lastFilter: values, filtersCollapsed: true}, () => this.loadData(1));
    }


    private resetFilters = () => {
        this.setState({
            nFilters: 0,
            highlightName: undefined,
            highlightPrescriptionName: undefined,
            lastFilter: undefined,
            filterKey: new Date().getTime()
        }, () => this.loadData(1));
    }

    private refresh = () => {
        this.setState({page: 1}, () => this.loadData());
    }

    private onClickDetail = (idPrescripcionGrupo: number, nombrePaciente: string, pending: boolean) => {
        if (this.props.addTab)
            this.props.addTab(this.props.t('prescriptionDetailTabTitle', {nombrePaciente, idPrescripcionGrupo}), 
            <NewPrescriptionsContainer idPrescripcionGrupo={idPrescripcionGrupo} addTab={this.props.addTab} closeTab={this.props.closeTab} pending={pending} hideOptions={this.props.hideOptions}
            fixedPatientLocation={this.props.fixedPatient} refresh={this.refresh}/>, 
            MenuConfig.getConfigById(COMPONENT_IDS.newPrescriptionContainer))
    }

    private onAddNewPrescripcionTab = () => {
        if (!this.props.addTab) {
            return;
        }
        const extendedProps: TabExtendedProps = {...MenuConfig.getConfigById(COMPONENT_IDS.newPrescriptionContainer)};
        extendedProps.id = extendedProps.id + '|Ex';

        this.props.addTab(this.props.t('menu:newPrescription'), <NewPrescriptionsContainer addTab={this.props.addTab} closeTab={this.props.closeTab}
                                       pending={true} fixedPatientLocation={this.props.fixedPatient} refresh={this.refresh} hideOptions={this.props.hideOptions}/>, extendedProps)
    }

}

export default withTranslation('prescriptionsList')(withRouter(PrescriptionsListContainer));
