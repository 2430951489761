import React, { ReactNode } from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Rest, TableUtils } from '../../../utils/utils';
import { FormInstance } from 'antd/lib/form';
import { MainTableBodyProps } from '../../../utils/tableUtils'
import { TabExtendedProps } from '../../../MainLayout';
import View from './listaPacientes';
import FilterData from './../../entities/filterData';
import PatientData from '../entities/patientData';
import GetPacientesResult from '../../entities/getPacientesResult';
import MenuConfig, { COMPONENT_IDS } from '../../../layout/menuConfig';
import InformacionPacienteContainer from './informacionPacienteContainer';
import PatientCommonContainer from '../PatientCommonContainer';
import PersonaSelected from '../entities/personaSelected';
import NewPrescriptionsContainer from '../../newPrescriptionsContainer';


interface IProps {
    addTab?: (tabCaption: string, component: ReactNode, extendedProps?: TabExtendedProps) => void;
    closeTab?: () => void;
    idPaciente?: number;
}

export interface IState extends MainTableBodyProps {
    patientList?: PatientData[];
    dataCount: number;
    limit: number;
    page?: number;
    idPrescripcion?: number;
    lastFilter?: any;
    filterKey?: number;
    filtersCollapsed?: boolean;
    highlightName?: string;
    highlightPrescriptionName?: string;
    prescriptionFilter?: { value: string }[];
    nFilters?: number;
    patientKey?: number;
    patientContainer?: boolean;
    patientSelected?: PatientData;
    editPatient?: boolean;
}

class listaPacientesContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public state: IState = {
        limit: 15,
        dataCount: 0,
        filtersCollapsed: true,
        
    };

    public render() {
        return(
            <>
            <View
                {...this.state}
                refresh={this.refresh}
                onChangePage={this.onChangePage}
                onCollapseFilters={this.onCollapseFilters}
                applyFilters={this.applyFilters}
                resetFilters={this.resetFilters}
                onClickDetail={this.onClickDetail}
                onNewPatient={this.onNewPatient}
                onClickPatientNewPrescription={this.onClickPatientNewPrescription}
            />
            <PatientCommonContainer
                    key={this.state.patientKey}
                    visible={this.state.patientContainer}
                    onClose={() => this.setState({ patientContainer: false })}
                    idPatient={this.state.patientSelected?.idPaciente}
                    patientSelected={this.state.patientSelected}
                    editPatient={this.state.editPatient} addPatientSelected={
                        ()=>{}
                    }  editPatientSelectedWithoutMail={
                        ()=>{}
                    }
                    fromListaPacientes={true}                     
                />
            </>
        )
    }
        
    

    public componentDidMount() {
        const tableHeightData = TableUtils.calculatePageSizeForMainTable();
        this.setState(tableHeightData, () => this.loadData());
    }
    private onNewPatient=()=>{
        this.setState({
            patientContainer:true,
            patientKey: new Date().getTime()
        })
    }

    private loadData = (page?: number) => {
        
        const values = this.state.lastFilter;
        const filter = {} as any;
        
        if (values) {
            filter.nifPatient = values && values.nifPatient ? values.nifPatient : undefined;
            filter.nameNombre = values && values.nameNombre ? values.nameNombre : undefined;
            filter.nameApellido1 = values && values.nameApellido1 ? values.nameApellido1 : undefined;
            filter.nameApellido2 = values && values.nameApellido2 ? values.nameApellido2 : undefined;
        }

        Rest<{type: string; limit: number; offset: number;}, GetPacientesResult>().operation({
            type: 'GetCompleteListado',
            limit: this.state.limit,
            offset: (((page || 1) -1) * this.state.limit),
            ...filter
            }).then(response => {
                this.setState({
                    patientList: response.data.map(e => {
                        e.key = e.idPaciente!.toString();
                        return e;
                    }),
                    dataCount: response.dataCount,
                    lastFilter: values, 
                    page
                });
            });
    }

    private onChangePage = (page: number) => {
        this.setState({page: page});
        this.loadData(page);
    }

    private refresh = () => {
        this.setState({page: 1}, () => this.loadData());
    }
    private onCollapseFilters = () => {
        this.setState({ filtersCollapsed: !this.state.filtersCollapsed});
    }


    private applyFilters = (form: FormInstance) => {
        var nFilters = 0;
        const values = form.getFieldsValue();
        var filters = values as FilterData;
        if(filters.nifPatient != undefined && filters.nifPatient != ""){
            nFilters = nFilters+1;
        } if (filters.nameNombre != undefined && filters.nameNombre != ""){
            nFilters = nFilters+1;
        } if(filters.nameApellido1 != undefined && filters.nameApellido1 != ""){
            nFilters = nFilters+1;
        } if(filters.nameApellido2 != undefined && filters.nameApellido2 != ""){
            nFilters = nFilters+1;
        } 
        console.log(values);
        this.setState({nFilters: nFilters, lastFilter: values, filtersCollapsed: true}, () => this.loadData(1));
    }

    private onClickDetail = (idPaciente: number, nombrePaciente: string) => {
        if (this.props.addTab) {
            
            const tabCaption = `${nombrePaciente}`;
            const component = (
                <InformacionPacienteContainer 
                    idPaciente={idPaciente} 
                    addTab={this.props.addTab} 
                    closeTab={this.props.closeTab}
                />
            );
            const extendedProps = MenuConfig.getConfigById(COMPONENT_IDS.infoPacienteContainer);
    
            this.props.addTab(tabCaption, component, extendedProps);
        }            
    }


    private resetFilters = () => {
        this.setState({
            nFilters: 0,
            highlightName: undefined,
            highlightPrescriptionName: undefined,
            lastFilter: undefined,
            filterKey: new Date().getTime()
        }, () => this.loadData(1));
    }

    private onClickPatientNewPrescription = (idPaciente: number) => {
        if(this.props.addTab) {
            this.props.addTab(this.props.t('menu:newPrescription'), <NewPrescriptionsContainer idPaciente={idPaciente} addTab={this.props.addTab} closeTab={this.props.closeTab} pending={true} />,  MenuConfig.getConfigById(COMPONENT_IDS.listaPacientesContainer));
        }
    }

}

export default withTranslation('listaPacientes')(withRouter(listaPacientesContainer));