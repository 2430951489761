import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LOGOERECETA from '../images/logoEReceta.png';

interface ButtonProps {
    caption: string,
    onClick: () => void,
}

interface IProps {
    title: string,
    text: string,
    isSuccess: boolean,
    buttonProps?: ButtonProps;
}

class SimpleLandingView extends React.Component<IProps & WithTranslation & RouteComponentProps> {

    public render() {
        return (
            <div className='enlace_token_container'>
                <div className='enlace_token_card_container'>
                    <div className='enlace_token_card'>
                        <div className='enlace_token_logo'><img src={LOGOERECETA}  alt=''/></div>
                        <div className='enlace_token_title'>{this.props.t(this.props.title)}</div>
                        <div className='enlace_token_result'>
                            <div className='enlace_token_result--inner'>
                                <div className='enlace_token_result-logo'><FontAwesomeIcon icon={this.props.isSuccess ? faCheckCircle: faTimesCircle} style={{ color: this.props.isSuccess ? 'yellowGreen' :  '#f5222d', fontSize: '2rem' }} /></div>
                                <div className='enlace_token_result-text' dangerouslySetInnerHTML={{__html:this.props.t(this.props.text)}}/>
                            </div>
                            {this.props.buttonProps &&
                                    <div className='enlace_token_buttons'>
                                        <Button type="primary" onClick={this.props.buttonProps.onClick}>
                                            {this.props.t(this.props.buttonProps.caption)}
                                        </Button>
                                    </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation('simpleLanding')(withRouter(SimpleLandingView));