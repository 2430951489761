import React from "react"
import { WithTranslation, withTranslation } from "react-i18next";
import View from './PrescriptionOptions';
import { Rest } from "../../utils/utils";
import { URL } from "../../utils/rest";
import ResendHipPatientModal from "./ResendHipPatientModal";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FormInstance } from "antd";

interface IProps {
    idPatient: number;
    idPrescripcionGrupo: number;
}

export interface IState {
    resendHipModalVisible?: boolean;
    patientEmail?: string;
    emptyEmail?: boolean;
    saveEmail: boolean;
    resendHipPatientModalKey?: number;
}

class PrescriptionOptionsContainer extends React.Component<WithTranslation & IProps & RouteComponentProps, IState> {

    public state: IState = {
        saveEmail: false
    };

    public render() {
        return (
            <>
                <View
                    hipDownload={this.hipDownload}
                    openResendHipModal={this.openResendHipModal} />
                <ResendHipPatientModal
                    key={this.state.resendHipPatientModalKey}
                    visible={this.state.resendHipModalVisible}
                    patientEmail={this.state.patientEmail}
                    emptyEmail={this.state.emptyEmail}
                    saveEmail={this.saveEmail}
                    onSubmit={this.submit}
                    onClose={this.closeResendHipModal} />
            </>
        );
    }

    private hipDownload = () => {
       /* Rest<{type: string, idPaciente: number, idPrescripcionGrupo: number}, any>().operation({
            type: 'CheckLastHipByIdPatient',
            idPaciente: this.props.idPatient,
            idPrescripcionGrupo: this.props.idPrescripcionGrupo
        }).then(response => {
            if (response && response.codeHip) {*/
                const file_path = URL + '/file?ddp&idPrescripcionGrupo=' + this.props.idPrescripcionGrupo;
                const a = document.createElement('a');
                a.href = file_path;
                a.click();
                document.body.removeChild(a);
            /*}
        })*/
    }

    private openResendHipModal = () => {
        Rest<{type: string, idPaciente: number}, any>().operation({
            type: 'GetPatientEmailById',
            idPaciente: this.props.idPatient
        }).then(response => {
            this.setState({patientEmail: response.email, resendHipPatientModalKey: new Date().getTime(), emptyEmail: response.emptyEmail, resendHipModalVisible: true});
        });
    }

    private closeResendHipModal = () => {
        this.setState({resendHipModalVisible: undefined});
    }

    private saveEmail = () => {
        this.setState({saveEmail: !this.state.saveEmail});
    }

    private submit = async (form: FormInstance, isDifferentEmail: boolean) => {
        var newEmail = undefined;
        if (isDifferentEmail) {
            const values = await form.validateFields();
            newEmail = values.newEmail;
        }

        if (!isDifferentEmail || (isDifferentEmail && newEmail !== undefined)) {
            Rest<{type: string, idPaciente: number, idPrescripcionGrupo:number, saveEmail: boolean, newEmail?: string}, any>().operation({
                type: 'ResendDocumentoHipToUser',
                idPaciente: this.props.idPatient,
                idPrescripcionGrupo: this.props.idPrescripcionGrupo,
                saveEmail: this.state.saveEmail,
                newEmail: newEmail
            }).then(response => {
                this.closeResendHipModal();
            });
        }
    }

}

export default withTranslation('prescriptionOptions')(withRouter(PrescriptionOptionsContainer));