

import React, { ReactNode, useState } from 'react';
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import informacionPacienteContainer, { IState, IState as IStateContainer } from './informacionPacienteContainer';
import PatientData from '../entities/patientData';
import { Tooltip, Button, Table, FormInstance, Popconfirm } from 'antd';
import { Functions, TableIcons, concatName } from '../../../utils/utils';
import { AlignType } from 'rc-table/lib/interface';
import State from 'ol/source/State';
import { concat } from 'lodash';
import { date } from 'date-fns/locale/af';
import { PatologiaData } from '../entities/patologiaData';
import { AlergieData } from '../entities/alergieData';
import PrescripcionData from '../../entities/prescripcionData';
import Form from 'antd/es/form/Form';
import PrescriptionOptionsContainer from '../../options/PrescriptionOptionsContainer';
import EnumDispensacionStatus from '../../../enum/EnumDispensacionStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import EnumPrescriptionStatus from '../../../enum/EnumPrescriptionStatus';
import { faAngleDoubleDown, faAngleDoubleUp, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import './detallePaciente.css';
import Flag from 'react-flagkit';

interface IProps {
    refresh: () => void;
    onChangePage: (page: number) => void;
    idPaciente?: number;
    patientInfo?: any;
    alergiasList?: any[];
    patologiasList?: any[];
    prescriptionListAct?: any[];
    prescriptionListFin?: any[];
    onclickEditInfoPatient?: () => void;
    onclickDatosSalud: (flagInfo: number) => void;
    onClickPatientNewPrescription: (idpaciente: number) => void;
    onClickDetail: (idPrescripcionGrupo: number, nombrePaciente: string, pending: boolean) => void;
    hideOptions?: boolean;
    onAddAlergies: () => void;
    onAddPatologies: () => void;
    deleteAlergia: (AlergieData: AlergieData) => void;
    deletePatologia: (PatologiaData: PatologiaData) => void;
    deletePaciente: (idpaciente: number) => void;
}

const InfoPaciente = (props: IProps & IStateContainer) => {
    const { t } = useTranslation(['patientInfo']);


    const listaAlergias = [
        {
            dataIndex: 'idVidal', ellipsis: true, align: 'left' as AlignType, width: '4rem',
            render: (value: any, record: any) => {
                return <span>
                    {record.idVidal}
                </span>
            }
        },
        {
            ellipsis: true, align: 'left' as AlignType, width: '65%',
            render: (value: any, record: any) => {

                var alergia;
                if (t('nombreAlergia') === ("nameAlergiaEn")) {
                    if (!record.nameAlergiaEn) {
                        alergia = record.nameAlergiaEs;
                    } else {
                        alergia = record.nameAlergiaEn;
                    }
                } else if (t('nombreAlergia') === ("nameAlergiaEs")) {
                    alergia = record.nameAlergiaEs;
                }
                return <span>
                    {alergia}
                </span>;

            }
        },
        {
            dataIndex: 'dateCreacion', align: 'left' as AlignType, width: '20%',
            render: (dateCreacion: Date) => {
                const act = dateCreacion && Functions.dateToString(dateCreacion)
                return <span> {t('añadida')}:  {act} </span>;
            }
        },
        {
            dataIndex: 'idVidal', ellipsis: true, align: 'left' as AlignType, width: '5rem',
            render: (value: any, record: AlergieData) => {
                return (
                    <span>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Popconfirm placement="bottom" title={t('seguroEliminarAlergia')} onConfirm={() => props.deleteAlergia(record)} okText={t('buttons:confirmar')} cancelText={t('buttons:cancel')}>
                                <Button className={'button__prescription'} type="default" size="small">{t('eliminar')}</Button>
                            </Popconfirm>
                        </div>
                    </span>
                );
            }
        }

    ];

    const listaPatologias = [
        {
            dataIndex: 'codeCie10', ellipsis: true, align: 'left' as AlignType, width: '4rem',
            render: (value: any, record: any) => {
                return <span>
                    {record.codeCie10}
                </span>
            }
        },
        {
            ellipsis: true, align: 'left' as AlignType, width: '65%',
            render: (value: any, record: any) => {
                var patologia;
                if (t('nombrePatologia') === ("nameCie10En")) {
                    patologia = record.nameCie10En;
                } else if (t('nombrePatologia') === ("nameCie10Es")) {
                    patologia = record.nameCie10Es;
                }
                return <span>
                    {patologia}
                </span>
            }
        },
        {
            dataIndex: 'dateCreacion', align: 'left' as AlignType, width: '20%',
            render: (dateCreacion: Date) => {
                const act = dateCreacion && Functions.dateToString(dateCreacion)
                return <span> {t('añadida')}:  {act} </span>;
            }
        },
        {
            ellipsis: true, align: 'left' as AlignType, width: '5rem',
            render: (value: any, record: PatologiaData) => {
                return (
                    <span>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Popconfirm placement="bottom" title={t('seguroEliminarPatologia')} onConfirm={() => props.deletePatologia(record)} okText={t('buttons:confirmar')} cancelText={t('buttons:cancel')}>
                                <Button className={'button__prescription'} type="default" size="small">{t('eliminar')}</Button>
                            </Popconfirm>
                        </div>
                    </span>
                );
            }
        }
    ];

    const statusDispensacionRenderer = (value: any, record?: PrescripcionData) => {

        let color;
        let status = record && record.estadoDispensacion ? record.estadoDispensacion : value;

        if (!status) {
            return;
        }

        switch (status) {
            case EnumDispensacionStatus.PENDIENTE:
                color = "#A9A9A9";
                break;
            case EnumDispensacionStatus.DISPENSADA:
                color = "#8EAD38";
                break;
            case EnumDispensacionStatus.CADUCADA:
                color = "#FADB15";
                break;
            case EnumDispensacionStatus.BLOQUEADA:
                color = "#FF4D4F";
                break;
            case EnumDispensacionStatus.FORMULA_MAGISTRAL:
                color = "#002E50";
                break;
            case EnumDispensacionStatus.DISPENSADA_SUSTITUCION_1:
                color = "#1890FF";
                break;
            case EnumDispensacionStatus.DISPENSADA_SUSTITUCION_2:
                color = "#1890FF";
                break;
        }

        return (
            <div className='dispensacion-column'>
                <div>
                    {status === EnumDispensacionStatus.DISPENSADA_PARCIALMENTE ?
                        <div className='dipensada_parcialmente_status' style={{ marginRight: '0.5rem' }} /> :
                        <FontAwesomeIcon icon={faCircle} style={{ color: color, marginRight: '0.5rem' }} />}
                    {t('dispensacionStatuses:' + status)}
                </div>
                {record && record.totalRecetas > 1 &&
                    <div className='message_receta'>({t('messageRecetaLabel', { numReceta: record.numReceta, totalReceta: record.totalRecetas })})</div>}
            </div>

        )
    }

    const statusPrescriptionRenderer = (value: number, record?: any) => {
        let color;
        let colorVisa;
        switch (value) {
            case EnumPrescriptionStatus.PTE_FIRMA:
                color = "#FADB15";
                break;
            case EnumPrescriptionStatus.EMITIDA:
                color = "#8EAD38";
                break;
            case EnumPrescriptionStatus.ANULADA:
                color = "#FF4D4F";
                break;
        }
        if (record != null) {
            switch (record.estadoVisado) {
                case 2:
                    colorVisa = "#FADB15";
                    break;
                case 3:
                    colorVisa = "#8EAD38";
                    break;
                case 4:
                    colorVisa = "#FF4D4F";
                    break;
            }
        }

        return <div style={{ display: 'flex', flexDirection: 'column' }} className='statusPrescriptionDiv'>
            <span><FontAwesomeIcon icon={faCircle} style={{ color: color, marginRight: '0.5rem' }} />{t('prescriptionStatuses:' + value)}</span>
            {record && record.flagMutualista != 0 && record.estadoVisado && record.estadoVisado != 1 && value != EnumPrescriptionStatus.PTE_FIRMA && <span className='visaStatus'><FontAwesomeIcon icon={faCircle} style={{ color: colorVisa, marginLeft: '0.075rem', marginRight: '0.58rem' }} />{t('visadoStatuses:' + record.estadoVisado)}</span>}
        </div>;
    }

    const listaPrescripcionesAct = [
        {
            dataIndex: 'dateCreacion', align: 'left' as AlignType, width: '5rem',
            render: (dateCreacion: Date) => {
                const act = dateCreacion && Functions.dateToString(dateCreacion)
                return <span> {act} </span>;
            }
        },
        {
            dataIndex: 'valuePrescripcion', ellipsis: true, align: 'left' as AlignType, width: '35%',
            render: (value: any, rec: any) => {
                return <span>
                    {rec.valuePrescripcion}
                </span>
            }
        }, { dataIndex: 'estadoPrescripcion', align: 'left' as AlignType, width: '5rem', render: statusPrescriptionRenderer },
        { dataIndex: 'estadoDispensacion', align: 'left' as AlignType, width: '5rem', render: statusDispensacionRenderer },
        {
            dataIndex: 'adherencia', ellipsis: true, align: 'left' as AlignType, width: '15%',
            render: (value: any, rec: any) => {
                if (rec.adherencia != null) {
                    return <span>
                        {t('adherencias')} {rec.adherencia} %
                    </span>
                } else {
                    return <span>{t('dispensadaAun')}</span>
                }
            }
        },
        {
            align: 'right' as AlignType, width: '6.5rem', render: (value: any, rec: PrescripcionData) => {

                const onClick = () => {
                    props.onClickDetail(rec.idPrescripcionGrupo, props.patientInfo?.nameNombre + ' ' + props.patientInfo?.nameApellido1, false);
                }

                return (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Tooltip title={t('detallePrescBoton')}>
                            <Button type='default' onClick={onClick} style={{ marginRight: '10px' }}>{t('buttons:details')}</Button>
                        </Tooltip>
                        {rec.estadoPrescripcion === 1 && !props.hideOptions && <PrescriptionOptionsContainer idPatient={rec.idPaciente} idPrescripcionGrupo={rec.idPrescripcionGrupo} />}
                    </div>
                );
            }
        }

    ];

    const listaPrescripcionesFin = [
        {
            dataIndex: 'dateCreacion', align: 'left' as AlignType, width: '5rem',
            render: (dateCreacion: Date) => {
                const act = dateCreacion && Functions.dateToString(dateCreacion)
                return <span> {act} </span>;
            }
        },
        {
            dataIndex: 'valuePrescripcion', ellipsis: true, align: 'left' as AlignType, width: '35%',
            render: (value: any, rec: any) => {
                return <span>
                    {rec.valuePrescripcion}
                </span>
            }
        }, { dataIndex: 'estadoPrescripcion', align: 'left' as AlignType, width: '5rem', render: statusPrescriptionRenderer },
        { dataIndex: 'estadoDispensacion', align: 'left' as AlignType, width: '5rem', render: statusDispensacionRenderer, },
        {
            dataIndex: 'adherencia', ellipsis: true, align: 'left' as AlignType, width: '15%',
            render: (value: any, rec: any) => {
                if (rec.adherencia != null) {
                    return <span>
                        {t('adherencias')} {rec.adherencia} %
                    </span>
                } else {
                    return <span>{t('noDispensada')}</span>
                }
            }
        },
        {
            align: 'right' as AlignType, width: '6.5rem', render: (value: any, rec: PrescripcionData) => {

                const onClick = () => {
                    props.onClickDetail(rec.idPrescripcionGrupo, props.patientInfo?.nameNombre + ' ' + props.patientInfo?.nameApellido1, false);
                }

                return (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Tooltip title={t('detallePrescBoton')}>
                            <Button type='default' onClick={onClick} style={{ marginRight: '10px' }}>{t('buttons:details')}</Button>
                        </Tooltip>
                        {rec.estadoPrescripcion === 1 && !props.hideOptions ? (
                            <PrescriptionOptionsContainer idPatient={rec.idPaciente} idPrescripcionGrupo={rec.idPrescripcionGrupo} />
                        ) : (
                            // Aquí puedes colocar lo que quieres que se renderice en el caso del else
                            <div style={{ width: '2rem' }}></div>
                        )}
                    </div>
                );
            }
        }

    ];

    var checkedEmbarazo;
    if (props.patientInfo?.flagEmbarazo == null || props.patientInfo?.flagEmbarazo == 0) {
        checkedEmbarazo = false;
        var colorEmbarazo = "#000000";
        var semanas = "";
    } else {
        checkedEmbarazo = true;
        //var colorEmbarazo = "#FF0000";
        var colorEmbarazo = "#000000";

        var semanas = props.patientInfo?.flagEmbarazo + " " + t('semanas');
    }

    var checkedLactancia;
    if (props.patientInfo?.flagLactancia == 0) {
        checkedLactancia = false;
    } else {
        checkedLactancia = true;
    }

    var tipo = "";
    if (props.patientInfo) {
        switch (props.patientInfo.typeIdentificacion) {
            case 1:
                tipo = "DNI";
                break;
            case 2:
                tipo = "NIE";
                break;
            case 3:
                tipo = t('pasaporte');
                break;
        }
    }

    var tipoT = "";
    if (props.patientInfo) {
        switch (props.patientInfo.typeIdentificacionTutor) {
            case 1:
                tipoT = "DNI";
                break;
            case 2:
                tipoT = "NIE";
                break;
            case 3:
                tipoT = t('pasaporte');
                break;
        }
    }


    //const dateNacimiento = new Date(dateIntro);
    var dateNac = props.patientInfo?.dateNacimiento;
    const fechaNac = dateNac?.toLocaleDateString();
    var dateprueba = "" + dateNac;
    age = 0;
    if (dateNac instanceof Date) {
        // Calcular la fecha actual
        var currentDate = new Date();

        // Calcular la diferencia en años
        var age = currentDate.getFullYear() - dateNac.getFullYear();

        // Ajustar la edad si aún no ha pasado el cumpleaños de este año
        if (currentDate.getMonth() < dateNac.getMonth() || (currentDate.getMonth() === dateNac.getMonth() && currentDate.getDate() < dateNac.getDate())) {
            age--;
        }
    }



    var tipoCliente = "";
    if (props.patientInfo && props.patientInfo.idMutualidad) {
        tipoCliente = t('pacienteMutualista');
    } else if (props.patientInfo && props.patientInfo.idAseguradora) {
        tipoCliente = t('pacienteAsegurado');
    } else {
        tipoCliente = t('pacientePrivado');
    }


    var cronico = false;
    if (props.patientInfo && props.patientInfo.valueDuracion && props.patientInfo.valueDuracion >= 365) {
        cronico = true;
        //var colorCronico = "#FF0000";
        var colorCronico = "#000000";

    } else {
        cronico = false;
        var colorCronico = "#000000";
    }

    const hayTutor = !!props.patientInfo?.nameNombreTutor;
    if (hayTutor) {
        var alturaIzq = "42.5rem";
        var alturaInfo = "25rem";
        var alturaAlergias = "20rem";
        var alturaTablaAlergias = "12.5rem";

    } else {
        var alturaIzq = "42.5rem"
        var alturaInfo = "20rem";
        var alturaAlergias = "20rem";
        var alturaTablaAlergias = "15rem";
    }

    const idioma = props.patientInfo?.ididioma;
    var nameIdioma = "";
    var pais = "";
    if (idioma == 1) {
        var nameIdioma = t('castellano');
        var pais = "ES";
    } else if (idioma == 2) {
        var nameIdioma = t('ingles');
        var pais = "GB";
    } else if (idioma == 3) {
        var nameIdioma = t('aleman');
        var pais = "DE";
    }

    const [isExpanded, setIsExpanded] = useState(true);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };


    //falta fecha de ultima actualizacion de embarazo

    return (
        <>
            <div style={{ overflowY: 'scroll', overflow: 'auto', height: '100%' }}>

                <div className="pantallaPaciente" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{
                        backgroundColor: '#F5F5F5',
                        borderRadius: '0.5rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '1rem',
                        marginLeft: '1rem',
                        marginRight: '1rem'
                    }}>
                        <span>
                            <span style={{ marginRight: '0.5rem', marginLeft: '1rem' }}>
                                <strong>{props.patientInfo?.codeIdentificacion}</strong>
                            </span>
                            <span style={{ marginRight: '0.3rem' }}>
                                {props.patientInfo?.nameNombre}
                            </span>
                            <span style={{ marginRight: '0.3rem' }}>
                                {props.patientInfo?.nameApellido1}
                            </span>
                            <span>
                                {props.patientInfo?.nameApellido2}
                            </span>
                        </span>
                        <div>
                            <Button type="primary" style={{ marginRight: '0.5rem' }} onClick={() => props.onClickPatientNewPrescription(props.idPaciente!)}>{t('prescribir')}</Button>
                            {/*<Button type="default" onClick={() => props.deletePaciente(props.idPaciente!)}>{t('darBaja')}</Button>*/}
                        </div>

                    </div>
                    <div className="uno" style={{ display: 'flex', margin: '1px', height: 'auto', marginBottom: '1rem' }}>



                        <div className="izq" style={{ display: 'flex', flexDirection: 'column', margin: '1px', height: 'auto', }}>
                            <div className="infoPaciente" style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F5F5F5', borderRadius: '0.5rem', margin: '1rem', maxHeight: alturaInfo, height: 'auto', padding: '1rem' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className='columnaInfo' style={{ display: 'flex', flexDirection: 'column', flex: 1, lineHeight: '1rem', marginLeft: '1rem' }}>
                                        <strong><p style={{ color: '#4A9CF2' }}>{t('datosPersonales')}
                                            <Button style={{ marginLeft: '10px', border: 'none', backgroundColor: 'none' }} onClick={() => props.onclickEditInfoPatient!()} type="primary" size="small" icon={TableIcons.getTableIcon(TableIcons.TableIcon.edit)}></Button></p></strong>
                                        <p>{t('nameNombre')}</p>
                                        <strong><p style={{ marginBottom: '1rem', marginTop: '-0.5rem' }}> {props.patientInfo?.nameNombre} {props.patientInfo?.nameApellido1} {props.patientInfo?.nameApellido2}</p></strong>
                                        {props.patientInfo?.nameNombreTutor ? (

                                            <>
                                                <p>{t('nameTutor')}</p>
                                                <strong>
                                                    <p className="item" >{props.patientInfo.nameNombreTutor} {props.patientInfo.nameApellido1Tutor} {props.patientInfo.nameApellido2Tutor}</p>
                                                </strong>
                                            </>
                                        ) : (
                                            <span>

                                            </span>
                                        )}
                                        <p>{t('tipoCliente')}</p>

                                        {tipoCliente ? (
                                            <>
                                                <strong><p className="item" >{tipoCliente}</p></strong>
                                            </>
                                        ) : (
                                            <span>
                                                <strong><p className="item" >-</p></strong>
                                            </span>
                                        )}
                                        <p>{t('numTarjeta')}</p>

                                        {props.patientInfo?.codeTarjetaSanitaria ? (
                                            <>
                                                <strong><p style={{ marginBottom: '2rem', marginTop: '-0.5rem' }}>{props.patientInfo?.codeTarjetaSanitaria}</p></strong>
                                            </>
                                        ) : (
                                            <span>
                                                <strong><p style={{ marginBottom: '2rem', marginTop: '-0.5rem' }}>-</p></strong>
                                            </span>
                                        )}
                                    </div>
                                    <div className='columnaInfo' style={{ display: 'flex', flexDirection: 'column', flex: 1, lineHeight: '1rem' }}>
                                        <br /><br />
                                        <p style={{ marginTop: '0.3rem' }}>{tipo} {t('paciente')}</p>
                                        <strong><p style={{ marginBottom: '1.1rem', marginTop: '-0.5rem' }} >{props.patientInfo?.codeIdentificacion}</p></strong>
                                        {props.patientInfo?.codeIdentificacionTutor ? (
                                            <>
                                                <p>{tipoT} {t('nameTutor')}</p>
                                                <strong>
                                                    <p className="item">{props.patientInfo.codeIdentificacionTutor}</p>
                                                </strong>
                                            </>
                                        ) : (
                                            <span>

                                            </span>

                                        )}
                                        <p>{t('aseguradora')}</p>
                                        {props.patientInfo?.nameAseguradora ? (
                                            <>
                                                <strong><p className="item" >{props.patientInfo?.nameAseguradora}</p></strong>
                                            </>
                                        ) : (
                                            <span>
                                                <strong><p className="item" >-</p></strong>
                                            </span>
                                        )}
                                        <p>EMAIL</p>

                                        {props.patientInfo?.valueEmail ? (
                                            <>
                                                <strong><p style={{ marginBottom: '1.5rem', marginTop: '-0.5rem' }}>{props.patientInfo?.valueEmail}</p></strong>
                                            </>
                                        ) : (
                                            <span>
                                                <strong><p style={{ marginBottom: '1.5rem', marginTop: '-0.5rem' }}>-</p></strong>
                                            </span>
                                        )}
                                    </div>
                                    <div className='columnaInfo' style={{ display: 'flex', flexDirection: 'column', flex: 1, lineHeight: '1rem' }}>
                                        <br />

                                        <p style={{ marginTop: '1.3rem' }}>{t('idioma')}</p>
                                        {nameIdioma != "" ? (
                                            <strong><p style={{ marginBottom: '0.7rem', marginTop: '-0.8rem' }}>
                                                <Flag style={{ marginRight: '0.5rem' }} country={pais} /> {nameIdioma}</p></strong>
                                            ) : (
                                                <span>
                                                    <strong><p style={{ marginBottom: '1rem', marginTop: '-0.5rem' }}>-</p></strong>
                                                </span>
                                            )
                                        }

                                        <p style={{ marginTop: '0.1rem' }}>{t('fechaNac')}</p>
                                        <strong><p style={{ marginBottom: '1.1rem', marginTop: '-0.5rem' }}>{fechaNac}</p></strong>
                                        <p>{t('telefono')}</p>
                                        {props.patientInfo?.valueTelefono ? (
                                            <>
                                                <strong><p style={{ marginBottom: '2rem', marginTop: '-0.55rem' }}>{props.patientInfo?.valueTelefono}</p></strong>
                                            </>
                                        ) : (
                                            <span>
                                                <strong><p style={{ marginBottom: '2rem', marginTop: '-0.55rem' }}>-</p></strong>
                                            </span>
                                        )}
                                    </div>

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ fontSize: '0.9rem' }}><input checked={props.patientInfo?.hasConsentimiento} style={{ marginRight: '1rem', marginLeft: '1rem', width: '1rem', height: '1rem', marginTop: '-2rem' }} type='checkbox' />{t('infoConsentimiento')}{/*<a style={{ color: '#4A9CF2', marginLeft: '1rem' }}><strong>{t('masInfo')}</strong></a>*/}</p>

                                </div>
                            </div>
                            <div className="infoSalud2" style={{ backgroundColor: '#F5F5F5', height: 'auto', maxHeight: '13rem' }}>
                                <div>
                                    <strong><p style={{ color: '#4A9CF2', marginLeft: '1rem' }}>{t('datosSalud')} <Button style={{ marginLeft: '10px', border: 'none', backgroundColor: 'none' }} type="primary" size="small" onClick={() => props.onclickDatosSalud(1)} icon={TableIcons.getTableIcon(TableIcons.TableIcon.edit)}></Button></p></strong>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', lineHeight: '1rem' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1rem', marginLeft: '1rem' }}>
                                        <p>{t('altura')}</p>
                                        <strong><p>{props.patientInfo?.valueAltura}</p></strong>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1rem', marginLeft: '5rem' }}>
                                        <p>{t('peso')}</p>
                                        <strong><p>{props.patientInfo?.valuePeso}</p></strong>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1rem', marginLeft: '5rem' }}>
                                        <p>{t('edad')}</p>
                                        <strong><p>{age}</p></strong>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', lineHeight: '1rem', gap: '1rem' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1rem', color: colorEmbarazo }}>
                                        <p><strong><input checked={checkedEmbarazo} style={{ marginRight: '1rem', marginLeft: '1rem', width: '1rem', height: '1rem', verticalAlign: 'middle' }} type='checkbox' />{t('flagEmbarazo')} {semanas}</strong></p>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1rem', marginLeft: '7rem' }}>
                                        <p><input checked={checkedLactancia} style={{ marginRight: '1rem', marginLeft: '1rem', width: '1rem', height: '1rem', verticalAlign: 'middle' }} type='checkbox' />{t('flagLactancia')}</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', lineHeight: '1rem', gap: '5rem' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1rem', color: colorCronico }}>
                                        <p><strong><input checked={cronico} style={{ marginRight: '1rem', marginLeft: '1rem', width: '1rem', height: '1rem', verticalAlign: 'middle' }} type='checkbox' />{t('pacienteCronico')}</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div className="infoAlergias" style={{ backgroundColor: '#F5F5F5', borderRadius: '0.5rem', margin: '1rem', top: 'auto', padding: '1rem', height: 'auto' }}>
                                <strong><p style={{ color: '#4A9CF2', marginLeft: '1rem' }}>{t('alergiasIntolerancias')}<Button style={{ marginLeft: '10px', border: 'none', backgroundColor: 'none' }} type="primary" size="small" onClick={() => props.onAddAlergies()} icon={TableIcons.getTableIcon(TableIcons.TableIcon.plus)}></Button></p></strong>
                                <div className='table-container' style={{ height: 'auto', marginLeft: '1rem', marginRight: '1rem' }}>

                                    <Table
                                        columns={listaAlergias}
                                        dataSource={props.alergiasList}
                                        size='small'
                                        pagination={false}
                                        showHeader={false}
                                    />
                                </div>
                            </div>
                        </div>



                        <div className="der" style={{ display: 'flex', flexDirection: 'column', margin: '1px', height: 'auto' }}>
                            <div className="infoSalud" style={{ backgroundColor: '#F5F5F5', height: 'auto', maxHeight: '13rem' }}>
                                <div>
                                    <strong><p style={{ color: '#4A9CF2', marginLeft: '1rem' }}>{t('datosSalud')} <Button style={{ marginLeft: '10px', border: 'none', backgroundColor: 'none' }} type="primary" size="small" onClick={() => props.onclickDatosSalud(1)} icon={TableIcons.getTableIcon(TableIcons.TableIcon.edit)}></Button></p></strong>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', lineHeight: '1rem' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1rem', marginLeft: '1rem' }}>
                                        <p>{t('altura')}</p>
                                        <strong><p>{props.patientInfo?.valueAltura}</p></strong>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1rem', marginLeft: '5rem' }}>
                                        <p>{t('peso')}</p>
                                        <strong><p>{props.patientInfo?.valuePeso}</p></strong>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1rem', marginLeft: '5rem' }}>
                                        <p>{t('edad')}</p>
                                        <strong><p>{age}</p></strong>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', lineHeight: '1rem', gap: '1rem' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1rem', color: colorEmbarazo }}>
                                        <p><strong><input checked={checkedEmbarazo} style={{ marginRight: '1rem', marginLeft: '1rem', width: '1rem', height: '1rem', verticalAlign: 'middle' }} type='checkbox' />{t('flagEmbarazo')} {semanas}</strong></p>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1rem', marginLeft: '7rem' }}>
                                        <p><input checked={checkedLactancia} style={{ marginRight: '1rem', marginLeft: '1rem', width: '1rem', height: '1rem', verticalAlign: 'middle' }} type='checkbox' />{t('flagLactancia')}</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', lineHeight: '1rem', gap: '5rem' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1rem', color: colorCronico }}>
                                        <p><strong><input checked={cronico} style={{ marginRight: '1rem', marginLeft: '1rem', width: '1rem', height: '1rem', verticalAlign: 'middle' }} type='checkbox' />{t('pacienteCronico')}</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div className="infoAlergias2" style={{ backgroundColor: '#F5F5F5', borderRadius: '0.5rem', margin: '1rem', top: 'auto', padding: '1rem', height: 'auto' }}>
                                <strong><p style={{ color: '#4A9CF2', marginLeft: '1rem' }}>{t('alergiasIntolerancias')}<Button style={{ marginLeft: '10px', border: 'none', backgroundColor: 'none' }} type="primary" size="small" onClick={() => props.onAddAlergies()} icon={TableIcons.getTableIcon(TableIcons.TableIcon.plus)}></Button></p></strong>
                                <div className='table-container' style={{ height: 'auto', color: 'red', marginLeft: '1rem', marginRight: '1rem' }}>

                                    <Table
                                        columns={listaAlergias}
                                        dataSource={props.alergiasList}
                                        size='small'
                                        pagination={false}
                                        showHeader={false}

                                    />

                                </div>
                            </div>
                            <div className="infoPatologia" style={{ backgroundColor: '#F5F5F5', display: 'flex', flexDirection: 'column', height: 'auto' }}>
                                <strong><p style={{ color: '#4A9CF2', marginLeft: '1rem' }}>{t('patologiasDiagnosticos')}<Button style={{ marginLeft: '10px', border: 'none', backgroundColor: 'none' }} type="primary" size="small" onClick={() => props.onAddPatologies()} icon={TableIcons.getTableIcon(TableIcons.TableIcon.plus)}></Button></p></strong>
                                <div className='table-container' style={{ height: 'auto', marginLeft: '1rem', marginRight: '1rem' }}>
                                    <Table
                                        columns={listaPatologias}
                                        dataSource={props.patologiasList}
                                        size='small'
                                        pagination={false}
                                        showHeader={false}
                                    />
                                </div>
                            </div>
                        </div>




                    </div>
                    <div className="infoTratamientosActivos" style={{ backgroundColor: '#F5F5F5', height: 'auto', marginTop: 'auto' }}>
                        <strong><p style={{ color: '#4A9CF2', marginLeft: '1rem', marginRight: '1rem' }}>{t('tratamientosActivos')} <Button style={{ border: 'none', backgroundColor: 'none', marginLeft: '0.5rem' }} size="small" onClick={() => props.onClickPatientNewPrescription(props.idPaciente!)} type="primary" icon={TableIcons.getTableIcon(TableIcons.TableIcon.plus)}></Button></p></strong>
                        <div className='table-container' style={{ height: 'auto', marginLeft: '1rem', marginRight: '1rem' }}>

                            <Table
                                columns={listaPrescripcionesAct}
                                dataSource={props.prescriptionListAct}
                                size='small'
                                pagination={false}
                                showHeader={false}
                            />

                        </div>
                    </div>



                    <div className="infoTratamientosNoActivos" style={{ backgroundColor: '#FFECEC', height: 'auto' }}>
                        <strong>
                            <p style={{ color: '#4A9CF2', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '1rem', }}>
                                {t('tratamientosFin')}
                            </p>
                        </strong>
                        <div className='table-container' style={{ height: 'auto', marginLeft: '1rem', marginRight: '1rem' }}>
                            <Table
                                columns={listaPrescripcionesFin}
                                dataSource={props.prescriptionListFin}
                                size='small'
                                pagination={false}
                                showHeader={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InfoPaciente;