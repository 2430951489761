import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import getLocale from './i18n/antdLocales';
import { ConfigProvider } from 'antd';

import 'moment/min/locales';
import moment from 'moment';

moment.locale(i18n.language);

ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <ConfigProvider locale={getLocale(i18n.language)} form={{validateMessages: {required: ' '}}}>
                <App />
            </ConfigProvider>
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();