import React from 'react';

import { Checkbox, Col, DatePicker, Form, Input, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Medicine from '../entities/medicine';
import { IState as IStateContainer } from './OtherDetailsContainer';
import { FormInstance } from 'antd/es/form/Form';
import OtherDetailsInfo from '../entities/otherDetailsInfo';
import moment from 'moment';
import { FormUtils } from '../../../utils/utils';
import Dcpf from '../entities/dcpf';
import MedicineInfoPopOver from './MedicineInfoPopOver';

const OtherDetails = (props: {
    visible?: boolean;
    medicine?: Medicine;
    dcpf?: Dcpf;
    otherProduct?: any;
    otherDetails?: OtherDetailsInfo;
    productSpecification?: string;
    onCancel: () => void;
    validateAndSave: (form: FormInstance) => void;
    setMedicine: (medicine: any) => Medicine;
} & IStateContainer) => {

    const { t } = useTranslation(['medicineConfig']);
    
    const [form] = Form.useForm();
    
    const onChangeNumber = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const { value } = e.target;
        if (isNaN(Number(value))) {
            const units = form.getFieldValue(field).slice(0, -1);
            form.setFieldsValue({...form.getFieldsValue(), [field]: units});
        }
    }

    return (
        <Modal title={t('otherDetailsModalTitle')}
            style={{ top: 40 }}
            visible={props.visible}
            onCancel={() => props.onCancel()}
            bodyStyle={{paddingTop:'0.5rem', paddingBottom: '1.5rem'}}
            width={700}
            destroyOnClose
            okText={t('buttons:save')} cancelText={t('buttons:cancel')}
            onOk={() => props.validateAndSave(form)}
             >

            <div className='medication-card__container'>
                {props.productSpecification ?
                    <div style={{fontSize: '1rem', fontWeight: 'bold'}}>{props.productSpecification}</div>
                :
                props.dcpf ?
                    <div className='medication-card-info'>
                        <div className='medication-info'>
                            <div className='medication-name'>
                                {props.dcpf?.nameDcpf}
                            </div>
                            <div className='medication-cn'>{t('nationalCodeField') + ' ' + props.dcpf?.codeDcpf}</div>
                        </div>
                    </div>
                :
                (props.otherProduct ? 
                    <div style={{fontSize: '1rem', fontWeight: 'bold'}}>{props.otherProduct.nameProducto}</div>
                :
                    <MedicineInfoPopOver
                        visible={true}
                        medicine={props.medicine}
                        setMedicine={props.setMedicine}
                     />)
                }
            </div>

            <Form layout='vertical' form={form} size='large' style={{paddingTop: '1rem'}}>
                <Form.Item name='confidentialPrescription' valuePropName='checked' style={{marginBottom: '0px'}} initialValue={!!props.otherDetails?.pin}>
                    <Checkbox><b>{t('confidentialPrescriptionLabel')}</b></Checkbox>
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                {() => form.getFieldValue('confidentialPrescription') &&
                    <Row gutter={8} style={{marginLeft: '1.3rem'}}>
                        <Col span={12}>
                            <Form.Item name='pin' label={t('pinLabel')} className='input-right-align' rules={[{ required: true }]} initialValue={props.otherDetails?.pin}
                                validateStatus={FormUtils.isError('pin', props.errors) ? 'error' : undefined} help={FormUtils.getErrorMessage('pin', props.errors)}>
                                <Input maxLength={4} onChange={(e) => onChangeNumber(e, 'pin')} />
                            </Form.Item>
                        </Col>
                    </Row>
                }
                </Form.Item>
                
                {/*<Form.Item name='isVisado' valuePropName='checked' style={{marginBottom: '0px'}} initialValue={props.otherDetails?.isVisado}>
                    <Checkbox><b>{t('visadoLabel')}</b></Checkbox>
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                {() => form.getFieldValue('isVisado') &&
                    <Row gutter={8} style={{marginLeft: '1.3rem'}}>
                        <Col span={12}>
                            <Form.Item name='visadoStart' label={t('iniVisado')} initialValue={props.otherDetails?.visadoStart && moment(props.otherDetails?.visadoStart)}>
                                <DatePicker format='L' style={{width:'100%'}} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='visadoEnd' label={t('endVisado')} initialValue={props.otherDetails?.visadoEnd && moment(props.otherDetails?.visadoEnd)}
                                validateStatus={FormUtils.isError('visadoEnd', props.errors) ? 'error' : undefined} help={FormUtils.getErrorMessage('visadoEnd', props.errors)}>
                                <DatePicker format='L' style={{width:'100%'}} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='visadoInput' label={t('inputVisado')} className='input-right-align' initialValue={props.otherDetails?.visadoInput}>
                                <Input maxLength={3} onChange={(e) => onChangeNumber(e, 'visadoInput')} suffix='%'/>
                            </Form.Item>
                        </Col>
                    </Row>
                }
                </Form.Item>*/}
            </Form>
        </Modal>
    )
}
export default OtherDetails;