import { FormInstance } from "antd";
import { Store } from "antd/lib/form/interface";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import EnumCodeIdentificationType from "../enum/EnumCodeIdentificationType";
import Municipio from "../prescriptions/locations/entities/municipio";
import Provincia from "../prescriptions/locations/entities/provincia";
import FormErrorField from "../utils/form/formErrorField";
import { FormUtils, Rest, validarEmail, validateDNI, validateCif } from "../utils/utils";
import GoToTpvData from "./entities/goToTpvData";
import SubscriptionData from "./entities/subscriptionData";
import SubscriptionLinkExpiredModal from "./SubscriptionLinkExpiredModal";
import SubscriptionRenovation from "./SubscriptionRenovation";

export interface IState {
    loaded: boolean;
    stepHigh: boolean;
    subscriptionData?: SubscriptionData;
    provinciaList?: Provincia[];
    municipioList?: Municipio[];
    codeProvincia?: string;
    errorFields: FormErrorField[];
    linkExpiredVisible: boolean;
    linkExpiredKey?: number;
}
class SubscriptionRenovationContainer extends React.Component<WithTranslation & RouteComponentProps<{ token: string }>, IState> {

    public token: string = this.props.match.params.token;

    state: IState = {
        loaded:false,
        errorFields: [],
        stepHigh: true,
        linkExpiredVisible: false
    };

    public componentDidMount() {
        this.loadData();
    }

    private loadData = () => {
        const promises: Array<Promise<any>> = [];
        const rqProvincias = Rest<{ type: string }, Provincia[]>();
        const rqPagoLicenciaData =  Rest<{ type: string, token: string }, SubscriptionData>();
        promises.push(rqProvincias.operation({ type: 'SelectProvincia' }));
        promises.push(rqPagoLicenciaData.operation({type: 'GetPagoLicenciaData',token: this.token}));

        Promise.all(promises).then(response => {
            if (response[0]){
                this.setState({provinciaList: response[0]});
            }
            if (response[1]) {
                this.setState({subscriptionData: response[1], loaded: true});
                if(response[1].valueProvincia){
                    this.setState({codeProvincia:response[1].valueProvincia})
                    this.onChangeProvince(response[1].valueProvincia);
                }
            }
           
        });
    };

    private onChangeProvince = (codeProvincia: string) => {
        Rest<{ type: string, codeProvincia: string }, Municipio[]>().operation(
            {type: 'SelectMunicipioByProvincia', codeProvincia: codeProvincia}).then(response =>
            this.setState({municipioList: response, codeProvincia}));
    };

    private onChangeCP = (form: FormInstance, e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        if (isNaN(Number(value))) {
            const cp = form.getFieldValue('codigoPostal').slice(0, -1);
            form.setFieldsValue({...form.getFieldsValue(), ['codigoPostal']: cp});
        }

        const cp = form.getFieldValue('codigoPostal');
        if (cp != undefined && cp.length === 5) {
            form.setFieldsValue({
                ...form.getFieldsValue(),
                codeProvincia: this.state.provinciaList?.find(i => i.codeProvincia===cp.substring(0, 2))?.codeProvincia,
                codeMunicipio: undefined,
            });
            this.onChangeProvince(cp.substring(0, 2));
        }else{
            form.setFieldsValue({codeProvincia:undefined});
            this.setState({codeProvincia:""});
        }
    };

    private validateAndSave = (form: FormInstance) => {
        this.setState({errorFields: []}, () => {
            form.validateFields().then((values) => {
                this.validate(values).then(errors => {
                    if (!errors || errors.length === 0) {
                        const subscriptionData = {
                            valueNif: values.codeIdentificacion,
                            valueTipoNif: values.tipoCliente == 1 ? values.typeDocParticular : values.typeDocEmpresa,
                            valueTipoFacturador: values.tipoCliente,
                            valueNombre: values.valueNombre,
                            valuePrimerApellido: values.valuePrimerApellido,
                            valueSegundoApellido: values.valueSegundoApellido,
                            valueDireccion: values.valueDireccion,
                            valueDetalleDireccion: values.valueDetalleDireccion,
                            valueCodigoPostal: values.codigoPostal,
                            valueProvincia: values.codeProvincia,
                            valuePoblacion: values.codeMunicipio,
                            valueEmail: values.valueEmail,
                            valueTelefono: values.valueTelefono,
                            valueColegiado: this.state.subscriptionData?.valueColegiado,
                            dateHasta: this.state.subscriptionData?.dateHasta,
                            amntPrice: this.state.subscriptionData?.amntPrice,
                            amntIva: this.state.subscriptionData?.amntIva
                        } as SubscriptionData;

                        Rest<{ type: string, token: String, subscriptionData: SubscriptionData }, GoToTpvData>()
                        .operation({type: 'SendSubscriptionDataForTpv', token: this.token, subscriptionData: subscriptionData})
                        .then( (response) =>{
                            if(response==null){
                                this.setState({linkExpiredVisible: true, linkExpiredKey:  new Date().getTime()})
                               
                            }else{
                                this.GoToTPV(response.url,response.dsSignatureVersion,response.dsMerchantParameters,response.dsSignature);
                            }
                            
                        });
                    } else {
                        this.setState({errorFields: errors});
                    }
                });
            });
        });
    };

    private onBackToHomeFromExpiredLink = () => {
        this.onCloseSubscriptionLinkExpired();
        this.props.history.push('/login');
    }

    private onCloseSubscriptionLinkExpired = () => {
        this.setState({linkExpiredVisible:false});
    }

    private validate = (values: Store) => {
        let errors: FormErrorField[] = [];
        var typeIdentificacion = (values.tipoCliente == 1) ? values.typeDocParticular : values.typeDocEmpresa;

        return new Promise((resolve: (f: FormErrorField[]) => void) => {
            if (!validarEmail(values.valueEmail)) {
                const emailError = {fieldName: 'valueEmail', errorMessage: this.props.t('formatoIncorrectoError')};
                errors = FormUtils.addError(this.state.errorFields, emailError);

            } 
            
            if (typeIdentificacion === EnumCodeIdentificationType.CIF) {
                if (!validateCif(values.codeIdentificacion)) {
                    const cifError = {fieldName: 'codeIdentificacion', errorMessage: this.props.t('incorrectCif')};
                    errors = FormUtils.addError(this.state.errorFields, cifError);
                   
                }
            } else if (typeIdentificacion === EnumCodeIdentificationType.PASAPORTE) { //Pasaporte value check
                if (values.codeIdentificacion.toString().length < 4) {
                    const passportError = {fieldName: 'codeIdentificacion', errorMessage: this.props.t('invalidPasaporteNumero')};
                    errors = FormUtils.addError(this.state.errorFields, passportError);
                }
            } else {
                if(!validateDNI(values.codeIdentificacion,
                typeIdentificacion.toString() === EnumCodeIdentificationType.NIE.toString())) { //DNI/NIE value check
                    const nifError = {fieldName: 'codeIdentificacion', errorMessage: this.props.t('incorrectNif')};
                    errors = FormUtils.addError(this.state.errorFields, nifError);
                }
            }
            resolve(errors);
        });
    };

    private GoToTPV = (url: string, Ds_SignatureVersion: string, Ds_MerchantParameters: string, Ds_Signature: string) => {
        const form = document.createElement('form') as HTMLFormElement;
        form.action = url;
        form.method = 'POST';
        document.body.appendChild(form);
        const input1 = document.createElement('INPUT') as HTMLInputElement;
        input1.name = 'Ds_SignatureVersion';
        input1.type = 'hidden';
        input1.value = Ds_SignatureVersion;
        form.appendChild(input1);
        const input2 = document.createElement('INPUT') as HTMLInputElement;
        input2.name = 'Ds_MerchantParameters';
        input2.type = 'hidden';
        input2.value = Ds_MerchantParameters;
        form.appendChild(input2);
        const input3 = document.createElement('INPUT') as HTMLInputElement;
        input3.name = 'Ds_Signature';
        input3.type = 'hidden';
        input3.value = Ds_Signature;
        form.appendChild(input3);
        form.submit();
    };

    public render() {
        return (
            this.state.loaded &&
                <>
                    {this.state.stepHigh &&
                        <SubscriptionRenovation
                            subscriptionData={this.state.subscriptionData}
                            provinciaList={this.state.provinciaList}
                            municipioList={this.state.municipioList}
                            codeProvincia={this.state.codeProvincia}
                            onChangeCP={this.onChangeCP}
                            validateAndSave={this.validateAndSave}
                            errors={this.state.errorFields}
                        />
                    }
                    <SubscriptionLinkExpiredModal
                    key={this.state.linkExpiredKey}
                    visible={this.state.linkExpiredVisible}
                    onClose={this.onCloseSubscriptionLinkExpired}
                    onChange={this.onBackToHomeFromExpiredLink}
                    />
               </>
               );
    }     

}

export default withTranslation('subscription')(withRouter(SubscriptionRenovationContainer));