import React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Rest, FormUtils, validarEmail } from '../../utils/utils';
import { FormInstance, Store } from 'rc-field-form/lib/interface';
import View from './newLocation';
import Provincia from './entities/provincia';
import Municipio from './entities/municipio';
import {LocationPrestador} from './entities/locationPrestador';
import FormErrorField from '../../utils/form/formErrorField';

interface IProps {
    visible?: boolean;
    locationForEdit?: LocationPrestador;
    modeNewLocation: boolean;
    addNewLocation: () => void;
    onClose: () => void;
    reloadSelectedLocation: (idLocalizacion: number) => void;
}

export interface IState {
    codeProvincia?: string;
    provinciaList?: Provincia[];
    municioioList?: Municipio[];
    errorFields: FormErrorField[];
    newLocationKey?: number;
}

class NewLocationContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    public state: IState = {
        errorFields: []
    };

    public componentDidMount() {
        if (this.props.visible) {
            this.loadData();
            this.setState({newLocationKey: new Date().getTime()});
        }
    }

    public render() {
        return (
            <View
                {...this.state}
                key={this.state.newLocationKey}
                visible={this.props.visible}
                locationForEdit={this.props.locationForEdit}
                onChangeCP={this.onChangeCP}
                onCancel={() => this.props.onClose()}
                onSubmit={this.validateAndSave}
            />
        );
    }

    private loadData = () => {
        if (this.props.modeNewLocation) { //For new location
            Rest<{ type: string }, Provincia[]>().operation({ type: 'SelectProvincia' }).then(
                response => this.setState({ provinciaList: response }));
        } else {
            this.loadSelectedData();
        }

    }

    private loadSelectedData = () => {
        const promises: Array<Promise<any>> = [];
        const rqProvincias = Rest<{ type: string }, Provincia[]>();
        const rqMunicipios = Rest<{ type: string; codeProvincia: string }, Municipio[]>();

        promises.push(rqProvincias.operation({ type: 'SelectProvincia' }));
        if(this.props.locationForEdit?.codeProvincia){
            promises.push(rqMunicipios.operation({
                type: 'SelectMunicipioByProvincia',
                codeProvincia: this.props.locationForEdit?.codeProvincia!
            }));
        }

        Promise.all(promises).then(response => {
            this.setState({
                provinciaList: response[0],
                municioioList: this.props.locationForEdit?.codeProvincia ? response[1] : undefined,
            });

        });
    }

    private onChangeProvince = (codeProvincia: string) => {
        Rest<{ type: string, codeProvincia: string }, Municipio[]>().operation(
            { type: 'SelectMunicipioByProvincia', codeProvincia: codeProvincia }).then(response =>
                this.setState({ municioioList: response, codeProvincia }));
    }

    private onChangeCP = (form: FormInstance, e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (isNaN(Number(value))) {
            form.setFieldsValue({ ...form.getFieldsValue(), ['codigoPostal']: form.getFieldValue('codigoPostal').slice(0, -1) });
        }
        
        const cp = form.getFieldValue('codigoPostal');
        if (cp.length == 0){
            form.setFieldsValue({codeProvincia: undefined});
        }
        else if (cp.length === 5) {
            form.setFieldsValue({ ...form.getFieldsValue(), codeProvincia: cp.substring(0, 2), codeMunicipio: undefined });
            this.onChangeProvince(cp.substring(0, 2));
        }
    }

    private validateAndSave = (form: FormInstance) => {

        this.setState({ errorFields: [] }, () => {
            form.validateFields().then((values) => {
                this.validate(values).then(errors => {
                    if (!errors || errors.length === 0) {
                        const location = {
                            nameLocalizacion: values.nameLocation,
                            valueDireccion: values.address,
                            valueDireccion2: values.addressDetail,
                            codeCodigoPostal: values.codigoPostal,
                            codeProvincia: values.codeProvincia,
                            codeMunicipio: values.codeMunicipio,
                            valueEmail: values.valueEmail,
                            valueTelefono: values.valueTelefono,
                            idPrestador: this.props.locationForEdit?.idPrestador,
                            idLocalizacion: this.props.locationForEdit?.idLocalizacion,
                            selected: false
                        } as LocationPrestador;

                        if (!this.props.modeNewLocation && typeof this.props.locationForEdit !== 'undefined') {
                            Rest<{ type: string, location: LocationPrestador }, number>().operation({
                                type: 'UpdateLocationForPrescription',
                                location: location
                            }).then(response => {
                                location.idLocalizacion = response;
                                this.props.reloadSelectedLocation(location.idLocalizacion);
                            });

                        } else {
                            Rest<{ type: string, location: LocationPrestador }, number>().operation({
                                type: 'NewLocationForPrescription',
                                location: location
                            }).then(response => {
                                location.idLocalizacion = response;
                                this.props.addNewLocation();
                            });
                        }
                    } else {
                        this.setState({ errorFields: errors });
                    }
                });
            });
        });

    }

    private validate = (values: Store) => {

        return new Promise((resolve: (f: FormErrorField[]) => void) => {
            if (values.valueEmail!="" && values.valueEmail && !validarEmail(values.valueEmail)) {
                const emailError = { fieldName: 'valueEmail', errorMessage: this.props.t('formatoIncorrectoError') };
                const errors: FormErrorField[] = FormUtils.addError(this.state.errorFields, emailError);
                resolve(errors);
            } else {
                resolve([]);
            }
        });
    }

}

export default withTranslation('newLocation')(withRouter(NewLocationContainer));