import React, { createRef } from 'react';

import { Route, BrowserRouter, Switch, Redirect, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { setHistory as setRestHistory } from './utils/rest';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import EventHub, { Event } from './utils/eventHub';
import ConsentContainer from './consent/ConsentContainer';
import MainLayout from './MainLayout';
import Login from './login/IndexContainer';
import './App.scss';
import NotValidLink from './consent/NotValidLink';
import WelcomeContainer from './welcome/welcomeContainer';
import RecoverPasswordContainer from './recoverPassword/RecoverPasswordContainer';
import RecoverPasswordCorrect from './recoverPassword/RecoverPasswordCorrect';
import MainLayoutGCO from './gco/MainLayoutGCO';
import MainLayoutEXT from './externalAccess/MainLayoutEXT';
import SubscriptionRenovationContainer from './subscription/SubscriptionRenovationContainer';
import SimpleLandingView from './layout/SimpleLandingView';
import DetallePrescripcionEmitidaContainer from './prescriptions/detalle/DetallePrescripcionEmitidaContainer';
import registroExternoContainer from './welcome/external/registroExternoContainer';

interface IState {
    showLoadingMask?: boolean; 
}

export default class App extends React.Component<{}, IState> {

    public state: IState = {showLoadingMask: false};

    private restCalls: number = 0;
    private timerFadeInEffect?: any;
    private timerFadeOutEffect?: any;
    private loadingMaskRef = createRef<HTMLDivElement>();

    public componentDidMount() {

        EventHub.on(Event.LOADING_ON, () => {
            this.maskOn();
        });

        EventHub.on(Event.LOADING_OFF, () => {
            this.maskOff();
        });
    } 

    public render() {

        return (
            <>
                {this.state.showLoadingMask && <div ref={this.loadingMaskRef} className='app-loading-mask'><LoadingOutlined style={{ fontSize: 48 }} spin /></div>}
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <AppRoutesWithRouter/>
                </BrowserRouter>
            </>
        );
    }

    private maskOn = () => {
        this.restCalls++;

        if (this.restCalls === 1) {
            clearTimeout(this.timerFadeOutEffect);
            this.setState({showLoadingMask: true}, () => {
                this.timerFadeInEffect = setTimeout(() => this.loadingMaskRef.current!.style.opacity = '1', 50);
            });
        }
    }

    private maskOff = () => {
        this.restCalls--;

        if (this.restCalls < 0) {
            this.restCalls = 0;
        }

        if (this.restCalls === 0 && this.loadingMaskRef.current) {
            clearTimeout(this.timerFadeInEffect);
            this.loadingMaskRef.current!.style.opacity = '0';
            clearTimeout(this.timerFadeOutEffect);
            this.timerFadeOutEffect = setTimeout(() => this.setState({showLoadingMask: false}), 500);
        }
    }
}

class AppRoutes extends React.Component<RouteComponentProps> {

    public componentDidMount() {
        setRestHistory(this.props.history);
    }
    
    public render() {
        return (
            <Switch>
                <Route path='/registro/:token' component={WelcomeContainer} />
                <Route path='/registroExterno/:token' component={registroExternoContainer} />
                <Route path='/suscripcion/:token' component={SubscriptionRenovationContainer}/>
                <Route path='/consentimiento/:token' component={ConsentContainer} />
                <Route path='/consentimientoAceptado' render={() => <SimpleLandingView title={'consentTitle'} text={'consentAcepted'} isSuccess={true} />}/>
                <Route path='/consentimientoRechazado' render={() => <SimpleLandingView title={'consentTitle'} text={'consentRefused'} isSuccess={false} />} />
                <Route path='/pagosuscripcion-ok' render={() => <PagoSuscripcionOK />} />
                <Route path='/pagosuscripcion-ko' render={() => <PagoSuscripcionKO />} />
                <Route path='/regeneracion-password/:token' component={RecoverPasswordContainer} />
                <Route path='/regeneracion-password-correcta' component={RecoverPasswordCorrect} />
                <Route path='/enlaceNoValido' component={NotValidLink} />
                <Route path='/app' component={MainLayout} />
                <Route path='/gco' component={MainLayoutGCO} />
                <Route exact path='/ext' component={MainLayoutEXT} />
                <Route exact path='/ext/:token' component={DetallePrescripcionEmitidaContainer} />
                <Route path='/login' component={Login} />
                <Redirect to='login'/>
            </Switch>
        );
    }
}

const PagoSuscripcionOK = () => {

    const history = useHistory();

    return (
        <SimpleLandingView title={'pagoTitle'} text={'pagoAcepted'} isSuccess={true} 
            buttonProps={{caption: 'pagoAceptedButton', onClick: () => { history.push('/login');}} }/>)
}


const PagoSuscripcionKO = () => {

    const history = useHistory();

    return (
        <SimpleLandingView title={'pagoTitle'} text={'pagoRefused'} isSuccess={false} 
        buttonProps={{caption: 'pagoRefusedButton', onClick: () => { history.push('/login');}} }/>)
}

const AppRoutesWithRouter = withRouter(AppRoutes);