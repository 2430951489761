
import React, {useContext} from 'react';

import { useTranslation } from 'react-i18next';
import {Form, Button, Row, Col, Input, Alert, Select} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserInjured } from '@fortawesome/free-solid-svg-icons/faUserInjured';
import Modal from 'antd/lib/modal/Modal';
import PersonaSelected from './entities/personaSelected';
import { Store } from 'antd/lib/form/interface';
import {IState as IStateContainer} from './selectPatientContainer';
import EnumCodeIdentificationType from '../../enum/EnumCodeIdentificationType';
import {Option} from "rc-select";
import PatientData from './entities/patientData';
import PrestadorConfigContext from '../../context/PrestadorConfigContext';
import TextArea from 'antd/lib/input/TextArea';

interface IProps extends IStateContainer {
    visible?: boolean;
    errorMessage?: string;
    selectedPatient?: PersonaSelected;
    searchPatient: (values: Store) => void;
    onCancel: (saved: boolean) => void;
    newPatient: (newPatientData?: PatientData) => void;
    newPatientFromPastedData: (patientData: string) => void;
    setSelectedPatient: (idPersona: number) => void;
}

const SelectPatient = (props: IProps) => {

    const { t } = useTranslation(['newPatientPrescription']);
    const [form] = Form.useForm();
    const [formPastedData] = Form.useForm();

    const footer = (
        <Row justify='end'>
            <Col>
                <Button type='default' style={{ cursor: 'pointer' }} onClick={() => props.onCancel(false)}>
                    {t('buttons:cancel')}
                </Button>
            </Col>
        </Row>
    );

    const isTheSamePerson = (currentPerson?: number) => {
        if(typeof currentPerson === 'undefined') {
            return false;
        }

        return props.selectedPatient?.idPersona === currentPerson;
    }

    const typeDocList = [
        <Option key={EnumCodeIdentificationType.DNI}
                value={EnumCodeIdentificationType.DNI}>{t('codeIdentificationType:' + EnumCodeIdentificationType.DNI)}</Option>,
        <Option key={EnumCodeIdentificationType.NIE}
                value={EnumCodeIdentificationType.NIE}>{t('codeIdentificationType:' + EnumCodeIdentificationType.NIE)}</Option>,
        <Option key={EnumCodeIdentificationType.PASAPORTE}
                value={EnumCodeIdentificationType.PASAPORTE}>{t('codeIdentificationType:' + EnumCodeIdentificationType.PASAPORTE)}</Option>
    ];

    const typeDocPaciente = (
        <Form.Item noStyle name='typeDocPaciente' rules={[{required: true}]}>
            <Select dropdownStyle={{minWidth: '8rem'}}>
                {typeDocList}
            </Select>
        </Form.Item>);

    const typeDocTutor = (
        <Form.Item noStyle name='typeDocTutor' rules={[{required: true}]}>
            <Select dropdownStyle={{minWidth: '8rem'}}>
                {typeDocList}
            </Select>
        </Form.Item>);

    const ctxValue = useContext(PrestadorConfigContext);

    const initialValues = {
        typeDocPaciente : ctxValue.data.defaultDocumentTypePassport? EnumCodeIdentificationType.PASAPORTE : EnumCodeIdentificationType.DNI,
        typeDocTutor : ctxValue.data.defaultDocumentTypePassport? EnumCodeIdentificationType.PASAPORTE : EnumCodeIdentificationType.DNI,
    }

    return (
        <Modal title={t('selectPatientTitle')}
            style={{ top: 40 }}
            visible={props.visible}
            onCancel={() => props.onCancel(false)}
            bodyStyle={{ paddingTop: '0.5rem', paddingBottom: '1rem' }}
            width={600}
            footer={footer}
            destroyOnClose>
            <Form className={ctxValue.data.allowPatientDataPasting ? "selectPatientFormWithPasting":""} layout='vertical' form={form} size='middle' onFinish={props.searchPatient} initialValues={initialValues}>
                <Row gutter={16} wrap={false} align='bottom' >
                    <Col span={10}>
                    <Form.Item name='codeIdentificacion' label={t('seleccionarNif')} style={{marginBottom: 0}}>
                        <Input addonBefore={typeDocPaciente} autoFocus={true}/>
                    </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item name='codeIdentificacionTutor' label={t('seleccionarNifTutor')} style={{marginBottom: 0}}>
                            <Input addonBefore={typeDocTutor}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                            <Button className='buscarPatientButton' type='primary' htmlType='submit'>{t('seleccionarBuscar')}</Button>
                    </Col>
                </Row>
            </Form>
            {ctxValue.data.allowPatientDataPasting && 
                <Form layout='vertical' form={formPastedData} size='middle' onFinish={props.searchPatient}  >
                    <Row gutter={16} wrap={false} align='bottom' >
                        <Col span={20}>
                        <Form.Item name='patientPastedData' label={t('pastedPatientData')} style={{marginBottom: 0}}>
                            <TextArea rows={7} style={ {resize: "none" }}/>
                        </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Button className="comprobarDataPastingButton" type='primary' htmlType='submit'>{t('seleccionarComprobar')}</Button>
                        </Col>
                    </Row>
                </Form>            
            }
            {typeof props.resultPatientList != 'undefined' && props.resultPatientList.length > 0 &&
            props.resultPatientList.map(resultPatient =>
                <Alert type='info' message={null} style={{marginTop: '1rem'}} description={
                    <Row gutter={16} wrap={false} align='middle'>
                        <Col style={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>{resultPatient.typeIdentificacion === EnumCodeIdentificationType.MENOR ?
                            t('others:menorSinNIF') : resultPatient.codeIdentificacion}</Col>
                        <Col flex='1 1 100%'>
                            <>{resultPatient.namePersona}
                                {resultPatient.codeIdentificacionTutor &&
                                    <div style={{fontSize:'0.75rem', color: 'gray'}}>{t('seleccionarPacienteResultadoTutor', {codeIdentificacionTutor: resultPatient.codeIdentificacionTutor})}</div>}</>
                        </Col>
                        <Col><Button type='default' size='small' className={ isTheSamePerson(resultPatient.idPersona) ? 'selected__button' : 'select__button'}
                            onClick={() => props.setSelectedPatient(resultPatient.idPersona)}>{
                            isTheSamePerson(resultPatient.idPersona) ? t('seleccionarBotonSeleccionado'): t('seleccionarBoton')}</Button></Col>
                    </Row>}
                />
            )
            }
            {props.noResult &&
                <Alert type='error' message={
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        {props.errorMessage}
                        {!props.invalidFormat && <Button type='primary' icon={<FontAwesomeIcon icon={faUserInjured} style={{ marginRight: '0.5rem', cursor: 'pointer' }} />} 
                                onClick={() => props.newPatient({
                                    typeIdentificacion: form.getFieldValue('typeDocPaciente'),
                                    codeIdentificacion: form.getFieldValue('codeIdentificacion')} as PatientData)}>
                            {t('newPatientButton')}
                        </Button>}
                    </div>
                } style={{marginTop: '1rem'}} />
            }
            {props.noResultPastedData &&
                <Alert type='error' message={
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        {props.errorMessage}
                        {!props.invalidFormat && <Button type='primary' icon={<FontAwesomeIcon icon={faUserInjured} style={{ marginRight: '0.5rem', cursor: 'pointer' }} />} 
                                onClick={() => props.newPatientFromPastedData(formPastedData.getFieldValue('patientPastedData'))}>
                            {t('newPatientButton')}
                        </Button>}
                    </div>
                } style={{marginTop: '1rem'}} />
            }
        </Modal>
    )
}
export default SelectPatient;