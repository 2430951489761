import React from 'react';

import {Store} from 'antd/lib/form/interface';
import {WithTranslation, withTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Rest} from '../../utils/utils';
import View from './signOtpModal';
import { History } from 'history';
import { StaticContext } from 'react-router';

export interface OtpCallerProps {
    otpType?: number;
    valueUuId?: string;
    email?: string;
    dispositivoMovil?: string;
    signOtpVisible?: boolean;
    signOTPkey?: number;
}

interface IProps {
    valueUuId: string;
    otpType?: number;
    email?: string;
    dispositivo?: string;
    visible?: boolean;
    idPrescripcionGrupo?: number;
    oncloseSignOTP: () => void;
    tryAgain: () => void;
    sendByEmail?: () => void;
    onValidationSuccess: (valueUuId: string, otp?: string) => void;
    onSignPrescription?: () => void;
}

export interface IState {
    errorMessage?: string;
    warningMessage?: string;
    otpFormVisible?: boolean;
    codeResult?: number;
}

class SignOtpModalContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    private retryTask?: number;

    public state: IState = {};

    public componentDidMount() {

        if (this.props.visible && this.props.otpType && this.props.otpType === 2) {
            this.poolAprobacion();
        }
    }
    
    public componentDidUpdate(prevProps: Readonly<IProps & WithTranslation & RouteComponentProps<{}, StaticContext, History.PoorMansUnknown>>, prevState: Readonly<IState>, snapshot?: any): void {
    
        if (prevProps.valueUuId && prevProps.valueUuId !== this.props.valueUuId) {
            this.setState({codeResult: undefined}, () => {
                if (this.props.otpType === 2) { // APP Móvil
                    this.clearPoolAprobacion();
                    this.poolAprobacion();
                }
            });
        }
    }

    public componentWillUnmount() {
        this.clearPoolAprobacion();
    }

    public render() {

        return (
            <View
                visible={this.props.visible}
                onSignOtpContinue={this.otpValidation}
                onValidationOtp={this.onValidationOtp}
                onClose={this.oncloseSignOTP}
                tryAgain={this.props.tryAgain}
                sendByEmail={this.props.sendByEmail}
                otpType={this.props.otpType || 0}
                errorMessage={this.state.errorMessage}
                warningMessage={this.state.warningMessage}
                email={this.props.email}
                dispositivo={this.props.dispositivo}
                codeResult={this.state.codeResult}
            />

        );
    }

    private onValidationOtp = () => {
        this.setState({warningMessage: this.props.t('login:emptyFieldOtpsWarning'), errorMessage: undefined});
    }

    private otpValidation = (values?: Store) => {
        
        let otpCode: string | undefined;
        if (values) {
            otpCode = values.txt_Key_1 + values.txt_Key_2 + values.txt_Key_3 + values.txt_Key_4 + values.txt_Key_5 + values.txt_Key_6;
            if (!otpCode || !otpCode.match(/^[0-9.]+$/)) {
                this.setState({errorMessage: 'El código debe ser numérico', warningMessage: undefined})
            }
        }
        
        Rest<{type: string, valueUuId: string, code?: string}, {valid: boolean, codeResult: number, pending: boolean}>().operation({
            type: 'OtpValidation',
            valueUuId: this.props.valueUuId,
            code: otpCode
        }, !values).then(response => {
            if (response.pending) {
                // do nothing
            } else if (response.valid) {
                this.clearPoolAprobacion();
                this.props.onValidationSuccess(this.props.valueUuId, otpCode);
            } else {
                this.clearPoolAprobacion();
                this.setState({
                    codeResult: response.codeResult,
                    errorMessage: this.props.t('codeResultLogin:' + response.codeResult),
                    warningMessage: undefined })
            }
        });
    }

    private poolAprobacion = () => {

        this.retryTask = window.setInterval(() => {
            this.otpValidation();
        }, 5000);
    }

    private clearPoolAprobacion = () => {

        if (this.retryTask) {
            clearInterval(this.retryTask);
        }
    }

    private oncloseSignOTP = () => {
        this.clearPoolAprobacion();
        this.props.oncloseSignOTP();
    }
}

export default withTranslation('signPrescripction')(withRouter(SignOtpModalContainer));