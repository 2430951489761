import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Input, Popconfirm, Row, Tooltip } from "antd";
import React from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import { Functions } from "../../utils/utils";
import PrescripcionAnterior from "./entities/PrescripcionAnteriorData";

interface IProps {

    visible?: boolean;
    onSearch: (evt: any) => void;
    prescriptions?: PrescripcionAnterior[];
    getPrescriptionTypeIcon: (type: number) => IconDefinition;
    searchResult?: string;
    fav?: boolean;
    fromNewPrescription?: boolean;
    editPrescription?: (idPrescriptionFav: number, typePrescription?: number) => void;
    onSelectFav?: (idPrescriptionFav: number) => void;
    delete?: (id:number) => void;
    showActives?: boolean;
}


const PrescripcionMedicacionList = (
    props: IProps
) => {
    const {t} = useTranslation(['prescripcionesAnteriores']);
    const [form] = Form.useForm();

    let initialValues: any = {}

    return (
        <div className={"new_prescription_container fav"} style={{margin: '0 auto'}}>
        <Form layout='horizontal' form={form} size='middle' initialValues={initialValues}>
            <Row justify='space-between'>
                {!props.showActives && <Col>
                    {t('prescripcionesCount', {count: props.prescriptions?.length})}
                </Col>
                }
                {!props.showActives &&
                <Col >
                    <Form.Item name='psaFilter' label={t('filtrar')} style={{marginBottom: '8px'}}>
                        <Input onChange={props.onSearch}/>
                    </Form.Item>
                </Col>
                }
            </Row>
            <div className="anteriorOrFavDataList">
            {props.prescriptions?.map(pres => {
                return (
                    <>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <div className='data_container'>
                                    <div className='medication-card__container'>
                                        <div className='medication-card-info'>
                                            {pres.photos ?
                                            <div className='medication-photo'>
                                                <div className={'loading-img'}>
                                                    <img alt='' src={pres?.photos[0].valueImagen} onLoad={e => {
                                                        e.currentTarget.parentElement!.classList.remove('loading-img');
                                                    }}/>
                                                </div>
                                            </div>:
                                                <div className={'medication-photo-placeholder'}>
                                                    <FontAwesomeIcon icon={props.getPrescriptionTypeIcon(pres.typeProducto!)}/>
                                                </div>
                                            }
                                            <div className='medication-info'>
                                                <div className='medication-type--container'>
                                                    {pres.typeProducto!=undefined && <span className='medication-type'>
                                                        <FontAwesomeIcon
                                                            icon={props.getPrescriptionTypeIcon(pres.typeProducto)}
                                                            style={{marginRight: '6px'}}/> {t('prescriptionType:' + pres.typeProducto).toUpperCase()}
                                                    </span>}
                                                    {(pres.codeDcpf || pres.codeCodigoNacional || pres.codeCodigoNacionalPres ) &&<span className='medication-type'>
                                                            {pres.codeDcpf ? t('genericoDcpf'): t('nationalCode')}
                                                            <span className='code-medication'>{pres.codeDcpf ? pres.codeDcpf : pres.codeCodigoNacional ? pres.codeCodigoNacional : pres.codeCodigoNacionalPres}</span>
                                                    </span>}
                                                    
                                                    {pres.namePrestador!=undefined && <span className={'medication-type'}>
                                                        {t('prescriptoPor')} <span className='code-medication'>{pres.namePrestador.toUpperCase()}</span>
                                                    </span>}
                                                    {pres.typeIdentificacion && <span className={'medication-type'}>
                                                        {pres.typeIdentificacion}<span className='code-medication'>{pres.codeIdentificacion}</span>
                                                    </span>}
                                                    {pres.dateEmision && <div className={'medication-type'}>
                                                        {Functions.dateToString(pres.dateEmision)}
                                                    </div>}
                                                    
                                                </div>
                                                {props.fav && !props.fromNewPrescription &&
                                                            <div className="buttons-prescriptionfav">
                                                                <Button  className={'button__prescription'} icon={<FontAwesomeIcon icon={faEdit} style={{fontSize: '0.8rem' }}/>} type='primary' onClick={()=>{props.editPrescription!(pres.idPrescripcion!, pres.typeProducto)}}/>
                                                                <Popconfirm placement="bottom" title={t('deleteFavPrescriptionMessage')} onConfirm={() => props.delete!(pres.idPrescripcion!)} okText={t('buttons:confirmar')} cancelText={t('buttons:cancel')}>
                                                                    <Button  className={'button__prescription'}  type="primary" danger icon={<FontAwesomeIcon icon={faTrashAlt} style={{ color: '#ffffff', fontSize: '0.8rem' }} />} />
                                                                </Popconfirm>
                                                            </div>
                                                    }
                                                    {props.fromNewPrescription && !props.showActives &&
                                                        <div className="buttons-prescriptionfavselect">
                                                            <Button size='small' type='default'
                                                                icon={<FontAwesomeIcon icon={faCheck} style={{color: '#0091FF', fontSize: '0.8rem'}}/>}
                                                                onClick={() => props.onSelectFav!(pres.idPrescripcion!)}></Button>
                                                        </div>
                                                    }
                                                <span>
                                                <div className='medication-name'>
                                                            {pres.valueComposicionFmv ?
                                                                <b>
                                                                    {typeof props.searchResult !== 'undefined' ?
                                                                     <Highlighter
                                                                        highlightStyle={{
                                                                            backgroundColor: '#f8d19b',
                                                                            padding: 0
                                                                        }}
                                                                        searchWords={[props.searchResult]} autoEscape
                                                                        textToHighlight={pres.valueComposicionFmv}
                                                                     />
                                                                    :
                                                                    <>{pres.valueComposicionFmv}</>}
                                                                </b>
                                                            :
                                                                <b>
                                                                    {pres.nameDcpf ?  
                                                                        (typeof props.searchResult !== 'undefined' ? 
                                                                            <Highlighter
                                                                            highlightStyle={{
                                                                                backgroundColor: '#f8d19b',
                                                                                padding: 0
                                                                            }}
                                                                            searchWords={[props.searchResult]}
                                                                            autoEscape
                                                                            textToHighlight={pres.nameDcpf}
                                                                            />:
                                                                            pres.nameDcpf)
                                                                        : 
                                                                    (pres.valuePresentacion &&  typeof props.searchResult !== 'undefined' ?
                                                                        <Highlighter
                                                                        highlightStyle={{
                                                                            backgroundColor: '#f8d19b',
                                                                            padding: 0
                                                                        }}
                                                                        searchWords={[props.searchResult]}
                                                                        autoEscape
                                                                        textToHighlight={pres.valuePresentacion}
                                                                        />
                                                                        :
                                                                        pres.valuePresentacion)
                                                                    }
                                                                </b>
                                                            }
                                                </div>
                                            </span>
                                            <div>
                                                        {pres.typePosologia != 1 && <> <b style={{paddingRight: '1rem'}}>{t('medicineConfig:posologyTitle')}</b> <b>{pres.valueDosis} {pres.valueUnidadContenido}</b> {t('medicineConfig:posologyFrequencyPres')} <b>{pres.valueFrecuencia} {t('prescriptionFrequencyType:' + pres.typeFrecuencia)}</b> {t('medicineConfig:posologyFrequencyDuring')}
                                                            <b style={{paddingLeft: '4px'}}>{pres.valueDuracion} {t('medicineConfig:durationSuffix')}</b>{pres.dateInicioTratamiento && ","+t('medicineConfig:posologyFrequencyBegining')} <b>{Functions.dateToString(pres.dateInicioTratamiento)}</b></>}
                                                            {pres.typePosologia == 1 && <div style= {{display:'flex'}}> <b style={{ paddingRight: '1rem' }}>{t('medicineConfig:posologyTitle')}</b> <div  dangerouslySetInnerHTML={{__html: t('medicineConfig:withoutPosology',{during: pres.valueDuracion, date: Functions.dateToString(pres.dateInicioTratamiento)})}}></div></div>}
                                                    </div>
                                                    {pres.descIndicacionesPaciente && <div><b style={{paddingRight: '1rem'}}>{t('medicineConfig:posologySelectedPatientIndications')}</b> {pres.descIndicacionesPaciente}</div>}
                                                    {pres.descIndicacionesFarmaceutico && <div><b style={{paddingRight: '1rem'}}>{t('medicineConfig:posologySelectedPharmacyIndications')}</b> {pres.descIndicacionesFarmaceutico}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </>
                )

            })}
            </div>
        </Form>
        </div>
    );
}
export default PrescripcionMedicacionList;