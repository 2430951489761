import { Button, Col, FormInstance, Input, Modal, Row, Form, Checkbox, Upload, Select } from 'antd';
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { IState as IStateContainer } from './AltaPrescriptorFormContainer';
import { UploadOutlined } from '@ant-design/icons';
import RcFileEx from './entities/RcFileEx';
import { UploadFile } from 'antd/lib/upload/interface';
import { removeAccents } from '../utils/utils';
import {Option} from 'rc-select';
import AltaPrestadorFromExternalData from './entities/altaPrescriptorFromExternalData';

interface IProps {
    formVisible: boolean;
    acceptedFileTypes: string;
    onSubmit: (form: FormInstance) => void;
    onCancel: (form: FormInstance) => void;
    onClickPrivacyPolicy?: (fromContact: boolean) => void;

    onFileUpload: (file: RcFileEx, groupId: string, form: FormInstance) => boolean;
    onFileRemove: (file: UploadFile, groupId: string) => void;

    onFormValuesChanged: (form: FormInstance, v: any, k: any) => void;

    fromExternalAccess?: boolean;
    existingPrestadorData?: AltaPrestadorFromExternalData;
}

const AltaPrescriptorForm = (props: IProps & IStateContainer) => {

    const { t } = useTranslation(['formularioAltaPrescriptor']);
    const [form] = Form.useForm();
    const [btnEnabled, setBtnEnabled] = useState<boolean>(props.fromExternalAccess ? true : false);
    const initialValues = props.existingPrestadorData && {
        valueNombre: props.existingPrestadorData.nameNombre,
        valuePrimerApellido: props.existingPrestadorData.nameApellido1,
        valueSegundoApellido: props.existingPrestadorData.nameApellido2,
        valueColegiado: props.existingPrestadorData.numeroColegiado,
        valueColegio: props.existingPrestadorData.numeroColegio,
        valueNif: props.existingPrestadorData.codeIdentificacion,
        valueEmail: props.existingPrestadorData.valueEmail,
        valueTelefono: props.existingPrestadorData.valueTelefono,
        valueDireccion: props.existingPrestadorData.valueDireccion,
        valueDetalleDireccion: props.existingPrestadorData.valueDetalleDireccion,
        valueCodigoPostal: props.existingPrestadorData.valueCodigoPostal,
        valueProvincia: props.existingPrestadorData.codeProvincia,
        valuePoblacion: props.existingPrestadorData.codeMunicipio,
        valueEspecialidad: props.existingPrestadorData.codeEspecialidad
    }

    const checkChar = (e: any) => {
        if ('0123456789'.indexOf(e.key) === -1) {
            e.preventDefault();
        }
    };

    const FileDocComp = (itemLabel: string, itemName: string, groupId: string, opt?: boolean) => {
            return (
                <Form.Item label={t(itemLabel)} name={itemName}
                style={{
                    width: '100%',
                  }}
                >
                    <Upload
                        multiple={false}
                        style={{display:"flex"}}
                        accept={props.acceptedFileTypes}
                        beforeUpload={(file) => props.onFileUpload(file, groupId, form)}
                        onRemove={(file) => props.onFileRemove(file, groupId)}>
                        <Button
                        style={{width:"100%"}}
                            icon={<UploadOutlined/>}>{t('seleccionaArchivos')}</Button>
                    </Upload>
                </Form.Item>
            );
        

    };

    useEffect( () => {
        if(form && props.formVisible && !props.fromExternalAccess) {
            setBtnEnabled(form.getFieldValue('typeNotification_'));
        }
    }, [form, props.formVisible]);

    const formulario = () => {
        return (
            <Form layout='vertical' form={form} size='large' initialValues={initialValues}
                onValuesChange={(v: any, k: any) => props.onFormValuesChanged(form, v, k)}>
            
                { /** TÍTULOS PROMOCIONALES **/}
                {!props.fromExternalAccess && <>
                    <Row style={{ justifyContent: 'center' }}>
                        <Col>
                            <h1 style={{ fontWeight: 'bold' }}>{t("altaPrescriptorFormTitle")}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <span className='altaPrescriptorForm subtitulo'>
                            {t('subtitulo')}
                        </span>
                    </Row>
                    <Row>
                        <span>
                            {t('intro')}
                        </span>
                    </Row>
                </>}
                    
                <Row>
                    <span className='altaPrescriptorForm datosPersonales'>
                        {t('datosPersonales')}
                    </span>
                </Row>
                <Row gutter={12} >
                    <Col span={8}>
                        <Form.Item label={t('nombre')} name='valueNombre' rules={[{required: true}]}>
                            <Input maxLength={120}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('primerApellido')} name='valuePrimerApellido' rules={[{required: true}]}>
                            <Input maxLength={120}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('segundoApellido')} name='valueSegundoApellido'>
                            <Input maxLength={120}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    
                    <Col span={8}>
                        <Form.Item label={t('numeroDeColegiado')} name='valueColegiado' rules={[{required: true}]}>
                            <Input  maxLength={256}/>
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item label={t('especialidad')} name='valueEspecialidad'
                                   rules={[{required: true}]}>
                            <Select showSearch={true} 
                                    filterOption={(inputValue, option) =>
                                        option!.props.children
                                            .toString()
                                            .toLowerCase()
                                            .includes(inputValue.toLowerCase())
                                    }
                            > 
                                {props.especialidadList?.map(pi => {
                                    return (
                                        <Option
                                            key={pi.codeEspecialidad.toString()}
                                            value={pi.codeEspecialidad.toString()}>
                                            {pi.descEspecialidad}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <Form.Item label={t('colegioProfesional')} name='valueColegio'
                                   rules={[{required: true}]}>
                            <Select showSearch={true} 
                                    filterOption={(inputValue, option) =>
                                        option!.props.children
                                            .toString()
                                            .toLowerCase()
                                            .includes(inputValue.toLowerCase())
                                    }
                            >
                                {props.colegiosList?.map(pi => {
                                    return (
                                        <Option
                                            key={pi.idColegio.toString()}
                                            value={pi.idColegio.toString()}>
                                            {pi.nombreColegio}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    
                </Row>
                <Row gutter={12}>
                    <Col span={6}>
                        <Form.Item name='valueNif' label={t('dni')} rules={[{required: true}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('email')} name='valueEmail' rules={[{
                            required: true,
                            type: 'email',
                        }]}>
                            <Input maxLength={128}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={t('telefono')} name='valueTelefono' rules={[{required: true}]}>
                            <Input maxLength={128}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <span className='altaPrescriptorForm domicilioProfesional'>
                        {t('domicilioProfesional')}
                    </span>
                </Row>
                <Row gutter={12}>
                    <Col span={16}>
                        <Form.Item label={t('direccion')} name='valueDireccion' rules={[{required: true}]}>
                            <Input maxLength={120}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('detalleDireccion')} name='valueDetalleDireccion'>
                            <Input maxLength={120}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>

                    <Col span={8}>
                        <Form.Item label={t('codigoPostal')} name='valueCodigoPostal' rules={[{required: true}]}>
                            <Input maxLength={5} minLength={5} onKeyPress={checkChar}
                                   placeholder={t('introduceCodigoPostal')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('provincia')} name='valueProvincia'
                                   rules={[{required: true}]}
                        >
                            <Select
                                placeholder={t('selectProvincia')}
                                showSearch={true}
                                defaultActiveFirstOption={true}
                                filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}
                            >
                                {props.provinciaPorCp?.map(pi => {
                                    return (
                                        <Option
                                            key={pi.codeProvincia}
                                            label={removeAccents(pi.nameProvincia)}
                                            value={pi.codeProvincia}>
                                            {pi.nameProvincia}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('poblacion')} name='valuePoblacion' rules={[{required: true}]}
                        >
                            <Select
                                showSearch={true}
                               
                                placeholder={t('selectPoblacion')}
                                filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}
                            >
                                {props.municipioList?.map(pi => {
                                    return (
                                        <Option

                                            key={pi.codeMunicipio}
                                            value={pi.codeMunicipio}
                                            label={removeAccents(pi.nameMunicipio)}
                                        >

                                            {pi.nameMunicipio}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {!props.fromExternalAccess && <>
                    <Row>
                        <span>
                            {t('textoExplicativo')}
                        </span>
                    </Row>
                    <Row gutter={12} style={{ marginTop: '20px' }}>
                        <Col span={8} className='altaPrescriptorUpload'>
                            {FileDocComp('documentoDeIdentidad', 'subirDocIdentidad', '1')}
                        </Col>
                        <Col span={8} className='altaPrescriptorUpload'>
                            {FileDocComp('carnetDeColegiado', 'subirCarnetDeColegiado', '3')}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item
                                style={{ fontWeight: 'bold' }}
                                name={'typeNotification_'} valuePropName='checked' shouldUpdate>
                                <Checkbox
                                    onChange={() => setBtnEnabled(!btnEnabled)}
                                >{t('aceptarPoliticaDePrivacidad')}</Checkbox>
                                <div className='view-policy-link' onClick={() => {props.onClickPrivacyPolicy!(false)}}>
                                    {t('verPolitica')}</div>
                            </Form.Item>
                        </Col>
                    </Row>
                </>
                }
                <div style={{ textAlign: 'center' }}>
                    <Button style={{ width: 'auto' }} disabled={!btnEnabled} type='primary' size='large'
                        onClick={() => props.onSubmit(form)}>{t('iniciarAlta')}</Button>
                </div>
            </Form>

        )
    }


    return (
        <>
            {!props.fromExternalAccess ? 
            <Modal
                visible={props.formVisible || false}
                footer={null}
                destroyOnClose
                closable={true}
                onOk={() => props.onSubmit(form)}
                onCancel={() => props.onCancel(form)}
                width='100%'
                style={{ top: 40}}
                maskClosable={false}
            >
                {formulario()}
            </Modal>
            : 
                <>
                {formulario()}
                </>
            }

        </>
    );
}

export default AltaPrescriptorForm;


