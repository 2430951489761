import React, { ReactNode } from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import { Rest } from '../utils/utils';
import AppHeader from './HeaderGCO';
import PrestadorPrescripcionData from '../layout/entities/PrestadorPrescripcionData';
import EventHub, { Event } from '../utils/eventHub';

export interface Props {
    idCompaniaCosalud: number;
    prestadorPrescripcionData?: PrestadorPrescripcionData;
    onClickBlocked: () => void;
    onCloseSession: () => void;
}

export interface IState {
    prestadorData?: PrestadorPrescripcionData;
    nPrescripcionesBloqueadas?: number;
}

class AppHeaderGCOContainer extends React.Component<Props & WithTranslation> {

    public componentDidMount() {
        EventHub.on(Event.RELOAD_PRESCRIPTIONS, () => {
            this.loadData();
        });
        this.loadData();
    }

    private loadData() {
        Rest<{type: string; }, any>().operation({
            type: 'GetHeaderInfo'
        }).then(response => {
            this.setState({prestadorData: response.prestadorData, nPrescripcionesBloqueadas: response.nPrescripcionesBloqueadas});
        });
    }

    public render() {

        return this.state ?
                <AppHeader
                    {...this.state}
                    idCompaniaCosalud={this.props.idCompaniaCosalud}
                    onClickBlocked={this.props.onClickBlocked}
                    onCloseSession={this.props.onCloseSession}
                    onClickClose={this.onClickClose}
                /> : null
    }

    private onClickClose = () => {
        this.props.onCloseSession();
    }
    
}

export default withTranslation()(AppHeaderGCOContainer);