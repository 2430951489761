
import React from 'react';

import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Input, DatePicker, Select } from 'antd';
import { Option } from 'rc-select';
import { FormInstance } from 'antd/lib/form';
import { IState as IStateContainer } from './tutorFormContainer';
import { FormUtils } from '../../utils/utils';
import Modal from 'antd/lib/modal/Modal';
import EnumGender from '../../enum/EnumGender';
import EnumCodeIdentificationType from '../../enum/EnumCodeIdentificationType';
import TutorData from './entities/tutorData';
import moment from 'moment';


const TutorForm = (props: {
    visible?: boolean;
    currentTutor?: TutorData;
    onCancel: () => void;
    onSave: (form: FormInstance) => void;
} & IStateContainer) => {

    const { t } = useTranslation(['tutorForm']);
    const [form] = Form.useForm();

    const genderList = [
        <Option key={EnumGender.MALE} value={EnumGender.MALE}>{t('gender:' + EnumGender.MALE)}</Option>,
        <Option key={EnumGender.FEMALE} value={EnumGender.FEMALE}>{t('gender:' + EnumGender.FEMALE)}</Option>,
        <Option key={EnumGender.OTHER} value={EnumGender.OTHER}>{t('gender:' + EnumGender.OTHER)}</Option>
    ];

    const typeDocList = [
        <Option key={EnumCodeIdentificationType.DNI} value={EnumCodeIdentificationType.DNI}>{t('codeIdentificationType:' + EnumCodeIdentificationType.DNI)}</Option>,
        <Option key={EnumCodeIdentificationType.NIE} value={EnumCodeIdentificationType.NIE}>{t('codeIdentificationType:' + EnumCodeIdentificationType.NIE)}</Option>,
        <Option key={EnumCodeIdentificationType.PASAPORTE} value={EnumCodeIdentificationType.PASAPORTE}>{t('codeIdentificationType:' + EnumCodeIdentificationType.PASAPORTE)}</Option>,
    ];

    const typeDoc = (
        <Form.Item noStyle name='typeDocTutor' rules={[{required: true}]}>
            <Select dropdownStyle={{minWidth: '8rem'}}
                    filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}
                    >
                {typeDocList}
            </Select>
        </Form.Item>);


    const initialValues= props.currentTutor && {
        ...props.currentTutor,
        nameTutor: props.currentTutor.nameNombre,
        tutorSurname1: props.currentTutor.nameApellido1,
        tutorSurname2: props.currentTutor.nameApellido2,
        dateBirtTutor: props.currentTutor.dateNacimiento && moment(props.currentTutor.dateNacimiento),
        genderTutor: props.currentTutor.typeSexo,
        typeDocTutor: props.currentTutor.typeIdentificacion,
        codeIdentificationTutor: props.currentTutor.codeIdentificacion,
        emailTutor: props.currentTutor.valueEmail,
        phoneTutor: props.currentTutor.valueTelefono,
        edit: props.currentTutor.edit
    }

    return (
        <Modal title={props.currentTutor ? t('edittutorTitle') : t('tutorTitle')}
            style={{ top: 40 }}
            visible={props.visible}
            onCancel={() => props.onCancel()}
            onOk={() => props.onSave(form)}
            bodyStyle={{paddingTop:'0.5rem', paddingBottom: '1.5rem'}}
            width={800}
            okText={t('buttons:save')} cancelText={t('buttons:cancel')}
            destroyOnClose
        >
            <Form layout='vertical' form={form} size='large' initialValues={initialValues}>
                <Form.Item name='edit' hidden/>
                <Row gutter={8}>
                    <Col span={8}>
                    <Form.Item label={t('namePatientTitle')} name='nameTutor' rules={[{ required: true }]}>
                            <Input maxLength={128} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('surname1Label')} name='tutorSurname1' rules={[{ required: true }]}>
                            <Input maxLength={128} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('surname2Label')} name='tutorSurname2'>
                            <Input maxLength={128} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={8}>
                        <Form.Item label={t('dateBirthLabel')} name='dateBirtTutor' rules={[{ required: true }]} validateStatus={FormUtils.isError('dateBirtTutor', props.errorFields) ? 'error' : undefined}
                                help={FormUtils.getErrorMessage('dateBirtTutor', props.errorFields)}>
                            <DatePicker format='L' style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name='genderTutor' label={t('genderLabel')} rules={[{ required: true }]}>
                            <Select filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}>
                                {genderList}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('codeIdentificationLabel')}
                                   name='codeIdentificationTutor'
                                   rules={[{required: true}]} 
                                   validateStatus={FormUtils.isError('codeIdentificationTutor', props.errorFields) ? 'error' : undefined}
                                    help={FormUtils.getErrorMessage('codeIdentificationTutor', props.errorFields)}>
                            <Input maxLength={128} addonBefore={typeDoc}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={16}>
                        <Form.Item label={t('emailLabel')} name='emailTutor' rules={[{ required: false }]} validateStatus={FormUtils.isError('emailTutor', props.errorFields) ? 'error' : undefined}
                                help={FormUtils.getErrorMessage('emailTutor', props.errorFields)}>
                            <Input maxLength={128} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('phoneLabel')} name='phoneTutor'>
                            <Input maxLength={128} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default TutorForm;