import history from './history';
import Rest from './rest';
import UserInfo from './userInfo';
import FormUtils from './formUtils';
import Icons from './icons';
import TableUtils from './tableUtils';
import Functions from './functions';
import TableIcons from './tableIcons';
import EnumPrescriptionType from '../enum/EnumPrescriptionType';
import {faFlask, faPills, faPumpMedical, faSyringe, faUtensils} from '@fortawesome/free-solid-svg-icons';

function formatNumberMoney(value: number) {
    return typeof value !== 'undefined' && value.toLocaleString(undefined, {minimumFractionDigits: 2, style: 'currency', currency: 'EUR'});
}

function normalizeString(value: string){
    return value.normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi,"$1$2").normalize();
}

function validarEmail(valor: string) {
    return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(valor.toLowerCase());
}

function toTitleCase(phrase: string) {
    return phrase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

function concatName(name: string, surname1: string, surname2?: string) {
    return toTitleCase(name + ' ' + surname1 + (surname2 ? ' ' + surname2 : ''));
}

// Converted to TS:
//https://trellat.es/funcion-para-validar-dni-o-nie-en-javascript/
// Acepta NIEs (Extranjeros con X, Y o Z al principio)
function validateDNI(dni: string, isNie?: boolean) {

    if (!dni) {
        return false;
    }

    if (isNie && !'XYZ'.includes(dni.charAt(0))) {
        return false;
    }

    if (!isNie && !'0123456789'.includes(dni.charAt(0))) {
        return false;
    }


    // let numero: number, char, letra;
    const expression_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;


    dni = dni.toUpperCase();

    if (expression_regular_dni.test(dni)) {
        let numero: string = dni.substr(0, dni.length - 1);
        numero = numero.replace('X', '0');
        numero = numero.replace('Y', '1');
        numero = numero.replace('Z', '2');
        const char = dni.substr(dni.length - 1, 1);
        const numeroN = +numero % 23;
        let letra: string = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letra = letra.substring(numeroN, numeroN + 1);
        return letra === char;
    } else {
        //alert('Dni erroneo, formato no válido');
        return false;
    }
}


//https://www.mapa.gob.es/app/materialvegetal/docs/CIF.pdf
function validateCif(cif: string): boolean {
    //Devuelve verdadero si el cif que se le pasa es valido
        let respuesta = false;//Tabla de caracter de control para cif extranjeros, organismos estatales y locales
        const tabla = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];//en realidad la "J" debe estar en la ultima posición pero como
        let sumap = 0; //las tablas en java cominezan en '0' la paso a la primera posicion
        let sumai = 0;
        let p;
        let R;
        let dc;
        cif = cif.toUpperCase();
        try {
            sumap = parseInt(cif.substring(2, 3)) + parseInt(cif.substring(4, 5)) + parseInt(cif.substring(6, 7));
    
    
            for (let i = 1; i <= 8; i++) {
                p = 2 * parseInt(cif.substring(i, i + 1));
                if (p > 9) {
                    sumai += (Math.floor(p / 10)) + Math.floor(p % 10);
                } else {
                    sumai += p;
                }
                i++;
            }
            R = sumap + sumai;//R es el resulrtado de las sumas de los productos
            dc = Math.floor(R % 10);//calculamos el digito de control que es el modulo de la suma de los productos
            dc = 10 - dc;//complemento a 10 del digito de control
            if (dc === 10) {//si el digito de control es 10 se le asigna el 0
                dc = 0;
            }
            const l8 = cif.charAt(8);
    
            if ((l8 >= 'a' && l8 <= 'z') || (l8 >= 'A' && l8 <= 'Z'))
                //    if (Character.isLetter(cif.charAt(8)))
            {//si es un caracter el ultimo digito se compara con la tabla que debe ocupar la posicion del dc
                if (tabla[dc] === cif.charAt(8)) {
                    respuesta = true;
                }
            } else {//si no es el caracter de contro que debe coincidir con el ultimo digito
                if (dc === parseInt(cif.substring(8, 9))) {
                    respuesta = true;
                }
            }
        } catch (error) {
            console.log(error);
            respuesta = false;
        }
        return respuesta;
    
}

function removeAccents(s: string) {
    return s.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
}

function getPrescriptionTypeIcon(type: number) {
    switch (type) {
        case EnumPrescriptionType.PRODUCTO_SANITARIO:
            return faPumpMedical;
        case EnumPrescriptionType.NUTRICION:
            return faUtensils;
        case EnumPrescriptionType.VACUNA:
            return faSyringe;
        case EnumPrescriptionType.FORMULA_MAGISTRAL:
            return faFlask;
        default:
            return faPills;
    }
};


export {history, FormUtils, Icons, Rest, UserInfo, TableUtils, TableIcons, Functions, formatNumberMoney, normalizeString, validarEmail, concatName, validateDNI, validateCif, removeAccents, getPrescriptionTypeIcon};