export default {
    PENDIENTE: 1,
    BLOQUEADA: 2,
    DISPENSADA: 3,
    DISPENSADA_SUSTITUCION_1: 4,
    CADUCADA: 5,
    DISPENSADA_PARCIALMENTE: 8,
    FORMULA_MAGISTRAL: 9,
    DISPENSADA_SUSTITUCION_2: 10
}
