import React from 'react';
import {Button, Modal, Space, Table, Tabs} from 'antd';
import {IAlertsDataArray, IAlertsDataItem} from './alertsData';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {AlignType} from 'rc-table/lib/interface';


const PrescriptionAlerts = (
    props: {
        visible?: boolean;
        arrayOfAlerts: IAlertsDataArray[];
        onCancel: () => void;
    },
) => {
    const {t} = useTranslation(['prescription']);


    const renderSeverityIdColumn = (value: any, record: IAlertsDataItem) => {
        const setColor = (severityId: number) => {
            switch (severityId) {
                case 4:
                    return '#CF113C';
                case 3:
                    return '#FF4326';
                case 2:
                    return '#F97D00';
                case 1:
                    return '#FFDC2C';
                case 0:
                    return '#8EAD38';
                default:
                    return 'black';
            }
        };

        return (
            <Space>
                <FontAwesomeIcon
                    icon={record.severityId === 0? faCheckCircle:  faExclamationTriangle}
                    style={{marginRight: '6px', color: setColor(record.severityId)}}/>
                <b>{record.severity}</b>
            </Space>
        );
    };

    const renderSummaryCategoryColumn = (value: any, record: IAlertsDataItem) => {


        return <b>{record.alertType}</b>;
    };

    const summaryColumns = [
        {
            title: t('category'),
            dataIndex: 'alertId',
            align: 'left' as AlignType,
            render: renderSummaryCategoryColumn,
        },
        {
            title: t('severity'),
            dataIndex: 'severityId',
            align: 'left' as AlignType,
            render: renderSeverityIdColumn,
        },
        {
            title: t('description'),
            dataIndex: 'description',
            align: 'left' as AlignType,
            render: (value: any, record: IAlertsDataItem) => {
                return <b>{record.description}</b>;
            },
        },
    ];

    const alertColumns = [
        {
            title: t('category'),
            dataIndex: 'alertId',
            align: 'left' as AlignType,
            render: (value: any, record: IAlertsDataItem) => {
                return <b>{record.alertType}</b>;
            },
        },
        {
            title: t('severity'),
            dataIndex: 'severityId',
            align: 'left' as AlignType,
            render: renderSeverityIdColumn,
        },
        {
            title: t('description'),
            dataIndex: 'description',
            align: 'left' as AlignType,
            render: (value: any, record: IAlertsDataItem) => {
                return <b>{record.description}</b>;
            },
        },
    ];

    const getAllAlertsOrderedBySeverity = () => {
        const allAlertsData = props.arrayOfAlerts.flatMap((alert) => alert.alertsData);
        return allAlertsData.sort((a, b) => b.severityId - a.severityId);
    };

    const TabPanels = () => {
        let total = 0;
        props.arrayOfAlerts.forEach(e => {
            total = total + e.alertsData.length;
        });
        const alertsData = getAllAlertsOrderedBySeverity();
        const maxSeverityId = Math.max(...alertsData.map(record => record.severityId));

        // Define las claves de las filas que deben estar expandidas inicialmente
        const defaultExpandedRowKeys = alertsData
        .filter(record => record.severityId === maxSeverityId)
        .map(record => record.key);

        return (
            <Tabs defaultActiveKey='0'>
                <Tabs.TabPane tab={t('all', {countAll: total})} key='0'>
                    <Table
                           columns={summaryColumns}
                           size='small'
                           dataSource={getAllAlertsOrderedBySeverity()}
                           pagination={false}
                           expandable={{
                               expandedRowRender: record => (
                                   <Space direction={'vertical'}>
                                       <p style={{marginLeft: '3rem'}}
                                          dangerouslySetInnerHTML={{__html: record.content}}/>
                                       <p style={{marginLeft: '3rem'}} dangerouslySetInnerHTML={{__html: record.detail}}
                                       />
                                   </Space>

                               ),
                               rowExpandable: record => record.severity !== 'Not Expandable',
                               defaultExpandedRowKeys,
                           }}
                    />
                </Tabs.TabPane>

                {
                    props.arrayOfAlerts.map(e => {
                        let total = e.alertsData.length;
                        return (
                            <Tabs.TabPane tab={e.alertsData[0].alertType + ' (' + total + ')'} key={e.alertId}>
                                <Table
                                    columns={alertColumns}
                                    size='small'
                                    dataSource={e.alertsData}
                                    expandable={{
                                        expandedRowRender: record => (
                                            <Space direction={'vertical'}>
                                                <p style={{marginLeft: '3rem'}}
                                                   dangerouslySetInnerHTML={{__html: record.content}}/>
                                                <p style={{marginLeft: '3rem'}} dangerouslySetInnerHTML={{__html: record.detail}}
                                                />
                                            </Space>

                                        ),
                                        rowExpandable: record => record.severity !== 'Not Expandable',
                                    }}

                                    pagination={false}/>
                            </Tabs.TabPane>
                        );

                    })
                }
            </Tabs>
        );
    };


    return (
        <Modal
            visible={props.visible}
            destroyOnClose
            closable
            onCancel={props.onCancel}
            cancelText={t('close')}
            style={{top: 40}}
            width={'1200px'}
            footer={[<Button key='close' type='primary' onClick={props.onCancel}>
                {t('buttons:close')}
            </Button>]}
        >
            <div>
                <TabPanels/>
            </div>
        </Modal>
    );

};

export default PrescriptionAlerts;
