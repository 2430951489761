import { Button, FormInstance, Modal, Result } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Municipio from "../prescriptions/locations/entities/municipio";
import Provincia from "../prescriptions/locations/entities/provincia";
import { Rest, validateDNI } from "../utils/utils";
import View from './AltaPrescriptorForm';
import DownloadDocItem from "./entities/DownloadDocItem";
import RcFileEx from "./entities/RcFileEx";
import _ from 'lodash';
import ValidateNewPrestadorResponse from "./entities/validateNewPrestadorResponse";
import Colegio from "./entities/Colegio";
import AltaPrestadorFromExternalData from "./entities/altaPrescriptorFromExternalData";
import Especialidad from "./entities/Especialidad";

interface IProps {
    closeForm: (form?: FormInstance) => void;
    showForm?: boolean;
    onClickPrivacyPolicy?: (fromContact: boolean) => void;
    fromExternalAccess?: boolean;
    existingPrestadorData?: AltaPrestadorFromExternalData;
    
}

export interface IState {
    loaded?: boolean;
    formVisible?: boolean;
    warningMessage?: string;
    errorFields?: string[];
    formularioForm?: FormInstance;
    confirmDialogVisible?: boolean;

    downloadDocList: DownloadDocItem[];
    fileList: RcFileEx[];
    base64File?: string;

    provinciaList?: Provincia[];
    codigoPostal?: string;
    provinciaPorCp?: Provincia[];
    municipioList?: Municipio[];
    disablePoblacion: boolean;
    colegiosList?: Colegio[];
    especialidadList?: Especialidad[];
}


class AltaPrescriptorFormContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public state: IState = {
        disablePoblacion: true,
        fileList: [],
        downloadDocList: []
    };

    private allProvincias: Provincia[] = [];
    private colegios: Colegio[] = [];
    private municipios: Municipio[] = [];
    private especialidades: Especialidad[] = [];

    readonly acceptedFileTypes = 'image/jpeg,image/jpg,image/png,image/bmp,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf';
    readonly fsLimit = 5 * 1024 * 1024;
    readonly acceptedFileTypesArray: string[] = this.acceptedFileTypes.split(',');

    public componentDidMount() {
        if (this.props.showForm) {
            this.loadData();
        }
    }

    public render() {
        return (
            this.state && this.state.loaded ?
                <>
                    <View
                        {...this.state}
                        onSubmit={this.onSubmitFormulario}
                        onCancel={this.onClose}
                        onClickPrivacyPolicy={this.props.onClickPrivacyPolicy!}
                        formVisible={this.props.showForm || false}
                        acceptedFileTypes={this.acceptedFileTypes}
                        onFileUpload={this.onFileUpload}
                        onFileRemove={this.onFileRemove}   
                        onFormValuesChanged={this.onFormValuesChanged}   
                        fromExternalAccess={this.props.fromExternalAccess}
                        existingPrestadorData={this.props.existingPrestadorData}             
                        />

                    {this.ResultDialog(this.state.confirmDialogVisible!)}
                </>
                : <></>
        )
    }

    private loadData = () => {
        const promises: Array<Promise<any>> = [];
        const rqProvincias = Rest<{ type: string }, Provincia[]>();
        const rqColegios = Rest<{ type: string }, Colegio[]>();
        const rqMunicipios = Rest<{type: string, codeProvincia: string}, Municipio[]>();
        const rqEspecialidades = Rest<{type: string}, Especialidad[]>();

        promises.push(rqProvincias.operation({ type: 'SelectProvincia' }));
        promises.push(rqColegios.operation({type:'SelectColegio'}));
        promises.push(rqEspecialidades.operation({type:'SelectEspecialidadesFormulario'}));

        {this.props.existingPrestadorData?.codeProvincia && promises.push(rqMunicipios.operation({type:'SelectMunicipiosByProvinciaOrdered',codeProvincia:this.props.existingPrestadorData?.codeProvincia.toString()!}))}
        
        Promise.all(promises).then(response => {
            this.colegios = response[1];
            this.allProvincias = response[0];
            this.especialidades = response[2];
            if(this.props.existingPrestadorData?.codeProvincia){
                this.municipios= response[3];
            }

                this.setState({
                    provinciaList: this.allProvincias,
                    provinciaPorCp: this.allProvincias,
                    formVisible: this.props.showForm,
                    colegiosList: this.colegios,
                    municipioList: this.municipios,
                    especialidadList: this.especialidades,
                    loaded: true,
                });
        });
    }

    private onSubmitFormulario = async (form: FormInstance) => {
        if(this.props.fromExternalAccess){
            this.props.closeForm(form);
            return;
        }
        let hasErrors = false;
        form.setFields([]);

        const subirFieldNames: string[] = [];
        for (const [key, value] of Object.entries(form.getFieldsValue())) {
            let fieldName = key;
            let fieldValue: any = value;
            if (fieldName.startsWith('subir')) {
                subirFieldNames.push(fieldName);
                if(typeof fieldValue !== 'undefined' && typeof fieldValue['fileList'] !== 'undefined' && fieldValue['fileList'].length === 0) {
                    form.setFieldsValue({[fieldName]: undefined});
                }
            }
        }

        await form.validateFields().then(() => {
            hasErrors = false;
        }).catch(errors => {
            hasErrors = true;
            let errFields: string[] = errors.errorFields.filter((e: any) => e.name[0].startsWith('subir'))
                .map((f: any) => f.name[0]);

            errFields.forEach(e => {

                form.setFields([
                    { name: e, errors: [this.props.t('attachFile')] },
                ]);

            })

        });

        if (hasErrors) {
            return;
        }
        
        let formData = new FormData();
        let prestadorData: { [k: string]: any } = {};

        for (const [key, value] of Object.entries(form.getFieldsValue())) {
            let v: any = value;

            if (v && (typeof v === 'object' && v['file'])) {  // Do not add files, later will be done whith added/deleted lists

            }
            else {
                if (v && (typeof v === 'object' && (typeof v['value'] !== 'undefined'))) {
                    prestadorData[key] = v.value;  // For <Select> where the pair of key/value is used
                }
                else {
                    prestadorData[key] = v;
                }
            }
        }
        
        prestadorData['idPrestador'] = undefined;
        formData.append('prestadorData', JSON.stringify(prestadorData));

        this.state.fileList.forEach(e => {
            const attachedFieldErrors: string[] = [];
            const fldIdx = parseInt(e.groupId!, 10) - 11;
            if ((e.fileId! < 0 && e.size) > this.fsLimit) {
                attachedFieldErrors.push(this.props.t('providerList:fileIsTooLarge'));
            }

            if (this.acceptedFileTypesArray.indexOf(e.type) === -1) {
                attachedFieldErrors.push(this.props.t('providerList:incorrectFileType'));
            }

            if (attachedFieldErrors.length > 0) {
                form.setFields([
                    {name: subirFieldNames[fldIdx + 10], errors: attachedFieldErrors},
                ]);

                hasErrors = true;
            }

            formData.append('file', e, e.name + '|' + e.groupId);
        });
        if (!hasErrors) {
            const promises: Array<Promise<any>> = [];
            const validateNewPrestador = Rest<{type: string, valueNif: string, valueColegiado: string, valueEmail: string, valueTelefono: string}, ValidateNewPrestadorResponse>();
            promises.push(validateNewPrestador.operation({ type: 'ValidateNewPrestadorFormData', 
                        valueNif: form.getFieldValue("valueNif"),
                        valueColegiado: form.getFieldValue("valueColegiado"),
                        valueEmail:form.getFieldValue("valueEmail"),
                        valueTelefono:form.getFieldValue("valueTelefono")}));

            Promise.all(promises).then(response => {
                var errors : ValidateNewPrestadorResponse;
                errors = response[0];

                if(errors.errorNif==false && errors.errorColegiado==false && errors.errorEmail==false && errors.errorTelefono==false){
                    this.processPrestadorData(formData);
                }
                //NIF check
                if (errors.errorNif==true) {
                    form.setFields([
                        { name: 'valueNif', errors: [this.props.t('incorrectNif')], },
                    ]);
                }
                
                //COLEGIADO check
                if (errors.errorColegiado==true) {
                    form.setFields([
                        { name: 'valueColegiado', errors: [this.props.t('colegiadoError')] },
                    ]);
                }

                //EMAIL check
                if (errors.errorEmail==true){
                    form.setFields([
                        { name: 'valueEmail', errors: [this.props.t('incorrectEmail')] },
                    ]);
                }
                //TELEFONO check
                if (errors.errorTelefono==true){
                    form.setFields([
                        { name: 'valueTelefono', errors: [this.props.t('telefonoError')] },
                    ]);

                }
        

            }); 
        }
        
    }

    private processPrestadorData = (formData: FormData) => {
       
        Rest<void, any>()
            .fetchURL('/fileUpload?typeUpload=uploadPrestadorForm', { method: 'POST', body: formData })
            .then(() => {
                this.setState({ confirmDialogVisible: true, formularioForm: undefined});
                this.onClose();
            });

    }

    private onClose = () => {
        this.props.closeForm();
    }

    private closeConfirmDlg = () => {
        this.setState({ confirmDialogVisible: false });
    }

    private ResultDialog = (mVisible: boolean) => {
        return (
            <Modal
                visible={mVisible}
                footer={null}
                onCancel={this.closeConfirmDlg}
            >
                <Result
                    status='success'
                    title={this.props.t('messageSent')}
                    subTitle={this.props.t('messageSentDetail')}
                    extra={<Button type='primary' style={{ cursor: 'pointer' }} onClick={this.closeConfirmDlg}>
                            {this.props.t('buttons:close')}
                        </Button>} />
            </Modal>
        );
    }

    private onFormValuesChanged = (frm: FormInstance, field: any) => {

        if (typeof field.valueCodigoPostal !== 'undefined') {

            let s: string = field.valueCodigoPostal.substring(0, 2);
            const isPvCodeTooShort = s.trim().length < 2;

            if (isPvCodeTooShort) {
                frm.setFieldsValue({
                    ...frm.getFieldsValue, 'valueProvincia': undefined,
                    'valuePoblacion': undefined
                });

                this.setState({
                    codigoPostal: undefined, provinciaPorCp: this.allProvincias, municipioList: undefined,
                    disablePoblacion: true
                });

                return;
            }

            const provinciasFiltered: Provincia[] = this.allProvincias.filter(e => e.codeProvincia.startsWith(s));

            if (s === this.state.codigoPostal) {
                return;
            }

            frm.setFieldsValue({
                ...frm.getFieldsValue, 'valueProvincia':
                    (provinciasFiltered.length > 0) ? provinciasFiltered[0].codeProvincia : undefined
            });

            if (provinciasFiltered.length > 0) {
                this.searchPoblacionByCP(frm, provinciasFiltered[0].codeProvincia, provinciasFiltered);
            }

        } else
            if (typeof field.valueProvincia !== 'undefined') {
                this.searchPoblacionByProvincia(frm, field.valueProvincia);
            }
    }

    private searchPoblacionByCP = _.debounce((frm: FormInstance, s: string, provinciasFiltered: Provincia[]) => this.doSearchPoblacion(frm, s, provinciasFiltered), 500);

    private doSearchPoblacion = (frm: FormInstance, s: string, provinciasFiltered: Provincia[]) => {
        Rest<{ type: string; codeProvincia: string }, any>().operation({
            type: 'SelectMunicipiosByProvinciaOrdered',
            codeProvincia: s,
        }).then(response => {
            const mcp: Municipio[] = response;
            frm.setFieldsValue({
                ...frm.getFieldsValue, 'valuePoblacion': undefined,
            });

            this.setState({
                codigoPostal: undefined, provinciaPorCp: provinciasFiltered, municipioList: mcp,
                disablePoblacion: false
            });
        });
    }

    private searchPoblacionByProvincia = _.debounce((frm: FormInstance, s: string) => this.doSearchPoblacionByProvincia(frm, s), 500);

    private doSearchPoblacionByProvincia = (frm: FormInstance, s: string) => {
        Rest<{ type: string; codeProvincia: string }, any>().operation({
            type: 'SelectMunicipiosByProvinciaOrdered',
            codeProvincia: s!,
        }).then(response => {
            const mcp: Municipio[] = response;
            frm.setFieldsValue({
                ...frm.getFieldsValue, 'valuePoblacion': undefined,
                'valueCodigoPostal': undefined
            });

            this.setState({
                codigoPostal: undefined, provinciaPorCp: this.allProvincias, municipioList: mcp,
                disablePoblacion: false
            });
        });
    }

    private onFileUpload = (file: RcFileEx, groupId: string) => {
        const fileList = [...this.state.fileList];
        file.groupId = groupId;
        // "-1" for new files to upload
        const fid = (-1) * (new Date().getTime());
        file.fileId = fid;
        fileList.push(file);
        getBase64(file, fileUrl => {
            const dl = this.state.downloadDocList;
            const fd: DownloadDocItem = {
                uid: fid.toString(), size: file.size, name: file.name, type: 'new',
                status: 'success', fileId: fid, groupId: file.groupId
            };
            dl.push(fd);
            this.setState({ base64File: fileUrl, fileList, downloadDocList: dl});
        }
        );
        return false;
    }

    private onFileRemove = (file: any) => {
        this.setState({ base64File: undefined, fileList: this.state.fileList.filter(f => f.fileId !== file.fileId) });
        
        const dl = this.state.downloadDocList;
        const fd: number = dl.findIndex(e => e.fileId === file.fileId);
        if (typeof fd !== 'undefined') {
            dl.splice(fd, 1);
        }
        this.setState({downloadDocList: dl});

    }
    
}

const getBase64 = (img: Blob, callback: (result: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
}

export default withTranslation('formularioAltaPrescriptor')(withRouter(AltaPrescriptorFormContainer));