import React, {useEffect} from 'react';

import {useTranslation} from 'react-i18next';
import {Form, Row, Col, Input, Select} from 'antd';
import {Option} from 'rc-select';
import {FormInstance} from 'antd/lib/form';
import {FormUtils} from '../../utils/utils';
import {IState as IStateContainer} from './newLocationContainer';
import Modal from 'antd/lib/modal/Modal';
import {LocationPrestador} from './entities/locationPrestador';

const NewLocation = (props: {
    visible?: boolean;
    onChangeCP: (form: FormInstance, e: React.ChangeEvent<HTMLInputElement>) => void;
    onCancel: () => void;
    onSubmit: (form: FormInstance) => void;
    locationForEdit?: LocationPrestador;
} & IStateContainer) => {

    const {t} = useTranslation(['newLocation']);
    const [form] = Form.useForm();

    const provinceList = (props.provinciaList || []).map(loc => {
        return (
            <Option key={loc.codeProvincia} value={loc.codeProvincia}>{loc.nameProvincia}</Option>
        );
    });



    useEffect(() => {

        if(!props.visible) {
            return;
        }

        form.resetFields();

        if (props.locationForEdit) {
            form.setFieldsValue({
                nameLocation: props.locationForEdit.nameLocalizacion,
                address: props.locationForEdit.valueDireccion,
                addressDetail: props.locationForEdit.valueDireccion2,
                codigoPostal: props.locationForEdit.codeCodigoPostal,
                codeProvincia: props.locationForEdit.codeProvincia,
                codeMunicipio: props.locationForEdit.codeMunicipio,
                valueEmail: props.locationForEdit.valueEmail,
                valueTelefono: props.locationForEdit.valueTelefono,
            });
        }

    }, [props.visible]);


    const dialogTitle = (typeof props.locationForEdit === 'undefined') ?
        t('newLocationTitle') : t('editLocationTitle', {locNombre: props.locationForEdit.nameLocalizacion});

    const municipioDisabled = (typeof props.codeProvincia === 'undefined') && (typeof props.locationForEdit === 'undefined');

    return (
        <Modal title={dialogTitle}
               style={{top: 40}}
               visible={props.visible}
               onCancel={() => props.onCancel()}
               onOk={() => props.onSubmit(form)}
               bodyStyle={{paddingTop: '0.5rem', paddingBottom: '1.5rem'}}
               width={550}
               okText={t('buttons:save')} cancelText={t('buttons:cancel')}
               destroyOnClose
        >
            <Form layout='vertical' form={form} size='large'>
                <Row gutter={8}>
                    <Col span={16}>
                        <Form.Item label={t('nameLocationLabel')} name='nameLocation' rules={[{required: true}]}>
                            <Input maxLength={256}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={16}>
                        <Form.Item label={t('addressLabel')} name='address' rules={[{required: false}]}>
                            <Input maxLength={256}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('addressDetailLabel')} name='addressDetail'>
                            <Input maxLength={256}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={8}>
                        <Form.Item label={t('cpLabel')} name='codigoPostal' rules={[{required: false}]}>
                            <Input maxLength={5} onChange={(e) => props.onChangeCP(form, e)}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('provinceLabel')} name='codeProvincia' rules={[{required: false}]}>
                            <Select disabled
                                    filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}>
                                {provinceList}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('townLabel')} name='codeMunicipio' rules={[{required: false}]}>
                            <Select showSearch
                                    notFoundContent={true}
                                    allowClear
                                    defaultActiveFirstOption={true}
                                    dropdownStyle={{minWidth: '20rem'}}
                                    disabled={municipioDisabled}
                                    filterOption={(input, option) =>
                                        (option?.props.children)
                                            .toUpperCase()
                                            .includes(input.toUpperCase())}>
                                {(props.municioioList || []).map(municipio =>
                                    <Option key={municipio.codeMunicipio} value={municipio.codeMunicipio}>
                                        {municipio.nameMunicipio}
                                    </Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={16}>
                        <Form.Item label={t('emailLabel')} name='valueEmail' rules={[{required: false}]}
                                   validateStatus={FormUtils.isError('valueEmail', props.errorFields) ? 'error' : undefined}
                                   help={FormUtils.getErrorMessage('valueEmail', props.errorFields)}>
                            <Input maxLength={256}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('telefonoLabel')} name='valueTelefono' rules={[{required: false}]}>
                            <Input maxLength={256}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default NewLocation;