import React, {ReactNode, useEffect, useState} from 'react';
import {Button, Image, Modal, Tabs} from 'antd';

import {useTranslation} from 'react-i18next';
import {IMedicamentoDescriptionVidal, IMedicamentosEquivalentes} from '../entities/IMedicamentoDescriptionVidal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye} from '@fortawesome/free-solid-svg-icons';

interface TabInfoEx {
    title: string;
    component: ReactNode;
    tabIcon?: JSX.Element;
    key: string;
    closable?: boolean;
}


const MedicamentoDetailsVidal = (props: {
    visible: boolean;
    activeTabIndex: number;
    tabKey: string;
    medicamentoDescriptionVidal?: IMedicamentoDescriptionVidal;
    onSelectVidalFromDetailsVidal: (vidalUrl: string) => void;
    onCancel: () => void;
    onSelectMedicine: (idVademecum: number) => void;
    onTabChange: (selectedKey: string) => void;
}) => {
    const {t} = useTranslation(['medicineConfig']);
    const [tabArray, setTabArray] = useState<JSX.Element[]>([]);


    const onClickDoc = (doc?: string) => {
        if (!doc || doc.length < 5) {
            return;
        }

        props.onSelectVidalFromDetailsVidal(doc);
    };

    const tabItem = (tabInfo: TabInfoEx) => {
        const tabItemTab = (
            <div>
                {tabInfo.tabIcon}
                {tabInfo.title}
            </div>);

        return (
            <Tabs.TabPane
                key={tabInfo.key}
                tab={tabItemTab}
                closable={tabInfo.closable}
            >
                {tabInfo.component}

            </Tabs.TabPane>
        );
    };


    const MedicamentoDetailsTabContent = (data: {
        medicamentoDescriptionVidal?: IMedicamentoDescriptionVidal;
        onSelectVidalFromDetailsVidal: (vidalUrl: string) => void;
    }) => {
        return (
            <>
                <div className='medicamento-vidal'>
                    <div className='content-1'>
                    <span style={{fontWeight: 'bold'}}>
                        {data.medicamentoDescriptionVidal?.nombre}
                    </span>
                        <div className='medicamento-header-info'>
                            <div style={{flexGrow: 1}}>
                                {data.medicamentoDescriptionVidal?.nombreGenerico}
                            </div>
                            <div>
                                <Button type='link' onClick={() => {
                                    onClickDoc(data.medicamentoDescriptionVidal?.urlVMP);
                                }}
                                        style={{marginLeft: '1rem'}} size='small'>
                                    FICHA VMP / VIDAL VADEMÉCUM
                                </Button>
                            </div>
                        </div>
                    </div>


                    <div className='content-2'>
                        <div style={{flexGrow: 2}}>
                            <div className='medicamento-vidal-item'>

                                <div className='header' style={{justifyContent: 'left'}}>
                                <span style={{margin: '0.5rem'}}>
                                    {t('activePrinciplesField')}
                                </span>
                                </div>
                                <div style={{justifyContent: 'left', paddingLeft: '0.5rem', flexDirection: 'column'}}>
                                    {data.medicamentoDescriptionVidal?.principiosActivos
                                        .map((e, i) =>
                                            <div style={{justifyContent: 'left'}}
                                                 key={i}
                                            >
                                                {e.nombre}
                                            </div>,
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div style={{flexGrow: 2}}>
                            <div className='medicamento-vidal-item'>
                                <div className='header' style={{justifyContent: 'center'}}>
                                <span style={{margin: '0.5rem'}}>
                                    {t('dosage')}
                                </span>

                                </div>
                                <div style={{flexDirection: 'column'}}>
                                    {data.medicamentoDescriptionVidal?.principiosActivos
                                        .map((e, i) =>
                                            <div style={{justifyContent: 'center'}}
                                                 key={i}
                                            >
                                                {e.dosificacion}
                                            </div>,
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div style={{flexGrow: 5}}>
                            <div className='medicamento-vidal-item'>
                                <div className='header'>
                                <span style={{margin: '0.5rem'}}>
                                    {t('form')}
                                </span>
                                </div>
                                <div style={{justifyContent: 'center'}}>
                                    {data.medicamentoDescriptionVidal?.forma}
                                </div>
                            </div>
                        </div>

                        <div style={{flexGrow: 2}}>
                            <div className='medicamento-vidal-item'>
                                <div className='header' style={{justifyContent: 'center'}}>
                                <span style={{margin: '0.5rem'}}>
                                    {t('via')}
                                </span>
                                </div>
                                <div style={{flexDirection: 'column'}}>
                                    {data.medicamentoDescriptionVidal?.vias
                                        .map((e, i) =>
                                            <div style={{justifyContent: 'center'}} key={i}>
                                                {e}
                                            </div>,
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='content-2'>
                        <div style={{flexGrow: 1}}>
                            <div className='medicamento-vidal-item'>

                                <div className='header-left'>
                                <span style={{margin: '0.5rem'}}>
                                    {t('excipientesDeDeclaracion')}
                                </span>
                                </div>
                                <div style={{flexDirection: 'column'}}>
                                    {data.medicamentoDescriptionVidal?.excipientes
                                        .map((e, i) =>
                                            <div style={{marginLeft: '0.5rem'}} key={i}>
                                                {e}
                                            </div>,
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='content-2'>
                        <div style={{flexGrow: 1}}>
                            <div className='medicamento-vidal-item'>

                                <div className='header' style={{justifyContent: 'left'}}>
                                <span style={{margin: '0.5rem'}}>
                                    {t('atcClass')}
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{flexDirection: 'row'}}>
                        <div style={{flexGrow: 2}}>
                            <div className='medicamento-vidal-item' style={{marginTop: 0}}>
                                <div className='header-light' style={{justifyContent: 'left'}}>
                                <span style={{marginLeft: '0.5rem'}}>
                                    {t('code')}
                                </span>

                                </div>
                                <div style={{flexDirection: 'column'}}>
                                    {data.medicamentoDescriptionVidal?.claseATC
                                        .map((e, i) =>
                                                <span style={{marginLeft: '0.5rem'}} key={i}>
                                            {e.codigo}
                                        </span>,
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div style={{flexGrow: 5}}>
                            <div className='medicamento-vidal-item' style={{marginTop: 0}}>
                                <div className='header-light' style={{justifyContent: 'left'}}>
                                <span style={{marginLeft: '0.5rem'}}>
                                    {t('name')}
                                </span>
                                </div>
                                <div style={{flexDirection: 'column'}}>
                                    {data.medicamentoDescriptionVidal?.claseATC
                                        .map((e, i) =>
                                                <span style={{justifyContent: 'left', marginLeft: '0.5rem'}} key={i}>
                                            {e.nombre}
                                        </span>,
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='content-2'>
                        <div style={{flexGrow: 5}}>
                            <div className='medicamento-vidal-item'>

                                <div className='header' style={{justifyContent: 'left'}}>
                                <span style={{margin: '0.5rem'}}>
                                    {t('estupefacienteField')}
                                </span>
                                </div>
                                <div style={{justifyContent: 'left', paddingLeft: '0.5rem', flexDirection: 'column'}}>
                                    {data.medicamentoDescriptionVidal?.estupefaciente ? t('yes') : t('no')}
                                </div>
                            </div>
                        </div>

                        <div style={{flexGrow: 5}}>
                            <div className='medicamento-vidal-item'>
                                <div className='header' style={{justifyContent: 'center'}}>
                                <span style={{margin: '0.5rem'}}>
                                    {t('psicotropicoField')}
                                </span>

                                </div>
                                <div style={{justifyContent: 'left', paddingLeft: '0.5rem', flexDirection: 'column'}}>
                                    {data.medicamentoDescriptionVidal?.psicotropico ? t('yes') : t('no')}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='content-2'>
                        <div style={{flexGrow: 1}}>
                            <div className='medicamento-vidal-item'>

                                <div className='header' style={{justifyContent: 'left'}}>
                                <span style={{margin: '0.5rem'}}>
                                    {t('enlacesDocumentacionClass')}
                                </span>
                                </div>
                                <div style={{flexDirection: 'column'}}>
                                    {data.medicamentoDescriptionVidal?.enlacesDocumentacion
                                        .map((e, i) =>
                                                <a href={e} style={{justifyContent: 'left', marginLeft: '0.5rem'}} target="_blank" key={i}>
                                                    {e}
                                        </a>,
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {getSimilarMeds(data.medicamentoDescriptionVidal?.medicamentosEquivalentes)}
                    <div className='content-2'>
                        <ul className='medicamento-vidal-image-container'>
                            {data.medicamentoDescriptionVidal?.imagenes
                                .map((e, i) => <li key={i}>
                                    <Image width={150} src={e}/>
                                </li>)
                            }
                        </ul>
                    </div>
                </div>
            </>
        );
    };


    useEffect(() => {
        let firstTabIcon = (<></>);
        let canClose = true;
        let tabTitleLength = props.medicamentoDescriptionVidal?.nombre.length;
        let tabTitle;
        if (tabTitleLength && tabTitleLength >= 25) {
            tabTitle = props.medicamentoDescriptionVidal?.nombre.substr(0, 25) + '…';
        } else {
            tabTitle = props.medicamentoDescriptionVidal?.nombre ? props.medicamentoDescriptionVidal?.nombre : '';
        }

        if (tabArray.length === 0) {
            firstTabIcon = (<FontAwesomeIcon
                icon={faEye}
                style={{marginRight: '6px'}}/>);
            canClose = false;
        }

        setTabArray([
            ...tabArray,
            tabItem({
                title: tabTitle, closable: canClose,
                tabIcon: firstTabIcon,
                key: props.tabKey,
                component:
                    MedicamentoDetailsTabContent(
                        {
                            onSelectVidalFromDetailsVidal: props.onSelectVidalFromDetailsVidal,
                            medicamentoDescriptionVidal: props.medicamentoDescriptionVidal,
                        },
                    ),
            }),
        ]);

    }, [props.medicamentoDescriptionVidal]);

    const handleTabChange = (selectedKey: string) => {
        props.onTabChange(selectedKey);
    };

    const onEditTab = (targetKey: any, action: any) => {
        if (action !== 'remove') {
            return;
        }

        //Simplified, the 1st tab always exists
        const idx = tabArray.findIndex(e => e.key === targetKey) - 1;
        const tmpTabArray = tabArray.filter(e => e.key != targetKey);

        setTabArray(tmpTabArray);
        props.onTabChange(tabArray[idx].key!.toString());
    };

    const MedTabs = () =>
        <Tabs activeKey={props.activeTabIndex.toString()} onChange={handleTabChange}
              hideAdd={true}
              onEdit={onEditTab}
              type='editable-card'>
            {tabArray.map(e => e)}
        </Tabs>;


    const getSimilarMeds = (sm?: IMedicamentosEquivalentes[]) => {
        if (!sm ||
            sm.length == 0) {
            return <></>;
        }

        const lines = sm
            .map(e => {
                return (
                    <div style={{marginLeft: '0.5rem'}}
                         key={e.idVademecum}
                         onClick={() => props.onSelectMedicine(e.idVademecum)}>
                        {e.nombre}
                    </div>
                );
            });

        return (
            <>
                <div className='medicamento-equivalente-header'>
                    <span style={{marginLeft: '0.5rem'}}>
                        {t('farmaciasEquivalentes')}
                    </span>
                </div>
                <div className='medicamento-equivalente-item'>
                    {lines}
                </div>
            </>
        );
    };

    const onCancel = () => {
        setTabArray([]);
        props.onCancel();
    };

    return (
        <Modal
            className='medicamento-vidal-modal'
            visible={props.visible}
            onCancel={onCancel}
            width={900}
            destroyOnClose
            footer={null}
        >
            {MedTabs()}
        </Modal>
    );
};


export default MedicamentoDetailsVidal;
